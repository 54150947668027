import React from 'react';
import { Label } from '@/components/ui/Label';
import { cn } from '@/lib/utils';

// Define the possible variants and sizes
type Variant = 'title' | 'heading' | 'label' | 'body' | 'overline';
type Size = 'l' | 'm' | 's' | 'xs';

// Define the props for the StyledText component
interface StyledTextProps {
  /** The text content to be displayed */
  value: string | number;
  /** The style variant of the text */
  variant?: Variant;
  /** The size of the text */
  size?: Size;
  /** The text is italic or not */
  isItalic?: boolean;
  /** Additional CSS classes to be applied */
  className?: string;
}

// Define the style mapping based on the provided table
const styleMap: Record<string, string> = {
  'heading/l': 'text-3xl leading-[40px] -tracking-[0.5px] font-bold font-lato',
  'heading/m': 'text-2xl leading-[32px] font-bold font-lato',
  'heading/s': 'text-xl leading-[28px] font-bold font-lato',
  'label/l': 'text-base leading-6 font-semibold font-lato',
  'label/m': 'text-sm leading-5 font-semibold font-lato',
  'label/s': 'text-xs leading-4 font-semibold font-lato',
  'body/l': 'text-base leading-6 font-normal font-lato',
  'body/m': 'text-sm leading-5 font-normal font-lato',
  'body/s': 'text-xs leading-4 font-normal font-lato',
  'overline/s': 'text-xs leading-4 tracking-[1px] font-semibold font-lato',
  'overline/xs':
    'text-[10px] leading-3 tracking-[0.8px] font-semibold font-lato',
};

/**
 * StyledText Component
 *
 * This component renders text with predefined styles based on the provided variant and size.
 * It uses Tailwind CSS classes for styling and the shadcn/ui Label component for consistent design.
 *
 * @component
 * @example
 * ```tsx
 * <Text variant="Heading" size="s" value='Heading Small'/>
 * ```
 */
export const Text: React.FC<StyledTextProps> = ({
  value,
  variant = 'Body',
  size = 'm',
  isItalic,
  className = '',
}) => {
  const key = `${variant}/${size}`;
  const baseStyle = styleMap[key] || '';

  // Handle special cases for italic Text
  const italicStyle = isItalic ? 'italic' : '';

  const combinedClassName = cn('mb-0', baseStyle, italicStyle, className);

  return <Label className={combinedClassName}>{value}</Label>;
};
