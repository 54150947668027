export const GET_ST_ITEM = 'GET_ST_ITEM';
export const DELETE_ST_ITEM = 'DELETE_ST_ITEM';
export const ACCEPT_ST_ITEM = 'ACCEPT_ST_ITEM';
export const UPDATE_ST_ITEM = 'UPDATE_ST_ITEM';
export const REJECT_ST_ITEM = 'REJECT_ST_ITEM';
export const ST_API_SUCCESS = 'ST_API_SUCCES';
export const ST_API_ERROR = 'ST_API_ERROR';
export const ST_API_PENDING = 'ST_API_PENDING';
export const SET_SITE_TRANSFER_LIST = 'SET_SITE_TRANSFER_LIST';
export const GET_SITE_TRANSFER_COUNT = 'GET_SITE_TRANSFER_COUNT';

export const ST_FORM_SUBMIT_SUCCESS = 'ST_FORM_SUBMIT_SUCCESS';
export const ST_FORM_SUBMIT_PENDING = 'ST_FORM_SUBMIT_PENDING';
export const ST_FORM_SUBMIT_ERROR = 'ST_FORM_SUBMIT_ERROR';

export const GET_INDENT_BOM_LIST_FOR_ST = 'GET_INDENT_BOM_LIST_FOR_ST';
export const GET_INDENT_BOM_LIST_FOR_ST_ERROR =
  'GET_INDENT_BOM_LIST_FOR_ST_ERROR';
export const GET_INDENT_BOM_LIST_FOR_ST_PENDING =
  'GET_INDENT_BOM_LIST_FOR_ST_PENDING';
export const RESET_INDENT_BOM_LIST_FOR_ST = 'RESET_INDENT_BOM_LIST_FOR_ST';
