// @ts-nocheck

import { KILO_BYTE, PRECISION_TYPE } from '../constants';

import Big from 'big.js';

export const trimDecimal = (num = 0, places = 2) => {
  return new Big(num).toFixed(places);
};

export const removeTrailingZeros = (str, precision = PRECISION_TYPE.MONEY) => {
  let numberStr = '';
  if (typeof str === 'number' && !Number.isNaN(str)) {
    numberStr = str.toString();
  } else {
    numberStr = str;
  }
  const subString = numberStr.split('.'.padEnd(precision + 1, '0'))[0];

  return subString;
};

export const add = (addendA, addendB, fixedTill = 2) => {
  let sum = 0;
  try {
    sum = new Big(Number(addendA) || 0)
      .plus(new Big(Number(addendB) || 0))
      .toFixed(fixedTill);
  } catch (err) {
    return removeTrailingZeros(sum, fixedTill);
  }

  return removeTrailingZeros(sum, fixedTill);
};

export const minus = (minuend, subtrahend, fixedTill = 2) => {
  let result = 0;
  try {
    result = new Big(Number(minuend) || 0)
      .minus(new Big(Number(subtrahend) || 0))
      .toFixed(fixedTill);
  } catch (err) {
    return removeTrailingZeros(result, fixedTill);
  }

  return removeTrailingZeros(result, fixedTill);
};

export const multiply = (multiplicand, multiplier) => {
  let result = 0;
  try {
    result = new Big(multiplicand).times(new Big(multiplier)).toFixed(2);
  } catch (err) {
    return removeTrailingZeros(result);
  }

  return removeTrailingZeros(result);
};

export const divide = (dividend, divisor) => {
  let result = 0;
  try {
    result = new Big(dividend).div(new Big(divisor)).toFixed(2);
  } catch (err) {
    return removeTrailingZeros(result);
  }

  return removeTrailingZeros(result);
};

export const mod = (x, y) => {
  let result = 0;
  try {
    result = new Big(x).mod(new Big(y)).toFixed(2);
  } catch (err) {
    return removeTrailingZeros(result);
  }

  return removeTrailingZeros(result);
};

export const abs = x => {
  let result = 0;
  try {
    result = new Big(x).abs().toFixed(2);
  } catch (err) {
    return removeTrailingZeros(result);
  }

  return removeTrailingZeros(result);
};

export const sum = (...args) => {
  const argsArr = Array.from(...args);
  let sum = new Big(0);
  try {
    for (const arg of argsArr) {
      sum = new Big(arg).plus(sum);
    }
  } catch (err) {
    return 0;
  }

  return removeTrailingZeros(sum.toFixed(2));
};

export const percent = (dividend, divisor) => {
  let result = 0;
  try {
    result = new Big(dividend).div(new Big(divisor)).times(100).toFixed(2);
  } catch (err) {
    return removeTrailingZeros(result);
  }

  return removeTrailingZeros(result);
};

export const equal = (paramA, paramB) => {
  let isEqual = false;
  try {
    isEqual = new Big(paramA).eq(new Big(paramB));
  } catch (err) {
    return isEqual;
  }

  return isEqual;
};

export const lessThan = (paramA, paramB) => {
  let isLT = false;
  try {
    isLT = new Big(paramA).lt(new Big(paramB));
  } catch (err) {
    return isLT;
  }

  return isLT;
};

export const greaterThan = (paramA, paramB) => {
  let isGT = false;
  try {
    isGT = new Big(paramA).gt(new Big(paramB));
  } catch (err) {
    return isGT;
  }

  return isGT;
};

export const lessThanEqual = (paramA, paramB) => {
  let isLTE = false;
  try {
    isLTE = new Big(paramA).lte(new Big(paramB));
  } catch (err) {
    return isLTE;
  }
};

export const greaterThanEqual = (paramA, paramB) => {
  let isGTE = false;
  try {
    isGTE = new Big(paramA).gte(new Big(paramB));
  } catch (err) {
    return isGTE;
  }
};

export const toFixed = (value, decimalDigit = 2) => {
  return new Big(value).toFixed(decimalDigit);
};

export const toNumber = (numStr, precision = 2) => {
  try {
    const result = new Big(numStr).toFixed(precision);
    return new Big(result).toNumber();
  } catch {
    return 0;
  }
};
// function to handle Precision in Decimal Value

export const convertToPreciseNumber = (numStr, precision = 0) => {
  if (!precision) return numStr;

  if (!numStr || isNaN(numStr)) {
    return 0;
  }

  numStr = String(numStr).trim();

  numStr = numStr.replace(/^0+(?!\.|$)/, '');

  try {
    if (numStr.includes('.')) {
      let [integerPart, decimalPart] = numStr.split('.');
      if (decimalPart.length > precision) {
        decimalPart = decimalPart.slice(0, precision);
      }

      numStr = `${integerPart}.${decimalPart}`;
    }
    return numStr;
  } catch {
    return '0';
  }
};

export function clamp(number, min, max) {
  return Math.max(min, Math.min(number, max));
}

export function formatSize(size) {
  if (!Number.isFinite(size)) return 'Unknown size';

  let i = Math.floor(Math.log(size) / Math.log(KILO_BYTE));
  if (!Number.isFinite(i)) {
    i = 1;
  }

  return `${(size / Math.pow(KILO_BYTE, i)).toFixed(2) * 1} ${
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  }`;
}

export function formatDecimal(num, limit = 2) {
  return toNumber(num, limit);
}

export const roundToNearestInteger = (value = 0) => {
  try {
    const safeValue = value == null ? 0 : value;

    return new Big(safeValue).round().toString();
  } catch {
    return '0';
  }
};
