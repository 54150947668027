import { useLocalStorage } from '@/utils/hooks';
import { postRequest } from '.';
import { API_VERSIONS, USER_APIS } from '@/routes.constant';
import { LOCAL_STORAGE_TOKEN_KEY } from '@/constants';
import { setLocalStorage } from '@/redux/base';
import { logout } from '@/redux/features/common/user';
import store from '@/redux/Store';
import { captureException, captureMessage } from '@sentry/react';

const { REFRESH_TOKEN } = LOCAL_STORAGE_TOKEN_KEY;

let isFetchingAuthToken = false;
let resolver: (value: unknown) => void;
const tokenPromise = new Promise(resolve => {
  resolver = resolve;
});

interface RefreshTokenResponse {
  auth_token: string;
  auth_token_expiry: number;
  refresh_token: string;
  refresh_token_expiry: number;
}

const getToken = async () => {
  const { getLocalStorageValue } = useLocalStorage();
  let data: { data: RefreshTokenResponse };
  try {
    try {
      data = await postRequest<RefreshTokenResponse>(
        USER_APIS.refreshToken,
        { refresh_token: getLocalStorageValue(REFRESH_TOKEN, false) },
        API_VERSIONS.V1
      );
    } catch (error) {
      captureException(error);
      captureMessage(
        `[Logout] ERROR get auth token from refresh token : ${
          error?.toString?.() || String(error) || 'Unknown Error'
        }`
      );
    }
    try {
      resolver(data.data?.auth_token);
    } catch (error) {
      captureException(error);
      captureMessage(
        `[Logout] ERROR resolve auth token : ${
          error?.toString?.() || String(error) || 'Unknown Error'
        }`
      );
    }
    try {
      setLocalStorage({
        token: data.data?.auth_token,
        tokenExpiry: data.data?.auth_token_expiry,
        refreshToken: data.data?.refresh_token,
      });
    } catch (error) {
      captureException(error);
      captureMessage(
        `[Logout] ERROR set auth token to local storage : ${
          error?.toString?.() || String(error) || 'Unknown Error'
        }`
      );
    }
    isFetchingAuthToken = false;
  } catch (error) {
    captureException(error);
    captureMessage(
      `[Logout] ERROR Fetch Refresh Token: ${
        error?.toString?.() || String(error) || 'Unknown Error'
      }`
    );
    store.dispatch(logout());
  }
};

const fetchAuthTokenFromRefreshToken = async () => {
  if (!isFetchingAuthToken) {
    isFetchingAuthToken = true;
    getToken();
  }
  return tokenPromise;
};

export default fetchAuthTokenFromRefreshToken;
