import {
  ARCHIVE_GRN_PENDING,
  ARCHIVE_GRN_SEQ,
  GRN_SEQ_PENDING,
  CREATE_GRN_SEQ,
  GET_ACTIVE_GRN_SEQ,
  GET_ALL_GRN_SEQ,
  GET_ARCHIVED_GRN_SEQ,
  GET_GRN_SEQ_PROJECT,
  RESTORE_GRN_SEQ,
  UPDATE_ACTIVE_GRN_SEQ,
  UPDATE_ALL_GRN_SEQ,
  UPDATE_ARCHIVED_GRN_SEQ,
  UPDATE_GRN_SEQ,
  GET_GRN_SEQ,
  GRN_SEQ_API_ERROR,
} from './GRNSequence.type';

const pendingInitialStae = {
  GRNSeqPending: false,
  archiveGRNSeqPending: false,
};

const initialState = {
  ...pendingInitialStae,
  allGRNSeq: [],
  archivedGRNSeq: [],
  activeGRNSeq: [],
  projectGRNSeq: [],
  createGRNSeq: null,
  selectedGRNSeq: null,
  addedGRNSeq: null,
  GRNSeq: null,
  archiveGRNSeqResp: null,
  restoreGRNSeq: null,
  updateGRNSeq: null,
  updateSTAllSeq: null,
  updateSTArchivedSeq: null,
  updateSTActiveSeq: null,
  error: null,
};

export default function grnSequenceReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case GRN_SEQ_PENDING:
      return { ...state, GRNSeqPending: payload };
    case CREATE_GRN_SEQ:
      return { ...state, createGRNSeq: payload };
    case GET_ALL_GRN_SEQ:
      return { ...state, allGRNSeq: payload };
    case GET_ACTIVE_GRN_SEQ:
      return { ...state, activeGRNSeq: payload };
    case GET_ARCHIVED_GRN_SEQ:
      return { ...state, archivedGRNSeq: payload };
    case GET_GRN_SEQ_PROJECT:
      return { ...state, projectGRNSeq: payload };
    case GET_GRN_SEQ:
      return { ...state, GRNSeq: payload };

    case UPDATE_GRN_SEQ:
      return { ...state, updateGRNSeq: payload };
    case UPDATE_ALL_GRN_SEQ:
      return { ...state, updateAllGRNSeq: payload };
    case UPDATE_ARCHIVED_GRN_SEQ:
      return { ...state, updateArchivedGRNSeq: payload };
    case UPDATE_ACTIVE_GRN_SEQ:
      return { ...state, updateActiveGRNSeq: payload };
    case ARCHIVE_GRN_PENDING:
      return { ...state, archiveGRNSeqPending: payload };
    case ARCHIVE_GRN_SEQ:
      return { ...state, archiveGRNSeqResp: payload };
    case RESTORE_GRN_SEQ:
      return { ...state, restoreGRNSeq: payload };

    case GRN_SEQ_API_ERROR:
      return { ...state, error: error };
    default:
      return state;
  }
}
