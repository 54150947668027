import { Dispatch } from 'redux';

import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../../apiClient';
import {
  ADD_VENDOR_ITEM,
  GET_VENDOR_ITEM,
  GET_VENDOR_ITEMS,
  UPDATE_VENDOR_ITEM,
  DELETE_VENDOR_ITEM,
  VENDOR_API_SUCCESS,
  VENDOR_API_ERROR,
  VENDOR_API_PENDING,
  VENDOR_ITEM_ADDED,
  GET_VENDOR_ITEMS_PENDING,
  GET_VENDOR_ITEM_PENDING,
  SET_VENDOR_ITEMS,
  SET_VENDOR_ITEMS_PAGINATION_DATA,
  ALL_PAYABLE_DATA,
  VENDOR_DELETE_API_PENDING,
  VENDOR_DELETE_API_SUCCESS,
  VENDOR_DELETE_API_ERROR,
  VENDOR_PROJECTS_API_PENDING,
  SET_VENDOR_PROJECTS,
  VENDOR_PROJECTS_API_SUCCESS,
  SET_VENDOR_PROJECTS_COUNT,
  SET_ORG_VENDOR_SELECTION_LIST_COUNT,
  SET_ORG_VENDOR_SELECTION_LIST,
  SET_VENDOR_PRICING_HISTORY,
  SET_VENDOR_PRICING_HISTORY_COUNT,
  SET_VENDOR_WITH_SAME_NAME_EXISTS_FLAG,
  VENDOR_PROJECTS_API_ERROR,
  VENDOR_CREATE_API_PENDING,
  VENDOR_CREATE_API_SUCCESS,
  VENDOR_CREATE_API_ERROR,
  VENDOR_EDIT_API_PENDING,
  VENDOR_EDIT_API_SUCCESS,
  VENDOR_EDIT_API_ERROR,
} from './Vendor.type';
import {
  API_VERSIONS,
  CUSTOM_INFO_APIS,
  VENDOR_APIS,
} from '../../../routes.constant';
import {
  dismissToast,
  parseString,
  toastErrorMessage,
  toastLoadingMessage,
  toastSuccessMessage,
} from '../../../utils';
import { eventDispatch, errorDispatch } from '../../base';
import {
  AddVendorPaymentProps,
  getVendorListRequestParams,
  VendorProps,
} from '../../../interfaces/Vendor';
import {
  SYNC_ATTENDANCE_PROJECT_PROFILE_VIEW,
  SYNC_MVP_CHILDREN,
  SYNC_ORG_VENDOR_SELECTION_VIEW,
  SYNC_VENDOR_ORG_PROJECT_LIST,
  SYNC_VENDOR_PROFILE_VIEW,
  SYNC_VENDOR_PROJECTS_VIEW,
} from '../common/refresh/Refresh.type';
import { customStringify } from '../../../utils/Url.util';
import {
  ICreateVendorProps,
  ICreateVendorWithProjectMappingProps,
  IGetItemExistsInOrgProps,
  IGetOrgVendorSelectionList,
  IGetOrgVendorSelectionListCount,
  IGetOrgVendorSelectionListCountParams,
  IGetOrgVendorSelectionListParams,
  IGetVendorProjectsCount,
  IGetVendorProjectsCountResponse,
  IGetVendorProjectsProps,
  IItemExistsWithSameNameResponse,
  ILogExistsOnVendorDeleteResponse,
  IManageVendorProjects,
  IUpdateVendorProps,
} from '../../../modules/vendor/types/models/Vendor.model';
import {
  IGetVendorPricingHistoryCountProps,
  IGetVendorPricingHistoryProps,
  IVendorPricingHistoryCountReponse,
  IVendorPricingHistoryReponse,
} from '../../../modules/vendor/types/models/PricingHistory.model';
import { IVendorProjects } from '../../../modules/vendor/types/interface/Vendor.interface';

export function getVendorById(vendorId: string) {
  const apiUrl = parseString(VENDOR_APIS.getVendorById, vendorId);
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(VENDOR_API_PENDING));
    dispatch(eventDispatch(GET_VENDOR_ITEM_PENDING, true));
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(VENDOR_API_SUCCESS));
      dispatch(eventDispatch(GET_VENDOR_ITEM, data));
    } catch (error) {
      dispatch(errorDispatch(VENDOR_API_ERROR, error));
    } finally {
      dispatch(eventDispatch(GET_VENDOR_ITEM_PENDING, false));
    }
  };
}

export function vendorFormAddedItemState(state: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(VENDOR_API_PENDING));
    try {
      dispatch(eventDispatch(VENDOR_API_SUCCESS));
      dispatch(eventDispatch(VENDOR_ITEM_ADDED, state));
    } catch (error) {
      dispatch(errorDispatch(VENDOR_API_ERROR, error));
    }
  };
}

export function getVendorListRequest(params: getVendorListRequestParams) {
  const apiUrl = parseString(
    VENDOR_APIS.getVendorListRequest,
    customStringify(params)
  );
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(VENDOR_API_PENDING));
    dispatch(eventDispatch(GET_VENDOR_ITEM_PENDING, true));
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(VENDOR_API_SUCCESS));
      dispatch(eventDispatch(GET_VENDOR_ITEMS_PENDING, false));
      dispatch(eventDispatch(GET_VENDOR_ITEMS, data));
    } catch (error) {
      dispatch(errorDispatch(VENDOR_API_ERROR, error));
    } finally {
      dispatch(eventDispatch(GET_VENDOR_ITEM_PENDING, false));
    }
  };
}

export function createVendor(reqBody: VendorProps, successCb?: Function) {
  return async (dispatch: Dispatch<any>) => {
    const toastId = toastLoadingMessage('Creating new Vendor Item');
    try {
      const { data } = await postRequest(
        VENDOR_APIS.createVendor,
        reqBody,
        API_VERSIONS.V1
      );
      dispatch(eventDispatch(VENDOR_API_SUCCESS));
      toastSuccessMessage(`${reqBody.name} created successfully`, toastId);
      dispatch(eventDispatch(ADD_VENDOR_ITEM, data));
      dispatch(eventDispatch(GET_VENDOR_ITEM, data));
      dispatch(vendorFormAddedItemState(true));
      if (successCb && typeof successCb == 'function') {
        successCb(data);
      }
      if (reqBody.org_id) {
        dispatch(
          getVendorListRequest({
            org_id: reqBody.org_id,
            sort_option: '-createdAt',
          })
        );
      }
    } catch (error) {
      dispatch(errorDispatch(VENDOR_API_ERROR, error));
      toastErrorMessage(`Error while creating ${reqBody.name}`, toastId);
    }
  };
}

export function updateVendor(
  vendorId: string,
  reqBody: VendorProps,
  getVendorFlag = true
) {
  const apiUrl = parseString(VENDOR_APIS.updateVendor, vendorId);
  return async (dispatch: Dispatch<any>) => {
    const toastId = toastLoadingMessage(`Updating ${reqBody.name}`);
    try {
      const { data } = await putRequest(apiUrl, reqBody, API_VERSIONS.V1);
      dispatch(eventDispatch(VENDOR_API_SUCCESS));
      toastSuccessMessage(`${reqBody.name} updated successfully`, toastId);
      dispatch(eventDispatch(UPDATE_VENDOR_ITEM, data));
      dispatch(vendorFormAddedItemState(true));
      if (reqBody.org_id && getVendorFlag) {
        dispatch(
          getVendorListRequest({
            org_id: reqBody.org_id,
            sort_option: '-createdAt',
          })
        );
      }
    } catch (error) {
      dispatch(errorDispatch(VENDOR_API_ERROR, error));
      toastErrorMessage(`Cannot update ${reqBody.name}`, toastId);
    }
  };
}

export function updateCredits(
  vendorId: string,
  reqBody: { vendor_credits: number; name: string }
) {
  const apiUrl = parseString(VENDOR_APIS.updateVendorCredits, vendorId);
  return async (dispatch: Dispatch<any>) => {
    const toastId = toastLoadingMessage(
      `Updating vendor credits of ${reqBody.name}`
    );
    try {
      await putRequest(apiUrl, reqBody, API_VERSIONS.V1);
      dispatch(getVendorById(vendorId));
      toastSuccessMessage(
        `Successfully updated Vendor credits of ${reqBody.name}`,
        toastId
      );
    } catch {
      toastErrorMessage(
        `Cannot update Vendor credits of ${reqBody.name}`,
        toastId
      );
    }
  };
}

export function deleteVendor(
  vendorId: string,
  vendorName: string,
  orgId: string
) {
  const apiUrl = parseString(VENDOR_APIS.deleteVendor, vendorId);
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(VENDOR_API_PENDING));
    const toastId = toastLoadingMessage(`Deleting ${vendorName}`);
    try {
      const { data } = await deleteRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(VENDOR_API_SUCCESS));
      dispatch(
        getVendorListRequest({
          org_id: orgId,
          sort_option: '-createdAt',
        })
      );
      dispatch(eventDispatch(DELETE_VENDOR_ITEM, data));
      toastSuccessMessage(`Successfully deleted ${vendorName}`, toastId);
    } catch (error) {
      toastErrorMessage(
        `Cannot Delete ${vendorName}: ${error?.errorMsg || ''}`,
        toastId
      );
      dispatch(errorDispatch(VENDOR_API_ERROR, error));
    }
  };
}

export function getVendorsByAmount({ page, limit, orgId, filters }) {
  const apiUrl = parseString(
    VENDOR_APIS.getVendorsByAmount,
    orgId,
    customStringify({ page, limit, ...filters })
  );

  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(VENDOR_API_PENDING));
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(VENDOR_API_SUCCESS));

      dispatch(eventDispatch(SET_VENDOR_ITEMS, data));
    } catch (error) {
      dispatch(errorDispatch(VENDOR_API_ERROR, error));
    }
  };
}

export function getVendorsByAmountPaginationData({ limit, filters, orgId }) {
  const apiUrl = parseString(
    VENDOR_APIS.getVendorsByAmountPaginationData,
    orgId,
    customStringify({ limit, ...filters })
  );

  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(VENDOR_API_PENDING));
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(VENDOR_API_SUCCESS));
      dispatch(eventDispatch(SET_VENDOR_ITEMS_PAGINATION_DATA, data));
    } catch (error) {
      dispatch(errorDispatch(VENDOR_API_ERROR, error));
    }
  };
}

export function getAllPayableData({ filters, orgId }) {
  const apiUrl = parseString(
    VENDOR_APIS.getAllPayableData,
    orgId,
    customStringify({ ...filters })
  );

  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(VENDOR_API_PENDING));
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(VENDOR_API_SUCCESS));
      dispatch(eventDispatch(ALL_PAYABLE_DATA, data));
    } catch (error) {
      dispatch(errorDispatch(VENDOR_API_ERROR, error));
    }
  };
}

export function addVendorPayment(
  reqBody: AddVendorPaymentProps,
  cb?: () => void
) {
  const apiUrl = VENDOR_APIS.addPaymentForInvoices;

  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(VENDOR_API_PENDING));
    try {
      await postRequest(apiUrl, reqBody, API_VERSIONS.V1);
      dispatch(eventDispatch(VENDOR_API_SUCCESS));
      dispatch(eventDispatch(SYNC_MVP_CHILDREN, true));
      toastSuccessMessage('Payment done');
    } catch (error) {
      dispatch(errorDispatch(VENDOR_API_ERROR, error));
      toastErrorMessage(error?.response?.data?.message || 'Error!');
    } finally {
      cb?.();
    }
  };
}

export function deleteVendorV2(
  vendorId: string,
  orgId: string,
  handlePostDelete: Function
) {
  const apiUrl = parseString(
    VENDOR_APIS.deleteVendorV2,
    vendorId,
    customStringify({ org_id: orgId })
  );
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(VENDOR_DELETE_API_PENDING));
    const toastId = toastLoadingMessage(`Deleting Vendor`);
    try {
      const { data }: { data: ILogExistsOnVendorDeleteResponse } =
        await deleteRequest(apiUrl, API_VERSIONS.V2);

      dispatch(eventDispatch(VENDOR_DELETE_API_SUCCESS));

      handlePostDelete(data);

      if (data?.log_exists) {
        dismissToast(toastId);
      } else {
        toastSuccessMessage('Successfully deleted', toastId);
      }
    } catch (error) {
      toastErrorMessage(error.errorMsg || 'Error in deleting vendor', toastId);
      dispatch(eventDispatch(VENDOR_DELETE_API_ERROR));
    }
  };
}

export function getVendorProjects(params: IGetVendorProjectsProps) {
  const { vendorId, ...restParams } = params;
  const apiUrl = parseString(
    VENDOR_APIS.getVendorProjects,
    vendorId,
    customStringify({ ...restParams })
  );

  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(VENDOR_PROJECTS_API_PENDING));
    try {
      const { data }: { data: IVendorProjects[] } = await getRequest(apiUrl);
      dispatch(eventDispatch(VENDOR_PROJECTS_API_SUCCESS));
      dispatch(eventDispatch(SET_VENDOR_PROJECTS, data));
    } catch (error) {
      dispatch(eventDispatch(VENDOR_PROJECTS_API_ERROR));
      toastErrorMessage(
        error?.errorMsg || 'Error in fetching vendor projects!'
      );
    }
  };
}

export function getVendorProjectsCount(params: IGetVendorProjectsCount) {
  const { vendorId, ...restParams } = params;
  const apiUrl = parseString(
    VENDOR_APIS.getVendorProjectsCount,
    vendorId,
    customStringify({ ...restParams })
  );

  return async (dispatch: Dispatch<any>) => {
    try {
      const { data }: { data: IGetVendorProjectsCountResponse } =
        await getRequest(apiUrl);

      dispatch(eventDispatch(SET_VENDOR_PROJECTS_COUNT, data));
    } catch (error) {
      toastErrorMessage(
        error?.errorMsg || 'Error in fetching vendor projects count!'
      );
    }
  };
}

export function updateVendorProjects(
  vendorId: string,
  reqBody: IManageVendorProjects,
  successCb: Function = () => {}
) {
  const apiUrl = parseString(VENDOR_APIS.manageVendorProjects, vendorId);

  return async (dispatch: Dispatch<any>) => {
    try {
      await putRequest(apiUrl, reqBody);
      dispatch(eventDispatch(SYNC_VENDOR_PROJECTS_VIEW, true));
      dispatch(eventDispatch(SYNC_ATTENDANCE_PROJECT_PROFILE_VIEW, true));
      dispatch(eventDispatch(SYNC_VENDOR_PROFILE_VIEW, true));
      dispatch(eventDispatch(SYNC_VENDOR_ORG_PROJECT_LIST, true));
      toastSuccessMessage('Successfully updated Vendor Projects');
      successCb();
    } catch (error) {
      toastErrorMessage(error?.errorMsg || 'Error in editing vendor projects!');
    }
  };
}

export function createVendorV5(
  reqBody: ICreateVendorProps,
  successCb: Function
) {
  const apiUrl = VENDOR_APIS.createVendor;

  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(eventDispatch(VENDOR_CREATE_API_PENDING));
      const { data }: { data: VendorProps } = await postRequest(
        apiUrl,
        reqBody,
        API_VERSIONS.V5
      );
      dispatch(eventDispatch(VENDOR_CREATE_API_SUCCESS));
      dispatch(eventDispatch(SYNC_ORG_VENDOR_SELECTION_VIEW, true));
      toastSuccessMessage('Successfully Created Vendor');
      successCb(data);
    } catch (error) {
      dispatch(eventDispatch(VENDOR_CREATE_API_ERROR));
      toastErrorMessage(error?.errorMsg || 'Error in Creating vendor!');
    }
  };
}

export function createVendorCustomInfo(reqBody, successCb, errorCb) {
  const apiUrl = VENDOR_APIS.createCustomInfo;
  return async () => {
    try {
      await postRequest(apiUrl, reqBody);
      toastSuccessMessage('Successfully Added Custom Information');
      successCb();
    } catch (error) {
      toastErrorMessage(
        error?.errorMsg || 'Error in Creating Custom Information!'
      );
      errorCb && errorCb();
    }
  };
}

export function editCustomInfo(id, orgId, reqBody, successCb, errorCb) {
  const apiUrl = parseString(CUSTOM_INFO_APIS.editCustomInfo, id, orgId);
  return async () => {
    try {
      await putRequest(apiUrl, reqBody);
      toastSuccessMessage('Successfully Edited Custom Information');
      successCb();
    } catch (error) {
      toastErrorMessage(
        error?.errorMsg || 'Error in Editing Custom Information!'
      );
      errorCb && errorCb();
    }
  };
}
export function deleteCustomInfo(id, orgId, successCb) {
  const apiUrl = parseString(CUSTOM_INFO_APIS.editCustomInfo, id, orgId);
  return async () => {
    try {
      await deleteRequest(apiUrl);
      toastSuccessMessage('Successfully Deleted Custom Information');
      successCb();
    } catch (error) {
      toastErrorMessage(
        error?.errorMsg || 'Error in Deleting Custom Information!'
      );
    }
  };
}

export function createVendorWithProjectMapping(
  reqBody: ICreateVendorWithProjectMappingProps,
  successCb: Function
) {
  const apiUrl = VENDOR_APIS.createVendorWithProjectMapping;

  return async (dispatch: Dispatch<any>) => {
    try {
      await postRequest(apiUrl, reqBody);
      dispatch(eventDispatch(SYNC_ORG_VENDOR_SELECTION_VIEW, true));
      toastSuccessMessage('Successfully Created Vendor');
      successCb();
    } catch (error) {
      toastErrorMessage(error?.errorMsg || 'Error in Creating vendor');
    }
  };
}

export function updateVendorV2(
  vendorId: string,
  reqBody: IUpdateVendorProps,
  successCb: Function
) {
  const apiUrl = parseString(VENDOR_APIS.updateVendor, vendorId);
  return async (dispatch: Dispatch<any>) => {
    const toastId = toastLoadingMessage(`Updating ${reqBody.name}`);
    try {
      dispatch(eventDispatch(VENDOR_EDIT_API_PENDING));
      await putRequest(apiUrl, reqBody, API_VERSIONS.V2);
      dispatch(eventDispatch(VENDOR_EDIT_API_SUCCESS));
      successCb();
      toastSuccessMessage(`${reqBody.name} updated successfully`, toastId);
    } catch (error) {
      dispatch(eventDispatch(VENDOR_EDIT_API_ERROR));
      toastErrorMessage(
        error?.errorMsg || `Cannot update ${reqBody.name}`,
        toastId
      );
    }
  };
}

export function getOrgVendorSelectionListCount(
  params: IGetOrgVendorSelectionListCountParams
) {
  const apiUrl = parseString(
    VENDOR_APIS.getOrgVendorSelectionListCount,
    customStringify({ ...params })
  );

  return async (dispatch: Dispatch<any>) => {
    try {
      const { data }: { data: IGetOrgVendorSelectionListCount } =
        await getRequest(apiUrl);

      dispatch(eventDispatch(SET_ORG_VENDOR_SELECTION_LIST_COUNT, data));
    } catch (error) {
      toastErrorMessage(
        error?.errorMsg || 'Error in fetching Org vendors count!'
      );
    }
  };
}

export function getOrgVendorSelectionList(
  params: IGetOrgVendorSelectionListParams
) {
  const apiUrl = parseString(
    VENDOR_APIS.getVendorListRequest,
    customStringify({ ...params })
  );

  return async (dispatch: Dispatch<any>) => {
    try {
      const { data }: { data: IGetOrgVendorSelectionList } = await getRequest(
        apiUrl,
        API_VERSIONS.V2
      );

      dispatch(
        eventDispatch(SET_ORG_VENDOR_SELECTION_LIST, data?.vendors || [])
      );
    } catch (error) {
      toastErrorMessage(error?.errorMsg || 'Error in fetching Org vendors!');
    }
  };
}

export function getVendorPricingHistory(params: IGetVendorPricingHistoryProps) {
  const { dFilter, vendorId, ...restParams } = params;
  const stringifiedDFilter = JSON.stringify(dFilter || {});

  const apiUrl = parseString(
    VENDOR_APIS.getVendorPricingHistory,
    vendorId,
    customStringify({ ...restParams, dFilter: stringifiedDFilter })
  );

  return async (dispatch: Dispatch<any>) => {
    try {
      const { data }: { data: IVendorPricingHistoryReponse } = await getRequest(
        apiUrl
      );

      dispatch(eventDispatch(SET_VENDOR_PRICING_HISTORY, data.history || []));
    } catch (error) {
      toastErrorMessage(
        error?.errorMsg || 'Error in fetching vendor pricing history!'
      );
    }
  };
}

export function getVendorPricingHistoryCount(
  params: IGetVendorPricingHistoryCountProps
) {
  const { dFilter, vendorId, ...restParams } = params;
  const stringifiedDFilter = JSON.stringify(dFilter || {});

  const apiUrl = parseString(
    VENDOR_APIS.getVendorPricingHistoryCount,
    vendorId,
    customStringify({ ...restParams, dFilter: stringifiedDFilter })
  );

  return async (dispatch: Dispatch<any>) => {
    try {
      const { data }: { data: IVendorPricingHistoryCountReponse } =
        await getRequest(apiUrl);

      dispatch(
        eventDispatch(SET_VENDOR_PRICING_HISTORY_COUNT, data?.history || 0)
      );
    } catch (error) {
      toastErrorMessage(
        error?.errorMsg || 'Error in fetching vendor pricing history count!'
      );
    }
  };
}

export function getItemExistsInOrg(params: IGetItemExistsInOrgProps) {
  const apiUrl = parseString(
    VENDOR_APIS.checkItemExists,
    customStringify({ ...params })
  );

  return async (dispatch: Dispatch<any>) => {
    try {
      const { data }: { data: IItemExistsWithSameNameResponse } =
        await getRequest(apiUrl);
      dispatch(
        eventDispatch(
          SET_VENDOR_WITH_SAME_NAME_EXISTS_FLAG,
          data?.item_exists || false
        )
      );
    } catch (error) {
      toastErrorMessage(error?.errorMsg || 'Error!');
    }
  };
}
