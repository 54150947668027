import { Dispatch } from 'redux';
import {
  LOGIN,
  GET_USER,
  GET_USER_PENDING,
  LOGIN_CHECK,
  LOGOUT,
  REQ_SUPPORT_CALL_SUCCESS,
  UPDATE_USER_DETAILS,
  USER_API_PENDING,
  USER_API_SUCCESS,
  AUTH_VIEW_INDEX_CHANGE,
  USER_API_ERROR,
  SEND_USER_OTP,
  SEND_USER_OTP_PENDING,
  VERIFY_OTP,
  VERIFY_OTP_PENDING,
  EDIT_USER_DISPLAYNAME_PENDING,
  GET_USER_ORGS_PENDING,
  GET_USER_ORGS,
  OTP_ERROR,
  VERIFY_USER_EMAIL_PENDING,
  SET_TOKEN_EXPIRY,
  GET_SELF_PERMISSION,
  GET_SIGNATURE,
  SET_COUNTRIES,
} from './User.type';
import apiClient from '../../../../utils/ApiClient.util';
import { MISC_APIS, USER_APIS } from '../../../../routes.constant';
import {
  toastSuccessMessage,
  toastErrorMessage,
} from '../../../../utils/Toast.util';
import { clearDocState } from '../document/Document.action';
import { identify, track } from '../Segment.action';
import { USER_SEGMENT } from '../../../../constant/segment_constant';
import UserProps, {
  GetUserOrgParamsProps,
  UpdateUserGuideProps,
  RequestSupportCallProps,
  SendOtpProps,
  VerifyOtpProps,
  EditDisplayNameProps,
  VerifyEmailParams,
} from '../../../../interfaces/User';
import { parseString } from '../../../../utils/Common.util';
import { BAD_REQUEST } from '../../../../constant/status_constants';
import { eventDispatch, setLocalStorage } from '../../../base';
import { ACTION_EVENTS, AUTH_EVENTS } from '../../../../constant';
import { RootState } from '../../../Reducers';
import { DATE_FORMATS, US_DATE_FORMATS } from '../../../../constants';
import { getRequest, postRequest } from '../../../../apiClient';
import { customStringify } from '../../../../utils/Url.util';
import { removeAllCookies, setCookie } from '../../../../utils/Cookies.util';
import { IndexedDBService } from '../../../../providers/indexedDB';
import LocalStorageUtil from '../../../../utils/LocalStorage.util';
import useSessionStorage from '../../../../utils/hooks/useSessionStorage.hook';

function apiError(error: any) {
  const err: any = { type: USER_API_ERROR };
  if (error.response) {
    err.error = {
      data: error.response.data,
      status: error.response.status,
      headers: error.response.headers,
    };
  } else if (error.request) {
    err.error = {
      data: error.request,
    };
  } else {
    err.error = {
      data: error,
    };
  }

  return err;
}

export function updateUserDetails(
  reqBody: UserProps,
  ifReload: boolean = false,
  cb?: any
) {
  return async (dispatch: Dispatch<any>) => {
    try {
      const { data } = await apiClient.put(
        USER_APIS.updateUserDetails,
        reqBody
      );
      if (data) {
        const { token, ...restData } = data;
        const role = data.email === 'master@master.com' ? 'master' : 'common';
        dispatch(
          eventDispatch(UPDATE_USER_DETAILS, {
            ...restData,
            updateUserDetailsSuccess: true,
          })
        );
        dispatch(clearDocState());
        setLocalStorage({ role, token: token, exp: data.exp });
        if (ifReload) {
          cb?.();
          window.location.reload();
        } else {
          toastSuccessMessage('User details updated successfully');
        }
      }
    } catch (err) {
      dispatch(apiError(err));
      dispatch(
        eventDispatch(UPDATE_USER_DETAILS, { updateUserDetailsErr: true })
      );
      if (cb && typeof cb === 'function') {
        cb();
      }
    }
  };
}

export function updateUserGuide(reqBody: UpdateUserGuideProps) {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await apiClient.put(USER_APIS.updateGuideInfo, reqBody);
      dispatch(eventDispatch(USER_API_SUCCESS, data));
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };
}

export function getUserOrgs(params: GetUserOrgParamsProps) {
  const apiUrl = parseString(USER_APIS.getUserOrgs, customStringify(params));

  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(GET_USER_ORGS_PENDING, true));
    try {
      const { data } = await apiClient.get(apiUrl);
      dispatch(eventDispatch(GET_USER_ORGS_PENDING, true));
      dispatch(eventDispatch(GET_USER_ORGS, data));
    } catch (err) {
      dispatch(eventDispatch(GET_USER_ORGS_PENDING, false));
    }
  };
}

export function getUser(history?: any) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(GET_USER_PENDING, true));
    try {
      const { data } = await apiClient.get(USER_APIS.userSelf);
      data.DATE_FORMATS = {
        '+91': DATE_FORMATS,
        '+1': US_DATE_FORMATS,
      }[data.country_code];
      dispatch(eventDispatch(GET_USER_PENDING, false));
      identify(data);
      dispatch(track(ACTION_EVENTS.USER_ACTIONS.LOGIN_SUCCESS));
      dispatch(eventDispatch(GET_USER, data));
      if (!data.email) {
        dispatch(eventDispatch(AUTH_VIEW_INDEX_CHANGE, 2));
        if (history) {
          history('/login');
        }
      } else if (!data.org_id || data.org_id == '') {
        dispatch(eventDispatch(AUTH_VIEW_INDEX_CHANGE, 3));
      } else {
        dispatch(eventDispatch(AUTH_VIEW_INDEX_CHANGE, 4));
      }
      dispatch(getUserOrgs({ user_id: data._id }));
    } catch (err) {
      dispatch(track(ACTION_EVENTS.USER_ACTIONS.LOGIN_FAILURE));
      dispatch(eventDispatch(GET_USER_PENDING, false));
    }
  };
}

export function logout() {
  return (dispatch: Dispatch<any>, getState: () => RootState) => {
    const projectId = getState().projectreducer.projectProfile._id;
    if (projectId)
      dispatch(
        track(AUTH_EVENTS.LOGOUT_SUCCESS, { page_source: 'project_view' })
      );
    else
      dispatch(track(AUTH_EVENTS.LOGOUT_SUCCESS, { page_source: 'org_view' }));
    LocalStorageUtil.clearLocalStorageValues();
    removeAllCookies();
    dispatch(track(USER_SEGMENT.LOGOUT_SUCCESS));
    return dispatch({
      type: LOGOUT,
      payload: {},
    });
  };
}

export function loginCheck(payload: any, history?: any) {
  const { checkSessionStorageValue, setSessionStorageValue } =
    useSessionStorage();
  if (checkSessionStorageValue('first_visit')) {
    setSessionStorageValue('first_visit', 'false');
  } else {
    setSessionStorageValue('first_visit', 'true');
  }
  return (dispatch: Dispatch<any>) => {
    if (payload.isAuth) {
      dispatch(getUser(history));
    }
    dispatch({ type: LOGIN_CHECK, payload });
  };
}

export function requestSupportCall(reqBody: RequestSupportCallProps) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(USER_API_PENDING, { pending: true }));
    try {
      await apiClient.post(USER_APIS.requestSupportCall, reqBody);
      dispatch(eventDispatch(USER_API_SUCCESS, { pending: false }));
      dispatch({ type: REQ_SUPPORT_CALL_SUCCESS });
    } catch (err) {
      dispatch(eventDispatch(USER_API_SUCCESS, { pending: false }));
      dispatch(apiError(err));
      toastErrorMessage(
        'Support call could not be requested at the moment, please try again'
      );
    }
  };
}

export function sendOTP(reqBody: SendOtpProps, cb?: Function) {
  const apiUrl = USER_APIS.sendUserOtp;
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(SEND_USER_OTP_PENDING, true));

    try {
      const { data } = await apiClient.post(apiUrl, reqBody);
      dispatch(eventDispatch(SEND_USER_OTP_PENDING, false));
      dispatch(eventDispatch(SEND_USER_OTP, data));
      dispatch(eventDispatch(AUTH_VIEW_INDEX_CHANGE, 1));
      dispatch(eventDispatch(OTP_ERROR, null));
      dispatch(track(AUTH_EVENTS.SEND_OTP_SUCCESS));
      toastSuccessMessage(data.message || 'OTP sent successfully');
      cb?.();
    } catch (err) {
      dispatch(eventDispatch(SEND_USER_OTP_PENDING, false));
      dispatch(track(AUTH_EVENTS.SEND_OTP_FAILURE));
      toastErrorMessage(
        err.response.data.message || 'Something went wrong, please try again'
      );
    }
  };
}

export function verifyOtp(reqBody: VerifyOtpProps) {
  return async (dispatch: Dispatch<any>, getState: any) => {
    const phoneNumber = getState().userreducer.phoneNumber;
    dispatch(eventDispatch(VERIFY_OTP_PENDING, true));
    try {
      const { data } = await apiClient.post(USER_APIS.verifyUserOtp, reqBody);
      IndexedDBService.deleteDbs();
      const role =
        data?.user?.email === 'master@master.com' ? 'master' : 'common';
      const requiredData = { ...data?.user };
      const {
        kam_user: kamUser,
        kam_email: kamEmail,
        is_kam: isKam,
      } = data.user;
      dispatch(eventDispatch(VERIFY_OTP_PENDING, false));
      setLocalStorage({
        token: data?.auth_token,
        tokenExpiry: data?.auth_token_expiry,
        refreshToken: data?.refresh_token,
      });
      if (isKam) {
        setCookie('kam_email', kamEmail);
        setCookie('kam_user', kamUser);
      }

      dispatch(
        loginCheck({
          token: data?.auth_token,
          isAuth: true,
        })
      );
      dispatch(eventDispatch(VERIFY_OTP, data.user));
      dispatch(
        eventDispatch(LOGIN, {
          ...requiredData,
          _id: data._id,
          role,
        })
      );
      toastSuccessMessage('OTP verified successfully');
      if (
        data?.user?.displayName == phoneNumber ||
        data?.user?.displayName == '' ||
        !data?.user?.displayName ||
        !data?.user?.email
      ) {
        dispatch(eventDispatch(AUTH_VIEW_INDEX_CHANGE, 2));
      } else {
        dispatch(getUser());
      }
    } catch (err) {
      dispatch(eventDispatch(VERIFY_OTP_PENDING, false));
      if (err.response.status === BAD_REQUEST) {
        dispatch(eventDispatch(OTP_ERROR, err.response.data.message));
      } else {
        toastErrorMessage('Error in verifying OTP');
      }
    }
  };
}

export function editDisplayName(reqBody: EditDisplayNameProps) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(EDIT_USER_DISPLAYNAME_PENDING, true));
    try {
      const { data } = await apiClient.put(USER_APIS.user, reqBody);
      setLocalStorage({ token: data?.token, exp: data?.exp });
      dispatch(eventDispatch(EDIT_USER_DISPLAYNAME_PENDING, false));
      dispatch(getUser());
    } catch (err) {
      toastErrorMessage('Error!');
    }
  };
}

export function sendEmailVerification(
  userId: string,
  reqBody: EditDisplayNameProps
) {
  const apiUrl = parseString(USER_APIS.sendEmailVerification, userId);
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(VERIFY_USER_EMAIL_PENDING, true));
    try {
      await apiClient.put(apiUrl, reqBody);
      dispatch(eventDispatch(VERIFY_USER_EMAIL_PENDING, false));
      dispatch(getUser());
      toastSuccessMessage(`Verification email sent to ${reqBody.email}`);
    } catch (err) {
      dispatch(eventDispatch(VERIFY_USER_EMAIL_PENDING, false));
      toastErrorMessage(`Something bad happend...`);
    }
  };
}

export function verifyEmail(params: VerifyEmailParams) {
  const apiUrl = parseString(USER_APIS.verifyEmail, customStringify(params));
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await apiClient.get(apiUrl);
      if (data) {
        toastSuccessMessage('Email Verified 🚀');
        dispatch(eventDispatch(SET_TOKEN_EXPIRY, false));
      }
    } catch (err) {
      toastErrorMessage(err.response.data.message);
      dispatch(eventDispatch(SET_TOKEN_EXPIRY, true));
    }
  };
}

export function getSelfPermission() {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await apiClient.get(USER_APIS.selfPermission);
      dispatch(eventDispatch(GET_SELF_PERMISSION, data));
    } catch (err) {
      dispatch(eventDispatch(GET_USER_PENDING, false));
    }
  };
}

export function updateUserAppSettings(appSettings: any) {
  return async (dispatch: Dispatch<any>) => {
    try {
      await apiClient.put(USER_APIS.updateAppSettings, appSettings);
    } catch (err) {
      dispatch(eventDispatch(GET_USER_PENDING, false));
    }
  };
}

export function verifyOtpAndOnboard(id: string, reqBody: any, cb?: Function) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(VERIFY_OTP_PENDING, true));

    try {
      const { data } = await postRequest(
        parseString(USER_APIS.premiumverifyOtp, id),
        reqBody
      );
      dispatch(eventDispatch(SEND_USER_OTP, data));
      const requiredData = { ...data };
      delete requiredData.token;
      dispatch(eventDispatch(VERIFY_OTP_PENDING, false));
      setLocalStorage({ token: data.token, exp: data.exp });
      dispatch(
        loginCheck({
          token: data.token,
          isAuth: true,
        })
      );
      dispatch(eventDispatch(VERIFY_OTP, data));
      dispatch(
        eventDispatch(LOGIN, {
          ...requiredData,
          _id: data._id,
        })
      );
      toastSuccessMessage('OTP verified successfully');
      cb?.();
    } catch (err) {
      dispatch(eventDispatch(VERIFY_OTP_PENDING, false));
      if (err.response.status === BAD_REQUEST) {
        dispatch(eventDispatch(OTP_ERROR, err.response.data.message));
      } else {
        toastErrorMessage('Error in verifying OTP');
      }
    } finally {
      dispatch(eventDispatch(VERIFY_OTP_PENDING, false));
    }
  };
}

export const getSignature = (cb?: Function) => {
  return async (dispatch: Dispatch) => {
    const apiUrl = USER_APIS.getSignature;
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_SIGNATURE, data));
      cb?.();
    } catch {
      toastErrorMessage('Error!');
    }
  };
};

export const fetchSupportedCountries = () => {
  return async (dispatch: Dispatch) => {
    const apiUrl = MISC_APIS.fetchSupportedCountries;

    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(SET_COUNTRIES, data));
    } catch (err) {
      toastErrorMessage('Error!');
    }
  };
};
