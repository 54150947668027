export const SET_SELECTED_BOQ = 'SET_SELECTED_BOQ';
export const BOQ_UPLOAD_ERROR = 'BOQ_UPLOAD_ERROR';
export const BOQ_UPLOAD_SUCCESS = 'BOQ_UPLOAD_SUCCESS';
export const SET_BOQ_LOADING = 'SET_BOQ_LOADING';
export const SET_BOQ_LOG_LOADING = 'SET_BOQ_LOADING';
export const SET_SELECTED_BOQ_LOG = 'SET_SELECTED_BOQ_LOG';
export const SET_BOQ_LOGS = 'SET_BOQ_LOGS';
export const SET_COLLAPSED_BOQ_IDS = 'SET_COLLAPSED_BOQ_IDS';
export const SET_CONSOLIDATED_SUMMARY = 'SET_CONSOLIDATED_SUMMARY';
export const SET_BOQ_SUMMARY_LOADING = 'SET_BOQ_SUMMARY_LOADING';
export const SET_BOQ_UPDATE_PENDING = 'SET_BOQ_UPDATE_PENDING';
