import {
  GET_ALL_DOCS,
  DOC_API_ERROR,
  DOC_API_PENDING,
  DOC_API_SUCCESS,
  DOC_UPLOAD,
  DOC_UPLOAD_SUCCESS,
  DOC_CLEAR_STATE,
  EXCEL_UPLOAD_MESSAGE,
  EXCEL_UPLOAD_WARNINGS,
  RESET_EXCEL_ERROR_LIST,
  EXCEL_UPLOAD_ERROR,
} from './Document.type';

import apiClient from '../../../../utils/ApiClient.util';
import axios from 'axios';
import { getVendorListRequest } from '../../vendor';
import { getPOListRequest } from '../../material/purchase-order';
import { getBOMListRequest, getBOMCount } from '../../material/bom';
import {
  UPLOAD_DOC_SEGMENT,
  ORG_VENDOR_SEGMENT,
} from '../../../../constant/segment_constant';
import { track } from '../Segment.action';
import { setRefreshView } from '../refresh/Refresh.action';
import {
  SYNC_BUDGET_VIEW,
  SYNC_INVENTORY_VIEW,
  SYNC_SITE_PLAN_VIEW,
  SYNC_ATTENDANCE_SUMMARY_VIEW,
  SYNC_ATTENDANCE_PROFILE_VIEW,
  SYNC_BROAD_PLAN_TASK_VIEW,
  SYNC_VENDOR_ORG_LIST_VIEW,
} from '../refresh/Refresh.type';
import {
  API_VERSIONS,
  ApiVersionType,
  DOCUMENT_APIS,
} from '../../../../routes.constant';
import { getProjectBaselines } from '../../task/task';
import { ACTION_EVENTS } from '../../../../constant';
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../../utils/Toast.util';
import { parseString } from '../../../../utils';
import { eventDispatch } from '../../../base';
import { customStringify } from '../../../../utils/Url.util';
import { postRequest } from '../../../../apiClient';
import { EXCEL_UPLOAD_TYPE } from '../../../../constants';
const GET_ALL_URL = 'docs';

const getDataSuccess = (data, actionType) => {
  return {
    type: actionType,
    payload: data,
  };
};

const apiPending = () => {
  return {
    type: DOC_API_PENDING,
  };
};

const apiSuccess = () => {
  return {
    type: DOC_API_SUCCESS,
  };
};

const docUploadSuccess = () => {
  return {
    type: DOC_UPLOAD_SUCCESS,
  };
};

const apiError = error => {
  return {
    type: DOC_API_ERROR,
    error,
  };
};

export const clearDocState = () => {
  return dispatch => {
    dispatch({ type: DOC_CLEAR_STATE });
  };
};

export const getAllDocuments = () => {
  return (dispatch, getState) => {
    const proj_id = getState().projectreducer.selectedProj.id;
    apiClient
      .get(`${GET_ALL_URL}?project_id=${proj_id}`)
      .then(res => {
        dispatch({ type: GET_ALL_DOCS, payload: res.data });
      })
      .catch(err => {
        toastErrorMessage('Error !');
        dispatch(apiError(err));
      });
  };
};

export const upload = (url, data) => {
  const apiUrl = `${url}`;

  return dispatch => {
    dispatch(apiPending());
    apiClient
      .post(apiUrl, data)
      .then(response => {
        dispatch(getDataSuccess(response.data, DOC_UPLOAD));
        dispatch(apiSuccess());
        toastSuccessMessage('Successfully uploaded document');
        dispatch(docUploadSuccess());
        dispatch(getAllDocuments());
      })
      .catch(error => {
        dispatch(apiError(error));
        toastErrorMessage('Error uploading document!');
      });
  };
};

export async function singleUploadToS3(file) {
  try {
    await axios({
      url: file.url,
      method: 'PUT',
      data: file.file,
      headers: {
        'Content-Type': `${file.type}; charset=utf-8`,
      },
    });
  } catch (err) {
    return false;
  }
  return true;
}

export async function uploadMultipleToS3(fileObj) {
  try {
    const promiseArr = [];
    fileObj.map(async file => {
      promiseArr.push(singleUploadToS3(file));
    });
    await Promise.allSettled(promiseArr);
  } catch (error) {
    return false;
  }
  return true;
}

export const getSignedUrlArr = async keys => {
  const url = `${DOCUMENT_APIS.signedUrls}?${customStringify({
    key: keys,
  })}`;

  try {
    const result = await apiClient.get(url);
    return result.data;
  } catch (e) {
    return [];
  }
};

export const getSignedUrl = async key => {
  const url = parseString(
    DOCUMENT_APIS.singleSignedUrl,
    customStringify({ key })
  );

  try {
    const result = await apiClient.get(url);
    return result.data;
  } catch (e) {
    return '';
  }
};

export const getPrivateSignedUrl = async (key, bucket) => {
  const url = parseString(
    DOCUMENT_APIS.privateSingleSignedUrl,
    customStringify({ key, bucket })
  );
  try {
    const result = await apiClient.get(url);
    return result.data;
  } catch (e) {
    return '';
  }
};

export const uploadExcel = (
  url,
  data,
  type,
  cb: Function = () => {},
  erroCb: Function = () => {},
  upload_type = '',
  apiVersion: ApiVersionType = API_VERSIONS.V1
) => {
  const apiUrl = `${url}`;
  return (dispatch, getState) => {
    const orgId = getState().organisationReducer.orgProfile._id;
    const projectId = getState().projectreducer.selectedProj.id;

    dispatch(eventDispatch(DOC_API_PENDING));

    dispatch(eventDispatch(RESET_EXCEL_ERROR_LIST));
    postRequest(apiUrl, data, apiVersion)
      .then(response => {
        if (response.data.error) {
          if (erroCb) {
            erroCb?.();
            return;
          }
          switch (upload_type) {
            case 'add_existing':
              dispatch(
                track(ACTION_EVENTS.TASK_ACTIONS.UPLOAD_EXISTING_PLAN_FAILURE, {
                  upload_type: type,
                })
              );
              break;
            case 'replace':
              dispatch(track(ACTION_EVENTS.TASK_ACTIONS.REPLACE_PLAN_FAILURE));
              break;
            case 'upload_plan':
              dispatch(track(ACTION_EVENTS.TASK_ACTIONS.UPLOAD_MSP_FAILURE));
              break;
            default:
              dispatch(
                track(UPLOAD_DOC_SEGMENT.UPLOAD_EXCEL_ERROR, {
                  upload_type: type,
                })
              );
          }
          dispatch(apiSuccess());
          toastErrorMessage('Error in upload sheet format!');
          if (!response.data.success)
            dispatch({
              type: EXCEL_UPLOAD_ERROR,
              payload: response.data.error,
            });
        } else {
          switch (upload_type) {
            case 'add_existing':
              dispatch(
                track(ACTION_EVENTS.TASK_ACTIONS.UPLOAD_EXISTING_PLAN_SUCCESS, {
                  upload_type: type,
                })
              );
              break;
            case 'replace':
              dispatch(getProjectBaselines(projectId));
              dispatch(
                track(ACTION_EVENTS.TASK_ACTIONS.REPLACE_PLAN_SUCCESS, {
                  option: 'complete',
                })
              );
              break;
            case 'upload_plan':
              dispatch(track(ACTION_EVENTS.TASK_ACTIONS.UPLOAD_MSP_SUCCESS));
              break;
            default:
              dispatch(
                track(UPLOAD_DOC_SEGMENT.UPLOAD_EXCEL_SUCCESS, {
                  upload_type: type,
                })
              );
          }

          dispatch(getDataSuccess(response, DOC_UPLOAD));
          switch (type) {
            case 'task':
              dispatch(setRefreshView(SYNC_SITE_PLAN_VIEW, true));
              break;
            case 'broad_plan_task':
              dispatch(eventDispatch(SYNC_BROAD_PLAN_TASK_VIEW, true));
              break;
            case 'vendor':
              dispatch(
                getVendorListRequest({
                  org_id: orgId,
                  sort_option: '-createdAt',
                })
              );
              dispatch(eventDispatch(SYNC_ATTENDANCE_SUMMARY_VIEW, true));
              break;
            case 'bom':
              dispatch(
                getBOMListRequest({
                  org_id: orgId,
                  sort_option: '-createdAt',
                })
              );
              dispatch(
                getBOMCount({
                  org_id: orgId,
                })
              );
              break;
            case 'boq':
              dispatch(setRefreshView(SYNC_BUDGET_VIEW, true));
              break;
            case 'po':
              dispatch(getPOListRequest({ project_id: projectId }));
              break;
            case 'inventory':
              dispatch(eventDispatch(SYNC_INVENTORY_VIEW, true));
              break;
            case 'labour':
              dispatch(eventDispatch(SYNC_ATTENDANCE_SUMMARY_VIEW, true));
              break;
            case 'vendorLabours':
              dispatch(eventDispatch(SYNC_ATTENDANCE_PROFILE_VIEW, true));
              break;
            case EXCEL_UPLOAD_TYPE.ORG_VENDORS:
              dispatch(eventDispatch(SYNC_VENDOR_ORG_LIST_VIEW, true));
              break;
          }
          dispatch(docUploadSuccess());
          toastSuccessMessage('Successfully uploaded');
          dispatch(apiSuccess());
          cb?.();

          if (response.data?.warnings?.length) {
            dispatch(
              eventDispatch(EXCEL_UPLOAD_MESSAGE, response.data?.message)
            );
            dispatch(
              eventDispatch(EXCEL_UPLOAD_WARNINGS, response.data?.warnings)
            );
          }
        }
      })
      .catch(error => {
        dispatch(eventDispatch(DOC_API_ERROR, error));
        if (erroCb) {
          erroCb?.();
          return;
        }
        dispatch(
          eventDispatch(EXCEL_UPLOAD_ERROR, error.response?.data?.error_list)
        );

        switch (type) {
          case 'task':
            dispatch(track(ACTION_EVENTS.TASK_ACTIONS.UPLOAD_MSP_FAILURE));
            dispatch(apiError(error));
            toastErrorMessage(error.response?.data?.message);
            break;
          case EXCEL_UPLOAD_TYPE.ORG_VENDORS:
            dispatch(
              track(ORG_VENDOR_SEGMENT.ERROR_SHEET_POP_UP, { screen: 'Vendor' })
            );
            toastErrorMessage(
              error?.response?.data?.message || 'Error uploading'
            );
            break;
          default:
            dispatch(
              track(UPLOAD_DOC_SEGMENT.UPLOAD_EXCEL_ERROR, {
                upload_type: type,
              })
            );
            dispatch(apiError(error));
            toastErrorMessage(
              error?.response?.data?.message || 'Error uploading'
            );
        }
      })
      .finally(() => {
        dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, 2));
      });
  };
};

export const updateExcel = (url, data, type, upload_type = '') => {
  const apiUrl = `${url}`;
  return async (dispatch, getState) => {
    const projectId = getState().projectreducer.selectedProj.id;

    dispatch(eventDispatch(DOC_API_PENDING));
    dispatch(eventDispatch(RESET_EXCEL_ERROR_LIST));

    try {
      const response = await apiClient.put(apiUrl, data);

      if (response.data.error) {
        if (upload_type === 'replace') {
          dispatch(track(ACTION_EVENTS.TASK_ACTIONS.REPLACE_PLAN_FAILURE));
        } else {
          dispatch(
            track(UPLOAD_DOC_SEGMENT.UPLOAD_EXCEL_ERROR, {
              upload_type: type,
            })
          );
        }
        dispatch(apiSuccess());
        toastErrorMessage('Error in upload sheet format!');
        if (!response.data.success) {
          dispatch({
            type: EXCEL_UPLOAD_ERROR,
            payload: response.data.error,
          });
        }
      } else {
        if (upload_type === 'replace') {
          dispatch(getProjectBaselines(projectId));
          dispatch(
            track(ACTION_EVENTS.TASK_ACTIONS.REPLACE_PLAN_SUCCESS, {
              option: 'specific',
            })
          );
        } else {
          dispatch(
            track(UPLOAD_DOC_SEGMENT.UPLOAD_EXCEL_SUCCESS, {
              upload_type: type,
            })
          );
        }

        dispatch(getDataSuccess(response, DOC_UPLOAD));
        if (type === 'task') {
          dispatch(setRefreshView(SYNC_SITE_PLAN_VIEW, true));
        }
        dispatch(docUploadSuccess());
        toastSuccessMessage('Successfully uploaded');
        dispatch(apiSuccess());
        if (response.data?.warnings?.length) {
          dispatch(eventDispatch(EXCEL_UPLOAD_MESSAGE, response.data?.message));
          dispatch(
            eventDispatch(EXCEL_UPLOAD_WARNINGS, response.data?.warnings)
          );
        }
      }
    } catch (error) {
      dispatch(eventDispatch(DOC_API_ERROR, error));
      dispatch(
        eventDispatch(EXCEL_UPLOAD_ERROR, error.response?.data?.error_list)
      );

      if (type === 'task') {
        dispatch(track(ACTION_EVENTS.TASK_ACTIONS.UPLOAD_MSP_FAILURE));
      } else {
        dispatch(
          track(UPLOAD_DOC_SEGMENT.UPLOAD_EXCEL_ERROR, { upload_type: type })
        );
      }

      dispatch(apiError(error));
      toastErrorMessage(error?.response?.data?.message || 'Error uploading');
    } finally {
      dispatch(eventDispatch(SYNC_SITE_PLAN_VIEW, 2));
    }
  };
};
