export const PROJECT_PATHS = {
  DASHBOARD: '/project/{}/dashboard',
  SITE_PLAN: '/project/{}/project-plan/{}',
  SITE_PLAN_GC: '/project/{}/broad-plan/{}',
  NEW_PURCHASE_ORDER_FORM: '/project/{}/commercial/purchase-order/new',
  NEW_INDENT_FORM: '/project/{}/commercial/indent/new',
  NEW_EXTERNAL_PO_FORM: '/project/{}/commercial/purchase-order/external/new',
  NEW_PRO_PO_FORM: '/project/{}/commercial/purchase-order/pro/new',
  PURCHASE_ORDER_PROFILE: '/project/{}/commercial/purchase-order/{}',
  PURCHASE_ORDER: '/project/{}/commercial/po',
  EDIT_PURCHASE_ORDER_FORM: '/project/{}/commercial/purchase-order/{}/edit',
  EDIT_INDENT_FORM: '/project/{}/commercial/indent/{}/edit',
  EDIT_PRO_PO_FORM: '/project/{}/commercial/purchase-order/{}/pro/edit',
  EDIT_EXTERNL_PO_FORM:
    '/project/{}/commercial/purchase-order/{}/external/edit',
  COMMERCIAL: '/project/{}/commercial{}',
  GRN_FINANCE: '/project/:projId/grn-finance',
  VENDOR: '/project/:projId/vendor',
  PROJECT_PROFILE_VIEW: '/project/:projId/project-profile',
  BOM: '/project/:projId/bom',
  REPORTS: '/project/:projId/reports',
  THREAD_PROFILE: '/project/:projId/threads/:threadId',
  THREADS: '/project/:projId/threads/',
  ATTENDANCE: '/project/:projId/attendance',
  ACTIVITIES: '/project/:projId/activities',
  BUDGET_PROFILE: '/project/:projId/budget/:boqId',
  BUDGET: '/project/{}/budget/boq',
  RECEIVABLES: '/project/{}/budget/receivables',
  FOLDER: '/project/:projId/drive/:folderId',
  DRIVE: '/project/:projId/drive',
  INDENT_PROFILE: '/project/{}/commercial/indents/{}',
  INDENTS: '/project/{}/commercial/indent',
  FALLBACK_DASHBOARD: '/project/:projId/',
  PROJECT_LEVEL_WORK_ORDER: '/project/:projId/work-order/vendor/:vendorId/new',
  NEW_ATTENDANCE: '/project/:projId/new-attendance/*',
  ASYNC_REPORT: '/project/:projId/new-report',
  PROJECT_LEVEL_EDIT_WORK_ORDER: '/project/{}/work-order/vendor/{}/edit',
  MATERIAL_ISSUE_NEW_FORM: '/project/{}/commercial/materialIssue/new',
  SITE_TRANSFER_NEW_FORM:
    '/sending-project/{}/receiving-project/{}/commercial/siteTransfer/new',
  EDIT_SITE_TRANSFER_FORM:
    '/sending-project/{}/receiving-project/{}/commercial/siteTransfer/{}/edit',
  EDIT_MATERIAL_ISSUE_FORM: '/project/{}/commercial/materialIssue/{}/edit',
  GRN_NEW_FORM: '/project/{}/commercial/grn/new',
  GRN_EDIT_FORM: '/project/{}/commercial/grn/{}/edit',
  PAYABLE_NEW_FORM: '/project/{}/commercial/payable/{}/{}',
  LABOUR_PROFILE: '/project/{}/labour/{}',
  VENDOR_PROFILE_NEW: '/project/{}/vendor/{}',
  ADVANCE_HISTORY: '/project/{}/{}/{}/advance-history',
  VENDOR_LIST: '/project/{}/new-attendance/vendor',
  LABOUR_LIST: '/project/{}/new-attendance/labour',
} as const;

export const ORG_PATHS = {
  VENDOR: '/vendor',
  ORG_PROFILE: '/org-profile',
  PROJECT_LIST: '/projects',
  MEMBERS: '/members{}',
  TEAMS: '/teams',
  TEAM_PROFILE: '/teams/{}',
  PURCHASE_ORDER: '/purchase-orders',
  BOM: '/bom',
  ORG_DASHBOARD: '/org-dashboard/:tab?',
  COMMERCIAL: '/commercial{}',
  INDENTS: '/commercial/indent',
  PO: '/commercial/po',
  GRN_PROFILE: '/grn/{}',
  VENDOR_PROFILE: '/vendor-profile/{}', // Remove this later : [MAT-4012]
  VENDOR_COMMERCIAL_PROFILE: '/commercial/vendor-profile/{}',
  ORG_ADDRESS_DETAILS: '/org-profile/address-details',
  ORG_WC: '/org-profile/work-categories',
  ORG_MATERIAL_CATEGORIES: '/org-profile/material-categories',
  ORG_PROJECT_STAGES: '/org-profile/project-stages',
  ORG_TASK_CHECKLIST: '/org-profile/task-checklist',
  ATTENDANCE: '/attendance',
  SAMPLE_DOCS: '/org-profile/sample-documents',
  APPROVAL_SETTINGS: '/org-profile/approval-settings',
  AUTO_DPR: '/org-profile/auto-dpr',
  ONBOARDING_DETAILS: '/org-profile/onboarding-details',
  ORG_ATTENDANCE: '/org-attendance/*',
  ANALYTICS_DASHBOARD: '/analytics-dashboard',
  ANALYTICS_DASHBOARD_VIEW: '/analytics-dashboard/{}',
  SC_PAYABLE_VIEW: '/sc-payable-vendor-list',
  MERGE_LABOUR: '/merge/labour',
  MERGE_VENDOR: '/merge/vendor',
  MERGE_VENDOR_LABOURS: '/merge/vendor-labours',
  ORGANISATION_SETTINGS: '/org-settings',
  GROUDPED_MATERIAL_FORM_NEW: '/grouped-material/new',
  GROUPED_MATERIAL_FORM_EDIT: '/grouped-material/edit/{}',
  INDENT_PROFILE: '/commercial/indent?{}',
  MATERIAL_ISSUE_FORM_NEW: '/commercial/materialIssue/new',
  SITE_TRANSFER_FORM_NEW: 'commercial/siteTransfer/new',
  SUPERSET_DASHBOARD: '/superset-dashboard',
  VENDOR_CREATE_FORM: '/vendor/new',
  VENDOR_EDIT_FORM: '/vendor/{}/{}',
  ORG_ASYNC_REPORT: '/org/async-report',
  PAYABLES: '/payables/{}',
  PAYABLE_PROFILE: '/payable-profile/{}',
  LABOUR_PROFILE: '/org/{}/labour/{}',
  VENDOR_PROFILE_NEW: '/org/{}/vendor/{}',
  WORK_ORDER: '/work-order',
  ADVANCE_HISTORY: '/org/{}/{}/{}/advance-history',
  VENDOR_LIST: '/org-attendance/vendor',
  LABOUR_LIST: '/org-attendance/labour',
  CUSTOM_INFO_LIST: '/custom-info',
  WORK_ORDER_NEW: '/work-order/new',
  EDIT_WORK_ORDER: '/work-order/{}/edit',
};
