import { WHATS_NEW_API_ERROR, WHATS_NEW_SUCCESS } from './WhatsNew.type';

const initialState = {
  error: '',
  data: [],
};

export default function whatsNewReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case WHATS_NEW_API_ERROR:
      return {
        ...state,
        error: error.response.data.message,
      };
    case WHATS_NEW_SUCCESS:
      return {
        ...state,
        error: '',
        data: payload,
      };

    default:
      return state;
  }
}
