import {
  GET_INDENT_ITEMS,
  INDENT_API_PENDING,
  INDENT_API_ERROR,
  INDENT_API_SUCCESS,
  ADD_INDENT_ITEM,
  GET_INDENT_DETAILS,
  GET_INDENT_DETAILS_PENDING,
  FORM_MODE,
  INDENT_FORM_SHOW,
  INDENT_ITEM_SELECTED,
  DELETE_INDENT,
  GET_INDENT_BY_ORG_ID,
  INDENT_MARK_AS_COMPLETE,
} from './Indent.type';
import { INDENT_SEGMENT } from '../../../../constant/segment_constant';
import { track } from '../../common/Segment.action';
import { API_VERSIONS, INDENT_APIS } from '../../../../routes.constant';
import {
  toastSuccessMessage,
  toastErrorMessage,
} from '../../../../utils/Toast.util';
import {
  SYNC_COMMERCIAL_VIEW,
  SYNC_VIEW,
} from '../../common/refresh/Refresh.type';
import { setRefreshView } from '../../common/refresh/Refresh.action';
import { parseString } from '../../../../utils';
import { eventDispatch, errorDispatch } from '../../../base';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../../../apiClient';
import { IndentFilterProps, IndentProps } from '../../../../interfaces/Indent';
import { Dispatch } from 'redux';
import { customStringify } from '../../../../utils/Url.util';

interface ISignatureProps {
  reason: string;
  signature?:
    | {
        content_type: string;
        data: string;
      }
    | {
        _id: string;
      };
}

export const getIndentList = (projectId: string) => {
  const apiUrl = parseString(
    INDENT_APIS.getIndentList,
    customStringify({ project_id: projectId })
  );

  return async dispatch => {
    dispatch(eventDispatch(INDENT_API_PENDING));
    try {
      const { data } = await getRequest<IndentProps[]>(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(INDENT_API_SUCCESS));
      dispatch(eventDispatch(GET_INDENT_ITEMS, data));
    } catch (err) {
      dispatch(errorDispatch(INDENT_API_ERROR, err));
    }
  };
};

export const getIndentById = (indentId: string) => {
  const apiUrl = parseString(INDENT_APIS.getIndentById, indentId);

  return async dispatch => {
    dispatch(eventDispatch(GET_INDENT_DETAILS_PENDING, true));
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_INDENT_DETAILS_PENDING, false));
      dispatch(eventDispatch(GET_INDENT_DETAILS, data));
    } catch (err) {
      dispatch(errorDispatch(INDENT_API_ERROR, err));
      dispatch(eventDispatch(GET_INDENT_DETAILS_PENDING, false));
    }
  };
};

export const setActiveIndent = indent => {
  return dispatch => {
    dispatch(eventDispatch(INDENT_ITEM_SELECTED, indent));
  };
};

export const setShowIndentForm = (state, formMode, item) => {
  return dispatch => {
    dispatch(eventDispatch(INDENT_API_PENDING));
    try {
      dispatch(eventDispatch(INDENT_API_SUCCESS));
      if (item !== undefined) {
        dispatch(eventDispatch(INDENT_ITEM_SELECTED, item));
      }
      if (formMode !== undefined) {
        dispatch(eventDispatch(FORM_MODE, formMode));
      }
      dispatch(eventDispatch(INDENT_FORM_SHOW, state));
    } catch (err) {
      dispatch(errorDispatch(INDENT_API_ERROR, err));
    }
  };
};

export const createIndent = (reqBody, cb?: () => void) => {
  return async dispatch => {
    try {
      dispatch(eventDispatch(INDENT_API_PENDING));
      const { data } = await postRequest(INDENT_APIS.createIndent, reqBody);
      dispatch(track(INDENT_SEGMENT.INDENT_CREATE_SUCCESS));
      dispatch(eventDispatch(INDENT_API_SUCCESS));
      dispatch(eventDispatch(SYNC_VIEW, true));
      toastSuccessMessage(
        `Indent added succesfully with ID - ${data.request_id}`
      );
      dispatch(eventDispatch(ADD_INDENT_ITEM, data));
      cb();
    } catch (err) {
      dispatch(track(INDENT_SEGMENT.INDENT_CREATE_ERROR));
      dispatch(errorDispatch(INDENT_API_ERROR, err));
      toastErrorMessage(err?.response?.data?.message || 'Error !');
    }
  };
};

export const updateIndent = (
  indentId: string,
  reqBody: IndentProps,
  cb?: () => void
) => {
  const apiUrl = parseString(INDENT_APIS.updateIndent, indentId);

  return async dispatch => {
    try {
      dispatch(eventDispatch(INDENT_API_PENDING));
      const { data } = await putRequest(apiUrl, reqBody);
      dispatch(eventDispatch(INDENT_API_SUCCESS));
      toastSuccessMessage(
        `Indent with ID - ${data.request_id} updated succesfully`
      );
      dispatch(setRefreshView(SYNC_COMMERCIAL_VIEW, true));
      dispatch(setRefreshView(SYNC_VIEW, true));
      cb();
    } catch (err) {
      dispatch(errorDispatch(INDENT_API_ERROR, err));
      toastErrorMessage(
        err?.response?.data?.message || 'Error! could not update the sequences'
      );
    }
  };
};

export const markAsCompleteIndent = (indentId: string) => {
  const apiUrl = parseString(INDENT_APIS.markAsCompleteIndet, indentId);
  return async dispatch => {
    try {
      dispatch(eventDispatch(INDENT_API_PENDING));
      const { data } = await putRequest(apiUrl);
      dispatch(eventDispatch(INDENT_MARK_AS_COMPLETE, data));
      dispatch(eventDispatch(INDENT_API_SUCCESS));
      toastSuccessMessage('Indent marked as fulfilled');
      dispatch(setRefreshView(SYNC_COMMERCIAL_VIEW, true));
      dispatch(setRefreshView(SYNC_VIEW, true));
      dispatch(getIndentById(indentId));
    } catch (err) {
      dispatch(errorDispatch(INDENT_API_ERROR, err));
      toastErrorMessage(
        `Error: ${err?.errorMsg || "couldn't mark indent as fulfilled"}`
      );
    }
  };
};

export const deleteIndent = (indentId: string) => {
  const apiUrl = parseString(INDENT_APIS.deleteIndent, indentId);
  return async dispatch => {
    try {
      dispatch(eventDispatch(INDENT_API_PENDING));
      await deleteRequest(apiUrl);
      dispatch(eventDispatch(DELETE_INDENT));
      dispatch(eventDispatch(INDENT_API_SUCCESS));
      toastSuccessMessage('Successfully deleted indent');
      dispatch(setRefreshView(SYNC_COMMERCIAL_VIEW, true));
      dispatch(setRefreshView(SYNC_VIEW, true));
    } catch (err) {
      dispatch(errorDispatch(INDENT_API_ERROR, err));
      toastErrorMessage(
        `Error: ${err?.errorMsg || "couldn't delete the indent"}`
      );
    }
  };
};

export const bulkDeleteIndents = (data, successCallback) => {
  const apiUrl = INDENT_APIS.indentList;
  return async (dispatch, getState) => {
    const projectId = getState().projectreducer.selectedProj.id;
    try {
      await putRequest(apiUrl, data);
      dispatch(eventDispatch(DELETE_INDENT));
      toastSuccessMessage('Successfully deleted indents');
      successCallback();
      dispatch(getIndentList(projectId));
      dispatch(setRefreshView(SYNC_COMMERCIAL_VIEW, true));
      dispatch(setRefreshView(SYNC_VIEW, true));
    } catch (err) {
      toastErrorMessage(
        err?.response?.data?.message || 'Error in deleting indents'
      );
    }
  };
};

export const approveIndent = (
  indentId: string,
  signatureImage,
  reason = ''
) => {
  const apiUrl = parseString(INDENT_APIS.approveIndent, indentId);
  return async (dispatch, getState) => {
    const signatureData = getState().userreducer.signature;
    try {
      const putData: ISignatureProps = { reason };
      if (signatureImage) {
        putData.signature = {
          content_type: 'base64',
          data: signatureImage.split(',')[1],
        };
      } else {
        putData.signature = { _id: signatureData._id };
      }
      await putRequest(apiUrl, putData);
      toastSuccessMessage('Successfully approved indent');
      dispatch(setRefreshView(SYNC_COMMERCIAL_VIEW, true));
      dispatch(setRefreshView(SYNC_VIEW, true));
      dispatch(getIndentById(indentId));
    } catch {
      toastErrorMessage('Error in approving indent');
    }
  };
};

export const rejectIndent = (indentId: string, signatureImage, reason = '') => {
  const apiUrl = parseString(INDENT_APIS.rejectIndent, indentId);
  return async (dispatch, getState) => {
    const signatureData = getState().userreducer.signature;
    try {
      const putData: ISignatureProps = { reason };
      if (signatureImage) {
        putData.signature = {
          content_type: 'base64',
          data: signatureImage.split(',')[1],
        };
      } else {
        putData.signature = { _id: signatureData._id };
      }
      await putRequest(apiUrl, putData);
      toastSuccessMessage('Successfully rejected indent');
      dispatch(setRefreshView(SYNC_COMMERCIAL_VIEW, true));
      dispatch(setRefreshView(SYNC_VIEW, true));
      dispatch(getIndentById(indentId));
    } catch {
      toastErrorMessage('Error in rejecting indent');
    }
  };
};

export const approveIndents = (idArr, callBack: Function = () => {}) => {
  return async (dispatch, getState) => {
    const projectId = getState().projectreducer.selectedProj.id;
    try {
      const { data } = await putRequest(INDENT_APIS.bulkApproveIndents, {
        indentIdArr: idArr,
      });
      dispatch(setRefreshView(SYNC_COMMERCIAL_VIEW, true));
      dispatch(setRefreshView(SYNC_VIEW, true));
      dispatch(getIndentList(projectId));
      callBack(data);
    } catch {
      toastErrorMessage('Error in approving indents');
    }
  };
};

export const getIndentsByOrgId =
  (
    orgId: string,
    page: number,
    limit: number,
    filters?: IndentFilterProps,
    showDeleted: boolean = false
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(eventDispatch(INDENT_API_PENDING));
    try {
      const parsedfilters = JSON.stringify(filters);

      const apiUrl = parseString(
        INDENT_APIS.getIndentsByOrgId,
        orgId,
        customStringify({
          filters: parsedfilters,
          page,
          limit,
          show_deleted: showDeleted,
        })
      );

      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(INDENT_API_SUCCESS));
      dispatch(eventDispatch(GET_INDENT_BY_ORG_ID, data));
    } catch (err) {
      dispatch(errorDispatch(INDENT_API_ERROR, err));
      toastErrorMessage('Error!');
    }
  };
