/**
 * Button Component
 *
 * A highly customizable button component that supports various styles, sizes, and states.
 * It can render different variants, include icons, and show loading states.
 *
 * @component
 * @example
 * <Button label="Click me" variant="primary" size="m" iconStart={Mail} />
 */

import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { Text } from './Text';
import { LoaderCircle, type LucideIcon } from 'lucide-react';

/**
 * Possible variants for the Button component.
 * Includes both new variants and some kept for backward compatibility.
 */
export type ButtonVariants =
  | 'outline'
  | 'default'
  | 'destructive'
  | 'secondary'
  | 'ghost'
  | 'link'
  | 'primary'
  | 'tertiary'
  | 'neutral'
  | 'neutral-tert'
  | 'descructive-primary'
  | 'descructive-tertiary';

/**
 * Defines the visual styles for different button variants and sizes.
 * Uses class-variance-authority (cva) for conditional class application.
 */
const buttonVariants = cva(
  'flex items-center justify-center gap-sm whitespace-nowrap rounded-small border-1 active:scale-x-0.98 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        // below 4 variant need to remove. I kept it for backward compatibility
        default: 'bg-main text-main-foreground hover:bg-main/90 border',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        ghost: 'hover:bg-accent hover:text-accent-foreground',

        primary:
          'bg-brand-700 text-white border-brand-700 hover:bg-brand-800 hover:border-brand-800',
        secondary:
          'bg-auxiliary-white text-brand-700 border-brand-700 hover:bg-brand-100',
        tertiary: 'border-0 text-brand-700 hover:bg-brand-100',
        link: 'bg-auxiliary-white border-0 text-brand-700 hover:underline p-0 !h-0',
        neutral:
          'bg-auxiliary-white text-steel-900 border-steel-300 hover:bg-steel-100',
        'neutral-tert': 'border-0 text-steel-900 hover:bg-steel-100',
        'descructive-primary':
          'bg-brick-700 border-brick-700 text-white hover:bg-brick-800 hover:border-brick-800',
        'descructive-tertiary': 'border-0 text-brick-700 hover:bg-brick-100',
      },
      size: {
        // below 4 size variant need to remove. I kept it for backward compatibility
        default: 'h-9 px-4 py-2',
        sm: 'h-8 rounded-md px-3 text-xs-12',
        lg: 'h-10 rounded-md px-8',
        icon: 'h-9 w-9',

        s: 'px-md h-[36px]',
        m: 'px-lg h-[40px]',
        l: 'px-xxl h-[48px]',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

/**
 * Props for the IconRenderer component.
 * @typedef {Object} IconRendererProps
 * @property {'s' | 'm' | 'l'} [size='s'] - The size of the icon
 * @property {LucideIcon} [Icon] - The icon component to render
 */
interface IconRendererProps {
  size?: 's' | 'm' | 'l';
  Icon?: LucideIcon;
  className?: string;
}

/**
 * Renders an icon based on the provided size and Icon component.
 * @param {IconRendererProps} props - The props for the IconRenderer
 * @returns {React.ReactElement | null} The rendered icon or null if no icon is provided
 */
const IconRenderer = (props: IconRendererProps) => {
  const { size = 's', Icon, className } = props;
  if (!Icon) {
    return null;
  }
  switch (size) {
    case 's':
    case 'm':
      return <Icon height={20} width={20} className={className} />;
    case 'l':
      return <Icon height={24} width={24} className={className} />;
  }
};

/**
 * Props for the Button component.
 * @typedef {Object} ButtonProps
 * @property {boolean} [asChild=false] - Whether to render the button as a child component
 * @property {string} [label=''] - The text to display on the button
 * @property {LucideIcon} [iconStart] - Icon to display at the start of the button
 * @property {LucideIcon} [iconEnd] - Icon to display at the end of the button
 * @property {boolean} [loading=false] - Whether to show a loading state
 */
export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  label?: string;
  iconStart?: LucideIcon;
  iconEnd?: LucideIcon;
  loading?: boolean;
}

/**
 * The main Button component.
 * Renders a button with various styles and states based on the provided props.
 *
 * @param {ButtonProps} props - The props for the Button component
 * @returns {React.ReactElement} The rendered Button component
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      label = '',
      iconStart,
      iconEnd,
      loading = false,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    if (props.children) {
      const classesForBackwardCompatibility = `inline-flex rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring ${
        variant !== 'outline' ? 'border-0' : ''
      }`;
      return (
        <Comp
          className={cn(
            buttonVariants({ variant, size, className }),
            classesForBackwardCompatibility
          )}
          ref={ref}
          {...props}
        />
      );
    }

    const widthBySizeForOnlyIcon = {
      s: 'w-[36px]',
      m: 'w-[40px]',
      l: 'w-[48px]',
    };

    const isOnlyIcon = loading || !label;
    const textSize = size === 's' ? 'm' : size;

    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className }),
          `${isOnlyIcon ? `p-0 ${widthBySizeForOnlyIcon[size]}` : ''}`
        )}
        disabled={loading}
        {...props}
        ref={ref}
      >
        {loading ? (
          <IconRenderer
            Icon={LoaderCircle}
            size={size as IconRendererProps['size']}
            className="animate-spin"
          />
        ) : (
          <>
            <IconRenderer
              Icon={iconStart}
              size={size as IconRendererProps['size']}
            />
            {label ? (
              <Text
                value={label}
                variant="label"
                size={textSize as IconRendererProps['size']}
                className={`mb-0 cursor-pointer ${
                  size === 'l' ? 'leading-none' : ''
                }`}
              />
            ) : null}
            <IconRenderer
              Icon={iconEnd}
              size={size as IconRendererProps['size']}
            />
          </>
        )}
      </Comp>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
