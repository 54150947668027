export const GET_ALL_ATTENDANCE_RECORD = 'GET_ALL_ATTENDANCE_RECORD';
export const EXPORT_ALL_RECORD = 'EXPORT_ALL_RECORD';
export const ATTENDANCE_API_SUCCESS = 'ATTENDANCE_API_SUCCESS';
export const ATTENDANCE_API_ERROR = 'ATTENDANCE_API_ERROR';
export const ATTENDANCE_API_PENDING = 'ATTENDANCE_API_PENDING';
export const ATTENDANCE_SUMMARY_API_SUCCESS = 'ATTENDANCE_SUMMARY_API_SUCCESS';
export const ATTENDANCE_SUMMARY_API_PENDING = 'ATTENDANCE_SUMMARY_API_PENDING';
export const EXPORT_API_SUCCESS = 'EXPORT_API_SUCCESS';
export const EXPORT_API_FAILURE = 'EXPORT_API_FAILURE';
export const EXPORT_API_PENDING = 'EXPORT_API_PENDING';
export const CREATE_ATTENDENCE_RECORD = 'CREATE_ATTENDENCE_RECORD';
export const ORG_VENDOR_ATTENDANCE = 'ORG_VENDOR_ATTENDANCE';
export const ORG_VENDOR_ATTENDANCE_COUNT = 'ORG_VENDOR_ATTENDANCE_COUNT';
export const VENDOR_PAYABLES = 'VENDOR_PAYABLES';
export const ATTENDANCE_SUMMARY = 'ATTENDANCE_SUMMARY';
export const ATTENDANCE_SUMMARY_COUNT = 'ATTENDANCE_SUMMARY_COUNT';
export const SELECTED_VENDOR = 'SELECTED_VENDOR';
export const SELECTED_LABOUR = 'SELECTED_LABOUR';
export const VENDOR_PROFILE = 'VENDOR_PROFILE';
export const LABOUR_PROFILE = 'LABOUR_PROFILE';
export const LABOUR_ATTENDANCE_SUMMARY = 'LABOUR_ATTENDANCE_SUMMARY';
export const VENDOR_ATTENDANCE_SUMMARY = 'VENDOR_ATTENDANCE_SUMMARY';
export const GET_ATTENDANCE_LOG = 'ATTENDANCE_LOG';
export const GET_NEW_ATTENDANCE_LOG = 'GET_NEW_ATTENDANCE_LOG';
export const CLEAR_ATTENDANCE_LOG = 'CLEAR_ATTENDANCE_LOG';
export const GET_WORKER_ATTENDANCE = 'GET_WORKER_ATTENDANCE';
export const SET_ADD_PAID_AMOUNT_ID = 'SET_ADD_PAID_AMOUNT_ID';
export const SET_VENDOR_WAGE_EDIT_ID = 'SET_VENDOR_WAGE_EDIT_ID';
export const SET_LABOUR_WAGE_EDIT_ID = 'SET_LABOUR_WAGE_EDIT_ID';
export const GET_LABOUR_LIST = 'GET_LABOUR_LIST';
export const GET_VENDOR_TRANSACTION_LIST = 'GET_VENDOR_TRANSACTION_LIST';
export const WORKER_ATTENDANCE_PROJECT_SUMMARY =
  'WORKER_ATTENDANCE_PROJECT_SUMMARY';
export const VENDOR_ATTENDANCE_LIST = 'VENDOR_ATTENDANCE_LIST';
export const VENDOR_LABOUR_LIST = 'VENDOR_LABOUR_LIST';
export const GET_VENDOR_PROJECT_LIST = 'GET_VENDOR_PROJECT_LIST';
export const GET_LABOUR_TRANSACTION_LIST = 'GET_LABOUR_TRANSACTION_LIST';
export const GET_LABOUR_PROJECT_LIST = 'GET_LABOUR_PROJECT_LIST';
export const ORG_LABOUR_ATTENDANCE_SUMMARY = 'ORG_LABOUR_ATTENDANCE_SUMMARY';
export const LABOUR_ATTENDANCE_LIST = 'LABOUR_ATTENDANCE_LIST';
export const GET_LABOUR_TOTAL_TO_PAY = 'GET_LABOUR_TOTAL_TO_PAY';
export const GET_VENDOR_TOTAL_TO_PAY = 'GET_VENDOR_TOTAL_TO_PAY';
export const VENDOR_PROFILE_SUMMARY = 'VENDOR_PROFILE_SUMMARY';
export const VENDOR_PROFILE_SUMMARY_PENDING = 'VENDOR_PROFILE_SUMMARY_PENDING';
export const LABOUR_PROFILE_SUMMARY = 'LABOUR_PROFILE_SUMMARY';
export const GET_MERGE_LABOUR_LIST = 'GET_MERGE_LABOUR_LIST';
export const GET_MERGE_VENDOR_LIST = 'GET_MERGE_VENDOR_LIST';
export const GET_PAYMENT_MODE = 'GET_PAYMENT_MODE';
export const GET_PAYMENT_DETAIL = 'GET_PAYMENT_DETAIL';

export const CREATE_LABOUR_PENDING = 'CREATE_LABOUR_PENDING';
export const CREATE_LABOUR_ERROR = 'CREATE_LABOUR_ERROR';

export const CREATE_VENDOR_PENDING = 'CREATE_VENDOR_PENDING';
export const CREATE_VENDOR_ERROR = 'CREATE_VENDOR_ERROR';

export const ADD_EXISTING_WORKER_PENDING = 'ADD_EXISTING_WORKER_PENDING';
export const GET_ALL_ASYNC_ATT_REPORT = 'GET_ALL_ASYNC_ATT_REPORT';
export const GET_ALL_ASYNC_UPDATE_REPORT = 'GET_ALL_ASYNC_UPDATE_REPORT';
export const GET_REPORT_ASYNC_DATA = 'GET_REPORT_ASYNC_DATA';
export const GET_ASYNC_REPORT_SUCCESS = 'GET_ASYNC_REPORT_SUCCESS';
export const GET_ASYNC_REPORT_PENDING = 'GET_ASYNC_REPORT_PENDING';
export const GET_ASYNC_REPORT_FAILURE = 'GET_ASYNC_REPORT_FAILURE';
export const GET_WORKER_WAGE_DETAILS = 'GET_WORKER_WAGE_DETAILS';
export const GET_WORKER_WAGE_PENDING = 'GET_WORKER_WAGE_PENDING';
export const CHECK_WORKER_EXISTS = 'CHECK_WORKER_EXISTS';
export const CHECK_WORKER_EXISTS_PENDING = 'CHECK_WORKER_EXISTS_PENDING';
export const LABOUR_WAGE_DATA = 'LABOUR_WAGE_DATA';
export const RESET_LABOUR_WAGE_DATA = 'RESET_LABOUR_WAGE_DATA';
export const PAYABLE_RECORDS = 'PAYABLE_RECORDS';
export const PROJECT_LABOUR_ATTENDANCE_SUMMARY =
  'PROJECT_LABOUR_ATTENDANCE_SUMMARY';
export const ORG_LABOUR_ATTENDANCE_SUMMARY_ALL =
  'ORG_LABOUR_ATTENDANCE_SUMMARY_ALL';
export const ORG_ATTENDANCE_ACTIVE_PROJECTS = 'ORG_ATTENDANCE_ACTIVE_PROJECTS';
export const ORG_ATTENDANCE_DATA = 'ORG_ATTENDANCE_DATA';
export const ORG_ATTENDANCE_ALL_PROJECTS = 'ORG_ATTENDANCE_ALL_PROJECTS';
export const DATE_WISE_ATTENDANCE_PAYABLE = 'DATE_WISE_ATTENDANCE_PAYABLE';
export const DATE_WISE_PROJECTS_LIST = 'DATE_WISE_PROJECTS_LIST';
export const VENDOR_LABOUR_LIST_WAGES = 'VENDOR_LABOUR_LIST_WAGES';
export const PAYABLE_RECORDS_ATTENDANCE = 'PAYABLE_RECORDS_ATTENDANCE';
export const ATTENDANCE_FINANCIAL_DATA = 'ATTENDANCE_FINANCIAL_DATA';
