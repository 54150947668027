export const ST_SEQUENCE_TYPES = {
  AUTO: 0,
  MANUAL: 1,
} as const;

export const ST_STATUS = Object.freeze({
  DRAFT: 0,
  APPROVAL_PENDING: 1,
  REJECTED: 2,
  APPROVED: 3,
  PARTIALLY_DELIVERED: 4,
  DELIVERED: 5,
  CANCELLED: 6,
});
