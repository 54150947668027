import { Dispatch } from 'redux';

import apiClient from '../../../../utils/ApiClient.util';
import {
  ADD_PO_ITEM,
  GET_PO_ITEM,
  GET_PO_ITEMS,
  UPDATE_PO_ITEM,
  PO_API_SUCCESS,
  PO_API_ERROR,
  PO_API_PENDING,
  GET_PO_SUMMARY,
  PO_FORM_SHOW,
  PO_ITEM_SELECTED,
  FORM_MODE,
  CLEAR_PO_DATA,
  CLEAR_SELECTED_PO,
  SET_ORG_PURCHASE_ORDERS,
  SET_PURCHASE_ORDERS_BY_ORG_SUMMARY,
  SET_GST_TYPE,
} from './PurchaseOrder.type';
import { setShowIndentDetails } from '../../common/utils/Utils.action';
import { SUBMIT_PENDING } from '../../common/Generic.type';
import { getIndentList } from '../indent/Indent.action';
import { PO_APIS } from '../../../../routes.constant';
import { track } from '../../common/Segment.action';
import { PURCHASE_ORDER_SEGMENT } from '../../../../constant/segment_constant';
import { eventDispatch, errorDispatch } from '../../../base';
import {
  PurchaseorderFilterProps,
  PurchaseOrderProps,
} from '../../../../interfaces/PurchaseOrder';
import {
  toastErrorMessage,
  toastSuccessMessage,
  parseString,
  toastLoadingMessage,
  openInNewTab,
} from '../../../../utils';
import { postRequest, putRequest } from '../../../../apiClient';
import { PO_TYPES } from '../../../../constant/po_constants';
import { EXCEL_UPLOAD_ERROR } from '../../common/document/Document.type';
import {
  SYNC_INDENT_PROFILE,
  SYNC_PRICING_HISTORY,
  SYNC_VIEW,
} from '../../common/refresh/Refresh.type';
import { customStringify } from '../../../../utils/Url.util';
import { ADD_MATERIAL_INVENTORY } from '../inventory/Inventory.type';
import { getAllCustomFields } from '../../common/project';

interface ISignatureProps {
  signature?:
    | {
        content_type: string;
        data: string;
      }
    | {
        _id: string;
      };
}

interface IOrgRequestBody {
  limit: number;
  page: number;
  dFilter?: PurchaseorderFilterProps;
  show_deleted?: boolean;
}

export const clearPOData = () => {
  return {
    type: CLEAR_PO_DATA,
  };
};

export const clearSelectedPO = () => {
  return {
    type: CLEAR_SELECTED_PO,
  };
};

export function getPOById(poId: string, cb?: Function) {
  const apiUrl = parseString(PO_APIS.getPOById, poId);
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(PO_API_PENDING));
    try {
      const { data } = await apiClient.get(apiUrl);
      if (data?.project_id) {
        dispatch(getAllCustomFields(data?.project_id) as any);
      }
      dispatch(eventDispatch(PO_API_SUCCESS));
      dispatch(eventDispatch(GET_PO_ITEM, data));
      if (cb && typeof cb === 'function') {
        cb(data);
      }
    } catch (error) {
      dispatch(errorDispatch(PO_API_ERROR, error));
    }
  };
}

export function getPOListRequest(params: any) {
  const apiUrl = parseString(PO_APIS.getPOListRequest, customStringify(params));
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await apiClient.get(apiUrl);
      dispatch(eventDispatch(PO_API_SUCCESS));
      dispatch(eventDispatch(GET_PO_ITEMS, data));
    } catch (error) {
      dispatch(errorDispatch(PO_API_ERROR, error));
      dispatch(eventDispatch(GET_PO_ITEMS, []));
    }
  };
}

export const getPOSummary = (poId: string) => {
  const apiUrl = parseString(PO_APIS.getPOSummary, poId);
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(PO_API_PENDING));
    try {
      const { data } = await apiClient.get(apiUrl);
      dispatch(eventDispatch(GET_PO_SUMMARY, data));
    } catch (error) {
      dispatch(errorDispatch(PO_API_ERROR, error));
    }
  };
};

export function createPurchaseOrder(
  apiUrl: string,
  reqBody: PurchaseOrderProps,
  fromIndent = false,
  cb?: Function
) {
  return async (dispatch: Dispatch<any>, getState: any) => {
    dispatch(eventDispatch(PO_API_PENDING));
    const projectId = getState().projectreducer.selectedProj.id;
    const showIndentDetails = getState().utilsReducer.indentDetailsShow;
    dispatch(eventDispatch(SUBMIT_PENDING));
    const toastId = toastLoadingMessage('Creating Purchase Order...');
    try {
      const { data } = await apiClient.post(apiUrl, reqBody);
      toastSuccessMessage(
        `PO with ID - ${data.po_display_id} created successfully`,
        toastId
      );
      var isRemark = false;
      var isAttached = false;
      var isCustom = false;
      if (Object.keys(reqBody?.custom_fields).length > 0) {
        isCustom = true;
      }
      var remarkCount = 0;
      var filesCount = 0;
      reqBody?.boms?.map(ele => {
        if (ele.attached_files) {
          filesCount++;
        }
        if (ele.remark) {
          remarkCount++;
        }
      });
      if (remarkCount > 0) {
        isRemark = true;
      }
      if (filesCount > 0) {
        isAttached = true;
      }
      dispatch(
        track(PURCHASE_ORDER_SEGMENT.PURCHASE_ORDER_CREATE_SUCCESS, {
          material_remark_added: { isRemark, remarkCount },
          material_photo_added: { isAttached, filesCount },
          custom_field_filled: isCustom,
        })
      );
      dispatch(eventDispatch(SUBMIT_PENDING));
      if (showIndentDetails) {
        dispatch(setShowIndentDetails(false));
      }
      dispatch(eventDispatch(SYNC_VIEW, true));
      dispatch(eventDispatch(ADD_PO_ITEM, data));
      dispatch(getPOListRequest({ project_id: projectId }));
      if (fromIndent) {
        dispatch(getIndentList(projectId));
      }
      if (cb && typeof cb === 'function') {
        cb(data);
      }
    } catch (error) {
      dispatch(errorDispatch(PO_API_ERROR, error));
      dispatch(track(PURCHASE_ORDER_SEGMENT.PURCHASE_ORDER_CREATE_ERROR));
      toastErrorMessage(error?.response?.data?.message, toastId);
    } finally {
      dispatch(eventDispatch(PO_API_SUCCESS));
    }
  };
}

export function updatePurchaseOrder(
  poId: string,
  reqBody: PurchaseOrderProps,
  cb?: Function,
  showToast = true
) {
  const { _id, ...body } = reqBody;
  const apiUrl = parseString(PO_APIS.updatePO, poId);

  const poDisplayId = reqBody.po_display_id;
  if (reqBody.po_type !== PO_TYPES.EXTERNAL) {
    delete body.po_display_id;
  }

  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(SUBMIT_PENDING));
    let toastId = '';

    if (showToast) {
      toastId = toastLoadingMessage(`Updating the PO with ID - ${poDisplayId}`);
    }

    try {
      const { data } = await apiClient.put(apiUrl, body);

      if (showToast) {
        toastSuccessMessage(
          `PO with ID - ${poDisplayId} updated successfully`,
          toastId
        );
      }

      dispatch(eventDispatch(PO_API_SUCCESS));
      dispatch(eventDispatch(SUBMIT_PENDING));
      dispatch(eventDispatch(UPDATE_PO_ITEM, data));
      dispatch(getPOById(data._id));
      dispatch(eventDispatch(PO_ITEM_SELECTED, data));
      dispatch(eventDispatch(SYNC_VIEW, true));

      if (cb && typeof cb === 'function') {
        cb(data);
      }
    } catch (error) {
      dispatch(errorDispatch(PO_API_ERROR, error));
      if (showToast) {
        toastErrorMessage(
          error?.response?.data?.message ||
            `Error in updating PO with ID - ${poDisplayId}`,
          toastId
        );
      }
    }
  };
}

export function deletePurchaseOrder(poId: string) {
  const apiUrl = parseString(PO_APIS.deletePO, poId);
  return async (dispatch: Dispatch<any>, getState: any) => {
    const projectId = getState().projectreducer.selectedProj.id;
    try {
      await apiClient.delete(apiUrl);
      dispatch(eventDispatch(PO_API_SUCCESS));
      dispatch(eventDispatch(SYNC_INDENT_PROFILE, true));
      dispatch(getPOListRequest({ project_id: projectId }));
      dispatch(eventDispatch(SYNC_VIEW, true));
      dispatch(eventDispatch(SYNC_PRICING_HISTORY, true));
      toastSuccessMessage('Successfully deleted PO Item');
    } catch (error) {
      dispatch(errorDispatch(PO_API_ERROR, error));
      toastErrorMessage(
        error?.response?.data?.message || 'Unable to delete PO'
      );
    }
  };
}

export function setShowPOForm(state: boolean, formMode?: string, item?: any) {
  return (dispatch: Dispatch) => {
    dispatch(eventDispatch(PO_API_PENDING));
    try {
      dispatch(eventDispatch(PO_API_SUCCESS));
      if (item !== undefined) {
        dispatch(eventDispatch(PO_ITEM_SELECTED, item));
      }
      if (formMode !== undefined) {
        dispatch(eventDispatch(FORM_MODE, formMode));
      }
      dispatch(eventDispatch(PO_FORM_SHOW, state));
    } catch (error) {
      dispatch(errorDispatch(PO_API_ERROR, error));
    }
  };
}

export function approvePo(poId: string, signatureImage: string) {
  const apiUrl = parseString(PO_APIS.approve, poId);
  return async (dispatch: Dispatch<any>, getState: any) => {
    const projectId = getState().projectreducer.selectedProj.id;
    const signatureData = getState().userreducer.signature;
    const toastId = toastLoadingMessage('Approving...');
    dispatch(eventDispatch(SUBMIT_PENDING));
    try {
      const putData: ISignatureProps = {};
      if (signatureImage) {
        putData.signature = {
          content_type: 'base64',
          data: signatureImage.split(',')[1],
        };
      } else {
        putData.signature = { _id: signatureData?._id };
      }
      const { data } = await putRequest(apiUrl, putData);
      dispatch(eventDispatch(PO_API_SUCCESS));
      dispatch(track(PURCHASE_ORDER_SEGMENT.PO_APPROVE_SUCCESS));
      dispatch(eventDispatch(SYNC_INDENT_PROFILE, true));
      toastSuccessMessage(
        `PO approved succesfully with ID - ${data.po_display_id}`,
        toastId
      );
      dispatch(eventDispatch(UPDATE_PO_ITEM, data));
      dispatch(setShowPOForm(false));
      dispatch(getPOById(data._id));
      dispatch(getPOListRequest({ project_id: projectId }));
      dispatch(eventDispatch(SYNC_VIEW, true));
    } catch (error) {
      dispatch(errorDispatch(PO_API_ERROR, error));
      toastErrorMessage(error?.errorMsg || "Couldn't approve the po", toastId);
    }
  };
}

export function rejectPo(poId: string, signatureImage: string) {
  const apiUrl = parseString(PO_APIS.reject, poId);
  const toastId = toastLoadingMessage('Rejecting...');
  return async (dispatch: Dispatch<any>, getState: any) => {
    const projectId = getState().projectreducer.selectedProj.id;
    const signatureData = getState().userreducer.signature;
    dispatch(eventDispatch(SUBMIT_PENDING));
    try {
      const putData: ISignatureProps = {};
      if (signatureImage) {
        putData.signature = {
          content_type: 'base64',
          data: signatureImage.split(',')[1],
        };
      } else {
        putData.signature = { _id: signatureData?._id };
      }
      const { data } = await putRequest(apiUrl, putData);
      dispatch(eventDispatch(PO_API_SUCCESS));
      dispatch(eventDispatch(SYNC_INDENT_PROFILE, true));
      toastSuccessMessage(
        `PO rejected succesfully with ID - ${data.po_display_id}`,
        toastId
      );
      dispatch(eventDispatch(UPDATE_PO_ITEM, data));
      dispatch(track(PURCHASE_ORDER_SEGMENT.PO_REJECT_SUCCESS));
      dispatch(setShowPOForm(false));
      dispatch(getPOById(data._id));
      dispatch(getPOListRequest({ project_id: projectId }));
      dispatch(eventDispatch(SYNC_VIEW, true));
    } catch (error) {
      dispatch(errorDispatch(PO_API_ERROR, error));
      toastErrorMessage(error?.errorMsg || 'PO reject failed!', toastId);
    }
  };
}

export function cancelPo(poId: string) {
  const apiUrl = parseString(PO_APIS.cancel, poId);
  return async (dispatch: Dispatch<any>, getState: any) => {
    const projectId = getState().projectreducer.selectedProj.id;
    dispatch(eventDispatch(SUBMIT_PENDING));
    try {
      const { data } = await apiClient.put(apiUrl);
      dispatch(eventDispatch(PO_API_SUCCESS));
      toastSuccessMessage(
        `PO cancelled succesfully with ID - ${data.po_display_id}`
      );
      dispatch(eventDispatch(UPDATE_PO_ITEM, data));
      dispatch(setShowPOForm(false));
      dispatch(getPOById(data._id));
      dispatch(getPOListRequest({ project_id: projectId }));
      dispatch(eventDispatch(SYNC_VIEW, true));
    } catch (error) {
      dispatch(errorDispatch(PO_API_ERROR, error));
      toastErrorMessage(error?.errorMsg || 'PO cancellation failed!');
    }
  };
}

export const sendPOEmail = (data: { email_ids: string[] }, poId: string) => {
  const apiUrl = parseString(PO_APIS.emailPO, poId);
  return async (dispatch: Dispatch<any>) => {
    const toastId = toastLoadingMessage('Please wait while we send your mails');
    dispatch(eventDispatch(PO_API_PENDING));
    try {
      await apiClient.put(apiUrl, data);
      toastSuccessMessage('Emails sent successfully', toastId);
    } catch (error) {
      dispatch(errorDispatch(PO_API_ERROR, error));
      toastErrorMessage(
        error?.errorMsg || 'Some error occured while sending the emails',
        toastId
      );
    }
  };
};

export function getPurchaseOrdersByOrganisation(
  orgId: string,
  reqBody: IOrgRequestBody,
  showDeleted: boolean = false
) {
  const apiUrl = parseString(PO_APIS.getPOByOrg, orgId);
  reqBody.show_deleted = showDeleted;
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(PO_API_PENDING));
    try {
      const { data } = await apiClient.post(apiUrl, reqBody);
      dispatch(eventDispatch(SET_ORG_PURCHASE_ORDERS, data));
      dispatch(eventDispatch(PO_API_SUCCESS));
    } catch (error) {
      dispatch(errorDispatch(PO_API_ERROR, error));
      toastErrorMessage(error?.errorMsg || 'Something went wrong!');
    }
  };
}

export function getPurchaseOrdersSummaryByOrg(
  orgId: string,
  reqBody: any,
  showDeleted: boolean = false
) {
  const apiUrl = parseString(PO_APIS.getPOByOrgSummary, orgId);
  reqBody.show_deleted = showDeleted;
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(PO_API_PENDING));
    try {
      const { data } = await apiClient.post(apiUrl, reqBody);
      dispatch(eventDispatch(SET_PURCHASE_ORDERS_BY_ORG_SUMMARY, data));
      dispatch(eventDispatch(PO_API_SUCCESS));
    } catch (error) {
      dispatch(errorDispatch(PO_API_ERROR, error));
      toastErrorMessage(error?.errorMsg || 'Something went wrong!');
    }
  };
}

export function downloadPurchaseOrderReport(
  orgId: string,
  reqBody: { filters: PurchaseorderFilterProps }
) {
  const apiUrl = parseString(PO_APIS.downloadPOReportByOrg, orgId);
  return async (dispatch: Dispatch) => {
    const toastId = toastLoadingMessage('Generating your report...');
    try {
      const { data } = await apiClient.post(apiUrl, reqBody);
      toastSuccessMessage('Report generated successfully', toastId);
      setTimeout(() => openInNewTab(data), 400);
    } catch (error) {
      dispatch(errorDispatch(PO_API_ERROR, error));
      toastErrorMessage(
        error?.errorMsg || 'There was some error while generating the report',
        toastId
      );
    }
  };
}

export function bulkCreatePO(reqBody: any, cb?: Function) {
  return async (dispatch: Dispatch<any>) => {
    const toastId = toastLoadingMessage('Uploading Purchase Orders...');
    try {
      const { data } = await postRequest(PO_APIS.bulkCreatePO, reqBody);
      cb?.();
      toastSuccessMessage(
        `${data?.count} Purchase Orders created successfully`,
        toastId
      );
      dispatch(eventDispatch(SYNC_VIEW, true));
    } catch (err) {
      toastErrorMessage(
        err?.errorMsg || 'Failed to upload Purchase Orders',
        toastId
      );
      dispatch(
        eventDispatch(EXCEL_UPLOAD_ERROR, err?.response?.data?.errors || [])
      );
    }
  };
}

export function createExternalPO(reqBody: PurchaseOrderProps, cb?: Function) {
  return async (dispatch: Dispatch<any>, getState: any) => {
    const projectId = getState().projectreducer.selectedProj.id;
    const showIndentDetails = getState().utilsReducer.indentDetailsShow;
    dispatch(eventDispatch(SUBMIT_PENDING));
    const toastId = toastLoadingMessage('Creating Purchase Order...');

    try {
      const { data } = await postRequest(PO_APIS.createExternalPO, reqBody);

      toastSuccessMessage(
        `PO with ID - ${data.po_display_id} created successfully`,
        toastId
      );
      dispatch(track(PURCHASE_ORDER_SEGMENT.PURCHASE_ORDER_CREATE_SUCCESS));

      dispatch(eventDispatch(PO_API_SUCCESS));
      dispatch(eventDispatch(SUBMIT_PENDING));

      if (showIndentDetails) {
        dispatch(setShowIndentDetails(false));
      }
      dispatch(eventDispatch(SYNC_VIEW, true));

      dispatch(eventDispatch(ADD_PO_ITEM, data));
      dispatch(getPOListRequest({ project_id: projectId }));

      if (cb && typeof cb === 'function') {
        cb(data);
      }
    } catch (error) {
      dispatch(errorDispatch(PO_API_ERROR, error));
      dispatch(track(PURCHASE_ORDER_SEGMENT.PURCHASE_ORDER_CREATE_ERROR));
      toastErrorMessage(error?.response?.data?.message || 'Error', toastId);
    }
  };
}

export function createProPO(reqBody: PurchaseOrderProps, cb?: Function) {
  return async (dispatch: Dispatch<any>, getState: any) => {
    const projectId = getState().projectreducer.selectedProj.id;
    const showIndentDetails = getState().utilsReducer.indentDetailsShow;
    dispatch(eventDispatch(SUBMIT_PENDING));
    const toastId = toastLoadingMessage('Creating Purchase Order...');

    try {
      const { data } = await postRequest(PO_APIS.createProPO, reqBody);

      toastSuccessMessage(
        `PO with ID - ${data.po_display_id} created successfully`,
        toastId
      );
      dispatch(track(PURCHASE_ORDER_SEGMENT.PURCHASE_ORDER_CREATE_SUCCESS));

      dispatch(eventDispatch(PO_API_SUCCESS));
      dispatch(eventDispatch(SUBMIT_PENDING));

      if (showIndentDetails) {
        dispatch(setShowIndentDetails(false));
      }
      dispatch(eventDispatch(SYNC_VIEW, true));

      dispatch(eventDispatch(ADD_PO_ITEM, data));
      dispatch(getPOListRequest({ project_id: projectId }));

      dispatch(eventDispatch(ADD_MATERIAL_INVENTORY, {}));
      if (cb && typeof cb === 'function') {
        cb(data);
      }
    } catch (error) {
      dispatch(errorDispatch(PO_API_ERROR, error));
      dispatch(track(PURCHASE_ORDER_SEGMENT.PURCHASE_ORDER_CREATE_ERROR));
      toastErrorMessage(
        error?.response?.data?.message || 'Something went wrong',
        toastId
      );
    }
  };
}

export function setGstTypeAction(gst_type: number) {
  return (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(SET_GST_TYPE, gst_type));
    dispatch(track(PURCHASE_ORDER_SEGMENT.PO_CREATE_SELECT_GST_SUCCESS));
  };
}
