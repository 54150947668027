/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RotateZoomImage from './RotatingImage';
import SwipeableViews from 'react-swipeable-views';
import { ChevronLeft, ChevronRight, Download, RotateCcw } from 'lucide-react';

import { IconButton } from '@mui/material';
import { KEY_CODES } from '../../../constant/key_codes';
import './styles.scss';
import { Dialog, DialogContent, DialogHeader } from '../../ui/Dialog';
import { Button } from '@/components/ui/Button';

const MultipleImagesModal = ({
  show,
  onHide,
  images,
  index,
  handleSetIndex,
  caption = '',
  onClick = () => {},
  clickableIndexes = [],
}) => {
  const initialArray = images?.map(() => 0);
  const [rotateArray, setRotateArray] = useState(initialArray);
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images?.length;

  useEffect(() => {
    setActiveStep(index);
  }, [index, images]);

  useEffect(() => {
    setRotateArray(images?.map(() => 0));
  }, [images]);

  const onRotate = () => {
    const newArr = rotateArray.slice();
    newArr[activeStep] = rotateArray[activeStep] + 1;
    setRotateArray(newArr);
  };

  const handleNext = () => {
    if (activeStep === maxSteps - 1) return;

    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep == 0) return;
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = step => {
    setActiveStep(step);
    handleSetIndex(step);
  };

  const handleKeyDown = event => {
    switch (event?.code) {
      case KEY_CODES.ARROW_RIGHT:
        handleNext();
        break;
      case KEY_CODES.ARROW_LEFT:
        handleBack();
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleDownload = activeStep => {
    window.open(images[activeStep], '_blank');
  };

  useEffect(() => {
    if (show) {
      const dialog = document.querySelector('.MuiDialog-root');
      if (dialog) {
        dialog.focus();
      }
    }
  }, [show]);

  return (
    <>
      <Dialog open={show} onOpenChange={onHide} className="w-[800px] p-0">
        <DialogContent className="w-[800px] p-4 ">
          <DialogHeader className="w-100">
            <div className="flex justify-between">
              <div className="flex gap-2  p-0">
                <IconButton onClick={onRotate}>
                  <RotateCcw className="w-6 h-6" />
                </IconButton>
                <IconButton onClick={() => handleDownload(activeStep)}>
                  <Download className="w-6 h-6" />
                </IconButton>
              </div>
            </div>
          </DialogHeader>
          <div className="flex flex-col items-center justify-center">
            <div className="w-full max-w-lg">
              <SwipeableViews
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {images?.map((image, index) => (
                  <div key={image}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <div className="text-center">
                        <RotateZoomImage
                          src={image}
                          rotate={rotateArray[index]}
                          index={index}
                          onClick={onClick}
                          zoomable={!clickableIndexes?.includes(index)}
                        />
                      </div>
                    ) : null}
                  </div>
                ))}
              </SwipeableViews>
              {caption && <p className="text-center mt-2">{caption}</p>}
            </div>
          </div>

          {images && images?.length > 1 && (
            <div className="mt-4 flex justify-between items-center">
              <Button
                onClick={handleBack}
                disabled={activeStep === 0}
                className="flex items-center"
              >
                <ChevronLeft className="w-4 h-4" /> Previous
              </Button>
              <Button
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
                className="flex items-center"
              >
                Next <ChevronRight className="w-4 h-4" />
              </Button>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

MultipleImagesModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  images: PropTypes.array,
  index: PropTypes.number,
  caption: PropTypes.string,
};

export default MultipleImagesModal;
