import { Dispatch } from 'redux';

import {
  GET_TODAYS_MILESTONE,
  CLEAR_DASHBOARD_DATA,
  DASHBOARD_API_ERROR,
  MILESTONE_PENDING,
  GET_30_DAYS_ACTIVITIES,
  GET_RECENTLY_UPDATED_TASKS,
  GET_RECENTLY_UPDATED_TASKS_PENDING,
  GET_UPCOMING_TASKS,
  GET_UPCOMING_TASKS_PENDING,
  GET_DEADLINE_TASKS,
  GET_DEADLINE_TASKS_PENDING,
  GET_ORG_UPCOMING_TASKS_PENDING,
  GET_ORG_UPCOMING_TASKS,
  GET_ORG_DEADLINE_TASKS,
  GET_ORG_DEADLINE_TASKS_PENDING,
  GET_ORG_UPDATED_TASKS,
  GET_ORG_UPDATED_TASKS_PENDING,
  GET_PACKAGE_WISE_TASK_SUMMARY,
  GET_TAG_WISE_TASK_SUMMARY,
  GET_S_CURVE_DATA_SUCCESS,
  GET_USER_WISE_TASK_SUMMARY,
  GET_TASK_COUNT_DETAILS,
  DASHBOARD_API_SUCCESS,
  GET_FILTERED_TASK_PROGRESS,
  GET_BROAD_PLAN_PROJECT_DASHBOARD_STATUS,
  GET_BROAD_PLAN_TOP_ACTIVE_USERS,
  GET_DASHBOARD_TASK_SUMMARY,
} from './Dashboard.type';
import {
  API_VERSIONS,
  DASHBOARD_APIS,
  S_CURVE_API,
  TASK_APIS,
} from '../../../routes.constant';
import { getRequest, postRequest } from '../../../apiClient';
import { eventDispatch, errorDispatch } from '../../base';
import { parseString, toastErrorMessage } from '../../../utils';
import {
  OrgTaskQueryV3Props,
  OrgTaskUpdatesProps,
  TaskQueryV3Props,
} from '../../../interfaces/Task';
import { DashboardSummaryProps } from '../../../interfaces/Base';
import {
  DashboardTaskSummaryProps,
  sCurveProps,
} from '../../../interfaces/Dashboard';
import { customStringify } from '../../../utils/Url.util';
import { track } from '../common/Segment.action';
import { APPLY_FILTER_CLICK_SUCCESS } from '../../../constant/events/UI/dashboard';
import { DASHBOARD_FILTER_SOURCES } from '../../../constant';

export const getYesterdayMilestone = (
  url: string,
  project_id: string,
  date: number
) => {
  const apiUrl = `${url}/${project_id}/?${customStringify({ date: date })}`;

  return async (dispatch: Dispatch) => {
    try {
      const response = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_TODAYS_MILESTONE, response));
      dispatch(eventDispatch(MILESTONE_PENDING, false));
    } catch (err) {
      dispatch(errorDispatch(DASHBOARD_API_ERROR, err));
      dispatch(eventDispatch(MILESTONE_PENDING, false));
    } finally {
      dispatch(eventDispatch(MILESTONE_PENDING, true));
    }
  };
};

export const getLast30DaysActivities = (projectId: string) => {
  const apiUrl = parseString(DASHBOARD_APIS.getLast30DaysActivities, projectId);
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(GET_30_DAYS_ACTIVITIES, data));
    } catch (err) {
      dispatch(errorDispatch(DASHBOARD_API_ERROR, err));
    }
  };
};

export const clearDashboardData = () => {
  return {
    type: CLEAR_DASHBOARD_DATA,
  };
};

export const getUpcomingTasks =
  (query: TaskQueryV3Props, page: number, limit: number, projectId: string) =>
  async (dispatch: Dispatch<any>) => {
    try {
      query.sortOpts = [{ key: 'start_date', order: 1 }];
      dispatch(eventDispatch(GET_UPCOMING_TASKS_PENDING, true));
      const apiUrl = parseString(TASK_APIS.getProjectPlan, projectId);
      const reqBody = {
        page,
        limit,
        ...query.filters,
      };

      const { data } = await postRequest(apiUrl, reqBody);
      dispatch(eventDispatch(GET_UPCOMING_TASKS, data));
      dispatch(
        track(APPLY_FILTER_CLICK_SUCCESS, {
          filter_option: DASHBOARD_FILTER_SOURCES.UPCOMING_TASKS,
        })
      );
    } catch (err) {
      dispatch(errorDispatch(DASHBOARD_API_ERROR, err));
    } finally {
      dispatch(eventDispatch(GET_UPCOMING_TASKS_PENDING, false));
    }
  };

export const getTaskCountDetails =
  (
    projectId: string,
    query?: {
      package_ids: string;
      tags: string;
      assigned_users: string;
      start_date_start: string | number;
      start_date_end: string | number;
      end_date_start: string | number;
      end_date_end: string | number;
    }
  ) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(eventDispatch(DASHBOARD_API_SUCCESS, true));
      const apiUrl = parseString(
        DASHBOARD_APIS.getPlanStatusCountWithFilter,
        projectId,
        customStringify(query)
      );
      const { data } = await getRequest(apiUrl, API_VERSIONS.V2);
      dispatch(eventDispatch(GET_TASK_COUNT_DETAILS, data.taskCount));
      dispatch(eventDispatch(GET_FILTERED_TASK_PROGRESS, data));
      dispatch(
        track(APPLY_FILTER_CLICK_SUCCESS, {
          filter_option: DASHBOARD_FILTER_SOURCES.PROJECT_STATUS,
        })
      );
    } catch (err) {
      dispatch(errorDispatch(DASHBOARD_API_ERROR, err));
    } finally {
      dispatch(eventDispatch(DASHBOARD_API_SUCCESS, false));
    }
  };

export const getInProgressTasks =
  (
    projectId: string,
    page: number,
    limit: number,
    query?: { start_date: number; end_date: number }
  ) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(eventDispatch(GET_RECENTLY_UPDATED_TASKS_PENDING, true));
      const apiUrl = parseString(
        DASHBOARD_APIS.getInProgressTasks,
        projectId,
        customStringify({ page, limit, ...query })
      );
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(GET_RECENTLY_UPDATED_TASKS, data));
      dispatch(
        track(APPLY_FILTER_CLICK_SUCCESS, {
          filter_option: DASHBOARD_FILTER_SOURCES.TASK_UPDATES,
        })
      );
    } catch (err) {
      toastErrorMessage(err.errorMsg || `Can't get task update`);
      dispatch(errorDispatch(DASHBOARD_API_ERROR, err));
    } finally {
      dispatch(eventDispatch(GET_RECENTLY_UPDATED_TASKS_PENDING, false));
    }
  };

export const getDeadlineTasks =
  (query: TaskQueryV3Props, page: number, limit: number) =>
  async (dispatch: Dispatch<any>) => {
    try {
      query.sortOpts = [{ key: 'end_date', order: 1 }];
      const taskq = JSON.stringify(query);

      dispatch(eventDispatch(GET_DEADLINE_TASKS_PENDING, true));
      const apiUrl = parseString(
        TASK_APIS.taskList,
        customStringify({ taskq, page, limit })
      );
      const { data } = await getRequest(apiUrl, API_VERSIONS.V3);
      dispatch(eventDispatch(GET_DEADLINE_TASKS, data));
      dispatch(
        track(APPLY_FILTER_CLICK_SUCCESS, {
          filter_option: DASHBOARD_FILTER_SOURCES.DEADLINE_TASKS,
        })
      );
    } catch (err) {
      dispatch(errorDispatch(DASHBOARD_API_ERROR, err));
    } finally {
      dispatch(eventDispatch(GET_DEADLINE_TASKS_PENDING, false));
    }
  };

export const getTasksSummary =
  (query: DashboardSummaryProps) => async (dispatch: Dispatch) => {
    const apiUrl = parseString(
      DASHBOARD_APIS.getTasksSummary,
      customStringify(query)
    );

    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(GET_DASHBOARD_TASK_SUMMARY, data));
    } catch (err) {
      dispatch(errorDispatch(DASHBOARD_API_ERROR, err));
    }
  };

export const getOrgUpcomingTasks =
  (query: OrgTaskQueryV3Props, page: number, limit: number) =>
  async (dispatch: Dispatch) => {
    try {
      query.sortOpts = [{ key: 'start_date', order: 1 }];
      const taskq = JSON.stringify(query);

      dispatch(eventDispatch(GET_ORG_UPCOMING_TASKS_PENDING, true));
      const apiUrl = parseString(
        TASK_APIS.getOrgTasks,
        customStringify({ taskq, page, limit })
      );
      const { data } = await getRequest(apiUrl, API_VERSIONS.V3);
      dispatch(eventDispatch(GET_ORG_UPCOMING_TASKS, data));
    } catch (err) {
      dispatch(errorDispatch(DASHBOARD_API_ERROR, err));
    } finally {
      dispatch(eventDispatch(GET_ORG_UPCOMING_TASKS_PENDING, false));
    }
  };

export const getOrgDeadlineTasks =
  (query: OrgTaskQueryV3Props, page: number, limit: number) =>
  async (dispatch: Dispatch) => {
    try {
      query.sortOpts = [{ key: 'end_date', order: 1 }];
      const taskq = JSON.stringify(query);

      dispatch(eventDispatch(GET_ORG_DEADLINE_TASKS_PENDING, true));
      const apiUrl = parseString(
        TASK_APIS.getOrgTasks,
        customStringify({ taskq, page, limit })
      );
      const { data } = await getRequest(apiUrl, API_VERSIONS.V3);
      dispatch(eventDispatch(GET_ORG_DEADLINE_TASKS, data));
    } catch (err) {
      dispatch(errorDispatch(DASHBOARD_API_ERROR, err));
    } finally {
      dispatch(eventDispatch(GET_ORG_DEADLINE_TASKS_PENDING, false));
    }
  };

export const getOrgInProgressTasks =
  (query: OrgTaskUpdatesProps, page: number, limit: number) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(eventDispatch(GET_ORG_UPDATED_TASKS_PENDING, true));
      const apiUrl = parseString(
        DASHBOARD_APIS.getOrgInProgressTasks,
        customStringify({ only_updated_task: true, page, limit, ...query })
      );
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(GET_ORG_UPDATED_TASKS, data));
    } catch (err) {
      dispatch(errorDispatch(DASHBOARD_API_ERROR, err));
    } finally {
      dispatch(eventDispatch(GET_ORG_UPDATED_TASKS_PENDING, false));
    }
  };

export function getPackageWiseTaskSummary(projectId: string) {
  return async (dispatch: Dispatch) => {
    try {
      const apiUrl = parseString(
        DASHBOARD_APIS.getPackageWiseTaskSummary,
        projectId
      );

      const { data } = await getRequest<DashboardTaskSummaryProps[]>(
        apiUrl,
        API_VERSIONS.V1
      );

      dispatch(eventDispatch(GET_PACKAGE_WISE_TASK_SUMMARY, data));
    } catch (err) {
      dispatch(errorDispatch(DASHBOARD_API_ERROR, err));
    }
  };
}

export function getTagWiseTaskSummary(projectId: string) {
  return async (dispatch: Dispatch) => {
    try {
      const apiUrl = parseString(
        DASHBOARD_APIS.getTagWiseTaskSummary,
        projectId
      );

      const { data } = await getRequest<DashboardTaskSummaryProps[]>(
        apiUrl,
        API_VERSIONS.V1
      );

      dispatch(eventDispatch(GET_TAG_WISE_TASK_SUMMARY, data));
    } catch (err) {
      dispatch(errorDispatch(DASHBOARD_API_ERROR, err));
    }
  };
}

export function getUserWiseTaskSummary(projectId: string) {
  return async (dispatch: Dispatch) => {
    try {
      const apiUrl = parseString(
        DASHBOARD_APIS.getUserWiseTaskSummary,
        projectId
      );

      const { data } = await getRequest<DashboardTaskSummaryProps[]>(
        apiUrl,
        API_VERSIONS.V1
      );

      dispatch(eventDispatch(GET_USER_WISE_TASK_SUMMARY, data));
    } catch (err) {
      dispatch(errorDispatch(DASHBOARD_API_ERROR, err));
    }
  };
}

export function getSCurveData(projectId: string, selectedBaselineId: string) {
  return async (dispatch: Dispatch) => {
    try {
      const apiUrl = parseString(
        S_CURVE_API.sCurve,
        projectId,
        customStringify({ baseline_id: selectedBaselineId })
      );

      const { data } = await getRequest<sCurveProps[]>(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(GET_S_CURVE_DATA_SUCCESS, data));
    } catch (err) {
      dispatch(errorDispatch(DASHBOARD_API_ERROR, err));
    }
  };
}

export const getBroadPlanProjectDashboardDetails =
  (query: { project_id: string; assigned_users?: string }) =>
  async (dispatch: Dispatch) => {
    try {
      const apiUrl = parseString(
        DASHBOARD_APIS.broadPlanDashboard,
        customStringify(query)
      );

      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_BROAD_PLAN_PROJECT_DASHBOARD_STATUS, data));
    } catch (err) {
      dispatch(errorDispatch(DASHBOARD_API_ERROR, err));
    }
  };

export const getBroadPlanTopActiveUsers =
  (query: { project_id: string; assigned_users?: string }) =>
  async (dispatch: Dispatch) => {
    try {
      const apiUrl = parseString(
        DASHBOARD_APIS.broadPlanTopActiveUsers,
        customStringify(query)
      );

      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_BROAD_PLAN_TOP_ACTIVE_USERS, data));
    } catch (err) {
      dispatch(errorDispatch(DASHBOARD_API_ERROR, err));
    }
  };
