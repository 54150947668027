import * as Sentry from '@sentry/react';

import {
  LOGIN,
  GET_USER,
  GET_USER_PENDING,
  LOGIN_CHECK,
  REQ_SUPPORT_CALL_SUCCESS,
  UPDATE_USER_DETAILS,
  USER_API_PENDING,
  USER_API_SUCCESS,
  AUTH_VIEW_INDEX_CHANGE,
  USER_API_ERROR,
  LOGOUT,
  SEND_USER_OTP,
  SEND_USER_OTP_PENDING,
  VERIFY_OTP,
  VERIFY_OTP_PENDING,
  EDIT_USER_DISPLAYNAME_PENDING,
  GET_USER_ORGS,
  GET_USER_ORGS_PENDING,
  OTP_ERROR,
  VERIFY_USER_EMAIL_PENDING,
  SET_TOKEN_EXPIRY,
  GET_SELF_PERMISSION,
  GET_SIGNATURE,
  SET_COUNTRIES,
  REVOKE_USER_PERMISSION,
} from './User.type';
import { ACTION_NAME } from '../../../../constant/action_constants';
import { POWERPLAY_WEB_VERSION } from '../../../../constants';
import { ReducerProps } from '../../../../interfaces/Base';
import {
  UserPermissionsMapProps,
  WebAccessProps,
} from '../../../../interfaces/Team';

const initialPendingState = {
  getUserPending: true,
  otpPending: false,
  verifyOtpPending: false,
  editUserDisplaynamePending: false,
  getUserOrgsPending: false,
  verifyUserEmailPending: false,
};

const initialState = {
  ...initialPendingState,
  _id: null,
  isAuth: false,
  isVerified: false,
  exists: true,
  error: null,
  confirming: true,
  updateUserDetailsSuccess: false,
  updateUserDetailsErr: false,
  pending: false,
  callRequestSuccess: false,
  registerResponse: null,
  role: 'common',
  email: '',
  displayName: '',
  is_mobile_verified: false,
  org_id: '',
  org_name: '',
  user_type: 'admin',
  photo_url: '',
  mobile_number: '',
  guide: {
    work_category: 0,
    project_plan: 0,
    work_category_detail: 0,
    manage_task: 0,
  },
  authViewIndex: 0,
  forgetPasswordViewIndex: 0,
  completeSignupViewIndex: 0,
  phoneNumber: '',
  country_code: '',
  userOrgs: [],
  user: {},
  otpError: null,
  is_email_verified: true,
  expiredToken: false,
  isLogout: false,
  userPermissionMap: new Map() as UserPermissionsMapProps,
  subscription: null,
  signature: null,
  otp_preference: {
    email_otp: false,
    whatsapp_otp: false,
  },
  supported_countries: [],
};

const setUserPermissions = (payload: any) => {
  const access: WebAccessProps[] = payload.access || [];
  const viewPermissions = access.filter((permission: any) => {
    return permission.permission_type.some(
      (type: any) =>
        type.permission === ACTION_NAME.VIEW && type.permission_value
    );
  });

  Sentry.setUser({
    email: payload.email,
    id: payload._id,
    mobile_number: payload.mobile_number,
    ip_address: '{{auto}}',
    version: POWERPLAY_WEB_VERSION,
  });

  const userPermissionMap: UserPermissionsMapProps = access.reduce(
    (map, resource) => {
      const obj = {
        permissions: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
        access_type: resource.access_type,
        resource_access: resource.resource_access,
        view_access: resource.access_type !== 0 && resource.resource_access,
        visible_columns: resource.visible_columns,
      };

      for (const perm of resource.permission_type) {
        obj.permissions[perm.permission] = perm.permission_value;
      }

      map.set(resource.resource_id, obj);

      return map;
    },
    new Map()
  );

  return {
    ...payload,
    userPermissionMap,
    subscription: payload.subscription,
    user: { ...payload, access, viewPermissions },
  };
};

function UserReducer(
  state = initialState,
  { type, payload, error }: ReducerProps
) {
  switch (type) {
    case LOGIN:
      return { ...state, ...payload, isLogout: false };
    case GET_USER: {
      const updatedData = setUserPermissions(payload);
      return {
        ...state,
        team_id: updatedData.team_id || payload.team_id,
        user_id: updatedData.user_id || payload.user_id,
        ...updatedData,
      };
    }
    case REVOKE_USER_PERMISSION: {
      const userPermissionMap = new Map(state.userPermissionMap);
      userPermissionMap.delete(payload);
      return { ...state, userPermissionMap };
    }
    case GET_USER_PENDING:
      return {
        ...state,
        getUserPending: payload,
      };
    case USER_API_ERROR:
      return { ...state, error };

    case LOGIN_CHECK:
      return { ...state, ...payload };
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        displayName: payload.displayName,
        photo_url: payload.photo_url,
        email: payload.email,
      };
    case USER_API_PENDING:
      return { ...state, ...payload };
    case USER_API_SUCCESS:
      return { ...state, ...payload };
    case REQ_SUPPORT_CALL_SUCCESS:
      return { ...state, callRequestSuccess: true };
    case AUTH_VIEW_INDEX_CHANGE:
      return {
        ...state,
        authViewIndex: payload,
      };
    case LOGOUT:
      return { ...initialState, isLogout: true };
    case SEND_USER_OTP:
      return {
        ...state,
        phoneNumber: payload.mobile_number,
        country_code: payload.country_code,
        otp_preference: payload,
      };
    case SEND_USER_OTP_PENDING:
      return { ...state, otpPending: payload };
    case VERIFY_OTP:
      return { ...state, ...payload };
    case VERIFY_OTP_PENDING:
      return { ...state, verifyOtpPending: payload };
    case EDIT_USER_DISPLAYNAME_PENDING:
      return { ...state, editUserDisplaynamePending: payload };
    case GET_USER_ORGS_PENDING:
      return { ...state, getUserOrgsPending: payload };

    case GET_USER_ORGS: {
      const now = Date.now();
      let data = [];
      if (payload.length) {
        data = payload
          .filter(org => org.org_id)
          .map((org: any) => {
            return {
              label: org.org_id.org_name,
              value: org.org_id._id,
              is_paid: org.org_id.is_paid,
              is_expired: org.org_id.free_trial_date < now,
            };
          });
      }
      return { ...state, userOrgs: data };
    }

    case OTP_ERROR:
      return { ...state, otpError: payload };
    case VERIFY_USER_EMAIL_PENDING:
      return { ...state, verifyUserEmailPending: payload };
    case SET_TOKEN_EXPIRY:
      return { ...state, expiredToken: payload };
    case GET_SELF_PERMISSION: {
      const user = state.user;
      const access = payload.access;
      const viewPermissions = access.filter((permission: any) => {
        return permission.permission_type.some(
          (type: any) =>
            type.permission === ACTION_NAME.VIEW && type.permission_value
        );
      });
      return {
        ...state,
        user: {
          ...user,
          app_settings: payload?.app_settings,
          access,
          viewPermissions,
        },
      };
    }
    case GET_SIGNATURE: {
      return { ...state, signature: payload };
    }
    case SET_COUNTRIES: {
      return { ...state, supported_countries: payload };
    }

    default:
      return state;
  }
}

export default UserReducer;
