import { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const RotateZoomImage = ({
  src,
  className,
  rotate,
  index,
  zoomable,
  onClick,
}) => {
  const classes = clsx(className);
  const [isZoom, setZoom] = useState(false);

  let cursorStyle = 'pointer';
  if (zoomable) {
    cursorStyle = isZoom ? 'zoom-out' : 'zoom-in';
  }

  return (
    <div className="zoom-rotate-image d-flex justify-content-center">
      <img
        src={src}
        onClick={() => {
          if (zoomable) {
            setZoom(!isZoom);
          } else {
            onClick(index);
          }
        }}
        style={{
          transform: `rotate(${rotate * 90}deg) ${
            isZoom ? 'scale(1.5)' : 'scale(1)'
          }`,
          transition: 'transform 300ms ease',
          cursor: cursorStyle,
          width: '500px',
          height: '500px',
          overflow: 'auto',
          objectFit: 'contain',
          maxHeight: '60vh',
          transformOrigin: 'center center',
        }}
        className={classes}
      />
    </div>
  );
};

RotateZoomImage.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  rotate: PropTypes.number,
  index: PropTypes.number,
  zoomable: PropTypes.bool,
  onClick: PropTypes.func,
};

export default RotateZoomImage;
