import {
  ORG_API_ERROR,
  ORG_API_PENDING,
  ORG_API_SUCCESS,
  GET_ORG_MEMBERS_PENDING,
  GET_ORG_TEAMS_PENDING,
  GET_ORG_MEMBERS,
  GET_ORG_TEAMS,
  GET_ORG_PROFILE,
  GET_TEAM_MEMBERS_PENDING,
  ADD_ORG_TEAM,
  SET_SELECTED_TEAM,
  UPDATE_ORG_TEAM,
  DELETE_ORG_TEAM,
  GET_TEAM_TEMPLATES,
  UPDATE_TEAM_USER,
  GET_ORG_PKG_LIST,
  GET_ORG_VENDOR_LIST,
  SET_QUESTIONNAIRE,
  UPDATE_ORG_PROFILE,
  SET_ORG_INFO_PENDING,
  SET_TEAM_MEMBERS,
  GET_PURCHASE_ORDER_SEQUENCE,
  SET_ORG_EXPERIMENTS,
  GET_INVENTORIES_BY_ORG_ID,
  GET_ORG_ADMINS,
  UPDATE_EXP_METADATA,
  GET_ORG_MEMBERS_PAGINATED,
  SET_MEMBERS_LIMIT,
  SET_MEMBERS_PAGE,
  SET_MEMBERS_FILTERS,
  DELETE_ORG_WORKER_PENDING,
} from './Organisation.type';
import { ReducerProps } from '../../../../interfaces/Base';
import { ExperimentProps } from '../../../../interfaces/Organisation';

const intialPendingState = {
  pending: false,
  membersPending: false,
  teamsPending: false,
  orgInfoPending: false,
  delete_org_worker_pending: false,
  teamMembersPending: false,
};

const initialState = {
  id: '9792998876',
  ...intialPendingState,
  error: null,
  selected: false,
  members: [],
  members_paginated: [],
  page: 0,
  limit: 20,
  total_members_count: 0,
  projects: [],
  org_teams: [],
  filters: {
    is_verified: undefined,
    team_ids: undefined,
  },
  orgProfile: {
    _id: '',
    org_name: '',
    pan: '',
    tan: '',
    gstinArr: [{ _id: '', id: '', state: '', address: '' }],
    logo_url: '',
    is_running_bom: false,
    is_paid: true,
    zoho_refresh_token: null,
    questionnaire: true,
    free_trial_date: 0,
    expense_settings: {
      petty_cash_approvers: [{ users: [] }],
      transfer_approvers: [{ users: [] }],
      grn_photo_approvers: [{ users: [] }],
      petty_cash_approver_mode: 0,
      transfer_approver_mode: 0,
      grn_photo_approver_mode: 0,
    },
    subscription: {
      subscription_expiry_dateMS: null,
    },
  },
  teamAdded: {},
  selectedTeam: null,
  templates: [],
  orgPackagesList: [],
  orgVendorList: [],
  orgBomList: [],
  permissions: [],
  questionnaireQuestions: [],
  team_members: [],
  poSequence: { seq_prefix: '', seq_value: 0 },
  experiments: new Map<string, boolean>(),
  inventoryItems: [],
  experimentsMetaData: new Map<string, any>(),
  orgAdmins: [],
};

function OrganisationReducer(
  state = initialState,
  { type, payload, error }: ReducerProps
) {
  switch (type) {
    case GET_ORG_PKG_LIST:
      return {
        ...state,
        orgPackagesList: payload.data,
      };

    case ORG_API_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
      };
    case ORG_API_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ORG_API_ERROR:
      return {
        ...state,
        pending: false,
        error: error,
      };
    case GET_ORG_MEMBERS_PENDING:
      return {
        ...state,
        membersPending: true,
      };
    case GET_TEAM_MEMBERS_PENDING:
      return {
        ...state,
        teamMembersPending: true,
      };
    case GET_ORG_MEMBERS:
      return {
        ...state,
        membersPending: false,
        members: payload,
      };
    case GET_ORG_MEMBERS_PAGINATED:
      return {
        ...state,
        membersPending: false,
        members_paginated: payload.data,
        page: payload.page,
        limit: payload.limit,
        total_members_count: payload.total_count,
      };
    case GET_ORG_TEAMS_PENDING:
      return {
        ...state,
        teamsPending: true,
      };
    case GET_ORG_TEAMS:
      return {
        ...state,
        teamsPending: false,
        org_teams: payload,
      };
    case GET_ORG_PROFILE:
    case UPDATE_ORG_PROFILE:
      return {
        ...state,
        orgProfile: payload,
      };
    case GET_ORG_VENDOR_LIST:
      return {
        ...state,
        orgVendorList: payload,
      };
    case ADD_ORG_TEAM:
      return {
        ...state,
        teamAdded: payload,
      };
    case SET_SELECTED_TEAM:
    case UPDATE_ORG_TEAM:
      return {
        ...state,
        selectedTeam: payload,
      };
    case DELETE_ORG_TEAM:
      return {
        ...state,
        selectedTeam: null,
      };
    case GET_TEAM_TEMPLATES:
      return {
        ...state,
        templates: payload,
      };
    case UPDATE_TEAM_USER:
      return {
        ...state,
      };
    case SET_QUESTIONNAIRE:
      return {
        ...state,
        questionnaireQuestions: payload,
      };
    case SET_ORG_INFO_PENDING:
      return {
        ...state,
        orgInfoPending: payload,
      };
    case SET_TEAM_MEMBERS:
      return {
        ...state,
        teamMembersPending: false,
        team_members: payload,
      };
    case GET_PURCHASE_ORDER_SEQUENCE:
      return {
        ...state,
        poSequence: payload,
      };
    case SET_ORG_EXPERIMENTS: {
      const experimentMap = (payload || []).reduce(
        (map: Map<string, boolean>, item: ExperimentProps) => {
          map.set(item.exp_name.toLowerCase(), item.is_active || false);
          return map;
        },
        new Map()
      );

      const experimentMetaDataMap = (payload || []).reduce(
        (map: Map<string, any>, item: ExperimentProps) => {
          map.set(item.exp_name.toLowerCase(), item.meta_data);
          return map;
        },
        new Map()
      );

      return {
        ...state,
        experiments: experimentMap,
        experimentsMetaData: experimentMetaDataMap,
      };
    }
    case GET_INVENTORIES_BY_ORG_ID:
      return { ...state, inventoryItems: payload };

    case GET_ORG_ADMINS:
      return { ...state, orgAdmins: payload };

    case UPDATE_EXP_METADATA:
      return { ...state, experimentsMetaData: payload };

    case SET_MEMBERS_PAGE:
      return { ...state, page: payload };

    case SET_MEMBERS_LIMIT:
      return { ...state, limit: payload, page: 0 };

    case SET_MEMBERS_FILTERS:
      return { ...state, filters: payload, page: 0 };
    case DELETE_ORG_WORKER_PENDING:
      return { ...state, delete_org_worker_pending: payload };
    default:
      return state;
  }
}

export default OrganisationReducer;
