import { Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  matchPath,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import store from './redux/Store';
import * as Sentry from '@sentry/react';
import {
  ThemeProvider,
  StyledEngineProvider,
  useMediaQuery,
} from '@mui/material';

import App from './App';
import ErrorBoundary from './views/error/ErrorBoundary';
import SmallScreenError from './views/error/SmallScreenError';
import CustomSpinner from './components/common/CustomSpinner';
import reportWebVitals from './reportWebVitals';
import {
  USE_SEENTRY,
  POWERPLAY_WEB_VERSION,
  SENTRY_CLIENT_DSN,
} from './constants';
import { theme } from './components/common/Theme';
import UserProps from './interfaces/User';
import { ConfirmDialogProvider } from './utils/hooks/useConfirm.hook';
import { apolloClient } from './apiClient';
import { UNPROTECTED_ROUTES } from './routes';
import { useAppSelector } from './redux/Hooks';

import './assets/main.scss';
import { TooltipProvider } from './components/ui/CustomToolTip';
import HelmetComponent from './HelmetComponent';

if (USE_SEENTRY) {
  Sentry.init({
    dsn: SENTRY_CLIENT_DSN,
    release: `powerplay@ver_${POWERPLAY_WEB_VERSION}`,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 0.2,
  });
}

const Index = () => {
  const location = useLocation();

  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const smallScreens = Boolean(
    UNPROTECTED_ROUTES.filter(v => v.allowSmallScreen)
      .map(v => v.path)
      .map(it => matchPath({ path: it }, location.pathname))
      .filter(Boolean).length
  );

  const user: UserProps = useAppSelector(state => state.userreducer);

  async function updateFcUser() {
    await (window as any)?.fcWidget?.user?.setProperties({
      firstName: user.displayName,
      email: user.email,
      phone: user.mobile_number,
      phoneCountry: user.country_code,
      externalId: user._id,
    });
  }

  if (user?._id && (window as any)?.fcWidget) {
    updateFcUser();
  }

  if (matches && !smallScreens) {
    return <SmallScreenError />;
  } else {
    return (
      <div className="h-100">
        <HelmetComponent />
        <Suspense fallback={<CustomSpinner />}>
          <Sentry.ErrorBoundary fallback={ErrorBoundary} showDialog>
            <App />
          </Sentry.ErrorBoundary>
        </Suspense>
      </div>
    );
  }
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ApolloProvider client={apolloClient}>
      <Router>
        <TooltipProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <ConfirmDialogProvider>
                <Index />
              </ConfirmDialogProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </TooltipProvider>
      </Router>
    </ApolloProvider>
  </Provider>
);

reportWebVitals();
