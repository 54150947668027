import { useEffect } from 'react';
import { Route, useNavigate, Routes, Navigate } from 'react-router-dom';
import { Toaster } from 'sonner';

import { setNavbarTitle } from './redux/features/common/layout/Layout.action';
import {
  COMMERCIAL_FILTER_KEYS,
  LOCAL_STORAGE_TOKEN_KEY,
  routeToNavTitle,
} from './constants';
import ProtectedRoute from './ProtectedRoute';
import { getOrgProfile } from './redux/features/common/organisation';
import { ORG_ROUTES, PROJECT_ROUTES, UNPROTECTED_ROUTES } from './routes';
import { OrganisationLayout, ProjectLayout } from './layouts';
import AlertDialog from './views/error/InternetErrorDialog';
import { useAppDispatch, useAppSelector } from './redux/Hooks';
import useLocalStorage from './utils/hooks/useLocalStorage.hook';
import { GanttEnterprise } from './providers/dhtmlx/gantt/dhtmlxgantt';
import useSessionStorage from './utils/hooks/useSessionStorage.hook';

declare global {
  interface Window {
    newrelic: any;
    Gantt: GanttEnterprise;
  }
}

const RedirectComponent = () => {
  const { getLocalStorageValue } = useLocalStorage();
  const isAuth: boolean = !!getLocalStorageValue(
    LOCAL_STORAGE_TOKEN_KEY.TOKEN,
    false
  );

  const projId = useAppSelector(state => state.projectreducer.selectedProj.id);

  if (!isAuth) {
    return <Navigate to={'/login'} />;
  }

  const path = projId ? `/project/${projId}` : '/projects';

  return <Navigate to={path} />;
};

const App = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { getLocalStorageValue } = useLocalStorage();
  const { removeSessionStorageValue } = useSessionStorage();
  const isAuth: boolean = !!getLocalStorageValue(
    LOCAL_STORAGE_TOKEN_KEY.TOKEN,
    false
  );

  const isLogout = useAppSelector(state => state.userreducer.isLogout);
  const orgId = useAppSelector(state => state.userreducer.org_id);
  const userId = useAppSelector(state => state.userreducer._id);

  useEffect(() => {
    if (orgId) {
      dispatch(getOrgProfile(orgId));
    }
  }, [orgId]);

  useEffect(() => {
    if (userId && (window as any)?.webengage) {
      (window as any).webengage.user.login(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (isLogout) {
      navigate('/login');
    }
  }, [isLogout]);

  useEffect(() => {
    if (!location.pathname.includes('/commercial/')) {
      Object.values(COMMERCIAL_FILTER_KEYS).forEach(value => {
        removeSessionStorageValue(value);
      });
    }
  }, [location.pathname]);

  const setSelectedMenu = pathname => {
    if (routeToNavTitle[`/${pathname?.split('/').pop()}`]) {
      const key = `/${pathname?.split('/').pop()}`;
      dispatch(setNavbarTitle(routeToNavTitle[key]));
    }
  };

  useEffect(() => {
    setSelectedMenu(`${location.pathname}`);
  }, [location.pathname]);

  return (
    <>
      <AlertDialog />
      <Toaster position="bottom-right" duration={2000} />

      <div className="h-100">
        <Routes>
          <Route index element={<RedirectComponent />} />

          <Route element={<OrganisationLayout />}>
            {ORG_ROUTES.map(({ path, ...props }, index) => {
              return (
                <Route
                  key={index}
                  path={path}
                  element={
                    <ProtectedRoute isAuthenticated={isAuth} {...props} />
                  }
                />
              );
            })}
          </Route>

          <Route element={<ProjectLayout />}>
            {PROJECT_ROUTES.map(({ path, ...props }, index) => {
              return (
                <Route
                  key={index}
                  path={path}
                  element={
                    <ProtectedRoute isAuthenticated={isAuth} {...props} />
                  }
                />
              );
            })}
          </Route>

          {UNPROTECTED_ROUTES.map(route => (
            <Route
              key={route.path}
              path={route.path}
              element={<route.component />}
            />
          ))}

          <Route path="*" element={<RedirectComponent />} />
        </Routes>
      </div>
    </>
  );
};

export default App;
