import { combineReducers } from 'redux';
import basereducer from './base/Base.reducer';
import bomreducer from './features/material/bom/Bom.reducer';
import boqreducer from './features/task/boq/Boq.reducer';
import receivablereducer from './features/task/receivables/Receivable.reducer';
import layoutreducer from './features/common/layout/Layout.reducer';
import userreducer from './features/common/user/User.reducer';
import projectPlanReducer from './features/task/project-plan/ProjectPlan.reducer';
import projectreducer from './features/common/project/Project.reducer';
import poreducer from './features/material/purchase-order/PurchaseOrder.reducer';
import dashboardReducer from './features/dashboard/Dashboard.reducer';
import taskreducer from './features/task/task/Task.reducer';
import documentreducer from './features/common/document/Document.reducer';
import packageReducer from './features/common/package/Package.reducer';
import userPackageReducer from './features/common/user-package/UserPackage.reducer';
import portfolioreducer from './features/common/portfolio/Portfolio.reducer';
import indentReducer from './features/material/indent/Indent.reducer';
import vendorReducer from './features/vendor/Vendor.reducer';
import utilsReducer from './features/common/utils/Utils.reducer';
import organisationReducer from './features/common/organisation/Organisation.reducer';
import financeReducer from './features/expenditure/finance/Finance.reducer';
import bomPriceReducer from './features/material/bom-price/BomPrice.reducer';
import serviceWorkerReducer from './features/miscellaneous/service-worker/ServiceWorker.reducer';
import refreshReducer from './features/common/refresh/Refresh.reducer';
import onboardingReducer from './features/miscellaneous/onboarding/onboarding.reducer';
import reportReducer from './features/common/reports/Report.reducer';
import threadReducer from './features/task/thread/Thread.reducer';
import attendanceReducer from './features/attendance/Attendance.reducer';
import notificationReducer from './features/miscellaneous/notification/Notification.reducer';
import activityReducer from './features/common/activity/Activity.reducer';
import tagReducer from './features/common/tag/Tag.reducer';
import grnReducer from './features/material/grn/grnReducer';
import milestoneReducer from './features/common/milestone/Milestone.reducer';
import expenseReducer from './features/expenditure/expense/Expense.reducer';
import driveReducer from './features/drive/Drive.reducer';
import exportReducer from './features/miscellaneous/export/Export.reducer';
import autoDprReducer from './features/miscellaneous/auto-dpr/AutoDpr.reducer';
import inventoryReducer from './features/material/inventory/Inventory.reducer';
import analyticsDashboardReducer from './features/analytics-dashboard/zoho/Zoho.reducer';
import workOrderReducer from './features/sc-payable/ScPaybale.reducer';
import termsAndConditionsReducer from './features/common/terms-and-conditions/TermsAndConditions.reducer';
import poSequenceReducer from './features/material/purchase-order-sequence/PurchaseOrderSequence.reducer';
import pettyCashReducer from './features/expenditure/petty-cash/PettyCash.reducer';
import indentSequenceReducer from './features/material/indent-sequence/IndentSequence.reducer';
import stReducer from './features/material/site-transfer/SiteTransfer.reducer';
import miSequenceReducer from './features/material/material-issue-sequence/MaterialIssueSequence.reducer';
import stSequenceReducer from './features/material/site-transfer-sequence/SiteTransferSequence.reducer';
import miReducer from './features/material/material-issue/MaterialIssue.reducer';
import supersetReducer from './features/analytics-dashboard/superset/Superset.reducer';
import grnSequenceReducer from './features/material/grn-sequence/GRNSequence.reducer';
import whatsNewReducer from './features/whatsNew/WhatsNew.reducer';
import { payableReducer } from './features/payable/payable';
import myBoardReducer from './features/analytics-dashboard/my-board/MyBoard.reducer';
import advanceReducer from './features/advance/Advance.reducer';
import customInfoReducer from './features/custom-information/CustomInfo.reducer';
import { woSequenceReducer } from './features/material/work-order-sequence';

const appReducer = combineReducers({
  basereducer,
  bomreducer,
  layoutreducer,
  userreducer,
  boqreducer,
  receivablereducer,
  projectPlanReducer,
  projectreducer,
  poreducer,
  dashboardReducer,
  taskreducer,
  documentreducer,
  packageReducer,
  userPackageReducer,
  portfolioreducer,
  grnSequenceReducer,
  indentReducer,
  vendorReducer,
  utilsReducer,
  organisationReducer,
  financeReducer,
  bomPriceReducer,
  serviceWorkerReducer,
  refreshReducer,
  onboardingReducer,
  reportReducer,
  threadReducer,
  attendanceReducer,
  notificationReducer,
  activityReducer,
  tagReducer,
  grnReducer,
  milestoneReducer,
  termsAndConditionsReducer,
  expenseReducer,
  driveReducer,
  exportReducer,
  autoDprReducer,
  inventoryReducer,
  analyticsDashboardReducer,
  workOrderReducer,
  poSequenceReducer,
  indentSequenceReducer,
  pettyCashReducer,
  stReducer,
  miSequenceReducer,
  stSequenceReducer,
  miReducer,
  supersetReducer,
  payableReducer,
  advanceReducer,
  whatsNewReducer,
  woSequenceReducer,
  myBoardReducer,
  customInfoReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'LOGOUT') {
    state = action.payload;
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

export default rootReducer;
