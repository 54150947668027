import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';
import { variables } from './components/common/Theme';
import { analyticsScript, facebookScript, webEngage } from './HelmetScripts';

function HelmetComponent() {
  return (
    <>
      <Helmet>
        <meta name="author" content="Powerplay" />
        <meta
          name="description"
          content="Powerplay - Manage construction & interior projects. India's 1st construction and architecture project management app"
        />
        <meta
          name="keywords"
          content="power, play, powerplay, construction, projects, civil, works, organisation, project management,labour"
        />
        <meta name="robots" content="follow, index" />

        <meta name="og:title" content="Powerplay" />
        <meta name="og:type" content="website" />
        <meta
          name="og:description"
          content="Powerplay - Manage construction & interior projects. India's 1st construction and architecture project management app"
        />
        <meta property="og:url" content="https://portal.getpowerplay.in" />

        <meta name="twitter:title" content="Powerplay" />
        <meta name="twitter:type" content="website" />
        <meta
          name="twitter:card"
          content="Powerplay - Manage construction & interior projects. India's 1st construction and architecture project management app"
        />
        <meta
          name="twitter:description"
          content="Powerplay - Manage construction & interior projects. India's 1st construction and architecture project management app"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap"
          rel="stylesheet"
        />
        <link rel="canonical" href="https://portal.getpowerplay.in/" />

        <script>{analyticsScript}</script>

        <script id="_webengage_script_tag" type="text/javascript">
          {webEngage}
        </script>

        <script>{facebookScript}</script>
      </Helmet>
      <Toaster
        position="bottom-center"
        toastOptions={{
          className: 'toast',
          error: {
            style: { backgroundColor: variables.red },
            iconTheme: {
              primary: variables.white,
              secondary: variables.red,
            },
            duration: 6000,
          },
          success: {
            style: { backgroundColor: variables.green600 },
            iconTheme: {
              primary: variables.white,
              secondary: variables.green600,
            },
            duration: 6000,
          },
          loading: {
            style: { backgroundColor: variables.blue600 },
            iconTheme: {
              primary: variables.white,
              secondary: variables.blue600,
            },
          },
        }}
      />
    </>
  );
}

export default HelmetComponent;
