export enum CUSTOM_COLUMN_MODULE {
  TASK = 'TASK',
  MATERIAL = 'MATERIAL',
  ATTENDANCE = 'ATTENDANCE',
}

export enum RESOURCE_TYPE_NAMES {
  TASK = 'task_details',
  TASK_LOG = 'task_updates',
  po = 'Purchase Order',
  grn = 'GRN',
}

export enum CF_RESOURCE_NAME {
  task = 'Task Details',
  task_log = 'Task Updates',
  po = 'Purchase Order',
  grn = 'GRN',
}

export type CustomColumnType = {
  type_id: number;
  type_name: string;
  type_icon: string;
};

export const CUSTOM_FIELD_RESOURCE_NAMES = Object.freeze({
  TASK: 'TASK',
  TASK_LOG: 'TASK_LOG',
});

export const CUSTOM_COLUMN_RESOURCE = {
  TASK: 'task',
  TASK_LOG: 'task_log',
  PO: 'po',
  GRN: 'grn',
};

export const COMPUTED_TYPE_CF =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/ComputedType.png';
export const DATE_TYPE_CF =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/DateType.png';
export const DOC_TYPE_CF =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/DocType.png';
export const DROPDOWN_TYPE_CF =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/DropdownType.png';
export const MEDIA_TYPE_CF =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/MediaType.png';
export const NUMBER_TYPE_CF =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/NumberType.png';
export const TEXT_TYPE_CF =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/TextType.png';
export const TIME_TYPE_CF =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/TimeType.png';
export const LINK_STEP_1 =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/step1.png';
export const LINK_STEP_2 =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/step2.png';
export const LINK_STEP_3 =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/step3.png';
export const RECOMMENDED_EMPTY_STATE =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/recommendedEmptyState.svg';

export const CUSTOM_COLUMN_TYPES: Record<string, CustomColumnType> = {
  TEXT: { type_id: 0, type_name: 'Text', type_icon: TEXT_TYPE_CF },
  NUMBER: {
    type_id: 1,
    type_name: 'Number',
    type_icon: NUMBER_TYPE_CF,
  },
  DROPDOWN: {
    type_id: 3,
    type_name: 'Dropdown',
    type_icon: DROPDOWN_TYPE_CF,
  },
  COMPUTED: {
    type_id: 4,
    type_name: 'Computed',
    type_icon: COMPUTED_TYPE_CF,
  },
  DATE: { type_id: 5, type_name: 'Date', type_icon: DATE_TYPE_CF },
  TIME: { type_id: 6, type_name: 'Time', type_icon: TIME_TYPE_CF },
  MEDIA: { type_id: 7, type_name: 'Media', type_icon: MEDIA_TYPE_CF },
  FILE: { type_id: 8, type_name: 'File', type_icon: DOC_TYPE_CF },
};

export const CUSTOM_COLUMN_FIELD_TYPES = {
  DROPDOWN: 'DROPDOWN',
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  TIME: 'TIME',
  MEDIA: 'MEDIA',
  COMPUTED: 'COMPUTED',
  FILE: 'FILE',
};

export const CUSTOM_COLUMN_FIELD_TYPES_LOWERCASE = {
  DROPDOWN: 'Dropdown',
  TEXT: 'Text',
  NUMBER: 'Number',
  DATE: 'Date',
  TIME: 'Time',
  MEDIA: 'Media',
  COMPUTED: 'Computed',
  FILE: 'File',
};

export const SUPPORTED_CF_TYPES = {
  TASK: [
    CUSTOM_COLUMN_FIELD_TYPES.TEXT,
    CUSTOM_COLUMN_FIELD_TYPES.COMPUTED,
    CUSTOM_COLUMN_FIELD_TYPES.DROPDOWN,
  ],
  TASK_LOG: [CUSTOM_COLUMN_FIELD_TYPES.NUMBER],
};
export const PDF_TYPE_ICON =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/pdf+(2).svg';
export const WORD_TYPE_ICON =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/Word.svg';
export const XLS_TYPE_ICON =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/XLS.svg';

export const DOC_MIME_TYPE_ICON_MAPPING = {
  'application/pdf': PDF_TYPE_ICON,
  'application/doc': WORD_TYPE_ICON,
  'application/docx': WORD_TYPE_ICON,
  'application/msword': WORD_TYPE_ICON,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    WORD_TYPE_ICON,
  'application/vnd.ms-excel': XLS_TYPE_ICON,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    XLS_TYPE_ICON,
  'application/xls': XLS_TYPE_ICON,
  'application/xlsx': XLS_TYPE_ICON,
};

export const CF_MEDIA_UPLOAD =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/mediaUpload.png';
export const CF_FILE_UPLOAD =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/fileUpload.png';
