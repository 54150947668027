import { ST_STATUS } from './components/commercial/SiteTransferDialog/STConstants';
import { MODULES, TEAM_MODULES } from './constant/module_constants';
import { TaxGroup } from './interfaces/Indent';
import { getEndDateEpoch, getStartDateEpoch } from './utils/Date.util';

/* eslint-disable no-undef */
const publicPath = '/';
const imagesPath = `${publicPath}images`;
const tourImagesPath = `${imagesPath}/guide`;

export const POWERPLAY_WEB_VERSION =
  import.meta.env.REACT_APP_POWERPLAY_WEB_VERSION || '';
export const FRESH_CHAT_TOKEN =
  import.meta.env.REACT_APP_FRESH_CHAT_TOKEN ||
  '791dd6ae-2256-4ded-bdda-84188acedd28';
export const FRESH_CHAT_HOST =
  import.meta.env.REACT_APPFRESH_CHAT_HOST || 'https://wchat.in.freshchat.com';
export const ACCESS_TYPE = ''; // should be empty to access all the modules or project_management to restrict only to project management modules
export const USE_SEENTRY = import.meta.env.REACT_APP_USE_SENTRY === 'true'; // Make this true in .env in production
export const NEW_UPDATE_URL = import.meta.env.REACT_APP_WHATS_NEW_URL || '';
export const POWERPLAY_BASE_URL = import.meta.env.REACT_APP_BASE_URL;
export const TASK_TYPES = ['PO Raise', 'Supply', 'Installation'];
export const YEAR_IN_DAY = 365;
export const ONE_DAY = 24 * 60 * 60 * 1000;
export const ONE_HOUR = 60 * 60 * 1000;
export const USER_GUIDE_LIMIT = 3;
export const ONE_MIN = 60 * 1000;
export const TEN_SECONDS = 10 * 1000;
export const GRN_PAYMENT_TYPE = ['Cash', 'RTGS', 'NEFT', 'IMPS'];
export const CS_TEAM_MEMBERS =
  import.meta.env.REACT_APP_CS_TEAM_MEMBERS?.split(',')
    .map(userId => userId.trim())
    .filter(userId => typeof userId === 'string') || [];

export const THREAD_ITEMS = {
  TASK: '0',
  BOQ: '1',
  PO: '2',
};
export const STORE_MAX_PROJECT =
  Number(import.meta.env.REACT_APP_STORE_MAX_PROJECT) || 5;
export const STORE_MAX_EMAIL =
  Number(import.meta.env.REACT_APP_STORE_MAX_EMAIL) || 10;

export const SUPERSET_DOMAIN = import.meta.env.REACT_APP_SUPERSET_DOMAIN;

export const SENTRY_CLIENT_DSN = import.meta.env.REACT_APP_SENTRY_CLIENT_DSN;

export const LOCAL_STORAGE_TOKEN_KEY = {
  TOKEN: 'token',
  SUPERSET_TOKEN: 'superset_token',
  REFRESH_TOKEN: 'refreshToken',
  TOKEN_EXPIRY: 'tokenExpiry',
};

const onboardingImagesPath = `${imagesPath}/onboarding`;
const authImagesPath = `${imagesPath}/auth`;
const logoPath = `${imagesPath}/logos`;
const placeHolderPaths = `${imagesPath}/placeholders`;

export const IMAGES_PATHS = {
  ONBOARDING: {
    REGISTER: `${onboardingImagesPath}/register.png`,
    CREATE_ORG: `${onboardingImagesPath}/createOrg.png`,
    CREATE_PROJECT: `${onboardingImagesPath}/createProject.png`,
    VERIFICATION: `${onboardingImagesPath}/verification.png`,
    INVITE: `${onboardingImagesPath}/invite.png`,
    INVITE_MOBILE: `${onboardingImagesPath}/inviteMobile.png`,
    INVITE_ARROW: `${onboardingImagesPath}/arrowUp.png`,
    DIALOG_MAN: `${onboardingImagesPath}/dialog.png`,
  },
  AUTH: {
    RESET_PASSWORD: `${authImagesPath}/passwordReset.png`,
    RESET_PASSWORD_EMAIL: `${authImagesPath}/passwordResetEmail.png`,
    RESET_PASSWORD_SUCCESSFUL: `${authImagesPath}/passwordResetSuccessful.png`,
    RESET_PASSWORD_UNSUCCESSFUL: `${authImagesPath}/passwordResetUnsuccessful.png`,
  },
  RIGHT_ONBOARD: `${onboardingImagesPath}/right_onboard.png`,
  ROCKET: `${onboardingImagesPath}/rocket.png`,
  PP: `${logoPath}/full_logo.svg`,
  LOGIN_BOTTOM_IMAGE: `${onboardingImagesPath}/rgStepBg.svg`,
  PRIMARY_LOGO_SRC: `${publicPath}favicon.svg`,
  NO_TASK: `${imagesPath}/NoTask.png`,
  PACKAGE_PROGRESS: `${imagesPath}/dashboard/PackageProgress.png`,
  PROJECT_STATUS: `${imagesPath}/dashboard/ProjectStatus.png`,
  PROJECT_PROGRESS: `${imagesPath}/dashboard/ProgressBar.png`,
  BUDGET_PROGRESS: `${imagesPath}/dashboard/BoqBar.png`,
  BOQ_STATUS: `${imagesPath}/dashboard/BoqStatus.png`,
  BOQ_STATUS_ACTIVE: `${imagesPath}/dashboard/BoqStatusActive.png`,
  ACTIVITIES_STATUS: `${imagesPath}/dashboard/Yesterday.png`,
  ACTIVITIES_DISABLED: `${imagesPath}/dashboard/YesterdayDisabled.png`,
  WORK_CATEGORY: {
    NO_TASK: `${imagesPath}/workcategory/timeline.png`,
    NO_BOQ: `${imagesPath}/workcategory/emptyWC.png`,
  },
  THREADS: {
    NO_THREADS: `${imagesPath}/threads/noThreadsScreen.png`,
    NO_COMMENTS: `${imagesPath}/threads/noCommentScreen.png`,
  },
  NO_ATTENDANCE: `${imagesPath}/NoAttendance.png`,
  BUDGET: {
    NO_MILESTONE: `${imagesPath}/budget/no-milestone.png`,
  },
  REPORT_BG: `${imagesPath}/report_bg.png`,
  PLACEHOLDERS: {
    PROJECT: `${placeHolderPaths}/project_img.png`,
  },
  TASK_PROFILE: {
    NO_ISSUES: `${imagesPath}/taskprofile/noIssues.png`,
    NO_PHOTOS: `${imagesPath}/taskprofile/no_photos.png`,
    NO_MATERIALS: `${imagesPath}/taskprofile/no_materials.png`,
  },
  AUTO_DPR_DISBABLED: `${imagesPath}/autodpr/disable.svg`,
  AUTO_DPR_NO_MEMBER: `${imagesPath}/autodpr/no-member.svg`,
  PAYABLES: {
    ROCKET: `${imagesPath}/payables/Rocket.png`,
  },
};

export const TOUR_IMAGES_PATHS = {
  PP_VIEW_GUIDE: `${tourImagesPath}/view_guide.gif`,
  PP_FILTER_GUIDE: `${tourImagesPath}/filter_guide.gif`,
  TASK_PROGRESS_GUIDE: `${tourImagesPath}/task_progress_guide.png`,
  PROJECT_PLAN_GUIDE: `${tourImagesPath}/project_plan_guide.png`,
  DPR_GUIDE: `${tourImagesPath}/dpr_guide.gif`,
  MANAGE_TASK_ADD: `${tourImagesPath}/mgtsk_add.gif`,
  MANAGE_TASK_HEADING: `${tourImagesPath}/mgtsk_sort.gif`,
  MANAGE_TASK_CHECKBOX: `${tourImagesPath}/mgtsk_check.gif`,
  ADD_TASK_TO_MILESTONE: `${tourImagesPath}/task_milestone.gif`,
};

export const PRIMARY_LOGO_SRC = '/favicon.svg';
export const SECONDARY_LOGO_SRC = '/logo_deeppurple.png';
export const DP_PLACEHOLDER = '/dp-placeholder.png';
export const ACCESS_DENIED_ICON = '/access_denied.png';
export const FILL_DEATILS_VECTOR = '/images/fill-details-vector.png';
export const ANCHER_TAG_VECTOR = '/images/ancher-tag-vector.png';
export const GET_IT_ON_GOOGLE_PLAY = '/get-it-on-google-play.png';
export const SYNC_ICON = '/images/sync.svg';
export const UPDATE_BANNER_CROSS = '/images/UpdateBannerCross.svg';
export const EXCLAMATORY_MARK = '/images/UpdatePendingExclamatoryIcon.svg';
export const PROJECT_SIDEBAR_CLOSE = '/images/projectSidebarClose.svg';
export const CHANGE_PASSWORD = './images/ChangePassword.svg';
export const CHANGE_PASSWORD_SUCCESSFUL =
  './images/ChangePasswordSuccessful.svg';

export const ANDROID_APP_URL =
  'https://play.google.com/store/apps/details?id=in.powerplay.android.fieldapp';
export const INDENT_STATUS = {
  0: 'Not Yet',
  1: 'Partially Fulfilled',
  2: 'Fulfilled',
};

export const ACCEPTED_FILE_FORMAT = [
  'image/*',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  '.csv',
  '.doc',
  '.docx',
  '.xlsx',
  '.xls',
];

export const ACCEPTED_FILE_FORMATS = {
  IMAGES: 'image/*',
};

export const ATTACH_FILE_COMPONENT_TYPE = {
  IMAGES: 'images',
  FILES: 'files',
};

export const ROLE_DETAILS = {
  admin:
    'By inviting new member in Organization Management team, you give them the edit rights to web and mobile view. They can also invite other members to the project.',
  project_manager:
    'By inviting new member in Project Management team, you give them the edit rights to web and mobile view. They can also invite other members to the project.',
  plan_and_bill:
    'By inviting new member in Planning team you give them the edit rights to the web view.',
  cost_and_budget:
    'By inviting new member in Costing and Budgeting team you give them the edit rights to web view.',
  purchase_and_contract:
    'By inviting new member in Commercial/Procurement team, you give them the edit rights to web view.',
  draw_and_coordinate:
    'By inviting new member in Design and drawing coordination team, you give them the edit rights to web view.',
  owner:
    'By inviting new member in Organization Management team, you give them the edit rights to web and mobile view. They can also invite other members to the project.',
  master:
    'By inviting new member in Organization Management team, you give them the edit rights to web and Portfolio view.',
  client:
    'By inviting new member in Organization Management team, you give them the view rights to web and mobile view. They can also invite other members to the project.',
  field_agent:
    'By inviting new member in the on-site project management team, you give them the edit rights to mobile view.',
  store_manager:
    'By inviting new member in on-site store management team, you give them the edit rights to mobile view.',
};
export const S3_BASE_URL = '';
export const _DATE_ = import.meta.env.REACT_APP_DATE || 0; // 1577232000000 = Dec 25
export const BASE_VRF_URL =
  import.meta.env.REACT_APP_BASE_VRF_URL || 'http://localhost:3000'; // 1577232000000 = Dec 25
export const WEEK = 7;
export const DEFAULT_PATH = {
  master: '/dashboard',
  owner: '/dashboard',
  project_manager: '/project-plan',
  plan_and_bill: '/dashboard',
  purchase_and_contract: '/commercial',
  client: '/dashboard',
  mep: '/project-plan',
};

export const UPLOAD_EXCEL_URL =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/sample-files/inventory/Inventory%2BUpload%2BSample%2Blist%2Bnew.xlsx';
export const ROLES = {
  master: 'Master',
  owner: 'Organization Management Team',
  project_manager: 'Project Management Team',
  plan_and_bill: 'Planning Team',
  purchase_and_contract: 'Commercial/Procurement Team',
  field_agent: 'Project On-site Team',
  accounts: 'Accounts Team',
  hr_admin: 'HR & Admin Team',
  mep: 'MEP Team',
  bdev: 'Business development Team',
  client: 'Client',
};

export const ONLY_MOBILE_ACCESS_ROLES = [
  'field_agent',
  'accounts',
  'hr_admin',
  'bdev',
];

export const DASHBOARRD_ACCESS_ROLES = ['owner', 'plan_and_bill'];
export const PROJECT_PLAN_ACCESS_ROLES = [
  'owner',
  'project_manager',
  'plan_and_bill',
  'mep',
];
export const WORK_CATEGORIES_ACCESS_ROLES = ['owner', 'plan_and_bill'];
export const INVITE_ACCESS_ROLES = ['owner'];
export const COMMERCIAL_ACCESS_ROLES = [
  'owner',
  'project_manager',
  'plan_and_bill',
  'purchase_and_contract',
];
export const VENDOR_ACCESS_ROLES = [
  'owner',
  'plan_and_bill',
  'purchase_and_contract',
];
export const BOM_ACCESS_ROLES = [
  'owner',
  'plan_and_bill',
  'purchase_and_contract',
];

// ENUM definition for Purchase Order Status
export const POStatus = Object.freeze({
  DRAFT: 0,
  PENDING: 1,
  APPROVED: 2,
  SENT: 3,
  REJECTED: 4,
  ACKNOWLEDGED: 5,
  0: 'Draft',
  1: 'Pending',
  2: 'Approved',
  3: 'Sent',
  4: 'Rejected',
  5: 'Acknowledged',
});

export const THREAD_TAGS = Object.freeze({
  Critical: 0,
  RFI: 1,
  Blocker: 2,
  Design: 3,
  Purchase: 4,
  Risk: 5,
  Modification: 6,
  Highlight: 7,
  RFA: 8,
  'QA/QC': 9,
  Budget: 10,
  'Site-Visit': 11,
  'Client-Verification': 12,
  0: 'Critical',
  1: 'RFI',
  2: 'Blocker',
  3: 'Design',
  4: 'Purchase',
  5: 'Risk',
  6: 'Modification',
  7: 'Highlight',
  8: 'RFA',
  9: 'QA/QC',
  10: 'Budget',
  11: 'Site-Visit',
  12: 'Client-Verification',
});

export const RADIAN = Math.PI / 180;

export const INR_SYMBOL = '₹';
export const PERCENT_SYMBOL = '%';

export const PACKAGES = [
  'AC Works',
  'Branding',
  'Civil work',
  'Civil Works',
  'Electrical Works',
  'Furniture',
  'Glass Works',
  'Gypsum Works',
  'Loose Furniture',
  'Networking',
  'Painting',
  'Plumbing',
  'Services',
  'Soft Furnishing Works',
  'Carpentry',
  'Other Works',
  'Demolition',
  'Site Maintenance',
  'Grid Ceiling',
  'Flooring',
  'Flooring work',
  'Loose Furniture/Bought Out Items',
  'Earth Work',
  'Plain Concrete Works',
  'Reinforced Concrete',
  'Stone Masonry Work',
  'Brick Masonry Work',
  'Wood Works',
  'Steel Work',
  'Roofing Works',
  'Plaster',
  'Drainage',
  'Door & Window Components',
  'Super-structural Work',
  'Block Work',
  'Plastering work',
  'Facade & External works',
  'Tiling, doors and windows',
  'External development & Services',
  'Tile grouting, CP/ sanitary fittings',
  'Sub-structural Work',
  'Wall finishes',
  'Panelling work',
  'Doors & Windows',
  'False ceiling work',
  'Furniture work',
  'Electrical and light work',
  'Hvac work',
  'Fire fighting/ detection work',
  'Paint',
  'Miscellanious',
  'Toilet work',
  'Walls, Partitions & Doors',
  'Ceiling',
  'Surface Finishes',
  'Furniture',
  'Furnishings',
  'Fabrication',
  'Electrical',
  'HVAC',
  'Plumbing',
  'Fire Safety & PA',
  'Ancilliaries',
  'Civil',
];

export const ONBOARDING_PACKAGES = [
  'Ceiling',
  'Civil Works',
  'Electrical Works',
  'Gypsum Works',
  'Loose Furniture',
  'Networking',
  'AC Works',
  'Painting',
  'Branding',
  'Furniture',
  'Plumbing',
  'Glass Works',
  'Services',
];

export const GST_TREATMENT = [
  'Registered Business - Regular',
  'Registered Business - Composition',
  'Unregistered business',
  'Overseas',
  'Special Economic Zone',
  'Deemed Export',
  'None',
] as const;
export const GST_TREATMENT_DESCRIPTION = {
  'Registered Business - Regular': 'Business that is registered under GST',
  'Registered Business - Composition':
    'Business that is registered under composition scheme in GST',
  'Unregistered business': 'Business that is not registered under GST',
  Overseas:
    'Businesses with whom you do import or export of supplies outside India',
  'Special Economic Zone':
    'Business that is located in Special Economic Zone(SEZ) of India or a SEZ developer',
  'Deemed Export':
    'Supply of goods to an export-oriented unit or against Advanced authorization/Export Promotion capital goods',
};

export const VENDOR_LEVEL = [
  'Trader',
  'Dealer',
  'Distributor',
  'Brand Manufacturer',
  'Contract Manufacturer',
];

export const VENDOR_TYPE = [
  'Agency(S&I)',
  'Labor(I)',
  'Material(S)',
  'Professional',
];

export const UOM_TYPE = [
  '%',
  'sqft',
  'm3',
  'm2',
  'nos',
  'met',
  'ton',
  'ls',
  'rmt',
  'm',
  'ft',
];

export const TAX_GROUP = Object.freeze({
  GST0: 0,
  GST3: 5,
  GST5: 1,
  GST6: 6,
  GST12: 2,
  GST18: 3,
  GST28: 4,
  0: 'GST0',
  5: 'GST3',
  1: 'GST5',
  6: 'GST6',
  2: 'GST12',
  3: 'GST18',
  4: 'GST28',
});

export const TAX_OPTIONS = Object.freeze({
  0: 0,
  3: 5,
  5: 1,
  6: 6,
  12: 2,
  18: 3,
  28: 4,
});

export const TAX_OPTIONS_VALUES = Object.freeze({
  0: 0,
  5: 3,
  1: 5,
  6: 6,
  2: 12,
  3: 18,
  4: 28,
});

export const DOC_STATUS = Object.freeze({
  DRAFT: 0,
  COMPLETED: 1,
  0: 'Draft',
  1: 'Completed',
});

export const FIXED_DECIMAL_LENGTH = 2;

export const COMPLETION_STATE = Object.freeze({
  draft: 'Draft',
  approvalPending: 'Approval Pending',
  approved: 'Approved',
  issued: 'Issued',
  completed: 'Completed',
  cancelled: 'Cancelled',
});

export const FORM_MODES = {
  add: 'Add',
  edit: 'Edit',
  create: 'Create',
  createPOWithIndent: 'Create PO with Indent',
  createPOWithIndents: 'Create PO with multiple Indents',
  duplicate: 'duplicate',
};

export const ITEM_TYPES = {
  boq: 'boq',
  task: 'task',
};

export const SEQ_TYPES = {
  EXTERNAL: 'external',
  AUTOMATIC: 'auto',
};

export const TAX_SUB_GROUP = {
  GST0: {
    'CGST(0%)': 0,
    'SGST(0%)': 0,
  },
  GST0_I: {
    'IGST(0%)': 0,
  },
  GST5: {
    'CGST(2.5%)': 2.5,
    'SGST(2.5%)': 2.5,
  },
  GST5_I: {
    'IGST(5%)': 5,
  },
  GST12: {
    'CGST(6%)': 6,
    'SGST(6%)': 6,
  },
  GST12_I: {
    'IGST(12%)': 12,
  },
  GST18: {
    'CGST(9%)': 9,
    'SGST(9%)': 9,
  },
  GST18_I: {
    'IGST(18%)': 18,
  },
  GST28: {
    'CGST(14%)': 14,
    'SGST(14%)': 14,
  },
  GST28_I: {
    'IGST(28%)': 28,
  },
  GST3: {
    'CGST(1.5%)': 1.5,
    'SGST(1.5%)': 1.5,
  },
  GST3_I: {
    'IGST(3%)': 3,
  },
  GST6: {
    'CGST(3%)': 3,
    'SGST(3%)': 3,
  },
  GST6_I: {
    'IGST(6%)': 6,
  },
};

export const MIN_WIDTH_BREAKPOINT_TASKBAR = 5;
export const MIN_RATIO_BREAKPOINT_TASKBAR = 4 / 10;
export const OFFSET_TASKBAR = 1 / 10;

export const TASK_STATUS = {
  1: {
    value: 'inprogress',
    text: 'In-Progress',
    class: 'bg-yellow-sec',
    fillColor: '#EBB840',
    bgColor: '-ongoing',
  },
  late: {
    value: 'late',
    text: 'Delayed',
    class: 'bg-orange-sec',
    fillColor: '#e6642e',
    bgColor: '-overdue',
  },
  2: {
    value: 'completed',
    text: 'Completed',
    class: 'bg-green-sec',
    fillColor: '#68d083',
    bgColor: '-completed',
  },
  0: {
    value: 'notstarted',
    text: 'Not Started',
    class: 'bg-legend-grey',
    secondaryClass: 'bg-legend-grey-2',
    fillColor: '#DEE2E6',
    bgColor: '-upcoming',
  },
} as const;

export enum TASK_STATUS_ENUM {
  NOT_STARTED = 'not-started',
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  DELAYED = 'delayed',
}
export const TASK_STATUS_VALUE = {
  0: 'not_started',
  1: 'in_progress',
  2: 'completed',
};
export const BUDGET_STATUS = {
  undershoot: {
    class: 'bg-green-sec',
    fillColor: '#68D083',
  },
  overshoot: {
    class: 'bg-orange-sec',
    fillColor: '#E6642E',
  },
};

export const BAR_RADIUS = {
  firstRadius: [10, 0, 0, 10],
  noRadius: [0, 0, 0, 0],
  lastRadius: [0, 10, 10, 0],
  fullRadius: [10, 10, 10, 10],
};

export const MIN_SEPERATION_TIMELINE = 2 / 10;
export const MIN_SEPERATION_BUDGET = 1 / 10;
export const MIN_WIDTH_BREAKPOINT_BUDGET = 5;
export const MIN_RATIO_BREAKPOINT_BUDGET = 2 / 10;
export const SAMPLE_VENDOR_FILE_URL =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/sample-files/Vendor.xlsx';
export const UNIFIED_VENDOR_SAMPLE_UPLOAD_SHEET_URL =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/sample-files/vendor/Vendor+Sample+Sheet.xlsx';
export const SAMPLE_BOM_FILE_URL =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/sample-files/BoM.xlsx';
export const SAMPLE_BOQ_FILE_URL =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/sample-files/BoQ.xlsx';
export const SAMPLE_PROJECT_PLAN_FILE_URL =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/sample-files/Project_Plan.xlsx';
export const SAMPLE_BROAD_PLAN_FILE_URL =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/sample-files/broad-plan/Sample-Broad-Plan.xlsx';
export const SAMPLE_PROJECT_NESTED_PLAN_FILE_URL =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/sample-files/Nested%2BExcel%2BUpload%2Btemplate%2B-%2BFinal%2B(2).xlsx';
export const SAMPLE_PROJECT_EXCEL_PLAN_FILE_URL =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/sample-files/Sample_Plan.xlsx';
export const SAMPLE_BOQ_BOM_LINK_FILE =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/sample-files/BoqBomLink.xlsx';
export const SAMPLE_PO_FILE_URL =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/sample-files/po_sample-sheet.xlsx';
export const SAMPLE_LABOURS_LIST_URL =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/sample-files/attendance/labours.xlsx';
export const SAMPLE_VENDORS_WITH_COUNT_LIST_URL =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/sample-files/attendance/vendors-with-type.xlsx';
export const SAMPLE_VENDORS_WITH_NAME_LIST_URL =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/sample-files/attendance/vendors-with-name.xlsx';
export const SAMPLE_LABOURS_FOR_VENDOR_WITH_COUNT_URL =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/sample-files/attendance/labours-of-vendor-with-type.xlsx';
export const SAMPLE_LABOURS_FOR_VENDOR_WITH_NAME_URL =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/sample-files/attendance/labours-of-vendor-with-name.xlsx';
export const SAMPLE_RESOURCE_FILE_URL =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/Resource_Sheet.xlsx';
export const SAMPLE_UPLOAD_PLAN_INSTRUCTIONS =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/sample_files/Instructions%20for%20file%20upload.pdf';
export const SAMPLE_UPLOAD_PLAN_TEMPLATE =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/sample-files/Sample_Template.xlsx';
export const TOOLTIP_CONTENT = {
  earned_value: {
    title: 'Earned Value : ',
    content: 'Earned Value = Total work progress * Planned budget',
  },
  total_earned_value: {
    title: 'Total Earned Value : ',
    content: 'Total Earned Value = Sum of earned value of all work categories',
  },
  planned_budget: {
    title: 'Planned Budget : ',
    content: 'Amount of budget planned to be used',
  },
  budget_progress: {
    title: 'Budget Progress : ',
    content: 'Budget Progress = Earned value ÷ planned budget',
  },
  actual_value: {
    title: 'Actual Value : ',
    content: 'Amount of budget used till today (Value of POs raised)',
  },
  budget_deviation: {
    title: 'Budget Deviation : ',
    content:
      'It is the amount deviated from the planned budget till today. For eg: "-15 Cr" means the budget used is 15crores less than the budget that should have got used till today. Similarly "+15 crores" means a usage of extra 15 crores till today.',
  },
  delay_in_work: {
    title: 'Delay in work : ',
    content:
      'It is the number of working days the project is running late. For eg: "-15 days" means the project is running 15 days ahead of the timeline. "On Track" means it is running on time."+15 days" means there is a delay of 15 days.',
  },
  tasks_running_late: {
    title: 'Tasks running late : ',
    content: 'Sum of all delayed and overdue tasks',
  },
  overdue_task: {
    title: 'Overdue Task : ',
    content: 'Tasks which are delayed and not started yet',
  },
  delayed_task: {
    title: 'Delayed Task : ',
    content: 'Tasks which are delayed and have started',
  },
  on_track_task: {
    title: 'On Track Task : ',
    content: 'Tasks which are running as planned',
  },
  upcoming_task: {
    title: 'Upcoming Task : ',
    content: 'Tasks which are planned for future',
  },
  expected_task: {
    title: 'Expected Task : ',
    content: 'Tasks which were expected to be started by today',
  },
  total_task: {
    title: 'Total Task : ',
    content: 'Total number of tasks',
  },
  completed_task: {
    title: 'Completed Task : ',
    content: 'Total number of tasks which are completed',
  },
  inprogress_task: {
    title: 'In Progress Task : ',
    content: 'Total number of tasks which are in progress',
  },
  notstarted_task: {
    title: 'Not Started Task : ',
    content: 'Total number of tasks which are not started yet',
  },
  late_task: {
    title: 'Late Task : ',
    content:
      'Total number of tasks which are late. It includes both in progress and not started tasks',
  },
  po_reference: {
    title: 'PO reference: ',
    content: 'Purchase Orders referenced from an external database',
  },
};

export const routeToNavTitle = {
  '/dashboard': 'Project Dashboard',
  '/work-categories': 'Work Categories ',
  '/grn-finance': 'Finance',
  '/commercial': 'Commercial',
  '/vendor': 'Vendor',
  '/project-profile': 'Settings',
  '/threads': 'Issues',
  '/drive': 'Drive',
  '/budget': 'Bill of Quantities',
  '/attendance': 'Attendance',
  '/activities': 'Activity',
  '/members': 'Members',
  '/org-profile': 'Organisation Profile',
  '/teams': 'User Roles & Permissions',
  '/master-boq': 'Master Budget Items',
  '/bom': 'Bill Of Materials',
  '/commercial/purchase-order': TEAM_MODULES.COMMERCIAL.resource_name,
  '/project-plan': MODULES.TASKS.title,
  // '/work-order': 'Work Order',
};

export const NotifTitle = Object.freeze({
  task: { title: 'Tasks', key: 'plan' },
  taskLog: { title: 'Tasks', key: 'plan' },
  thread: { title: 'Issues', key: '' },
  indent: { title: 'Commercial', key: 'indent' },
  purchase_order: { title: 'Commercial', key: 'po' },
});

export const DATE_FILTER_TYPES = {
  none: 'None',
  planned: 'Planned Dates',
  actual: 'Actual Dates',
};

export const DATE_RESCHEDULE_TYPES = {
  days: 'Days',
  weeks: 'Weeks',
};

export const SET_LOCALSTORAGE_PARAMETERS = [
  'token',
  'exp',
  'role',
  'refreshToken',
  'tokenExpiry',
];
export const ZOHO_LOGIN_URL = `https://accounts.zoho.in/oauth/v2/auth?scope=ZohoBooks.purchaseorders.READ&prompt=Consent&client_id=${
  import.meta.env.REACT_APP_ZOHO_CLIENT_ID
}&response_type=code&access_type=offline&redirect_uri=${
  import.meta.env.REACT_APP_ZOHO_REDIRECT_URI
}`;

export const AGENT_TYPE = Object.freeze({
  USER: 0,
  ORG: 1,
});

export const LIMIT = 10;
export const DEFAULT_PAGE = 0;

export const PAYMENT_TYPE = ['Cash', 'RTGS', 'NEFT', 'IMPS'];

export const GRN_PAYMENT_STATUS = Object.freeze({
  NOT_PAID: 'Not Paid',
  PARTIALLY: 'Partially Paid',
  PAID: 'Paid',
});

export const CONFIG_TYPE = Object.freeze({
  TASK_INSIGHT: 'task_insight',
});

export const EXPENSE_PAYMENT_STATUS = { Paid: 1, Pending: 0 };

export const IndentStatus = Object.freeze({
  'Not Yet': 0,
  Partially_Fulfilled: 1,
  Fulfilled: 2,
});

export const RecordAccess = Object.freeze({
  NO_ACCESS: { value: 0, text: 'No Access' } as const,
  RESTRICTED_ACCESS: { value: 1, text: 'Restricted Access' } as const,
  ALL_ACCESS: { value: 2, text: 'All Access' } as const,
});

export const PermissionType = Object.freeze({
  create: {
    idx: 1,
    text: 'CREATE',
    description: 'Create records in selected access',
  },
  edit: {
    idx: 2,
    text: 'EDIT',
    description: 'Edit records in selected access',
  },
  delete: {
    idx: 3,
    text: 'DELETE',
    description: 'Delete records in selected access',
  },
  view: {
    idx: 0,
    text: 'VIEW',
    description: 'View records in selected access',
  },
});

export const VisibleColumnsMap = Object.freeze({
  salesboq: 'Sales & BOQ',
  finbudget: 'Financial Budget',
  poearnedval: 'PO & Earned Value',
});

export const DISCOUNT_TYPE = Object.freeze({
  PERCENT: 0,
  NUMBER: 1,
});

export const DEFAULT_LANDING = '/projects';

export const KEYBOARD_KEYS = Object.freeze({
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
});

export const DATE_FORMATS = Object.freeze({
  DD_MM_YYYY: 'DD/MM/YYYY',
  DD_MMM_YYYY: 'DD MMM, YYYY',
  DD_MMMM_YYYY: 'DD MMMM, YYYY',
  _hh_mm_a: 'hh:mm a',
  HH_mm: 'HH:mm',
  DASHED_DD_MM_YYYY: 'DD-MM-YYYY',
  DD_MMM_YYYY_hh_mm_a: 'DD MMM, YYYY, hh:mm A',
  hh_mm_a_DD_MMM_YYYY: 'hh:mm A, DD MMM, YYYY',
  DD_MMM_hh_mm_a: 'DD MMM hh:mm a',
  DD_MMM_YYYY_: 'DD_MMM_YYYY',
});

export const US_DATE_FORMATS = Object.freeze({
  DD_MM_YYYY: 'MM/DD/YYYY',
  DD_MMM_YYYY: 'DD MMM, YYYY',
  DD_MMM_YYYY_V2: 'DD MMM YYYY',
  _hh_mm_a: 'hh:mm a',
  HH_mm: 'HH:mm',
  DASHED_DD_MM_YYYY: 'MM-DD-YYYY',
  DD_MMM_YYYY_hh_mm_a: 'DD MMM, YYYY hh:mm a',
});

export const THREAD_OPTIONS = [
  { value: 'all', label: 'All Issues' },
  { value: 'created_by_me', label: 'Created by Me' },
  { value: 'assigned_to_me', label: 'Assigned to Me' },
];

export const OPEN_THREAD_OPTIONS = [
  { value: 'open', label: 'Open Issues' },
  { value: 'closed', label: 'Closed Issues' },
];

export const MIN_FILE_SIZE_FOR_COMPRESSION = 20 * 1024; // 20kB

export const DL_BASE_LINK = 'https://portal.getpowerplay.in';

export const phoneRegExp = /^(\+\d{1,3}\s?)?\d{3}[-\s]?\d{3}[-\s]?\d{4}$/;

export const DAY_IN_MS = 24 * 60 * 60 * 1000;

export const DRIVE_BUCKET_NAME =
  import.meta.env.REACT_APP_DRIVE_BUCKET_NAME || 'powerplay-drive-staging';

export const GOOGLE_GA_TRACKING_ID =
  import.meta.env.REACT_APP_GOOGLE_GA_TRACKING_ID || '';
export const FB_PIXEL_TRAKING_ID =
  import.meta.env.REACT_APP_FB_PIXEL_TRACKING_ID || '';

export const MAX_DECIMAL_TASK_UPDATE = 4;
export const MAP_API_KEY = import.meta.env.REACT_APP_MAP_API_KEY;
export const DEFAULT_MAP_ZOOM = 6;
export const DEFAULT_MAP_LOCATION_ZOOM = 15;
export const DEFAULT_LOCATION = {
  lat: 28.7041,
  lng: 77.1025,
};
export const GOOGLE_MAP_SCRIPT_URL =
  'https://maps.googleapis.com/maps/api/js?libraries=places&key=';

export const MapTypeId = {
  ROADMAP: 'roadmap',
  SATELLITE: 'satellite',
  HYBRID: 'hybrid',
  TERRAIN: 'terrain',
} as const;

export const PO_STATUS_TEXT = Object.freeze({
  0: 'Draft',
  1: 'Approval Pending',
  2: 'Rejected',
  3: 'Ordered',
  4: 'Partially Delivered',
  5: 'Delivered',
  6: 'Cancelled',
});

export const ST_STATUS_TEXT = Object.freeze({
  0: 'Draft',
  1: 'Approval Pending',
  2: 'Rejected',
  3: 'Approved',
  4: 'Partially Transferred',
  5: 'Transferred',
  6: 'Cancelled',
});

export const MI_ST_STATUS_TEXT = Object.freeze({
  0: 'Draft',
  1: 'Approval Pending',
  2: 'Rejected',
  3: 'Approved',
  4: 'Partially Transfered',
  5: 'Transfered',
  6: 'Cancelled',
});

export const PO_STATUS = Object.freeze({
  DRAFT: 0,
  APPROVAL_PENDING: 1,
  REJECTED: 2,
  ORDERED: 3,
  PARTIALLY_DELIVERED: 4,
  DELIVERED: 5,
  CANCELLED: 6,
});

export const PO_STATUS_STYLES_CONFIG = Object.freeze({
  [PO_STATUS.DRAFT]: {
    textClasses: 'cbg-grey-500 px-md py-xs',
  },
  [PO_STATUS.APPROVAL_PENDING]: {
    textClasses:
      'bg-surface-sunken border-1 border-stroke-neutral text-content-secondary font-lato px-md py-xs rounded-lg',
  },
  [PO_STATUS.REJECTED]: {
    textClasses:
      'bg-surface-critical border-1 border-stroke-critical text-base-critical px-md py-xs rounded-lg font-lato',
  },
  [PO_STATUS.ORDERED]: {
    textClasses:
      'text-content-brand bg-surface-brand border-1 border-stroke-brand px-md py-xs rounded-lg font-lato',
  },
  [PO_STATUS.PARTIALLY_DELIVERED]: {
    textClasses:
      'bg-stroke-warning rounded-lg text-content-warning font-lato px-md py-xs',
  },
  [PO_STATUS.DELIVERED]: {
    textClasses:
      'bg-surface-success border-1 border-stroke-success text-content-success font-lato px-md py-xs rounded-lg',
  },
  [PO_STATUS.CANCELLED]: {
    textClasses:
      'bg-surface-sunken border-1 border-stroke-neutral text-content-secondary font-lato px-md py-xs rounded-lg',
  },
});

export const ST_STATUS_STYLES_CONFIG = Object.freeze({
  [ST_STATUS.DRAFT]: {
    textClasses:
      'bg-stroke-neutral text-content-secondary font-lato px-md py-xs rounded-lg',
  },
  [ST_STATUS.APPROVAL_PENDING]: {
    textClasses:
      'bg-surface-sunken border-1 border-stroke-neutral text-content-secondary font-lato px-md py-xs rounded-lg',
  },
  [ST_STATUS.REJECTED]: {
    textClasses: 'cbg-red-500 text-white px-md py-xs rounded-lg font-lato',
  },
  [ST_STATUS.APPROVED]: {
    textClasses:
      'text-content-brand bg-surface-brand border-1 border-stroke-brand px-md py-xs rounded-lg font-lato',
  },
  [ST_STATUS.PARTIALLY_DELIVERED]: {
    textClasses:
      'bg-surface-warning border-1 border-stroke-warning text-content-warning font-lato rounded-lg px-md py-xs',
  },
  [ST_STATUS.DELIVERED]: {
    textClasses:
      'bg-surface-success border-1 border-stroke-success text-content-success font-lato rounded-lg px-md py-xs',
  },
  [ST_STATUS.CANCELLED]: {
    textClasses:
      'bg-stroke-neutral text-content-secondary font-lato px-md py-xs rounded-lg px-md py-xs',
  },
});

export const PIN_PROJECT_STORAGE_KEY = 'pinnedProjects_{}';
export const PIN_PROJECT_LIMIT = 5;

export const GANTT_VIEW_SCROLL_STATE_KEY = 'ganttViewScroll_{}';
export const GANTT_VIEW_ZOOM_STATE_KEY = 'ganttViewZoom_{}';
export const GANTT_DEFAULT_LEAD_DAY = 0;

export const MEASUREMENT_UNIT_TYPES = {
  TASK: 0,
  MATERIAL: 1,
};

export const EXCEL_REPORT_TYPES = {
  MATERIAL_USAGE_REPORT: 0,
  PROJECT_STAGES_REPORT: 1,
  PETTY_CASH_REPORT: 2,
  INDENT_REPORT: 3,
  INVENTORY_REPORT: 4,
  ISSUES_REPORT: 5,
  PO_EXTRACT: 6,
  INV_EXTRACT: 7,
  ST_EXTRACT: 8,
  MVP_EXTRACT: 9,
  INDENT_EXTRACT: 10,
  GRN_EXTRACT: 11,
  EXPENSE_EXTRACT: 12,
  CONSUMPTION_EXTRACT: 14,
  LABOUR_ATTENDANCE_EXPORT: 16,
  VENDOR_ATTENDANCE_EXPORT: 17,
  GROUPED_MATERIAL_REPORT: 18,
  ALL_MATERIAL_REPORT: 19,
  PROJECT_PLAN_EXTRACT: 20,
  PROJECT_PLAN_EXTRACT_NESTING: 21,
  PROJECT_LABOUR_ATTENDANCE_EXPORT: 22,
  PROJECT_VENDOR_ATTENDANCE_EXPORT: 23,
  ORG_PAYABLE_EXPORT: 24,
  BOQ_EXTRACT: 25,
  ORG_PAYMENT_EXPORT: 26,
  WORK_ORDER: 27,
} as const;

export const REPORT_SECTION_KEYS = Object.freeze({
  TASK: {
    PROGRESS: 1,
    SHOW_TASK_PHOTOS: 2,
    TASK_PRICE: 3,
    SHOW_TASK_NESTING: 4,
    SHOW_CUMULATIVE_PROGRESS: 5,
    SHOW_DB_REMARKS: 6,
    SHOW_TASK_ATTACHMENTS: 7,
    BROAD_PLAN_PROGRESS: 8,
    BROAD_PLAN_PHOTOS: 9,
  },
  THREADS: {
    THREADS: 1,
  },
  MATERIALS: {
    INVENTORY: 1,
    INDENTS: 2,
    GRN: 3,
    EXPENSES: 4,
    MI: 5,
    SHOW_ESTIMATED_QUANTITY: 6,
    ST: 7,
    CONSUMPTION: 8,
  },
  ATTENDANCE: {
    ATTENDANCE: 1,
    VENDOR_ATTENDANCE: 3,
    VENDOR_PAYABLES: 4,
    LABOUR_ATTENDANCE: 5,
    LABOUR_PAYABLES: 6,
    ATTENDANCE_PHOTOS: 7,
    ASYNC_ATTENDANCE: 8,
    ASYNC_ATTENDANCE_PAYABLES: 9,
  },
  PAYABLES: {
    PAYABLES_PO: 0,
    PAYABLES_GRN: 1,
    PAYABLES_ATTENDANCE: 2,
    PAYABLES_CUSTOM: 4,
    PAYBLES_WO: 3,
  },
});

export const REPORT_TYPES = Object.freeze({
  PROJECT_PROGRESS_REPORT: 0,
  INVENTORY_REPORT: 1,
  CLIENT_REPORT: 2,
  USER_PROFILE: 3,
  INDENT_REPORT: 4,
  PO_REPORT: 5,
  GRN_REPORT: 25,
  // EXPERIMENTAL REPORTS
  LABOUR_OVERTIME_PROGRESS_REPORT: 6, // show_att_conolidated - true report
  MONET_MATERIAL_REPORT: 7, // v3 ui version report
  MONET_FOUNDER_REPORT: 8, // v2 ui version report
  MONET_PAYABLE_REPORT: 9, // project-report-pow-2187
  PLG_SITE_FLOW_V3: 11, // EXP_PLG_SITE_FLOW_V3
  ENGAGE_LOOP_REPORT: 12, // generate-project-report-plg-s2
  THREAD_REVAMP_REPORT: 14, // exp_task_material_usage
  DPR_NEW_ATTENDANCE_REPORT: 19,
  WORK_ORDER_REPORT: 26,
});

export const CHANNEL_TYPES = Object.freeze({
  SMS: 0,
  WHATSAPP: 1,
  CALL: 2,
  EMAIL: 3,
});

export const CUSTOM_COLUMNS = Object.freeze({
  TASK: 0,
  TASK_LOG: 1,
  TL_FREE_TEXT: 2,
  DROPDOWN: 3,
  TL_NUMBER_NA: 4,
});

export const CUSTOM_FIELD_TYPES = Object.freeze({
  FREE_TEXT: 0,
  NON_ADDITIVE_NUMBER: 1,
  ADDITIVE_NUMBER: 2,
  DROPDOWN: 3,
  COMPUTED: 4,
  DATE: 5,
  TIME: 6,
  MEDIA: 7,
  FILE: 8,
});

export const CUSTOM_FIELD_RESOURCE_TYPES = Object.freeze({
  TASK: 0,
  TASK_LOG: 1,
  GRN: 2,
});

export const CUSTOM_FIELD_RESOURCE_NAMES = Object.freeze({
  TASK: 'TASK',
  TASK_LOG: 'TASK_LOG',
  GRN: 'GRN',
});

export const SUPPORTED_CF_TYPES = {
  TASK: [
    CUSTOM_FIELD_TYPES.FREE_TEXT,
    CUSTOM_FIELD_TYPES.COMPUTED,
    CUSTOM_FIELD_TYPES.DROPDOWN,
  ],
  TASK_LOG: [CUSTOM_FIELD_TYPES.ADDITIVE_NUMBER],
};

export const APPROVAL_STATES = Object.freeze({
  INITIAL: 0,
  APPROVED: 1,
  REJECTED: 2,
  CANCELLED: 3,
});

export const APPROVAL_STATES_DISPLAY_V2 = Object.freeze({
  0: {
    text: 'Approval Pending',
    bgClass:
      'bg-surface-warning border-1 border-stroke-warning text-content-warning',
  },
  1: {
    text: 'Approved',
    bgClass:
      'bg-surface-success border-1 border-stroke-success text-content-success',
  },
  2: {
    text: 'Rejected',
    bgClass:
      'bg-surface-critical border-1 border-stroke-critical text-content-critical',
  },
  3: {
    text: 'Cancelled',
    bgClass: 'cbg-blue-500',
  },
});

export const APPROVAL_STATES_DISPLAY = Object.freeze({
  0: { text: 'Waiting for approval', bgClass: 'cbg-grey-800' },
  1: { text: 'Approved', bgClass: 'cbg-green-500' },
  2: { text: 'Rejected', bgClass: 'cbg-red-500' },
  3: { text: 'Cancelled', bgClass: 'cbg-blue-500' },
});

export const INDENT_APPROVAL_STATUS = Object.freeze({
  APPROVED: 'Approved',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
});
export const INDENT_APPROVAL_STATUS_MAP = Object.freeze({
  [INDENT_APPROVAL_STATUS.APPROVED]: 1,
  [INDENT_APPROVAL_STATUS.PENDING]: 0,
  [INDENT_APPROVAL_STATUS.REJECTED]: 2,
});
export const APPROVALS_STYLES_CONFIG = {
  [APPROVAL_STATES.INITIAL]: {
    textClasses: 'color-yellow-600 cbg-yellow-100',
    text: 'Waiting for Approval',
  },
  [APPROVAL_STATES.APPROVED]: {
    textClasses: 'color-green-800 cbg-green-100',
    text: 'Approved',
  },
  [APPROVAL_STATES.REJECTED]: {
    textClasses: 'color-red-500 cbg-red-100',
    text: 'Rejected',
  },
};
export const APPROVALS_STYLES_CONFIG_V2 = {
  [APPROVAL_STATES.INITIAL]: {
    textClasses:
      'bg-surface-warning border-1 border-stroke-warning text-content-warning rounded-lg py-xs px-md',
    text: 'Approval Pending',
  },
  [APPROVAL_STATES.APPROVED]: {
    textClasses:
      'bg-surface-success border-1 border-stroke-success rounded-lg py-xs px-md text-content-success',
    text: 'Approved',
  },
  [APPROVAL_STATES.REJECTED]: {
    textClasses:
      'bg-surface-critical border-1 border-stroke-critical rounded-lg py-xs px-md text-content-critical',
    text: 'Rejected',
  },
};
export const INDENT_DETAIL_STYLES_CONFIG = {
  [INDENT_APPROVAL_STATUS.APPROVED]: {
    [IndentStatus['Not Yet']]: {
      dialogHeaderClass: 'cbg-blue-600',
      agHeaderClass: 'cbg-blue-200',
      headerTitle: 'Approved',
    },
    [IndentStatus.Partially_Fulfilled]: {
      dialogHeaderClass: 'cbg-yellow-600',
      agHeaderClass: 'cbg-yellow-100',
      headerTitle: 'Partially to PO',
    },
    [IndentStatus.Fulfilled]: {
      dialogHeaderClass: 'cbg-green-600',
      agHeaderClass: 'cbg-green-100',
      headerTitle: 'Converted to PO',
    },
  },
  [INDENT_APPROVAL_STATUS.REJECTED]: {
    dialogHeaderClass: 'cbg-red-500',
    agHeaderClass: 'cbg-red-100',
    headerTitle: 'Rejected',
  },
  [INDENT_APPROVAL_STATUS.PENDING]: {
    dialogHeaderClass: 'cbg-grey-600',
    agHeaderClass: 'cbg-grey-100',
    headerTitle: 'Approval Pending',
  },
};

export const DATE_TYPE = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export enum TL_PROGRESS_TYPES {
  UPDATE = 'update',
  NO_PROGRESS = 'no_progres',
}

export enum WORKER_COUNT_TYPES {
  SKILLED = 'skilled_worker_count',
  UNSKILLED = 'unskilled_worker_count',
}

export const VENDOR_DETAIL_TYPE = Object.freeze({
  VENDOR_WORKER: 0,
  VENDOR_COUNT: 1,
});

export const VENDOR_DETAIL_TYPE_MAP = Object.freeze({
  0: 'VENDOR_WORKER',
  1: 'VENDOR_COUNT',
});

export const WORKER_ITEM_TYPE = Object.freeze({
  VENDOR: 0,
  LABOUR: 1,
});

export const UPLOAD_TYPE = {
  VENDORS: 0,
  LABOURS: 1,
  VENDOR_LABOURS: 2,
};

export const INVENTORY_HISTORY_UPDATE_TYPE = {
  INWARD: 0,
  OUTWARD: 1,
  DELETE: 2,
};

export const INVENTORY_HISTORY_ITEM_TYPE = {
  GRN: 0,
  MI: 1,
  TASKLOG: 2,
  ST: 3,
};

export const wageDurationType = {
  DURATION_SELECT: 0,
  ALL_HISTORY: 1,
};

export const SEQ_LEVEL = {
  ORG: 0,
  PROJECT: 1,
};

export const MATERIAL_SEQUNCE = {
  SITE_TRANSFER: 'st',
  MATERIAL_ISSUE: 'mi',
  GRN: 'grn',
};

export const SHOW_ONBOARDING_AFTER_DATE_MS = 1689705000000;

export const APP_DL = 'https://app.getpowerplay.in/6pnaryjaejTV8pKU7';

export const DEFUALT_AUTO_DPR_TIME = {
  SITE_TEAM_TIME: '06:00',
  OFFICE_TEAM_TIME: '08:00',
};

export const VENDOR_TYPE_SELECT = [
  {
    type: 'VENDOR_WORKER',
    value: 0,
    text: 'Each worker Seperately',
  },
  {
    type: 'VENDOR_COUNT',
    value: 1,
    text: 'Count of Workers',
  },
];

export const STATUS_CODE = Object.freeze({
  CONFLICT: 409,
});

export const FILE_TYPE = Object.freeze({
  MPP: 'mpp',
  XML: 'xml',
  XLS: 'xls',
  XLSX: 'xlsx',
  CSV: 'csv',
});

export const TERMS_AND_CONDITION_ENTITY_TYPE = Object.freeze({
  PO: 0,
  WO: 1,
});

export const FORM_MODE = Object.freeze({
  DUPLICATE: 'duplicate',
  CREATE: 'create',
  EDIT: 'edit',
});

export const phoneWithEmptyAllowedRegExp = /^([1-9])(\d{3,14})$|^$/;

export const WAGE_TYPE = {
  DAILY: 0,
  MONTHLY: 1,
};

export const TRANSACTION_CATEGORY = Object.freeze({
  MISCELLANEOUS: 0,
  MATERIAL: 1,
  ATTENDANCE: 2,
  SUBCONTRACTOR: 3,
  MACHINE: 4,
});

export const TRANSACTION_CATEGORY_MAP = Object.freeze({
  0: 'Miscellaneous',
  1: 'Material',
  2: 'Attendance',
  3: 'Subcontractors',
  4: 'Machine',
});

export const VENDOR_CATEGORY_MAP = Object.freeze({
  2: 'Material',
  1: 'Attendance',
  3: 'Subcontractor',
});

export const VENDOR_CATEGORY = {
  ATTENDANCE: 1,
  MATERIAL: 2,
  SUBCONTRACTOR: 3,
} as const;

export const VENDOR_TO_TRANSACTION_CATEGORY_MAP = Object.freeze({
  [VENDOR_CATEGORY.ATTENDANCE]: TRANSACTION_CATEGORY.ATTENDANCE,
  [VENDOR_CATEGORY.MATERIAL]: TRANSACTION_CATEGORY.MATERIAL,
  [VENDOR_CATEGORY.SUBCONTRACTOR]: TRANSACTION_CATEGORY.SUBCONTRACTOR,
});

export const WORK_ORDER_STAGE = {
  PARENT: 1,
  CHILD: 2,
  STAGE: 3,
};

export const REPORT_STATUS = {
  NOT_STARTED: 0,
  PENDING: 1,
  COMPLETED: 2,
  FAILED: 3,
};

export const ATTENDANCE_PAYMENT_TYPE = {
  DEFAULT: 0,
  PETTY_CASH: 1,
};

export const WAGE_DURATION = Object.freeze({
  FUTURE_DATES: 0,
  SPECIFIC_DATES: 1,
  CUSTOM_DURATION: 2,
});

export const DELIVERY_TYPES = {
  SITE_TRANSFER: 0,
  PURCHASE_ORDER: 1,
  SIMPLE_ORDER: 2,
  INDENT: 3,
};

export const VENDOR_PAYMENT_TYPE = {
  PAYMENT: 0,
  PETTY_CASH: 1,
};

export const PC_STATUS_TYPE = {
  DRAFT: 0,
  APPROVAL_PENDING: 1,
  REJECTED: 2,
  APPROVED: 3,
};

export const mergeStep = {
  SELECT_AND_MERGE: 1,
  REVIEW_SELECTION: 2,
};

export const ATTENDANCE_STATUS = {
  DEFAULT: -1,
  ABSENT: 0,
  PRESENT: 1,
  HALF_DAY: 2,
  MANUAL: 3,
} as const;

export type AttendanceStatusType =
  (typeof ATTENDANCE_STATUS)[keyof typeof ATTENDANCE_STATUS];

export const PROJECT_APPROVER_CHOICES = {
  PO: 'po',
  GRN: 'grn',
  INDENT: 'indent',
  BOQ: 'boq',
  MI_SC: 'mi_subcontractor',
  MI_ST: 'mi_sitetransfer',
  DRIVE: 'drive',
};

export const WORK_ORDER_UNIT_OPTION = [
  { value: 'acr', label: 'Acres' },
  { value: 'bdl', label: 'Bundle' },
  { value: 'cm', label: 'Centimeters' },
  { value: 'cum', label: 'Cubic Meters' },
  { value: 'cuf', label: 'Cubic Feet' },
  { value: 'ft', label: 'Feet' },
  { value: 'gal', label: 'Gallons' },
  { value: 'gm', label: 'Grams' },
  { value: 'ha', label: 'Hectares' },
  { value: 'in', label: 'Inches' },
  { value: 'jw', label: 'Job Work' },
  { value: 'kg', label: 'Kilograms' },
  { value: 'km', label: 'Kilometers' },
  { value: 'lb', label: 'Pounds' },
  { value: 'm', label: 'Meters' },
  { value: 'mg', label: 'Milligrams' },
  { value: 'mi', label: 'Miles' },
  { value: 'ml', label: 'Milliliters' },
  { value: 'mm', label: 'Millimeters' },
  { value: 'nos', label: 'Numbers' },
  { value: 'oz', label: 'Ounces' },
  { value: 'pcs', label: 'Pieces' },
  { value: 'pkt', label: 'Packet' },
  { value: 'rmt', label: 'Running Meters' },
  { value: 'sqft', label: 'Square Feet' },
  { value: 'sqm', label: 'Square Meters' },
  { value: 'ton', label: 'Tons' },
  { value: 'yd', label: 'Yard' },
  { value: '%', label: '%' },
];

export const signatureFonts = [
  'Open Sans',
  'Indie Flower',
  'Gloria Hallelujah',
  'Homemade Apple',
  'Macondo',
  'Zeyada',
];

export const signatureFontsLink =
  'https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Homemade+Apple&family=Indie+Flower&family=Macondo&family=Nabla&family=Tangerine:wght@400;700&family=Zeyada&display=swap';

export const GST_TYPE = {
  IGST: 0,
  SGST_CGST: 1,
};

export const GST_TYPE_NAME = {
  0: 'IGST',
  1: 'CGST/SGST',
};

export const DECIMAL_PRECISION = 3;

export const VENDOR_PROFILE_TABS = {
  RECORDS: 0,
  HISTORY: 1,
  PAYABLES: 2,
  LABOUR_LIST: 3,
  WORK_ORDER: 4,
};

export const SORTING_ORDER = {
  ASCENDING: 1,
  DESCENDING: -1,
};

export const ATTENDANCE_NEW_APPROVAL_STATUS = {
  DRAFT: 0,
  APPROVAL_PENDING: 1,
  REJECTED: 2,
  APPROVED: 3,
};

export const AG_DEFAULT_ROW_HEIGHT = 40;

export const MEDIA_CATEGORY = {
  IMAGE: 1,
  VIDEO: 2,
  DOCUMENT: 3,
};

export const GROUPED_MATERIAL_TYPE = {
  SAME_NAME: 0,
  DIFFERENT_MATERIAL: 1,
};

export const MATERIAL_SELECT_MODE = {
  SELECT_BOMS: 'SELECT_BOMS',
};

export const MATERIAL_VIEW_TAB = {
  ALL: 0,
  GROUPED: 1,
  PROJECT: 2,
};
export const GROUPED_MATERIAL_STATUS = {
  DRAFT: 0,
  DONE: 1,
};

export const GROUP_ENTITY_TYPE = {
  BOMS: 0,
};

export const MATERIAL_INVENTORY_DETAILS = {
  STOCK_LOGS: 0,
  APPROVED_REQUESTS: 1,
  AVAILABLE_STOCKS: 2,
  PRICING_HISTORY: 3,
};

export const ES_MIN_LENGTH = {
  PROJECT: 3,
  VENDOR: 3,
  LABOUR: 3,
};

export const GSTIN_LENGTH = 15;
export const ES_SEARCH_TEXT_MAX_LENGTH = 15;
export const ES_SEARCH_TEXT_MIN_LENGTH = 2;
export const PO_FORM_UOM_MAX_DISPLAY_LENGTH = 14;
export const REMARKS_MAX_LENGTH = 200;
export const KILO_BYTE = 1024;

export const VENDOR_CATEGORY_LABELS = Object.freeze({
  ATTENDANCE: 'Labours',
  MATERIAL: 'Material',
  SUB_CONTRACTOR: 'SubContractor',
});

export const VENDOR_CATEGORY_LABELS_MAP = Object.freeze({
  [VENDOR_CATEGORY.ATTENDANCE]: 'Labours',
  [VENDOR_CATEGORY.MATERIAL]: 'Material',
  [VENDOR_CATEGORY.SUBCONTRACTOR]: 'SubContractor',
});

export const MAX_NUMBER_ALLOWED = 99999999;
export const MAX_DEC_NUMBER_ALLOWED = 99999999.999;
export const MAX_QUANTITY_ALLOWED = 9999999999.99;

export const RESTRICT_HEADER_QUERY = 'restrict-header';

export const TAX_GROUP_VALUES: TaxGroup[] = [0, 1, 2, 3, 4, 5, 6];
export const EXCEL_UPLOAD_TYPE = {
  ORG_VENDORS: 'orgVendors',
};

export const ALLOWED_LIMIT_LIST = [10, 20, 30];

export const QUERY_FILTER_KEYS = {
  INDENT: 'indentFilter',
  PO: 'poFilter',
  GRN: 'grnFilter',
  MI: 'miFilter',
  ST: 'stFilter',
  CONSUMPTIONS: 'consumptionFilter',
  INVENTORY: 'inventory',
  MVP: 'mvpFilter',
  LABOUR_PROJECT_IDS: 'labourProjectIds',
  LABOUR_IDS: 'labourIds',
  LABOUR_CREATE_DATE: 'labourCreateDate',
  VENDOR_PROJECT_IDS: 'vendorProjectIds',
  VENDOR_IDS: 'vendorIds',
  VENDOR_CREATE_DATE: 'vendorCreateDate',
  WORK_ORDER: 'workOrderFilter',
  LABOUR_PAGE: 'labourPage',
  LABOUR_LIMIT: 'labourLimit',
  VENDOR_PAGE: 'vendorPage',
  VENDOR_LIMIT: 'vendorLimit',
  LABOUR_PAYABLE_PAGE: 'labourPayablePage',
  LABOUR_PAYABLE_LIMIT: 'labourPayableLimit',
  LABOUR_PROJECT_PAGE: 'labourProjectPage',
  LABOUR_PROJECT_LIMIT: 'labourProjectLimit',
  VENDOR_PROJECT_PAGE: 'vendorProjectPage',
  VENDOR_PROJECT_LIMIT: 'vendorProjectLimit',
  VENDOR_TRANSACTION_PAGE: 'vendorTransactionPage',
  VENDOR_TRANSACTION_LIMIT: 'vendorTransactionLimit',
};
export const MAX_LENGTH_TRUNCATE_NAME = 30;

export const QUERY_PARAMS = {
  INV_ID: 'inv_id',
  INV_VIEW_ID: 'inv_view_id',
  INDENT_ID: 'indent_id',
  PO_INDENT_ID: 'po_indent_id',
  INDENT_VIEW_ID: 'indent_view_id',
  PO_ID: 'po_id',
  INDENT_PO_ID: 'indent_po_id',
  GRN_ID: 'grn_id',
  WO_ID: 'wo_id',
};

export const PRECISION_TYPE = {
  QUANTITY: 3,
  MONEY: 2,
};

export const DEFAULT_PREVIOUS_DAYS = 30;

export const DEFAULT_PREVIOUS_DAYS_PAYABLES = 31;

export const ALL_MODULE_SOURCE_URL = {
  po: 'po',
  indent: 'indent',
  inv: 'inventory',
  grn: 'grn',
  siteTransfer: 'site transfer',
  materialIssue: 'material issue',
  expenses: 'expenses',
  consuptions: 'consuptions',
  mvp: 'mvp',
  labour: 'labour',
  vendor: 'vendor',
  payable: 'payable',
};

export const COMMERCIAL_FILTER_KEYS = {
  MATERIAL_ST_FILTER: 'material_st_filter',
  MATERIAL_INDENT_FILTER: 'material_indent_filter',
  MATERIAL_MI_FILTER: 'material_mi_filter',
  MATERIAL_PO_FILTER: 'material_po_filter',
  MATERIAL_PO_FILTER_PROJECT: 'material_po_filter_project',
  MATERIAL_INDENT_FILTER_PROJECT: 'material_indent_filter_project',
  MATERIAL_EXPENSE_FILTER: 'material_exp_filter',
  MATERIAL_MVP_FILTER: 'material_mvp_filter',
  MATERIAL_PROJECT_INV_FILTER: 'material_project_inv_filter',
  MATERIAL_GRN_FILTER: 'material_grn_filter',
  MATERIAL_ALL_MATERIAL_FILTER: 'material_all_material_filter',
  MATERIAL_CONSUMPTIONS_FILTER: 'material_consumptions_filter',
  MATERIAL_GROUPED_MATERIAL_FILTER: 'material_grouped_material_filter',
  SELECTED_PROJECT_ID: 'selected_project_id',
};

export const ORG_WORK_ORDER_FILTER = 'org_work_order_filter';

export const REPORT_TYPE_OPTIONS = [
  { name: 'Project Stages', value: EXCEL_REPORT_TYPES.PROJECT_STAGES_REPORT },
  { name: 'Petty Cash', value: EXCEL_REPORT_TYPES.PETTY_CASH_REPORT },
  { name: 'Issues', value: EXCEL_REPORT_TYPES.ISSUES_REPORT },
  { name: 'Purchase Order', value: EXCEL_REPORT_TYPES.PO_EXTRACT },
  { name: 'Site Transfer', value: EXCEL_REPORT_TYPES.ST_EXTRACT },
  { name: 'MVP Extract', value: EXCEL_REPORT_TYPES.MVP_EXTRACT },
  { name: 'Indent Extract', value: EXCEL_REPORT_TYPES.INDENT_EXTRACT },
  { name: 'GRN Extract', value: EXCEL_REPORT_TYPES.GRN_EXTRACT },
  { name: 'Expense Extract', value: EXCEL_REPORT_TYPES.EXPENSE_EXTRACT },
  {
    name: 'Material Consumption',
    value: EXCEL_REPORT_TYPES.CONSUMPTION_EXTRACT,
  },
  {
    name: 'Labour Attendance',
    value: EXCEL_REPORT_TYPES.LABOUR_ATTENDANCE_EXPORT,
  },
  {
    name: 'Vendor Attendance',
    value: EXCEL_REPORT_TYPES.VENDOR_ATTENDANCE_EXPORT,
  },
  {
    name: 'Grouped Material',
    value: EXCEL_REPORT_TYPES.GROUPED_MATERIAL_REPORT,
  },
  {
    name: 'Work Order',
    value: EXCEL_REPORT_TYPES.WORK_ORDER,
  },
];

export const IS_ORG_DPR_DIALOG_OPEN = 'openDPRDialog';
export const IS_PROJECT_DPR_DIALOG_OPEN = 'ProjectDPRDialog';

export const SOURCE_MODULE_ASYNC_REPORT = 'async report section';

export const DEFAULT_CREATE_DATE = {
  create_date: {
    end: getEndDateEpoch(Date.now()),
    start: getStartDateEpoch(Date.now() - DAY_IN_MS * 30),
  },
};

export const COMING_SOON_ROCKET_ICON =
  'https://assets-powerplay.s3.ap-south-1.amazonaws.com/images/Rocket.png';

export const MATERIAL_CF_ICON =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/materialCF.png';

export const TASK_CF_ICON =
  'https://prod-powerplay.s3.ap-south-1.amazonaws.com/taskCF.png';
export const PAYABLE_PAYMENT_STATUS = {
  UNPAID: 0,
  PARTIALLY_PAID: 1,
  PAID: 2,
  // OVERDUE: 3,
};
