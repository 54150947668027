import React, { useEffect, useState, useCallback } from 'react';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover';
import Autoplay from 'embla-carousel-autoplay';
import { SquareArrowOutUpRight } from 'lucide-react';
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
} from '@/components/ui/carousel';
import { Button } from '@/components/ui/Button';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { getWhatsNew } from '@/redux/features/whatsNew';
import { Text } from '@/components/ui/Text';

import { NewFeatureIcon } from '../../../../common/Icons';
import { useAppDispatch, useAppSelector } from '../../../../../redux/Hooks';

const localstorageKey = 'whatsNewTimestamp';
interface StyledBadgeProps {
  children: React.ReactNode;
  [key: string]: any;
}

interface WhatsNewDataProps {
  image_url: string;
  title: string;
  subtitle: string;
  url: string;
}

interface CarouselItemBoxProps {
  item: WhatsNewDataProps;
}

const StyledBadge: React.FC<StyledBadgeProps> = ({ children, ...props }) => {
  return <Badge {...props}>{children}</Badge>;
};

const CarouselItemBox: React.FC<CarouselItemBoxProps> = React.memo(
  ({ item }: CarouselItemBoxProps) => {
    return (
      <div className="px-6 pb-3">
        <div className="rounded-lg">
          <div className="flex justify-center mb-4">
            <img
              src={item.image_url}
              alt={item.title}
              className="rounded-lg aspect-w-16 aspect-h-9 max-h-96"
            />
          </div>
        </div>
        <div className="mt-6">
          <div>
            <div className="flex justify-between">
              <div className="flex flex-col gap-2 pr-4">
                <Text
                  variant="title"
                  size="l"
                  className="mb-0 fw-bold"
                  value={item.title}
                />
                <Text
                  className="mt-0"
                  variant="body"
                  size="m"
                  value={item.subtitle}
                />
              </div>
              <div className="flex">
                <a href={item.url} target="_blank" rel="noreferrer">
                  <Button size="sm" className="text-white">
                    Learn more
                    <SquareArrowOutUpRight className="ml-2" size={12} />
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const WhatsNewMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [whatsNewCount, setWhatsNewCount] = useState<number>(0);
  const whatsNewData = useAppSelector(state => state.whatsNewReducer.data);
  const dispatch = useAppDispatch();
  const [api, setApi] = useState<CarouselApi | null>(null);
  const [current, setCurrent] = useState<number>(0);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (api) {
      setCount(api.scrollSnapList().length);
      setCurrent(api.selectedScrollSnap() + 1);
      api.on('select', () => {
        setCurrent(api.selectedScrollSnap() + 1);
      });
    }
  }, [api]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    localStorage.setItem(localstorageKey, Date.now().toString());
    setWhatsNewCount(0);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleSlideChange = useCallback(
    (index: number) => {
      api?.scrollTo(index);
    },
    [api]
  );

  useEffect(() => {
    const lastTimestamp = localStorage.getItem(localstorageKey);
    if (lastTimestamp) {
      const lastTimestampNumber = parseInt(lastTimestamp, 10);
      const newWhatsNewData = whatsNewData.filter(
        item => new Date(item.created_at).getTime() > lastTimestampNumber
      );
      setWhatsNewCount(newWhatsNewData.length);
    } else {
      setWhatsNewCount(whatsNewData.length);
    }
  }, [whatsNewData]);

  useEffect(() => {
    dispatch(getWhatsNew());
  }, [dispatch]);

  return whatsNewData?.length ? (
    <div className="mx-2">
      <StyledBadge
        aria-owns={anchorEl ? 'mouse-over-popover' : undefined}
        badgeContent={whatsNewCount}
        aria-haspopup="true"
        color="primary"
        className="mr-2"
        onClick={handlePopoverOpen}
      >
        <IconButton className={`p-0 ${whatsNewCount ? 'blinking-border' : ''}`}>
          <NewFeatureIcon />
        </IconButton>
      </StyledBadge>

      <Popover
        id="mouse-over-popover"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        className="mt-2"
      >
        <div className="bg-gray-50 whats-new-div">
          <div className="pt-3 px-4">
            <div className="flex justify-between items-center pb-1">
              <Text variant="title" size="l" value="What’s new? ✨" />
              <div className="text-sm text-gray-500">
                <IconButton
                  onClick={handlePopoverClose}
                  size="small"
                  className="border rounded-pill"
                >
                  <Close className="p-1" />
                </IconButton>
              </div>
            </div>
            <div className="flex justify-center my-3">
              {Array.from({ length: count }).map((_, index) => (
                <div
                  key={`whats-new-${index}`}
                  onClick={() => handleSlideChange(index)}
                  className={`h-2 hand-pointer w-1/${count} mr-1 rounded ${
                    index === current - 1 ? 'bg-blue-500' : 'bg-gray-300'
                  }`}
                />
              ))}
            </div>
          </div>

          <Carousel
            plugins={[
              Autoplay({
                delay: 5000,
              }),
            ]}
            className="w-full max-w-lg pt-0"
            setApi={setApi}
          >
            <CarouselContent>
              {whatsNewData.map((item, index) => (
                <CarouselItem
                  key={`whats-new-carousel-${index}`}
                  className="w-full pl-3"
                >
                  <CarouselItemBox item={item} />
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
        </div>
      </Popover>
    </div>
  ) : null;
};

export default WhatsNewMenu;
