import { Dispatch } from 'redux';

import {
  ONBOARDING_CREATE_ORG_AND_PROJECT_PENDING,
  ONBOARDING_API_ERROR,
  SET_ONBOARDING_STEP,
  SET_NEW_ONBOARDING_QUESTIONS,
  SET_QUESTION_LOADING,
  SET_ANSWER,
  SET_NO_QUESTION_LEVEL,
} from './Onboarding.type';
import {
  toastSuccessMessage,
  toastErrorMessage,
  toastLoadingMessage,
} from '../../../../utils/Toast.util';
import {
  API_VERSIONS,
  INVENTORY_APIS,
  ONBOARDING_APIS,
} from '../../../../routes.constant';
import { getOrgProfile } from '../../common/organisation/Organisation.action';
import { getSelfPermission, getUserOrgs } from '../../common/user';
import {
  CreateOrgAndProjectProps,
  FetchNewOnboardingQuestions,
} from '../../../../interfaces/Onboarding';
import { eventDispatch } from '../../../base';
import OrgProfileProps from '../../../../interfaces/Organisation';
import { getRequest, postRequest } from '../../../../apiClient';
import { RootState } from '../../../Reducers';
import { parseString } from '../../../../utils';
import { STATUS_CODE } from '../../../../constants';
import { customStringify } from '../../../../utils/Url.util';

export function createOrgAndProject(reqBody: CreateOrgAndProjectProps) {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(eventDispatch(ONBOARDING_CREATE_ORG_AND_PROJECT_PENDING, true));
    try {
      const { data: org } = await postRequest(
        ONBOARDING_APIS.createOrg,
        reqBody
      );
      const userId = getState().userreducer._id;
      dispatch(eventDispatch(SET_ONBOARDING_STEP, 1));
      await postRequest(
        ONBOARDING_APIS.createProject,
        {
          ...reqBody,
          org_id: org.org_id,
        },
        'v2'
      );
      dispatch(eventDispatch(ONBOARDING_CREATE_ORG_AND_PROJECT_PENDING, false));
      dispatch(eventDispatch(SET_ONBOARDING_STEP, 2));
      toastSuccessMessage('Successfully created Organisation and Project');
      dispatch(getOrgProfile(org.org_id, true));
      dispatch(getUserOrgs({ user_id: userId }));
      dispatch(getSelfPermission());
    } catch (e) {
      dispatch(eventDispatch(ONBOARDING_API_ERROR, e));
      dispatch(eventDispatch(ONBOARDING_CREATE_ORG_AND_PROJECT_PENDING, false));
      toastErrorMessage(
        e.errorMsg || 'Error in creating Organisation and Project'
      );
    }
  };
}

export const createOrg =
  (reqBody: OrgProfileProps) =>
  async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const toastId = toastLoadingMessage(`Creating ${reqBody.org_name}`);
    const userId = getState().userreducer._id;
    try {
      await postRequest(ONBOARDING_APIS.createOrg, reqBody);
      dispatch(getUserOrgs({ user_id: userId }));
      toastSuccessMessage(`Successfully created ${reqBody.org_name}`, toastId);
    } catch {
      toastErrorMessage(`Cannot create ${reqBody.org_name}`, toastId);
    }
  };

export const fetchQuestions =
  (query: FetchNewOnboardingQuestions) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(eventDispatch(SET_QUESTION_LOADING, true));
      const url = parseString(
        ONBOARDING_APIS.getNewOnboardingQuestions,
        customStringify(query)
      );
      const {
        data: { questions = [], no_question_level: noQuestionLevel },
      } = await getRequest(url, API_VERSIONS.V2);

      const prefilledAnswers = questions.reduce(
        (map, { _id, answer = [], other_answer }) => {
          map.set(_id, {
            question_id: _id,
            options: answer,
            other_answer,
          });

          return map;
        },
        new Map()
      );

      dispatch(eventDispatch(SET_NEW_ONBOARDING_QUESTIONS, questions));
      dispatch(eventDispatch(SET_NO_QUESTION_LEVEL, noQuestionLevel));
      dispatch(eventDispatch(SET_ANSWER, prefilledAnswers));
      dispatch(eventDispatch(SET_QUESTION_LOADING, false));
    } catch (error) {
      dispatch(eventDispatch(SET_QUESTION_LOADING, false));
      toastErrorMessage('Something went wrong while fetching questions');
    }
  };

export const postAnswers =
  (questions, answers, orgId, cb = () => {}) =>
  async () => {
    const answersToPost = questions.map(question => answers.get(question._id));
    const body = {
      org_id: orgId,
      answers: answersToPost,
    };

    try {
      const url = ONBOARDING_APIS.postAnswerForNewOnboarding;
      await postRequest(url, body, API_VERSIONS.V2);
      cb?.();
    } catch (error) {
      toastErrorMessage('Something went wrong while submitting answers');
      throw error;
    }
  };

export const postAnswerswithBody =
  (body, successCallback = () => {}, sendToast = false) =>
  async () => {
    try {
      const url = ONBOARDING_APIS.postAnswerForNewOnboarding;
      await postRequest(url, body, API_VERSIONS.V2);
      if (sendToast) {
        toastSuccessMessage('Members Added Successfully');
      }
      successCallback();
    } catch (error) {
      toastErrorMessage('Something went wrong while submitting answers');
      throw error;
    }
  };

export const sendWhatsappInviteAdmin = body => async () => {
  try {
    const url = ONBOARDING_APIS.whatsappInviteAdmin;
    await postRequest(url, body);
    toastSuccessMessage('Successfully Sent the Invite on whatsapp');
  } catch (error) {
    toastErrorMessage('Something went wrong while sending invite');
  }
};

export function onboardingCreateInventories(
  reqBody,
  successCallback = () => {}
) {
  return async () => {
    try {
      await postRequest(INVENTORY_APIS.createInventories, reqBody);
      successCallback();
      toastSuccessMessage('Successfully added inventories');
    } catch (error) {
      if (error?.response?.status === STATUS_CODE.CONFLICT) {
        toastErrorMessage(error?.response?.data?.message);
      } else {
        toastErrorMessage('Error in Creating inventories');
      }
    }
  };
}

export function inviteOnboardingMember(query, successCallback = () => {}) {
  return async () => {
    try {
      const url = parseString(
        ONBOARDING_APIS.inviteOnboardingMember,
        customStringify(query)
      );
      await getRequest(url, API_VERSIONS.V2);
      successCallback();
    } catch (error) {
      toastErrorMessage('Error in inviting Members');
    }
  };
}
