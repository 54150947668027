import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const TreeStateIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...(props as any)}
  >
    <path d="M5.83423 8.33301L10.0009 12.4997L14.1676 8.33301H5.83423Z" />
  </SvgIcon>
);

export const PlaylistAddAboveIcon = (props: SvgIconProps) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M8 6H14.5V8H8V6Z" />
    <path d="M5.5 10.25H14.5V11.5H5.5V10.25Z" />
    <path d="M6 7H4V5H3V7H1V8H3V10H4V8H6V7Z" />
  </SvgIcon>
);

export const PlanViewIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M3 10.5L3 7.5C3 7.22386 3.22386 7 3.5 7L4.5 7C4.77614 7 5 7.22386 5 7.5L5 10.5C5 10.7761 4.77614 11 4.5 11L3.5 11C3.22386 11 3 10.7761 3 10.5Z" />
    <path d="M3 16.5L3 13.5C3 13.2239 3.22386 13 3.5 13L4.5 13C4.77614 13 5 13.2239 5 13.5L5 16.5C5 16.7761 4.77614 17 4.5 17L3.5 17C3.22386 17 3 16.7761 3 16.5Z" />
    <path d="M12.5 11H3.5C3.22386 11 3 10.7761 3 10.5V9.5C3 9.22386 3.22386 9 3.5 9H12.5C12.7761 9 13 9.22386 13 9.5V10.5C13 10.7761 12.7761 11 12.5 11Z" />
    <path d="M12.5 17H3.5C3.22386 17 3 16.7761 3 16.5V15.5C3 15.2239 3.22386 15 3.5 15H12.5C12.7761 15 13 15.2239 13 15.5V16.5C13 16.7761 12.7761 17 12.5 17Z" />
    <path d="M5.5 2H2.5C2.22386 2 2 2.22386 2 2.5V5.5C2 5.77614 2.22386 6 2.5 6H5.5C5.77614 6 6 5.77614 6 5.5V2.5C6 2.22386 5.77614 2 5.5 2Z" />
    <path d="M17.5 8H14.5C14.2239 8 14 8.22386 14 8.5V11.5C14 11.7761 14.2239 12 14.5 12H17.5C17.7761 12 18 11.7761 18 11.5V8.5C18 8.22386 17.7761 8 17.5 8Z" />
    <path d="M17.5 14H14.5C14.2239 14 14 14.2239 14 14.5V17.5C14 17.7761 14.2239 18 14.5 18H17.5C17.7761 18 18 17.7761 18 17.5V14.5C18 14.2239 17.7761 14 17.5 14Z" />
  </SvgIcon>
);

export const ListViewIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M17.5 5H7.5C7.22386 5 7 4.77614 7 4.5V3.5C7 3.22386 7.22386 3 7.5 3H17.5C17.7761 3 18 3.22386 18 3.5V4.5C18 4.77614 17.7761 5 17.5 5Z" />
    <path d="M17.5 17H7.5C7.22386 17 7 16.7761 7 16.5V15.5C7 15.2239 7.22386 15 7.5 15H17.5C17.7761 15 18 15.2239 18 15.5V16.5C18 16.7761 17.7761 17 17.5 17Z" />
    <path d="M17.5 11H7.5C7.22386 11 7 10.7761 7 10.5V9.5C7 9.22386 7.22386 9 7.5 9H17.5C17.7761 9 18 9.22386 18 9.5V10.5C18 10.7761 17.7761 11 17.5 11Z" />
    <path d="M5.5 2H2.5C2.22386 2 2 2.22386 2 2.5V5.5C2 5.77614 2.22386 6 2.5 6H5.5C5.77614 6 6 5.77614 6 5.5V2.5C6 2.22386 5.77614 2 5.5 2Z" />
    <path d="M5.5 14H2.5C2.22386 14 2 14.2239 2 14.5V17.5C2 17.7761 2.22386 18 2.5 18H5.5C5.77614 18 6 17.7761 6 17.5V14.5C6 14.2239 5.77614 14 5.5 14Z" />
    <path d="M5.5 8H2.5C2.22386 8 2 8.22386 2 8.5V11.5C2 11.7761 2.22386 12 2.5 12H5.5C5.77614 12 6 11.7761 6 11.5V8.5C6 8.22386 5.77614 8 5.5 8Z" />
  </SvgIcon>
);
