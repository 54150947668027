import React, { useEffect, useLayoutEffect, useState } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import type { ClassNameMap } from '@mui/material';

import clsx from 'clsx';
import { theme } from './Theme';

interface CustomDialogTitleProps {
  children: React.ReactNode;
  classes?: ClassNameMap<any>;
  iconClasses?: string;
  onClose?: React.MouseEventHandler | null;
  titleClass?: string;
  ESDialogV2?: boolean;
  [other: string]: any;
}
export const CustomDialogTitle = (
  props: DialogTitleProps & CustomDialogTitleProps
) => {
  const {
    children,
    classes,
    onClose,
    titleClass,
    ESDialogV2 = false,
    ...other
  } = props;
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <DialogTitle
      {...other}
      style={{
        padding: '0.71875rem 1.5rem',
        zIndex: 1000,
      }}
    >
      {onClose ? (
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item sx={{ flexGrow: 1 }}>
            <div
              className={`${
                ESDialogV2 ? 'dialog-title-V2 font-lato' : 'dialog-title'
              } ${titleClass}`}
            >
              {children}
            </div>
          </Grid>
          <Grid item>
            {onClose ? (
              <div
                onClick={onClose}
                className="p-2"
                style={{
                  color: theme.palette.grey[500],
                  cursor: 'pointer',
                  marginRight: '-0.75rem',
                  backgroundColor: isHovering ? 'rgba(0,0,0,0.1)' : '',
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <CloseIcon className={props.iconClasses} />
              </div>
            ) : null}
          </Grid>
        </Grid>
      ) : (
        children
      )}
    </DialogTitle>
  );
};

interface CustomDialogContentProps {
  classes?: ClassNameMap;
  [other: string]: any;
}
export const CustomDialogContent = (
  props: DialogContentProps & CustomDialogContentProps
) => {
  const { children, classes, className, ...other } = props;
  return (
    <DialogContent
      dividers
      classes={classes}
      {...other}
      className={clsx('custom-scroll', className)}
      style={{ overflowX: 'hidden' }}
    >
      {children}
    </DialogContent>
  );
};

interface CustomDialogActionsProps {
  children: React.ReactNode;
  classes?: ClassNameMap;
  leftAlign?: boolean;
  [other: string]: any;
}
export const CustomDialogActions = (
  props: DialogActionsProps & CustomDialogActionsProps
) => {
  const { children, classes, leftAlign, ...other } = props;
  return (
    <DialogActions
      className="pr-4 pl-4 pt-3 pb-3"
      style={{ justifyContent: leftAlign ? 'left' : '' }}
      classes={classes}
      {...other}
    >
      {children}
    </DialogActions>
  );
};

interface CustomDialogProps {
  /**size defines how much *percentage* of the screen will the dialog take. Should be between `0-100. default value: `55`*/
  width?: number;
  onUnmount?: () => void;
}
export const SideScreenDialog = ({
  children,
  width = 45,
  open,
  ...other
}: DialogProps & CustomDialogProps) => {
  const [fullScreen, setFullScreen] = useState(window.innerWidth <= 1024);

  useLayoutEffect(() => {
    function updateSize() {
      setFullScreen(window.innerWidth <= 1024);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (!open) {
      other?.onUnmount?.();
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      open={open}
      fullScreen={fullScreen}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'left' } as any}
      PaperProps={{
        style: {
          position: 'absolute',
          top: 0,
          right: 0,
          maxWidth: width ? `${width}vw` : '55vw',
          width: fullScreen ? '100vw' : `${width}vw`,
          minHeight: '100dvh',
          margin: 0,
          borderRadius: 0,
          transition: 'all 0.16s linear',
        },
      }}
      {...other}
    >
      {children}
    </Dialog>
  );
};
