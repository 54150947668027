import { ReducerProps } from '@/interfaces/Base';
import {
  GET_LINKED_LOGS,
  GET_LINKED_LOGS_ERROR,
  GET_LINKED_LOGS_PENDING,
  GET_LINKED_LOGS_SUCCESS,
  GET_GRN_PAYABLE_DATA,
  GET_GRN_PAYABLE_DATA_ERROR,
  GET_GRN_PAYABLE_DATA_PENDING,
  GET_GRN_PAYABLE_DATA_SUCCESS,
  GET_PO_PAYABLE_DATA,
  GET_PO_PAYABLE_DATA_ERROR,
  GET_PO_PAYABLE_DATA_PENDING,
  GET_PO_PAYABLE_DATA_SUCCESS,
  GET_ORG_PAYABLES,
  GET_ORG_PAYABLES_ERROR,
  GET_ORG_PAYABLES_PENDING,
  GET_ORG_PAYABLES_SUCCESS,
  GET_ORG_PAYABLES_LIST_COUNT,
  GET_PO_PAYABLE_PREFILL_DATA,
  GET_PO_PAYABLE_PREFILL_DATA_ERROR,
  GET_PO_PAYABLE_PREFILL_DATA_PENDING,
  GET_PO_PAYABLE_PREFILL_DATA_SUCCESS,
  GET_PAYABLE_PROFILE,
  GET_PAYABLE_PROFILE_PENDING,
  GET_PAYABLE_PROFILE_ERROR,
  GET_PAYABLE_PROFILE_SUCCESS,
  CREATE_PAYABLE_SUCCESS,
  CREATE_PAYABLE_ERROR,
  CREATE_PAYABLE_PENDING,
  SET_REFERENCE_ID_EXISTS,
  GET_PAYABLE_DATA_FOR_EDIT,
  GET_PAYABLE_DATA_FOR_EDIT_ERROR,
  GET_PAYABLE_DATA_FOR_EDIT_PENDING,
  GET_PAYABLE_DATA_FOR_EDIT_SUCCESS,
  PAYABLE_DELETE_PENDING,
  UPDATE_PAYABLE_ERROR,
  UPDATE_PAYABLE_PENDING,
  UPDATE_PAYABLE_SUCCESS,
  RESET_PAYABLE_DATA_FOR_EDIT,
  GET_ORG_PAYABLES_SUMMARY,
} from './Payable.type';

const initialState = {
  linkedLogs: null,
  linkedLogsPending: false,
  linkedLogsError: null,

  poPayableData: null,
  poPayableDataPending: false,
  poPayableDataError: null,

  grnPayableData: null,
  grnPayableDataPending: false,
  grnPayableDataError: null,

  orgPayablesList: null,
  orgPayablesListPending: false,
  orgPayablesListError: null,
  orgPayableSummary: null,

  orgPayablesListCount: null,

  poPayablePrefillData: null,
  poPayablePrefillDataPending: false,
  poPayablePrefillDataError: null,

  payableProfile: null,
  payableProfilePending: false,
  payableProfileError: null,

  createPayablePending: false,
  createPayableError: null,

  updatePayablePending: false,
  updatePayableError: null,

  referenceIdExists: false,

  payableDataForEdit: null,
  payableDataForEditPending: false,
  payableDataForEditError: null,

  payableDeletePending: false,
};

function PayableReducer(
  state = initialState,
  { type, payload, error }: ReducerProps
): any {
  switch (type) {
    case GET_PO_PAYABLE_DATA:
      return {
        ...state,
        poPayableData: payload,
      };
    case GET_PO_PAYABLE_DATA_PENDING:
      return {
        ...state,
        poPayableDataPending: payload,
      };

    case GET_PO_PAYABLE_DATA_ERROR:
      return {
        ...state,
        poPayableDataError: error,
        poPayableDataPending: false,
      };

    case GET_PO_PAYABLE_DATA_SUCCESS:
      return {
        ...state,
        poPayableDataError: null,
        poPayableDataPending: false,
      };

    case GET_GRN_PAYABLE_DATA:
      return {
        ...state,
        grnPayableData: payload,
      };
    case GET_GRN_PAYABLE_DATA_PENDING:
      return {
        ...state,
        grnPayableDataPending: payload,
      };
    case GET_GRN_PAYABLE_DATA_ERROR:
      return {
        ...state,
        grnPayableDataError: error,
        grnPayableDataPending: false,
      };
    case GET_GRN_PAYABLE_DATA_SUCCESS:
      return {
        ...state,
        grnPayableDataError: null,
        grnPayableDataPending: false,
      };

    case GET_LINKED_LOGS:
      return {
        ...state,
        linkedLogs: payload,
      };
    case GET_LINKED_LOGS_PENDING:
      return {
        ...state,
        linkedLogsPending: payload,
      };
    case GET_LINKED_LOGS_ERROR:
      return {
        ...state,
        linkedLogsError: error,
        linkedLogsPending: false,
      };
    case GET_LINKED_LOGS_SUCCESS:
      return {
        ...state,
        linkedLogsError: null,
        linkedLogsPending: false,
      };

    case GET_ORG_PAYABLES:
      return {
        ...state,
        orgPayablesList: payload,
      };
    case GET_ORG_PAYABLES_PENDING:
      return {
        ...state,
        orgPayablesListPending: payload,
      };
    case GET_ORG_PAYABLES_ERROR:
      return {
        ...state,
        orgPayablesListError: error,
        orgPayablesListPending: false,
      };
    case GET_ORG_PAYABLES_SUCCESS:
      return {
        ...state,
        orgPayablesListError: null,
        orgPayablesListPending: false,
      };

    case GET_ORG_PAYABLES_LIST_COUNT:
      return {
        ...state,
        orgPayablesListCount: payload,
      };

    case GET_PO_PAYABLE_PREFILL_DATA:
      return {
        ...state,
        poPayablePrefillData: payload,
      };
    case GET_PO_PAYABLE_PREFILL_DATA_PENDING:
      return {
        ...state,
        poPayablePrefillDataPending: payload,
      };
    case GET_PO_PAYABLE_PREFILL_DATA_ERROR:
      return {
        ...state,
        poPayablePrefillDataError: error,
        poPayablePrefillDataPending: false,
      };
    case GET_PO_PAYABLE_PREFILL_DATA_SUCCESS:
      return {
        ...state,
        poPayablePrefillDataError: null,
        poPayablePrefillDataPending: false,
      };

    case GET_PAYABLE_PROFILE:
      return {
        ...state,
        payableProfile: payload,
      };
    case GET_PAYABLE_PROFILE_PENDING:
      return {
        ...state,
        payableProfilePending: payload,
      };
    case GET_PAYABLE_PROFILE_ERROR:
      return {
        ...state,
        payableProfileaError: error,
        payableProfilePending: false,
      };
    case GET_PAYABLE_PROFILE_SUCCESS:
      return {
        ...state,
        payableProfileError: null,
        payableProfilePending: false,
      };

    case CREATE_PAYABLE_SUCCESS:
      return {
        ...state,
        createPayablePending: false,
        createPayableError: null,
      };

    case CREATE_PAYABLE_PENDING:
      return {
        ...state,
        createPayablePending: true,
      };

    case CREATE_PAYABLE_ERROR:
      return {
        ...state,
        createPayablePending: false,
        createPayableError: payload,
      };

    case SET_REFERENCE_ID_EXISTS: {
      return { ...state, referenceIdExists: payload };
    }

    case GET_PAYABLE_DATA_FOR_EDIT:
      return {
        ...state,
        payableDataForEdit: payload,
      };
    case RESET_PAYABLE_DATA_FOR_EDIT:
      return {
        ...state,
        payableDataForEdit: null,
      };
    case GET_PAYABLE_DATA_FOR_EDIT_PENDING:
      return {
        ...state,
        payableDataForEditPending: payload,
      };
    case GET_PAYABLE_DATA_FOR_EDIT_ERROR:
      return {
        ...state,
        payableDataForEditError: error,
        payableDataForEditPending: false,
      };
    case GET_PAYABLE_DATA_FOR_EDIT_SUCCESS:
      return {
        ...state,
        payableDataForEditError: null,
        payableDataForEditPending: false,
      };
    case PAYABLE_DELETE_PENDING: {
      return { ...state, payableDeletePending: payload };
    }

    case UPDATE_PAYABLE_SUCCESS:
      return {
        ...state,
        updatePayablePending: false,
        updatePayableError: null,
      };

    case UPDATE_PAYABLE_PENDING:
      return {
        ...state,
        updatePayablePending: true,
      };

    case UPDATE_PAYABLE_ERROR:
      return {
        ...state,
        updatePayablePending: false,
        updatePayableError: payload,
      };
    case GET_ORG_PAYABLES_SUMMARY:
      return {
        ...state,
        orgPayableSummary: payload,
      };

    default:
      return state;
  }
}

export default PayableReducer;
