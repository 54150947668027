import apiClient from '../../../utils/ApiClient.util';
import { WHATS_NEW_API_ERROR, WHATS_NEW_SUCCESS } from './WhatsNew.type';
import { MISC_APIS } from '../../../routes.constant';
import { eventDispatch } from '../../base';

const apiError = (error?: string | any) => {
  return {
    type: WHATS_NEW_API_ERROR,
    error,
  };
};

export const getWhatsNew = () => {
  const apiUrl = MISC_APIS.whatsNew;
  return dispatch => {
    return apiClient
      .get(apiUrl)
      .then(({ data }) => {
        dispatch(eventDispatch(WHATS_NEW_SUCCESS, data));
      })
      .catch(error => {
        dispatch(apiError(error));
      });
  };
};
