export const GET_MI_ITEM = 'GET_MI_ITEM';
export const DELETE_MI_ITEM = 'DELETE_MI_ITEM';
export const ACCEPT_MI_ITEM = 'ACCEPT_MI_ITEM';
export const UPDATE_MI_ITEM = 'UPDATE_MI_ITEM';
export const REJECT_MI_ITEM = 'REJECT_MI_ITEM';
export const MI_API_SUCCESS = 'MI_API_SUCCES';
export const MI_API_ERROR = 'MI_API_ERROR';
export const MI_API_PENDING = 'MI_API_PENDING';

export const MI_FORM_SUBMIT_SUCCESS = 'MI_FORM_SUBMIT_SUCCESS';
export const MI_FORM_SUBMIT_PENDING = 'MI_FORM_SUBMIT_PENDING';
export const MI_FORM_SUBMIT_ERROR = 'MI_FORM_SUBMIT_ERROR';
