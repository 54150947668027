import {
  DELETE_ST_ITEM,
  GET_ST_ITEM,
  SET_SITE_TRANSFER_LIST,
  ST_API_ERROR,
  ST_API_PENDING,
  ST_FORM_SUBMIT_ERROR,
  ST_FORM_SUBMIT_PENDING,
  ST_FORM_SUBMIT_SUCCESS,
  UPDATE_ST_ITEM,
  GET_INDENT_BOM_LIST_FOR_ST,
  GET_INDENT_BOM_LIST_FOR_ST_ERROR,
  GET_INDENT_BOM_LIST_FOR_ST_PENDING,
  GET_SITE_TRANSFER_COUNT,
  RESET_INDENT_BOM_LIST_FOR_ST,
} from './SiteTransfer.type';

const pendingInitialStae = {
  pending: false,
};

const initialState = {
  ...pendingInitialStae,
  stItemData: {},
  updateData: {},
  STItems: [],
  STTotalCount: {},
  error: null,
  indentBomListForST: [],
  indentBomListForSTPending: false,
  indentBomListForSTError: null,
};

export default function stReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case GET_ST_ITEM:
      return { ...state, stItemData: payload };
    case UPDATE_ST_ITEM:
      return { ...state, updateData: payload };
    case DELETE_ST_ITEM:
      return { ...state, updateData: payload };
    case ST_API_PENDING:
      return { ...state, pending: payload };
    case GET_INDENT_BOM_LIST_FOR_ST:
      return { ...state, indentBomListForST: payload };
    case GET_INDENT_BOM_LIST_FOR_ST_PENDING:
      return { ...state, indentBomListForSTPending: payload };
    case GET_INDENT_BOM_LIST_FOR_ST_ERROR:
      return { ...state, indentBomListForSTError: error };
    case RESET_INDENT_BOM_LIST_FOR_ST:
      return { ...state, indentBomListForST: [] };
    case SET_SITE_TRANSFER_LIST:
      return { ...state, STItems: payload };
    case GET_SITE_TRANSFER_COUNT:
      return { ...state, STTotalCount: payload };
    case ST_API_ERROR:
      return { ...state, error: error };
    case ST_FORM_SUBMIT_PENDING:
      return { ...state, pending: true };
    case ST_FORM_SUBMIT_SUCCESS:
    case ST_FORM_SUBMIT_ERROR:
      return {
        ...state,
        pending: false,
      };
    default:
      return state;
  }
}
