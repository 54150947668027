export const SET_SELECTED_PROJECTS = 'SET_SELECTED_PROJECTS';
export const SET_PROJECT_SUMMARY_LAST_UPDATED_PROGRESS_DATE =
  'SET_PROJECT_SUMMARY_LAST_UPDATED_PROGRESS_DATE';

export interface SelectProject {
  _id: string;
  name: string;
}

export type MyBoardActionType =
  | {
      type: 'SET_SELECTED_PROJECTS';
      payload: SelectProject[];
    }
  | {
      type: 'SET_PROJECT_SUMMARY_LAST_UPDATED_PROGRESS_DATE';
      payload: number;
    };

export interface MyBoardInitialStateInterface {
  selectedProjects: SelectProject[];
  projectSummaryWidget: {
    lastUpdatedProgressDate?: number;
  };
}
