import {
  SET_RECEIVABLE_SUMMARY,
  SET_RECEIVABLES,
  SET_RECEIVABLES_LOADING,
  SET_RECEIVABLES_SUMMARY_LOADING,
  SET_RECORD_PAYMENT_MODE,
  SET_RECORD_PAYMENT_PENDING,
  SET_SELECTED_RECEIVABLE,
  SET_SHOW_RECORD_PAYMENT,
  SYNC_RECEIVABLE_VIEW,
} from './Receivable.type';

const initialState = {
  showRecordPayment: false,
  isReceivableLoading: false,
  isReceivableSummaryLoading: false,
  isRecordPaymentPending: false,
  receivableSummary: {
    total_received: 0,
    receivable_count: null,
  },
  receivables: {
    docs: [],
    page: 0,
    pages: 0,
    total: 0,
    limit: 20,
  },
  recordPaymentMode: 'create',
  selectedReceivable: null,
  syncReceivableView: false,
};

export default function receivableReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_RECEIVABLES:
      return {
        ...state,
        receivables: {
          ...payload,
          docs: payload.docs.map((receivable, idx) => ({
            ...receivable,
            index_number: payload.limit * payload.page + idx + 1,
          })),
        },
      };

    case SET_RECEIVABLES_LOADING:
      return {
        ...state,
        isReceivableLoading: payload,
      };

    case SET_RECEIVABLES_SUMMARY_LOADING:
      return {
        ...state,
        isReceivableSummaryLoading: payload,
      };

    case SET_RECEIVABLE_SUMMARY:
      return {
        ...state,
        receivableSummary: {
          ...state.receivableSummary,
          ...payload,
        },
      };

    case SET_SELECTED_RECEIVABLE:
      return {
        ...state,
        selectedReceivable: payload,
      };

    case SET_RECORD_PAYMENT_MODE:
      return {
        ...state,
        recordPaymentMode: payload,
      };

    case SET_SHOW_RECORD_PAYMENT:
      return {
        ...state,
        showRecordPayment: payload,
      };

    case SYNC_RECEIVABLE_VIEW:
      return {
        ...state,
        syncReceivableView: payload,
      };

    case SET_RECORD_PAYMENT_PENDING:
      return {
        ...state,
        isRecordPaymentPending: payload,
      };

    default:
      return state;
  }
}
