import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { logout } from '../../../../../redux/features/common/user';
import EditProfile from './EditProfile';
import CustomButton from '../../../../common/customButton/CustomButton';
import { eventDispatch } from '../../../../../redux/base';
import { RESET_PROJECTS } from '../../../../../redux/features/common/project/Project.types';
import ProfileImage from '../../../../common/ProfileImage';
import { AUTH_EVENTS } from '../../../../../constant';
import { track } from '../../../../../redux/features/common/Segment.action';
import { POWERPLAY_WEB_VERSION } from '../../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../../redux/Hooks';
import { IndexedDBService } from '../../../../../providers/indexedDB';

interface Props {
  handlePopoverClose: () => void;
}

function UserProfile({ handlePopoverClose }: Props) {
  const dispatch = useAppDispatch();

  const [editProfile, setEditProfile] = useState<boolean>(false);

  const displayName = useAppSelector(state => state.userreducer.displayName);
  const photoUrl = useAppSelector(state => state.userreducer.photo_url);
  const email = useAppSelector(state => state.userreducer.email);
  const role = useAppSelector(state => state.userreducer.team_name);

  const handleEditProfile = () => {
    setEditProfile(!editProfile);
  };
  const handleBack = () => {
    setEditProfile(false);
  };

  const clickLogout = () => {
    dispatch(track(AUTH_EVENTS.LOGOUT_BUTTON_CLICK));
    dispatch(logout());
    dispatch(eventDispatch(RESET_PROJECTS));
    IndexedDBService.deleteDbs();
  };

  return (
    <Box className="user-profile" p={2}>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          {editProfile ? (
            <IconButton onClick={handleBack} size="large">
              <ArrowBackIcon />
            </IconButton>
          ) : null}
        </div>
        <div>
          <IconButton onClick={handlePopoverClose} size="large">
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      {!editProfile ? (
        <div className="d-flex flex-column align-items-center">
          <ProfileImage
            user={{ user_name: displayName, photo_url: photoUrl }}
            size="profile"
            className={'fs-xl'}
          />
          <div className="text-light-black fw-bold fs-xl mt-2">
            {displayName}
          </div>
          <div className="text-grey mt-1 ">{role}</div>
          <div className="font-italic mt-1">{email}</div>
          <div className="text-center mt-3 d-flex flex-column user-profile-dropdown-links">
            <CustomButton
              className="user-profile-dropdown-button"
              variant="text"
              buttonSize="small"
              handleSubmit={handleEditProfile}
              label="Edit Profile"
            />
          </div>
          <hr className="user-profile-dropdown-hr" />
          <CustomButton
            className="user-profile-dropdown-button-red"
            handleSubmit={clickLogout}
            variant="text"
            buttonSize="small"
            label="Log Out"
          />
          <div className="mt-3">Version: {POWERPLAY_WEB_VERSION}</div>
        </div>
      ) : (
        <EditProfile handleClose={handlePopoverClose} />
      )}
    </Box>
  );
}

export default UserProfile;
