import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { diffInDays } from '@/utils/Date.util';
import { useAppDispatch, useAppSelector } from '@/redux/Hooks';
import { sendEmailVerification } from '@/redux/features/common/user';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
const PLAN_EXPIRY_THRESHOLD = 14;
const WARNING_THRESHOLD = 7;

const TopNavWaningBar = () => {
  const dispatch = useAppDispatch();
  const emailVerified = useAppSelector(
    state => state.userreducer.is_email_verified
  );
  const email = useAppSelector(state => state.userreducer.email);
  const orgProfile = useAppSelector(
    state => state.organisationReducer.orgProfile
  );

  const userId = useAppSelector(state => state.userreducer._id);

  const sendVerificationEmail = () => {
    dispatch(sendEmailVerification(userId, { email }));
  };
  const [expiryDays, setExpiryDays] = useState<number>(-1);
  const [showExpiryBar, setShowExpiryBar] = useState<boolean>(false);

  useEffect(() => {
    try {
      const currentTimestamp = Date.now();
      const expiryTimestamp =
        orgProfile?.subscription?.subscription_expiry_dateMS;

      if (expiryTimestamp) {
        const days = diffInDays(
          new Date(currentTimestamp),
          new Date(expiryTimestamp)
        );

        if (expiryTimestamp > currentTimestamp) {
          setExpiryDays(days);
          setShowExpiryBar(days <= PLAN_EXPIRY_THRESHOLD);
        } else {
          setShowExpiryBar(true);
          setExpiryDays(-1 * days);
        }
      }
    } catch {
      setShowExpiryBar(false);
    }
  }, [orgProfile]);

  const handleClose = () => {
    setShowExpiryBar(false);
  };

  const barClass =
    expiryDays > WARNING_THRESHOLD ? 'bg-warning' : 'bg-danger text-light';
  let message;

  if (expiryDays > 0) {
    message = (
      <>
        Your plan will expire in <b>{expiryDays} days</b>.
      </>
    );
  } else if (expiryDays === 0) {
    message = (
      <>
        Your plan is expiring <b>today</b>.
      </>
    );
  } else {
    message = 'Your plan has expired.';
  }

  return (
    <div className={`sticky-top top-nav-warning`}>
      {showExpiryBar ? (
        <div className={`org-plan-expiry-bar ${barClass}`}>
          <div className="col">
            {message} Contact admin to continue using all features
            {expiryDays > 0 && (
              <span className="float-end hand-pointer" onClick={handleClose}>
                <CloseIcon className={barClass} />
              </span>
            )}
          </div>
        </div>
      ) : (
        !emailVerified && (
          <span className={`verify-email-bar`}>
            <WarningIcon className={`ico`} />
            {'Verify your Email ID using the link sent to your account.'}
            <span onClick={sendVerificationEmail}>
              <span className="email-verificaton-btn">{'Resend Email'}</span>
              <LaunchIcon className={'ico-resend'} />
            </span>
          </span>
        )
      )}
    </div>
  );
};

export default TopNavWaningBar;
