export const GET_PO_PAYABLE_DATA = 'GET_PO_PAYABLE_DATA';
export const GET_PO_PAYABLE_DATA_PENDING = 'GET_PO_PAYABLE_DATA_PENDING';
export const GET_PO_PAYABLE_DATA_ERROR = 'GET_PO_PAYABLE_DATA_ERROR';
export const GET_PO_PAYABLE_DATA_SUCCESS = 'GET_PO_PAYABLE_DATA_SUCCESS';

export const GET_GRN_PAYABLE_DATA = 'GET_GRN_PAYABLE_DATA';
export const GET_GRN_PAYABLE_DATA_PENDING = 'GET_GRN_PAYABLE_DATA_PENDING';
export const GET_GRN_PAYABLE_DATA_ERROR = 'GET_GRN_PAYABLE_DATA_ERROR';
export const GET_GRN_PAYABLE_DATA_SUCCESS = 'GET_GRN_PAYABLE_DATA_SUCCESS';

export const GET_LINKED_LOGS = 'GET_LINKED_LOGS';
export const GET_LINKED_LOGS_PENDING = 'GET_LINKED_LOGS_PENDING';
export const GET_LINKED_LOGS_ERROR = 'GET_LINKED_LOGS_ERROR';
export const GET_LINKED_LOGS_SUCCESS = 'GET_LINKED_LOGS_SUCCESS';

export const GET_ORG_PAYABLES = 'GET_ORG_PAYABLES';
export const GET_ORG_PAYABLES_PENDING = 'GET_ORG_PAYABLES_PENDING';
export const GET_ORG_PAYABLES_ERROR = 'GET_ORG_PAYABLES_ERROR';
export const GET_ORG_PAYABLES_SUCCESS = 'GET_ORG_PAYABLES_SUCCESS';
export const GET_ORG_PAYABLES_SUMMARY = 'GET_ORG_PAYABLES_SUMMARY';

export const GET_ORG_PAYABLES_LIST_COUNT = 'GET_ORG_PAYABLES_LIST_COUNT';

export const GET_PO_PAYABLE_PREFILL_DATA = 'GET_PO_PAYABLE_PREFILL_DATA';
export const GET_PO_PAYABLE_PREFILL_DATA_PENDING =
  'GET_PO_PAYABLE_PREFILL_DATA_PENDING';
export const GET_PO_PAYABLE_PREFILL_DATA_ERROR =
  'GET_PO_PAYABLE_PREFILL_DATA_ERROR';
export const GET_PO_PAYABLE_PREFILL_DATA_SUCCESS =
  'GET_PO_PAYABLE_PREFILL_DATA_SUCCESS';

export const GET_PAYABLE_PROFILE = 'GET_PAYABLE_PROFILE';
export const GET_PAYABLE_PROFILE_PENDING = 'GET_PAYABLE_PROFILE_PENDING';
export const GET_PAYABLE_PROFILE_ERROR = 'GET_PAYABLE_PROFILE_ERROR';
export const GET_PAYABLE_PROFILE_SUCCESS = 'GET_PAYABLE_PROFILE_SUCCESS';

export const CREATE_PAYABLE_SUCCESS = 'CREATE_PAYABLE_SUCCESS';
export const CREATE_PAYABLE_ERROR = 'CREATE_PAYABLE_ERROR';
export const CREATE_PAYABLE_PENDING = 'CREATE_PAYABLE_PENDING';

export const SET_REFERENCE_ID_EXISTS = 'SET_REFERENCE_ID_EXISTS';

export const GET_PAYABLE_DATA_FOR_EDIT = 'GET_PAYABLE_DATA_FOR_EDIT';
export const GET_PAYABLE_DATA_FOR_EDIT_PENDING =
  'GET_PAYABLE_DATA_FOR_EDIT_PENDING';
export const GET_PAYABLE_DATA_FOR_EDIT_ERROR =
  'GET_PAYABLE_DATA_FOR_EDIT_ERROR';
export const GET_PAYABLE_DATA_FOR_EDIT_SUCCESS =
  'GET_PAYABLE_DATA_FOR_EDIT_SUCCESS';
export const PAYABLE_DELETE_PENDING = 'PAYABLE_DELETE_PENDING';
export const RESET_PAYABLE_DATA_FOR_EDIT = 'RESET_PAYABLE_DATA_FOR_EDIT';

export const UPDATE_PAYABLE_SUCCESS = 'UPDATE_PAYABLE_SUCCESS';
export const UPDATE_PAYABLE_ERROR = 'UPDATE_PAYABLE_ERROR';
export const UPDATE_PAYABLE_PENDING = 'UPDATE_PAYABLE_PENDING';
