import DriveIcon from '@mui/icons-material/CloudRounded';
import TableChartIcon from '@mui/icons-material/TableChart';
import DescriptionIcon from '@mui/icons-material/Description';
import VerifiedIcon from '@mui/icons-material/Verified';

import {
  Dashboard,
  ProjectPlan,
  Commercial,
  Vendor,
  AttendanceIcon,
  ThreadsIcons,
  Activity,
  Members,
  OrganizationProfileOverview,
  ManageTasksIcon,
  IndentsIcon,
  PurchaseOrderIcons,
  GanttViewIcon,
  SettingsIcon,
  PermissionPageIcon,
  InventoryIcon,
  MaterialVendorIcon,
  SiteTransferIcon,
  CurrentTasksIcon,
  MaterialIssueIcon,
  PettyCashIcon,
  GrnIcon,
  AllMembersIcon,
  MembersByProjectIcon,
  Attendance_Icon,
  LabourIcon,
  WorkDoneIcon,
  VendorIcon,
  ConsumptionIcon,
  AnalyticsDashboard,
  AsyncReport,
  PlanViewIcon,
  ListViewIcon,
  WorkOrderIcon,
} from '../components/common/Icons';
import {
  ATTENDANCE_KEYS,
  BUDGET_KEYS,
  COMMERCIAL_KEYS,
  MEMBERS_KEYS,
  PAYABLES_KEYS,
  TASKS_KEYS,
  TASKS_PLAN_TAB_KEYS,
} from '../constant/tab_menu_key';
import { RESOURCE_ID_MAP, TEAM_MODULES } from '../constant/module_constants';
import { EXPERIMENTS } from '../constant/experiment_constants';
import { UserPermissionsMapProps } from '../interfaces/Team';
import { ModuleProps } from '../interfaces/Layout';
import { PROJECT_PLAN_TYPES } from '../constant/project_constant';
import { useAppSelector } from '../redux/Hooks';
import { Description } from '@mui/icons-material';
import { PieChart, ReceiptText } from 'lucide-react';

const PROJECT_MANAGEMENT_MODULES: ModuleProps[] = [
  {
    id: TEAM_MODULES.PROJECT_DASHBOARD.resource_id,
    title: TEAM_MODULES.PROJECT_DASHBOARD.resource_name,
    to: TEAM_MODULES.PROJECT_DASHBOARD.resource_path,
    htmlBefore: <Dashboard />,
  },
  {
    id: [
      TEAM_MODULES.BUDGET_AND_BILLING.resource_id,
      TEAM_MODULES.RECEIVABLES.resource_id,
    ],
    title: TEAM_MODULES.BUDGET_AND_BILLING.resource_name,
    htmlBefore: <Description />,
    to: TEAM_MODULES.BUDGET_AND_BILLING.resource_path,
    labels: [
      {
        id: RESOURCE_ID_MAP.BUDGET_AND_BILLING,
        label: 'Bill of Quantities',
        ico: <PlanViewIcon />,
        key: BUDGET_KEYS.BOQ,
      },
      {
        id: RESOURCE_ID_MAP.RECEIVABLES,
        label: 'Receivables',
        ico: <ListViewIcon />,
        key: BUDGET_KEYS.RECEIVABLES,
      },
    ],
    keys: [...Object.values(BUDGET_KEYS)],
    newTabs: true,
  },
  {
    id: [TEAM_MODULES.TASKS.resource_id, TEAM_MODULES.GANTT_VIEW.resource_id],
    title: TEAM_MODULES.TASK.resource_name,
    htmlBefore: <ProjectPlan />,
    labels: [
      {
        id: RESOURCE_ID_MAP.TASK_VIEW,
        label: 'Plan View',
        ico: <PlanViewIcon />,
        key: TASKS_KEYS.PLAN_VIEW,
      },
      {
        id: RESOURCE_ID_MAP.TASK_VIEW,
        label: 'List View',
        ico: <ListViewIcon />,
        key: TASKS_KEYS.LIST_VIEW,
      },
      {
        id: RESOURCE_ID_MAP.GANTT_VIEW,
        label: 'Gantt View',
        ico: <GanttViewIcon />,
        key: TASKS_KEYS.GANTT,
      },
    ],
    keys: [...Object.values(TASKS_KEYS)],
    to: TEAM_MODULES.TASKS.resource_path,
    newTabs: true,
  },
  {
    id: TEAM_MODULES.THREADS.resource_id,
    title: TEAM_MODULES.THREADS.resource_name,
    htmlBefore: <ThreadsIcons />,
    to: TEAM_MODULES.THREADS.resource_path,
  },
  {
    id: [
      TEAM_MODULES.PURCHASE_ORDERS.resource_id,
      TEAM_MODULES.INDENT.resource_id,
    ],
    title: TEAM_MODULES.COMMERCIAL.resource_name,
    htmlBefore: <Commercial />,
    labels: [
      {
        id: RESOURCE_ID_MAP.INDENT,
        label: 'Indents',
        ico: <IndentsIcon />,
        key: COMMERCIAL_KEYS.INDENT,
      },
      {
        id: RESOURCE_ID_MAP.PURCHASE_ORDER,
        label: 'Purchase Orders',
        ico: <PurchaseOrderIcons />,
        key: COMMERCIAL_KEYS.PURCHASE_ORDER,
      },
    ],
    keys: [...Object.values(COMMERCIAL_KEYS)],
    to: TEAM_MODULES.COMMERCIAL.resource_path,
    newTabs: true,
  },
  {
    id: TEAM_MODULES.PROJECT_DRIVE.resource_id,
    title: TEAM_MODULES.PROJECT_DRIVE.resource_name,
    htmlBefore: <DriveIcon />,
    to: TEAM_MODULES.PROJECT_DRIVE.resource_path,
  },
  {
    id: TEAM_MODULES.ACTIVITIES.resource_id,
    title: TEAM_MODULES.ACTIVITIES.resource_name,
    htmlBefore: <Activity />,
    to: TEAM_MODULES.ACTIVITIES.resource_path,
  },
  {
    id: TEAM_MODULES.PROJECT_PROFILE.resource_id,
    title: TEAM_MODULES.PROJECT_PROFILE.resource_name,
    htmlBefore: <SettingsIcon />,
    to: TEAM_MODULES.PROJECT_PROFILE.resource_path,
  },
  {
    id: undefined,
    title: TEAM_MODULES.ASYNC_REPORT.resource_name,
    htmlBefore: <AsyncReport />,
    to: TEAM_MODULES.ASYNC_REPORT.resource_path,
  },
];

const ORG_MODULES: ModuleProps[] = [
  {
    id: TEAM_MODULES.ORGANISATION_PROFILE.resource_id,
    title: TEAM_MODULES.ORGANISATION_PROFILE.resource_name,
    htmlBefore: <OrganizationProfileOverview />,
    to: TEAM_MODULES.ORGANISATION_PROFILE.resource_path,
  },
  {
    id: TEAM_MODULES.ORGANISATION_MEMBERS.resource_id,
    title: TEAM_MODULES.ORGANISATION_MEMBERS.resource_name,
    htmlBefore: <Members />,
    to: TEAM_MODULES.ORGANISATION_MEMBERS.resource_path,
    labels: [
      {
        id: RESOURCE_ID_MAP.MEMBERS,
        label: 'All Members',
        ico: <AllMembersIcon />,
        key: MEMBERS_KEYS.ALL_MEMBERS,
      },
      {
        id: RESOURCE_ID_MAP.MEMBERS,
        label: 'Members By Project',
        ico: <MembersByProjectIcon />,
        key: MEMBERS_KEYS.MEMBERS_BY_PROJECT,
      },
    ],
    keys: [...Object.values(MEMBERS_KEYS)],
    newTabs: true,
  },
  {
    id: TEAM_MODULES.ORGANISATION_ROLES_AND_PERMISSIONS.resource_id,
    title: TEAM_MODULES.ORGANISATION_ROLES_AND_PERMISSIONS.resource_name,
    htmlBefore: <PermissionPageIcon />,
    to: TEAM_MODULES.ORGANISATION_ROLES_AND_PERMISSIONS.resource_path,
  },
  {
    id: TEAM_MODULES.ORGANISATION_SETTINGS.resource_id,
    title: TEAM_MODULES.ORGANISATION_SETTINGS.resource_name,
    htmlBefore: <SettingsIcon />,
    to: TEAM_MODULES.ORGANISATION_SETTINGS.resource_path,
  },
  {
    id: TEAM_MODULES.VENDOR.resource_id,
    title: TEAM_MODULES.VENDOR.resource_name,
    htmlBefore: <Vendor />,
    to: TEAM_MODULES.VENDOR.resource_path,
  },
  {
    id: [
      RESOURCE_ID_MAP.PURCHASE_ORDER,
      RESOURCE_ID_MAP.INDENT,
      RESOURCE_ID_MAP.GRN,
      RESOURCE_ID_MAP.INVENTORY,
      RESOURCE_ID_MAP.SITE_TRANSFER,
      RESOURCE_ID_MAP.MATERIAL_ISSUE,
      RESOURCE_ID_MAP.EXPENSES,
    ],
    title: TEAM_MODULES.COMMERCIAL.resource_name,
    htmlBefore: <Commercial />,
    labels: [
      {
        id: RESOURCE_ID_MAP.INDENT,
        label: 'Indents',
        ico: <IndentsIcon />,
        key: COMMERCIAL_KEYS.INDENT,
      },
      {
        id: RESOURCE_ID_MAP.PURCHASE_ORDER,
        label: 'Purchase Orders',
        ico: <PurchaseOrderIcons />,
        key: COMMERCIAL_KEYS.PURCHASE_ORDER,
      },
      {
        id: RESOURCE_ID_MAP.INVENTORY,
        label: 'Inventory',
        ico: <InventoryIcon />,
        key: COMMERCIAL_KEYS.INVENTORY,
      },
      {
        id: RESOURCE_ID_MAP.GRN,
        label: 'GRN',
        ico: <GrnIcon />,
        key: COMMERCIAL_KEYS.GRN,
      },
      {
        id: RESOURCE_ID_MAP.SITE_TRANSFER,
        label: 'Site Transfers',
        ico: <SiteTransferIcon />,
        key: COMMERCIAL_KEYS.SITE_TRANSFERS,
      },
      {
        id: RESOURCE_ID_MAP.MATERIAL_ISSUE,
        label: 'Material Issue',
        ico: <MaterialIssueIcon />,
        key: COMMERCIAL_KEYS.MATERIAL_ISSUE,
      },
      {
        id: undefined,
        label: 'Petty Cash',
        ico: <PettyCashIcon />,
        key: COMMERCIAL_KEYS.EXPENSES,
      },
      {
        id: RESOURCE_ID_MAP.STOCK_UPDATES,
        label: 'Consumptions',
        ico: <ConsumptionIcon />,
        key: COMMERCIAL_KEYS.CONSUMPTIONS,
      },
    ],
    keys: [...Object.values(COMMERCIAL_KEYS)],
    experimentalLabels: [
      {
        expName: EXPERIMENTS.MATERIAL_VENDOR_PAYABLE,
        label: {
          label: 'Material Vendor Payable',
          ico: <MaterialVendorIcon />,
          key: COMMERCIAL_KEYS.MATERIAL_VENDOR_PAYABLE,
        },
      },
    ],
    to: TEAM_MODULES.COMMERCIAL.resource_path,
    newTabs: true,
  },
  {
    id: undefined,
    title: TEAM_MODULES.ORG_ASYNC_REPORT.resource_name,
    htmlBefore: <AsyncReport />,
    to: TEAM_MODULES.ORG_ASYNC_REPORT.resource_path,
  },
];

const filterModules = (
  userPermissions: UserPermissionsMapProps,
  moduleList: ModuleProps[],
  modules: ModuleProps[]
) => {
  for (const module of moduleList) {
    if (!module.id) {
      modules.push(module);
      continue;
    }

    const moduleIds = Array.isArray(module.id) ? module.id : [module.id];
    const hasModuleAccess = moduleIds.some(
      moduleId => userPermissions.get(moduleId)?.resource_access
    );

    if (hasModuleAccess) {
      modules.push(module);
    }
  }
};

export default function (
  userPermissions: UserPermissionsMapProps,
  experiments: Map<string, boolean>
) {
  const projectProfile = useAppSelector(
    state => state.projectreducer.projectProfile
  );

  let modules: ModuleProps[] = [];

  if (userPermissions.size) {
    filterModules(userPermissions, PROJECT_MANAGEMENT_MODULES, modules);
  } else {
    modules.push(...PROJECT_MANAGEMENT_MODULES);
  }

  if (experiments?.get(EXPERIMENTS.EXP_ATTENDANCE_MODULE_V3)) {
    modules.push({
      id: [TEAM_MODULES.NEW_ATTENDANCE.resource_id],
      title: TEAM_MODULES.NEW_ATTENDANCE.resource_name,
      htmlBefore: <Attendance_Icon />,
      labels: [
        {
          id: undefined,
          label: 'Labour',
          ico: <LabourIcon />,
          key: ATTENDANCE_KEYS.LABOUR,
        },
        {
          id: undefined,
          label: 'Vendor',
          ico: <VendorIcon />,
          key: ATTENDANCE_KEYS.VENDOR,
        },
        {
          id: undefined,
          label: 'Work Done',
          ico: <WorkDoneIcon />,
          key: ATTENDANCE_KEYS.WORKDONE,
        },
      ],
      keys: [...Object.values(ATTENDANCE_KEYS)],
      to: TEAM_MODULES.NEW_ATTENDANCE.resource_path,
      newTabs: true,
    });
  }

  if (projectProfile?.project_plan_type === PROJECT_PLAN_TYPES.BROAD_PLAN) {
    const BroadPlanModel = {
      id: [TEAM_MODULES.BROAD_PLAN.resource_id],
      title: TEAM_MODULES.TASK.resource_name,
      htmlBefore: <ProjectPlan />,
      labels: [
        {
          id: RESOURCE_ID_MAP.TASK_VIEW,
          label: 'Master Plan',
          ico: <CurrentTasksIcon />,
          key: TASKS_PLAN_TAB_KEYS.MASTER_PLAN,
        },
        {
          id: RESOURCE_ID_MAP.TASK_VIEW,
          label: 'Weekly Plan',
          ico: <ManageTasksIcon />,
          key: TASKS_PLAN_TAB_KEYS.WEEKLY_PLAN,
        },
      ],
      keys: [...Object.values(TASKS_PLAN_TAB_KEYS)],
      to: TEAM_MODULES.BROAD_PLAN.resource_path,
      newTabs: true,
    };

    modules = [...modules.slice(0, 1), BroadPlanModel, ...modules.slice(2)];
  }

  return modules;
}

export function getOrgNavItems(
  userPermissions: UserPermissionsMapProps,
  experiments: Map<string, boolean>,
  orgFetchedAndIsPaid: boolean
) {
  const modules: ModuleProps[] = [];

  if (userPermissions) {
    filterModules(userPermissions, ORG_MODULES, modules);
  } else {
    modules.push(...ORG_MODULES);
  }

  if (experiments?.get(EXPERIMENTS.EXP_WO_SC_PAYABLE)) {
    const taskIndex = modules.findIndex(
      module => module.title === TEAM_MODULES.TASK.resource_name
    );

    if (taskIndex !== -1) {
      modules.splice(taskIndex, 0, {
        id: TEAM_MODULES.WORK_ORDER.resource_id,
        title: TEAM_MODULES.WORK_ORDER.resource_name,
        htmlBefore: (
          <div className="position-relative">
            <DescriptionIcon />
            <VerifiedIcon
              className="position-absolute"
              style={{ width: '12px', height: '12px' }}
            />
          </div>
        ),
        to: TEAM_MODULES.WORK_ORDER.resource_path,
      });
    } else {
      modules.push({
        id: TEAM_MODULES.WORK_ORDER.resource_id,
        title: TEAM_MODULES.WORK_ORDER.resource_name,
        htmlBefore: <WorkOrderIcon />,
        to: TEAM_MODULES.WORK_ORDER.resource_path,
      });
    }
  }

  if (orgFetchedAndIsPaid) {
    modules.push({
      id: undefined,
      title: TEAM_MODULES.PAYABLE_AND_PAYMENTS.resource_name,
      htmlBefore: <PettyCashIcon />,
      labels: [
        {
          id: undefined,
          label: 'Payables',
          ico: (
            <ReceiptText className="inline-block w-6 h-6" strokeWidth={1.5} />
          ),
          key: PAYABLES_KEYS.PAYABLE,
        },
        {
          id: undefined,
          label: 'Payments',
          ico: <PettyCashIcon />,
          key: PAYABLES_KEYS.PAYMENT,
        },
        {
          id: undefined,
          label: 'Expenses',
          ico: <PieChart className="inline-block w-6 h-6" strokeWidth={1.5} />,
          key: PAYABLES_KEYS.EXPENSE,
        },
      ],
      to: TEAM_MODULES.PAYABLE_AND_PAYMENTS.resource_path,
      keys: [...Object.values(PAYABLES_KEYS)],
      newTabs: true,
    });
  }

  if (experiments?.get(EXPERIMENTS.ORG_DASHBOARD)) {
    modules.push({
      id: TEAM_MODULES.ORG_DASHBOARD.resource_name,
      title: TEAM_MODULES.ORG_DASHBOARD.resource_name,
      htmlBefore: <TableChartIcon />,
      to: TEAM_MODULES.ORG_DASHBOARD.resource_path,
    });
  }

  if (experiments?.get(EXPERIMENTS.EXP_ATTENDANCE_MODULE_V3)) {
    modules.push({
      id: [TEAM_MODULES.ORG_NEW_ATTENDANCE.resource_id],
      title: TEAM_MODULES.ORG_NEW_ATTENDANCE.resource_name,
      htmlBefore: <Attendance_Icon />,
      labels: [
        {
          id: undefined,
          label: 'Labour',
          ico: <LabourIcon />,
          key: ATTENDANCE_KEYS.LABOUR,
        },
        {
          id: undefined,
          label: 'Vendor',
          ico: <VendorIcon />,
          key: ATTENDANCE_KEYS.VENDOR,
        },
        {
          id: undefined,
          label: 'Work Done',
          ico: <WorkDoneIcon />,
          key: ATTENDANCE_KEYS.WORKDONE,
        },
      ],
      keys: [...Object.values(ATTENDANCE_KEYS)],
      to: TEAM_MODULES.ORG_NEW_ATTENDANCE.resource_path,
      newTabs: true,
    });
  }

  if (experiments?.get(EXPERIMENTS.ATTENDANCE_MODULE)) {
    modules.push({
      id: TEAM_MODULES.VENDOR_ATTENDANCE.resource_id,
      title: TEAM_MODULES.VENDOR_ATTENDANCE.resource_name,
      htmlBefore: <AttendanceIcon />,
      to: TEAM_MODULES.VENDOR_ATTENDANCE.resource_path,
    });
  }

  if (experiments?.get(EXPERIMENTS.EXP_ZOHO_DASHBOARD_ORG))
    modules.push({
      id: TEAM_MODULES.ANALYTICS_DASHBOARD.resource_id,
      title: TEAM_MODULES.ANALYTICS_DASHBOARD.resource_name,
      htmlBefore: <AnalyticsDashboard />,
      to: TEAM_MODULES.ANALYTICS_DASHBOARD.resource_path,
    });

  /** Will be used after removing zoho
  modules.push({
    id: TEAM_MODULES.SUPERSET_DASHBOARD.resource_id,
    title: TEAM_MODULES.SUPERSET_DASHBOARD.resource_name,
    htmlBefore: <AnalyticsDashboard />,
    to: TEAM_MODULES.SUPERSET_DASHBOARD.resource_path,
  });
   */

  return modules;
}
