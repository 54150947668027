import { COMMERCIAL_KEYS, FINANCE_KEYS } from './tab_menu_key';

export const MODULE_ID_MAP = Object.freeze({
  WORK_CATEGORIES: 'ASC102',
  TASKS: 'ASC101',
  ISSUES: 'ASC113',
  BOM: 'ASC111',
  BUDGET: 'ASC115',
  VENDOR: 'ASC110',
  ATTENDANCE: 'ASC112',
  PROJECT_PROFILE: 'ASC104',
  ACTIVITIES: 'ASC114',
  ORG_PROFILE: 'ASC117',
  TEAMS: 'ASC119',
  MEMBERS: 'ASC118',
  [COMMERCIAL_KEYS.PURCHASE_ORDER]: 'ASC120',
  [COMMERCIAL_KEYS.INDENT]: 'ASC121',
  [FINANCE_KEYS.GRN_APPROVAL]: 'ASC122',
  [FINANCE_KEYS.GRN_BILLING]: 'ASC123',
  [FINANCE_KEYS.EXPENESE]: 'ASC124',
  ANALYTICS_DASHBOARD: 'ASC140',
  WORK_ORDER: 'ASC142',
} as const);

export const MODULE_NAME_MAP = Object.fromEntries(
  Object.entries(MODULE_ID_MAP).map(([key, value]) => [value, key])
);

export const SUBSCRIPTION_TYPES = {
  PRO: 0,
  PRO_PLUS: 1,
  ADVANCED: 2,
  ENTERPRISE: 3,
} as const;

export const ADD_ONS = {
  WBS: 0,
  PRO_PO: 1,
  PRO_WITH_MATERIAL: 5,
  PRO_WITH_PO: 7,
} as const;

export const MODULE_NAME = Object.freeze({
  INDENT: 'INDENT',
  PURCHASE_ORDER: 'PURCHASE_ORDER',
} as const);

// TODO: use resource_id instead of title for layout menu changes
export const MODULES = Object.freeze({
  HOME: { title: 'Home', path: '/projects' },
  DASHBOARD: { title: 'Project Dashboard', path: '/dashboard' },
  WORK_CATEGORIES: { title: 'Work Categories', path: '/work-categories' },
  TASKS: { title: 'Tasks', path: '/project-plan' },
  ISSUES: { title: 'Issues', path: '/threads' },
  DRIVE: { title: 'Drive', path: '/drive' },
  BUDGET: { title: 'Budget & Billing', path: '/budget' },
  FINANCE: { title: 'Finance', path: '/grn-finance' },
  COMMERCIAL: { title: 'Commercial', path: '/commercial' },
  BOM: { title: 'Bill Of Materials', path: '/bom' },
  VENDOR: { title: 'Vendor', path: '/vendor' },
  ATTENDANCE: { title: 'Attendance', path: '/attendance' },
  ACTIVITY: { title: 'Activity', path: '/activities' },
  PROJECT_PROFILE: { title: 'Settings', path: '/project-profile' },
  MEMBER: { title: 'Members', path: '/members' },
  TEAM: { title: 'User Permissions & Roles', path: '/teams' },
  ORG_PROFILE: { title: 'Organisation Profile', path: '/org-profile' },
  PURCHASE_ORDER: {
    title: 'Purchase Order',
    path: '/commercial/purchase-order',
  },
  ORG_DASHBOARD: {
    title: 'Organisation Dashboard',
    path: '/org-dashboard',
  },
  ANALYTICS_DASHBOARD: {
    title: 'Analytics Dashboard',
    path: '/analytics-dashboard',
  },
  ORGANISATION_SETTINGS: {
    title: 'Settings',
    path: '/org-settings',
  },
} as const);

// TODO: add resource_title field which shows the field name
export const TEAM_MODULES = {
  PROJECT_DASHBOARD: {
    resource_id: 'ASC100',
    resource_name: 'Project Dashboard',
    resource_path: '/dashboard',
    description: 'Project dashboard',
  },
  TASKS: {
    resource_id: 'ASC101',
    resource_name: 'Tasks',
    resource_path: '/project-plan',
    description: 'Project planning and manage tasks',
  },
  BROAD_PLAN: {
    resource_id: 'ASC101',
    resource_name: 'Tasks',
    resource_path: '/broad-plan',
    description: 'Project planning and manage tasks',
  },
  GANTT_VIEW: {
    resource_id: 'ASC103',
    resource_name: 'Gantt View',
    resource_path: '/project-plan',
    description: 'Project planning and manage tasks',
  },
  PURCHASE_ORDERS: {
    resource_id: 'ASC120',
    resource_name: 'Purchase Order',
    resource_path: '/commercial',
    description: 'Manage purchase orders',
    org_resource_path: '/purchase-orders',
  },
  INDENT: {
    resource_id: 'ASC121',
    resource_name: 'Indent',
    resource_path: '/commercial',
    description: 'Manage indents',
  },
  GRN_APPROVAL: {
    resource_id: 'ASC122',
    resource_name: 'GRN Approval',
    resource_path: '/grn-finance',
    description: 'GRN Approval',
  },
  GRN_BILLING: {
    resource_id: 'ASC123',
    resource_name: 'GRN Billing',
    resource_path: '/grn-finance',
    description: 'GRN Billing',
  },
  EXPENSE: {
    resource_id: 'ASC124',
    resource_name: 'Expense',
    resource_path: '/grn-finance',
    description: 'Manage expenses',
  },
  PROJECT_PROFILE: {
    resource_id: 'ASC104',
    resource_name: 'Settings',
    resource_path: '/project-profile',
    description: 'Project profile',
  },
  VENDOR: {
    resource_id: 'ASC110',
    resource_name: 'Vendor',
    resource_path: '/vendor',
    description: 'Manage vendors',
  },
  BOM: {
    resource_id: 'ASC111',
    resource_name: 'BOM',
    resource_path: '/bom',
    description: 'Manage bill of materials',
  },
  ATTENDANCE: {
    resource_id: 'ASC112',
    resource_name: 'Attendance',
    resource_path: '/attendance',
    description: 'Manage attendance',
  },
  THREADS: {
    resource_id: 'ASC113',
    resource_name: 'Issues',
    resource_path: '/threads',
    description: 'Issues',
  },
  ACTIVITIES: {
    resource_id: 'ASC114',
    resource_name: 'Activities',
    resource_path: '/activities',
    description: 'Project activities',
  },
  BUDGET_AND_BILLING: {
    resource_id: 'ASC115',
    resource_name: 'Receivables',
    resource_path: '/budget',
    description: 'Bill of Quantities',
  },
  RECEIVABLES: {
    resource_id: 'ASC141',
    resource_name: 'Receivables',
    resource_path: '/budget',
    description: 'Receivables',
  },
  ORGANISATION_PROFILE: {
    resource_id: 'ASC117',
    resource_name: 'Organisation Profile',
    resource_path: '/org-profile',
    description: 'Organisation profile',
  },
  ORGANISATION_MEMBERS: {
    resource_id: 'ASC118',
    resource_name: 'Members',
    resource_path: '/members',
    description: 'Organisation members',
  },
  ORGANISATION_ROLES_AND_PERMISSIONS: {
    resource_id: 'ASC119',
    resource_name: 'User Permissions & Roles',
    resource_path: '/teams',
    description: 'User permissions and roles',
  },
  PROJECT_DRIVE: {
    resource_id: 'ASC116',
    resource_name: 'Drive',
    resource_path: '/drive',
    description: 'Drive',
  },
  ORG_NEW_ATTENDANCE: {
    resource_id: 'ASC138',
    resource_name: 'Attendance',
    resource_path: '/org-attendance',
    description: 'ORGANISATION ATTENDANCE',
  },
  MATERIAL: {
    resource_id: 'ASC125',
    resource_name: 'Material',
    resource_path: '/materials',
    description: 'Manage Materials',
  },
  COMMERCIAL: {
    resource_id: undefined,
    resource_name: 'Commercial',
    resource_path: '/commercial',
  },
  TASK: {
    resource_id: undefined,
    resource_name: 'Tasks',
    resource_path: '/project_plan',
  },
  ORG_DASHBOARD: {
    resource_id: undefined,
    resource_name: 'Organisation Dashboard',
    resource_path: '/org-dashboard',
  },
  ASYNC_REPORT: {
    resource_id: 'ASC150',
    resource_name: 'Reports',
    resource_path: '/new-report',
  },
  ORG_ASYNC_REPORT: {
    resource_id: 'ASC151',
    resource_name: 'Reports',
    resource_path: '/org/async-report',
  },
  VENDOR_ATTENDANCE: {
    resource_id: undefined,
    resource_name: 'Vendor Attendance',
    resource_path: '/attendance',
  },
  STOCK_UPDATES: {
    resource_id: 'ASC127',
    resource_name: 'Stock Updates',
  },
  MATERIAL_ISSUES: {
    resource_id: 'ASC128',
    resource_name: 'Material Issue',
  },
  SITE_TRANSFERS: {
    resource_id: 'ASC129',
    resource_name: 'Site Transfer',
  },
  EXPENSES: {
    resource_id: 'ASC130',
    resource_name: 'Expenses',
  },
  ATTENDANCE_PAYABLE: {
    resource_id: 'ASC131',
    resource_name: 'Payable',
  },
  NEW_ATTENDANCE: {
    resource_id: 'ASC112',
    resource_name: 'New Attendance',
    resource_path: '/new-attendance',
  },
  MAIN_TASK: {
    resource_id: 'ASC133',
    resource_name: 'Master Plan',
    resource_path: '/master-plan',
  },
  WEEKLY_TASK: {
    resource_id: 'ASC134',
    resource_name: 'WEEKLY TASK',
    resource_path: '/weekly-task',
  },
  MAIN_TASK_UPDATE: {
    resource_id: 'ASC135',
    resource_name: 'Master Plan',
    resource_path: '/master-plan',
  },
  WEEKLY_TASK_UPDATE: {
    resource_id: 'ASC136',
    resource_name: 'WEEKLY TASK',
    resource_path: '/weekly-task',
  },
  SC_PAYABALS: {
    resource_id: 'ASC141',
    resource_name: 'VENDOR LIST',
    resource_path: '/sc-payable-vendor-list',
  },
  ANALYTICS_DASHBOARD: {
    resource_id: 'ASC140',
    resource_name: 'Analytics Dashboard',
    resource_path: '/analytics-dashboard',
  },
  SUPERSET_DASHBOARD: {
    resource_id: 'NA',
    resource_name: 'Superset Dashboard',
    resource_path: '/superset-dashboard',
  },
  ORGANISATION_SETTINGS: {
    resource_id: 'ASC117',
    resource_name: 'Settings',
    resource_path: '/org-settings',
    description: 'Organisation settings',
  },
  PAYABLE_AND_PAYMENTS: {
    resource_id: undefined,
    resource_name: 'Payables & Payments',
    resource_path: '/payables',
    description: 'Payables and Payments',
  },
  WORK_ORDER: {
    resource_id: undefined,
    resource_name: 'Work Order',
    resource_path: '/work-order',
    Description: 'Work Order',
  },
} as const;

export const RESOURCE_ID_MAP = {
  PROJECT_DASHBOARD: 'ASC100',
  TASK_VIEW: 'ASC101',
  WORK_CATEGORIES: 'ASC102',
  GANTT_VIEW: 'ASC103',
  PROJECT_PROFILE: 'ASC104',
  VENDOR: 'ASC110',
  BOM: 'ASC111',
  ATTENDANCE: 'ASC112',
  THREADS: 'ASC113',
  ACTIVITIES: 'ASC114',
  BUDGET_AND_BILLING: 'ASC115',
  RECEIVABLES: 'ASC141',
  DRIVE: 'ASC116',
  ORG_PROFILE: 'ASC117',
  MEMBERS: 'ASC118',
  USER_ROLES_AND_PERMISSION: 'ASC119',
  PURCHASE_ORDER: 'ASC120',
  INDENT: 'ASC121',
  GRN_APPROVAL: 'ASC122',
  GRN: 'ASC123',
  EXPENSE: 'ASC124',
  INVENTORY: 'ASC125',
  TASK_LOGS: 'ASC126',
  STOCK_UPDATES: 'ASC127',
  MATERIAL_ISSUE: 'ASC128',
  SITE_TRANSFER: 'ASC129',
  EXPENSES: 'ASC130',
  LABOUR: 'ASC132',
  NEW_ATTENDANCE_VENDOR: 'ASC132',
  WORK_DONE: 'ASC112',
  CONSUMPTIONS: 'ASC131',
  MAIN_TASK: 'ASC133',
  WEEKLY_TASK: 'ASC134',
  ATTENDANCE_PAYABLE: 'ASC131',
  MAIN_TASK_UPDATE: 'ASC135',
  WEEKLY_TASK_UPDATE: 'ASC136',
  FREE_TEXT_UPDATE: 'ASC137',
  SC_WORK_PERISSION: 'ASC139',
  SC_PAYABLES_PERMISSION: 'ASC140',
  SC_PAYABALS: 'ASC141',
} as const;
