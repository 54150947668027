import clsx from 'clsx';
import { Navbar } from 'react-bootstrap';
import NavbarNav from './NavbarNav/NavbarNav';
import OrgDropdown from './OrgDropdown';
import TopNavWaningBar from './TopNavWaningBar';

interface Props {
  stickyTop?: boolean;
  noOrg?: boolean;
  orgView?: boolean;
  dividers?: boolean;
}

function MainNavbar({ stickyTop = true, noOrg, orgView, dividers }: Props) {
  const classes = clsx(
    'main-navbar-container',
    stickyTop && 'sticky-top',
    dividers && 'border-bottom'
  );

  return (
    <>
      <TopNavWaningBar />
      <div className={classes}>
        {!noOrg && <OrgDropdown />}

        <Navbar
          variant="light"
          className="align-items-right flex-md-nowrap p-0 w-100"
        >
          <NavbarNav orgView={orgView} />
        </Navbar>
      </div>
    </>
  );
}

export default MainNavbar;
