import { LOCAL_STORAGE_TOKEN_KEY } from '../constants';
import LocalStorageUtil from './LocalStorage.util';
import { jwtDecode } from 'jwt-decode';

interface JwtTokenProps {
  exp: number;
}

interface SupersetTokenProps extends JwtTokenProps {
  resources: { id: string }[];
}

export default function authHeader() {
  // return authorization header with jwt token
  const token = LocalStorageUtil.getLocalStorageValue(
    LOCAL_STORAGE_TOKEN_KEY.TOKEN,
    false
  );

  if (token) {
    return {
      token,
      isAuth: true,
    };
  } else {
    return { isAuth: false };
  }
}

export function isJwtTokenValid(jwtToken: string): boolean {
  if (!jwtToken) {
    return false;
  }
  try {
    const decodedToken: JwtTokenProps = jwtDecode(jwtToken);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp && decodedToken.exp > currentTime) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}
export function getSupersetGuestToken(orgId: string): {
  viewIds?: string[];
  token?: string;
} {
  const token = LocalStorageUtil.getLocalStorageValue(
    `${LOCAL_STORAGE_TOKEN_KEY.SUPERSET_TOKEN}_${orgId}`,
    false
  );

  if (!token) {
    return {};
  }

  try {
    const decodedToken: SupersetTokenProps = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp && decodedToken.exp > currentTime) {
      const viewIds =
        decodedToken.resources?.map((resource: any) => resource.id) || [];
      return { viewIds, token };
    } else {
      return {};
    }
  } catch (error) {
    return {};
  }
}
