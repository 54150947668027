import { ReducerProps } from '@/interfaces/Base';
import {
  ADD_ADVANCE_ERROR,
  ADD_ADVANCE_PENDING,
  ADD_ADVANCE_SUCCESS,
  ADVANCE_DELETE_PENDING,
  GET_ADANCE_LEFT,
  GET_ADVANCE_HISTORY,
  GET_ADVANCE_HISTORY_COUNT,
  GET_ADVANCE_HISTORY_ERROR,
  GET_ADVANCE_HISTORY_PENDING,
  GET_ADVANCE_HISTORY_SUCCESS,
  GET_ADVANCE_PROFILE,
  GET_ADVANCE_PROFILE_ERROR,
  GET_ADVANCE_PROFILE_PENDING,
  GET_ADVANCE_PROFILE_SUCCESS,
  GET_AVAILABLE_ADVANCE,
  GET_AVAILABLE_ADVANCE_ERROR,
  GET_AVAILABLE_ADVANCE_PENDING,
  GET_AVAILABLE_ADVANCE_SUCCESS,
  GET_LEFT_OVER_ADVANCE_DATA_FOR_PAYABLE,
  GET_LEFT_OVER_ADVANCE_DATA_FOR_ATTENDANCE_PAYABLE,
  GET_ORG_PAYMENT_LIST_COUNT,
  GET_ORG_PAYMENTS,
  GET_ORG_PAYMENTS_ERROR,
  GET_ORG_PAYMENTS_PENDING,
  GET_ORG_PAYMENTS_SUCCESS,
  GET_ORG_PAYMENTS_EXPENSE,
  GET_ORG_PAYMENTS_EXPENSE_PENDING,
  GET_ORG_PAYMENTS_EXPENSE_SUCCESS,
  GET_ORG_PAYMENTS_EXPENSE_ERROR,
  GET_PAYABLE_PAYMENTS,
  GET_PAYABLE_PAYMENTS_ERROR,
  GET_PAYABLE_PAYMENTS_PENDING,
  GET_PAYABLE_PAYMENTS_SUCCESS,
  GET_PAYMENT_PROFILE,
  GET_PAYMENT_PROFILE_ERROR,
  GET_PAYMENT_PROFILE_PENDING,
  GET_PAYMENT_PROFILE_SUCCESS,
  PAYMENT_DELETE_PENDING,
  USE_ADVANCE_ERROR,
  USE_ADVANCE_PENDING,
  USE_ADVANCE_SUCCESS,
  GET_ORG_PAYMENTS_EXPENSE_AMOUNT,
  GET_ORG_PAYMENTS_EXPENSE_AMOUNT_SUCCESS,
  GET_ORG_PAYMENTS_EXPENSE_AMOUNT_ERROR,
  GET_ORG_PAYMENTS_EXPENSE_AMOUNT_PENDING,
  GET_ORG_PAYMENT_AMOUNT,
  GET_ORG_PAYMENT_AMOUNT_PENDING,
  GET_ORG_PAYMENT_AMOUNT_SUCCESS,
  GET_ORG_PAYMENT_AMOUNT_ERROR,
} from './Advance';

const initialState = {
  advanceAvailable: null,

  addAdvancePending: false,
  addAdvanceError: null,

  useAdvancePending: false,
  useAdvanceError: null,

  leftOverAdvanceForPayableData: null,
  leftOverAdvanceForAttendacePayableData: null,

  orgPaymentsList: null,
  orgPaymentsListPending: false,
  orgPaymentsListError: null,

  orgPaymentAmountList: null,
  orgPaymentAmountPending: false,
  orgPaymentAmountError: null,

  orgPaymentsExpenseList: null,
  orgPaymentsExpenseListPending: false,
  orgPaymentsExpenseListError: null,

  orgPaymentsExpenseAmountList: null,
  orgPaymentsExpenseAmountListPending: false,
  orgPaymentsExpenseAmountListError: null,

  orgPaymentsListCount: null,

  advanceHistory: null,
  advanceHistoryPending: false,
  advanceHistoryError: null,

  advanceHistoryCount: null,

  paymentProfile: null,
  paymentProfilePending: false,
  paymentProfileError: null,

  payablePaymentsData: null,
  payablePaymentsDataPending: false,
  payablePaymentsDataError: null,

  itemAvailableAdvance: null,
  itemAvailableAdvancePending: false,
  itemAvailableAdvanceError: null,

  deleteAdvancePending: false,
  deletePaymentPending: false,
};

function advanceReducer(
  state = initialState,
  { type, payload, error }: ReducerProps
): any {
  switch (type) {
    case ADD_ADVANCE_PENDING:
      return {
        ...state,
        addAdvancePending: payload,
      };

    case ADD_ADVANCE_ERROR:
      return {
        ...state,
        addAdvanceError: error,
        addAdvancePending: false,
      };

    case ADD_ADVANCE_SUCCESS:
      return {
        ...state,
        addAdvanceError: null,
        addAdvancePending: false,
      };

    case USE_ADVANCE_PENDING:
      return {
        ...state,
        useAdvancePending: payload,
      };

    case USE_ADVANCE_ERROR:
      return {
        ...state,
        useAdvanceError: error,
        useAdvancePending: false,
      };

    case USE_ADVANCE_SUCCESS:
      return {
        ...state,
        useAdvanceError: null,
        useAdvancePending: false,
      };

    case GET_ADANCE_LEFT:
      return {
        ...state,
        advanceAvailable: payload,
      };

    case GET_LEFT_OVER_ADVANCE_DATA_FOR_PAYABLE:
      return {
        ...state,
        leftOverAdvanceForPayableData: payload,
      };

    case GET_LEFT_OVER_ADVANCE_DATA_FOR_ATTENDANCE_PAYABLE:
      return {
        ...state,
        leftOverAdvanceForAttendacePayableData: payload,
      };
    case GET_ORG_PAYMENTS:
      return {
        ...state,
        orgPaymentsList: payload,
      };
    case GET_ORG_PAYMENTS_PENDING:
      return {
        ...state,
        orgPaymentsListPending: payload,
      };
    case GET_ORG_PAYMENTS_ERROR:
      return {
        ...state,
        orgPaymentsListError: error,
        orgPaymentsListPending: false,
      };
    case GET_ORG_PAYMENTS_SUCCESS:
      return {
        ...state,
        orgPaymentsListError: null,
        orgPaymentsListPending: false,
      };

    case GET_ORG_PAYMENT_LIST_COUNT:
      return {
        ...state,
        orgPaymentsListCount: payload,
      };

    case GET_ORG_PAYMENT_AMOUNT:
      return {
        ...state,
        orgPaymentAmountList: payload,
      };
    case GET_ORG_PAYMENT_AMOUNT_PENDING:
      return {
        ...state,
        orgPaymentAmountPending: payload,
      };
    case GET_ORG_PAYMENT_AMOUNT_ERROR:
      return {
        ...state,
        orgPaymentAmountError: error,
        orgPaymentAmountPending: false,
      };
    case GET_ORG_PAYMENT_AMOUNT_SUCCESS:
      return {
        ...state,
        orgPaymentAmountError: null,
        orgPaymentAmountPending: false,
      };

    case GET_ORG_PAYMENTS_EXPENSE:
      return {
        ...state,
        orgPaymentsExpenseList: payload,
      };
    case GET_ORG_PAYMENTS_EXPENSE_PENDING:
      return {
        ...state,
        orgPaymentsExpenseListPending: payload,
      };
    case GET_ORG_PAYMENTS_EXPENSE_ERROR:
      return {
        ...state,
        orgPaymentsExpenseListError: error,
        orgPaymentsExpenseListPending: false,
      };
    case GET_ORG_PAYMENTS_EXPENSE_SUCCESS:
      return {
        ...state,
        orgPaymentsExpenseListError: null,
        orgPaymentsExpenseListPending: false,
      };

    case GET_ORG_PAYMENTS_EXPENSE_AMOUNT:
      return {
        ...state,
        orgPaymentsExpenseAmountList: payload,
      };
    case GET_ORG_PAYMENTS_EXPENSE_AMOUNT_PENDING:
      return {
        ...state,
        orgPaymentsExpenseAmountListPending: payload,
      };
    case GET_ORG_PAYMENTS_EXPENSE_AMOUNT_ERROR:
      return {
        ...state,
        orgPaymentsExpenseAmountListError: error,
        orgPaymentsExpenseAmountListPending: false,
      };
    case GET_ORG_PAYMENTS_EXPENSE_AMOUNT_SUCCESS:
      return {
        ...state,
        orgPaymentsExpenseAmountListError: null,
        orgPaymentsExpenseAmountListPending: false,
      };

    case GET_PAYMENT_PROFILE:
      return {
        ...state,
        paymentProfile: payload,
      };
    case GET_PAYMENT_PROFILE_PENDING:
      return {
        ...state,
        paymentProfilePending: payload,
      };
    case GET_PAYMENT_PROFILE_ERROR:
      return {
        ...state,
        paymentProfileError: error,
        paymentProfilePending: false,
      };
    case GET_PAYMENT_PROFILE_SUCCESS:
      return {
        ...state,
        paymentProfileError: null,
        paymentProfilePending: false,
      };

    case GET_ADVANCE_PROFILE:
      return {
        ...state,
        advanceProfile: payload,
      };
    case GET_ADVANCE_PROFILE_PENDING:
      return {
        ...state,
        advanceProfilePending: payload,
      };
    case GET_ADVANCE_PROFILE_ERROR:
      return {
        ...state,
        advanceProfileError: error,
        advanceProfilePending: false,
      };
    case GET_ADVANCE_PROFILE_SUCCESS:
      return {
        ...state,
        advanceProfileError: null,
        advanceProfilePending: false,
      };

    case GET_ADVANCE_HISTORY:
      return {
        ...state,
        advanceHistory: payload,
      };
    case GET_ADVANCE_HISTORY_PENDING:
      return {
        ...state,
        advanceHistoryPending: payload,
      };
    case GET_ADVANCE_HISTORY_ERROR:
      return {
        ...state,
        advanceHistoryError: error,
        advanceHistoryPending: false,
      };
    case GET_ADVANCE_HISTORY_SUCCESS:
      return {
        ...state,
        advanceHistoryError: null,
        advanceHistoryPending: false,
      };

    case GET_ADVANCE_HISTORY_COUNT:
      return {
        ...state,
        advanceHistoryCount: payload,
      };

    case GET_PAYABLE_PAYMENTS:
      return {
        ...state,
        payablePaymentsData: payload,
      };
    case GET_PAYABLE_PAYMENTS_PENDING:
      return {
        ...state,
        payablePaymentsDataPending: payload,
      };
    case GET_PAYABLE_PAYMENTS_ERROR:
      return {
        ...state,
        payablePaymentsDataError: error,
        payablePaymentsDataPending: false,
      };
    case GET_PAYABLE_PAYMENTS_SUCCESS:
      return {
        ...state,
        payablePaymentsDatayError: null,
        payablePaymentsDataPending: false,
      };

    case GET_AVAILABLE_ADVANCE:
      return {
        ...state,
        itemAvailableAdvance: payload,
      };
    case GET_AVAILABLE_ADVANCE_PENDING:
      return {
        ...state,
        itemAvailableAdvancePending: payload,
      };
    case GET_AVAILABLE_ADVANCE_ERROR:
      return {
        ...state,
        itemAvailableAdvanceError: error,
        itemAvailableAdvancePending: false,
      };
    case GET_AVAILABLE_ADVANCE_SUCCESS:
      return {
        ...state,
        itemAvailableAdvanceError: null,
        itemAvailableAdvancePending: false,
      };

    case PAYMENT_DELETE_PENDING: {
      return { ...state, deletePaymentPending: payload };
    }
    case ADVANCE_DELETE_PENDING: {
      return { ...state, deleteAdvancePending: payload };
    }

    default:
      return state;
  }
}

export default advanceReducer;
