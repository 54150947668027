/* eslint-disable no-undef */
import axios from 'axios';

import { logout } from '../redux/features/common/user';
import {
  INTERNAL_SERVER_ERROR,
  UNAUTHORIZED,
} from '../constant/status_constants';
import store from '../redux/Store';
import LocalStorageUtil from './LocalStorage.util';
import { getAuthorizationHeaderToken } from '@/apiClient';
import { skipAuthTokenApis } from '@/routes.constant';

const apiClient = axios.create({
  baseURL: import.meta.env.REACT_APP_BASE_URL || '/api/v1',
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'ngrok-skip-browser-warning': '*',
  },
});

apiClient.interceptors.request.use(async config => {
  if (!skipAuthTokenApis.includes(config.url)) {
    const token = await getAuthorizationHeaderToken();
    config.headers.authorization = token ? `Bearer ${token}` : '';
  }
  config.headers['x-platform'] = 'web';
  config.headers['x-apm-is_paid'] =
    LocalStorageUtil.getLocalStorageValue('is_paid', false) || '';
  config.headers['x-apm-org_id'] =
    LocalStorageUtil.getLocalStorageValue('org_id', false) || '';

  return config;
});

apiClient.interceptors.response.use(
  response => response,
  error => {
    const responseError = Object.assign({}, error);

    if (responseError.response && responseError.response.data) {
      const { status } = responseError.response;

      if (status === UNAUTHORIZED) {
        store.dispatch(logout());
      }

      if (!responseError.response.data.message) {
        responseError.response.data = { message: 'UNKNOWN_ERROR' };
      }
    } else if (responseError.request) {
      responseError.response = {
        data: {
          message:
            responseError.request.message || responseError.request.statusText,
        },
      };
    } else {
      responseError.response = {
        data: { message: responseError.message },
        status: INTERNAL_SERVER_ERROR,
      };
    }

    return Promise.reject(responseError);
  }
);

export default apiClient;
