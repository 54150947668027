import {
  GET_ALL_SEQ,
  GET_ACTIVE_SEQ,
  GET_ARCHIVED_SEQ,
  GET_SEQ_WO,
  CREATE_SEQ,
  UPDATE_SEQ,
  UPDATE_ALL_SEQ,
  UPDATE_ACTIVE_SEQ,
  UPDATE_ARCHIVED_SEQ,
  ARCHIVE_SEQ,
  SEQ_API_ERROR,
  GET_ALL_PENDING,
  GET_ACTIVE_PENDING,
  GET_ARCHIVED_PENDING,
  CREATE_PENDING,
  UPDATE_PENDING,
  UPDATE_ALL_PENDING,
  UPDATE_ACTIVE_PENDING,
  UPDATE_ARCHIVED_PENDING,
  ARCHIVE_PENDING,
  GET_SEQ_WO_PENDING,
  GET_SEQ_PROJECT_PENDING,
  GET_SEQ_PROJECT,
  RESTORE_PENDING,
  RESTORE_SEQ,
} from './WOSequence.type';

const pendingInitialStae = {
  allSeqPending: false,
  activeSeqPending: false,
  archivedSeqPending: false,
  createSeqPending: false,
  archiveSeqRespPending: false,
  poSeqPending: false,
  archiveSeqPending: false,
  updateSeqPending: false,
  updateAllSeqPending: false,
  updateActiveSeqPending: false,
  updateArchivedSeqPending: false,
  projectSeqPending: false,
};

const initialState = {
  ...pendingInitialStae,
  allSeq: [],
  archivedSeq: [],
  activeSeq: [],
  projectSeq: [],
  createSeq: null,
  selectedSeq: null,
  addedSeq: null,
  poSeq: null,
  archiveSeqResp: null,
  restoreSeq: null,
  updateSeq: null,
  updateAllSeq: null,
  updateArchivedSeq: null,
  updateActiveSeq: null,
  error: null,
};

export default function woSequenceReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case CREATE_PENDING:
      return { ...state, createSeqPending: payload };
    case CREATE_SEQ:
      return { ...state, createSeq: payload };
    case GET_ALL_PENDING:
      return { ...state, allSeqPending: payload };
    case GET_ALL_SEQ:
      return { ...state, allSeq: payload };
    case GET_ACTIVE_PENDING:
      return { ...state, activeSeqPending: payload };
    case GET_ACTIVE_SEQ:
      return { ...state, activeSeq: payload };
    case GET_ARCHIVED_PENDING:
      return { ...state, archivedSeqPending: payload };
    case GET_ARCHIVED_SEQ: {
      return { ...state, archivedSeq: payload };
    }
    case GET_SEQ_PROJECT_PENDING:
      return { ...state, projectSeqPending: payload };
    case GET_SEQ_PROJECT:
      return { ...state, projectSeq: payload };
    case GET_SEQ_WO_PENDING:
      return { ...state, poSeqPending: payload };
    case GET_SEQ_WO:
      return { ...state, poSeq: payload };

    case UPDATE_PENDING:
      return { ...state, updateSeqPending: payload };
    case UPDATE_SEQ:
      return { ...state, updateSeq: payload };
    case UPDATE_ALL_PENDING:
      return { ...state, updateAllSeqPending: payload };
    case UPDATE_ALL_SEQ:
      return { ...state, updateAllSeq: payload };
    case UPDATE_ARCHIVED_PENDING:
      return { ...state, updateArchivedSeqPending: payload };
    case UPDATE_ARCHIVED_SEQ:
      return { ...state, updateArchivedSeq: payload };
    case UPDATE_ACTIVE_PENDING:
      return { ...state, updateActiveSeqPending: payload };
    case UPDATE_ACTIVE_SEQ:
      return { ...state, updateActiveSeq: payload };

    case ARCHIVE_PENDING:
      return { ...state, archiveSeqRespPending: payload };
    case ARCHIVE_SEQ:
      return { ...state, archiveSeqResp: payload };
    case RESTORE_PENDING:
      return { ...state, restorePending: payload };
    case RESTORE_SEQ:
      return { ...state, restoreSeq: payload };

    case SEQ_API_ERROR:
      return { ...state, error: error };
    default:
      return state;
  }
}
