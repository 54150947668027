import {
  ATTENDANCE_API_SUCCESS,
  ATTENDANCE_API_ERROR,
  ATTENDANCE_API_PENDING,
  EXPORT_ALL_RECORD,
  GET_ALL_ATTENDANCE_RECORD,
  EXPORT_API_SUCCESS,
  EXPORT_API_FAILURE,
  EXPORT_API_PENDING,
  CREATE_ATTENDENCE_RECORD,
  ORG_VENDOR_ATTENDANCE,
  ORG_VENDOR_ATTENDANCE_COUNT,
  VENDOR_PAYABLES,
  ATTENDANCE_SUMMARY,
  SELECTED_VENDOR,
  SELECTED_LABOUR,
  VENDOR_PROFILE,
  LABOUR_PROFILE,
  LABOUR_ATTENDANCE_SUMMARY,
  VENDOR_ATTENDANCE_SUMMARY,
  GET_ATTENDANCE_LOG,
  CLEAR_ATTENDANCE_LOG,
  GET_WORKER_ATTENDANCE,
  GET_NEW_ATTENDANCE_LOG,
  ATTENDANCE_SUMMARY_API_SUCCESS,
  ATTENDANCE_SUMMARY_API_PENDING,
  ATTENDANCE_SUMMARY_COUNT,
  SET_ADD_PAID_AMOUNT_ID,
  SET_LABOUR_WAGE_EDIT_ID,
  SET_VENDOR_WAGE_EDIT_ID,
  GET_LABOUR_LIST,
  GET_VENDOR_TRANSACTION_LIST,
  WORKER_ATTENDANCE_PROJECT_SUMMARY,
  VENDOR_ATTENDANCE_LIST,
  VENDOR_LABOUR_LIST,
  GET_VENDOR_PROJECT_LIST,
  GET_LABOUR_TRANSACTION_LIST,
  GET_LABOUR_PROJECT_LIST,
  LABOUR_ATTENDANCE_LIST,
  ORG_LABOUR_ATTENDANCE_SUMMARY,
  GET_LABOUR_TOTAL_TO_PAY,
  GET_VENDOR_TOTAL_TO_PAY,
  VENDOR_PROFILE_SUMMARY,
  LABOUR_PROFILE_SUMMARY,
  GET_MERGE_LABOUR_LIST,
  GET_MERGE_VENDOR_LIST,
  GET_PAYMENT_MODE,
  GET_PAYMENT_DETAIL,
  VENDOR_PROFILE_SUMMARY_PENDING,
  CREATE_LABOUR_PENDING,
  CREATE_LABOUR_ERROR,
  ADD_EXISTING_WORKER_PENDING,
  CREATE_VENDOR_PENDING,
  CREATE_VENDOR_ERROR,
  GET_ALL_ASYNC_ATT_REPORT,
  GET_REPORT_ASYNC_DATA,
  GET_ASYNC_REPORT_PENDING,
  GET_ASYNC_REPORT_SUCCESS,
  GET_ASYNC_REPORT_FAILURE,
  GET_WORKER_WAGE_DETAILS,
  GET_WORKER_WAGE_PENDING,
  CHECK_WORKER_EXISTS,
  CHECK_WORKER_EXISTS_PENDING,
  LABOUR_WAGE_DATA,
  PAYABLE_RECORDS,
  PROJECT_LABOUR_ATTENDANCE_SUMMARY,
  ORG_LABOUR_ATTENDANCE_SUMMARY_ALL,
  ORG_ATTENDANCE_ACTIVE_PROJECTS,
  ORG_ATTENDANCE_DATA,
  ORG_ATTENDANCE_ALL_PROJECTS,
  DATE_WISE_ATTENDANCE_PAYABLE,
  DATE_WISE_PROJECTS_LIST,
  VENDOR_LABOUR_LIST_WAGES,
  PAYABLE_RECORDS_ATTENDANCE,
  ATTENDANCE_FINANCIAL_DATA,
  RESET_LABOUR_WAGE_DATA,
} from './Attendance.type';
import { ReducerProps } from '../../../interfaces/Base';

const initialState = {
  attendanceSummaryPending: false,
  pending: false,
  exportPending: false,
  error: null,
  records: [],
  exportRecords: [],
  dataLoaded: false,
  addedRecord: {},
  orgVendorAttendance: [],
  orgVendorAttendanceCount: 0,
  vendorPaybles: null,
  attendancePayables: null,
  attendanceSummaryWorkerCount: 0,
  vendorProfile: null,
  vendorLabourList: null,
  labourProfile: null,
  labourAttendanceSummary: null,
  vendorAttendanceSummary: null,
  workerProjectSummary: null,
  asyncReport: {
    records: [],
    page: 0,
    limit: 20,
    total: null,
  },
  asyncReportPending: false,
  attendanceLog: [],
  paymentMode: [],
  paymentDetail: {},
  attendanceLogHasMore: true,
  workerAttendance: null,
  addPaidAmountWorkerId: '',
  vendorEditWageId: '',
  LabourEditWageId: '',
  getLabourList: '',
  getVendorTransactionList: '',
  workerAttendanceList: null,
  getVendorProjectList: '',
  getLabourTransactionList: '',
  getLabourProjectList: '',
  vendorAttendanceList: null,
  labourAttendanceList: null,
  getReportAsyncData: null,
  orgLabourAttendanceSummary: null,
  getVendorTotalToPay: '',
  getLabourTotalToPay: '',
  labourProfileSummary: null,
  vendorProfileSummary: null,
  getMergeLabourList: null,
  getMergeVendorList: null,
  vendorProfileSummaryPending: false,
  createLabourPending: false,
  createLabourError: null,
  addExistingWorkerPending: false,
  createVendorPending: false,
  createVendorError: null,
  getWorkerWage: null,
  getWorkerWagePending: false,
  checkWorkerExist: null,
  checkWorkerExistPending: false,
  labourWageData: {},
  payableRecordsData: {},
  projectLabourAttendanceSummary: {},
  allOrgLabourAttendanceSummary: {},
  orgAttendanceActiveProjects: {},
  orgAttendanceData: {},
  orgAttendanceAllProjects: {},
  dateWiseAttendancePayable: {},
  dateWiseProjectList: {},
  vendorLabourListWages: {},
  payableRecordsAttendance: {},
  attendanceFinancialData: {},
};

export default function attendanceReducer(
  state = initialState,
  { type, payload, error }: ReducerProps
) {
  switch (type) {
    case ATTENDANCE_API_SUCCESS:
      return { ...state, pending: false };
    case ATTENDANCE_API_ERROR:
      return { ...state, pending: false, error: error };
    case ATTENDANCE_API_PENDING:
      return { ...state, pending: true };
    case ATTENDANCE_SUMMARY_API_SUCCESS:
      return { ...state, attendanceSummaryPending: false };
    case ATTENDANCE_SUMMARY_API_PENDING:
      return { ...state, attendanceSummaryPending: true };
    case VENDOR_PROFILE_SUMMARY_PENDING:
      return { ...state, vendorProfileSummaryPending: payload };
    case EXPORT_ALL_RECORD:
      return { ...state, exportRecords: payload };
    case GET_ALL_ATTENDANCE_RECORD:
      return { ...state, records: payload };
    case EXPORT_API_SUCCESS:
      return { ...state, exportPending: false, dataLoaded: true };
    case EXPORT_API_FAILURE:
      return { ...state, exportPending: false };
    case EXPORT_API_PENDING:
      return { ...state, exportPending: true, dataLoaded: false };
    case CREATE_ATTENDENCE_RECORD:
      return { ...state, addedRecord: payload };
    case ORG_VENDOR_ATTENDANCE:
      return { ...state, orgVendorAttendance: payload };
    case ORG_VENDOR_ATTENDANCE_COUNT:
      return { ...state, orgVendorAttendanceCount: payload };
    case VENDOR_PAYABLES:
      return { ...state, vendorPaybles: payload };
    case ATTENDANCE_SUMMARY:
      return { ...state, attendancePayables: payload };
    case SELECTED_VENDOR:
      return { ...state, selectedVendor: payload };
    case SELECTED_LABOUR:
      return { ...state, selectedVendor: payload };
    case VENDOR_PROFILE:
      return { ...state, vendorProfile: payload };
    case LABOUR_PROFILE:
      return { ...state, labourProfile: payload };
    case VENDOR_PROFILE_SUMMARY:
      return { ...state, vendorProfileSummary: payload };
    case LABOUR_PROFILE_SUMMARY:
      return { ...state, labourProfileSummary: payload };
    case GET_WORKER_ATTENDANCE:
      return { ...state, workerAttendance: payload };
    case GET_PAYMENT_DETAIL:
      return { ...state, paymentDetail: payload };
    case WORKER_ATTENDANCE_PROJECT_SUMMARY:
      return { ...state, workerProjectSummary: payload };
    case VENDOR_ATTENDANCE_LIST:
      return { ...state, vendorAttendanceList: payload };
    case LABOUR_ATTENDANCE_LIST:
      return { ...state, labourAttendanceList: payload };
    case VENDOR_LABOUR_LIST:
      return { ...state, vendorLabourList: payload };
    case LABOUR_ATTENDANCE_SUMMARY:
      return {
        ...state,
        labourAttendanceSummary: payload,
      };
    case VENDOR_ATTENDANCE_SUMMARY:
      return {
        ...state,
        vendorAttendanceSummary: payload,
      };
    case ORG_LABOUR_ATTENDANCE_SUMMARY:
      return {
        ...state,
        orgLabourAttendanceSummary: payload,
      };
    case GET_ATTENDANCE_LOG:
      return {
        ...state,
        attendanceLog: [...state.attendanceLog, ...payload.attendances],
        attendanceLogHasMore: payload.has_more,
      };
    case GET_NEW_ATTENDANCE_LOG:
      return {
        ...state,
        attendanceLog: payload.attendances,
        attendanceLogHasMore: payload.has_more,
      };
    case CLEAR_ATTENDANCE_LOG:
      return {
        ...state,
        attendanceLog: [],
        attendanceLogHasMore: true,
      };
    case GET_PAYMENT_MODE:
      return {
        ...state,
        paymentMode: payload,
      };
    case GET_ALL_ASYNC_ATT_REPORT:
      return {
        ...state,
        asyncReport: payload,
      };
    case ATTENDANCE_SUMMARY_COUNT:
      return {
        ...state,
        attendanceSummaryWorkerCount: payload,
      };
    case SET_ADD_PAID_AMOUNT_ID: {
      return {
        ...state,
        addPaidAmountWorkerId: payload,
      };
    }
    case SET_VENDOR_WAGE_EDIT_ID: {
      return {
        ...state,
        vendorEditWageId: payload,
      };
    }
    case SET_LABOUR_WAGE_EDIT_ID: {
      return {
        ...state,
        LabourEditWageId: payload,
      };
    }
    case GET_LABOUR_LIST: {
      return {
        ...state,
        getLabourList: payload,
      };
    }
    case GET_VENDOR_TRANSACTION_LIST: {
      return {
        ...state,
        getVendorTransactionList: payload,
      };
    }
    case GET_ASYNC_REPORT_PENDING: {
      return {
        ...state,
        asyncReportPending: true,
      };
    }
    case GET_ASYNC_REPORT_SUCCESS: {
      return {
        ...state,
        asyncReportPending: false,
      };
    }
    case GET_ASYNC_REPORT_FAILURE: {
      return {
        ...state,
        asyncReportPending: false,
      };
    }
    case GET_REPORT_ASYNC_DATA: {
      return {
        ...state,
        getReportAsyncData: payload,
      };
    }
    case GET_VENDOR_PROJECT_LIST: {
      return {
        ...state,
        getVendorProjectList: payload,
      };
    }
    case GET_LABOUR_TRANSACTION_LIST: {
      return {
        ...state,
        getLabourTransactionList: payload,
      };
    }
    case GET_LABOUR_PROJECT_LIST: {
      return {
        ...state,
        getLabourProjectList: payload,
      };
    }
    case GET_LABOUR_TOTAL_TO_PAY: {
      return {
        ...state,
        getLabourTotalToPay: payload,
      };
    }
    case GET_VENDOR_TOTAL_TO_PAY: {
      return {
        ...state,
        getVendorTotalToPay: payload,
      };
    }
    case GET_MERGE_LABOUR_LIST: {
      return {
        ...state,
        getMergeLabourList: payload,
      };
    }
    case GET_MERGE_VENDOR_LIST: {
      return {
        ...state,
        getMergeVendorList: payload,
      };
    }
    case CREATE_LABOUR_PENDING: {
      return { ...state, createLabourPending: payload };
    }
    case CREATE_LABOUR_ERROR: {
      return { ...state, createLabourError: payload };
    }
    case ADD_EXISTING_WORKER_PENDING: {
      return { ...state, addExistingWorkerPending: payload };
    }
    case CREATE_VENDOR_PENDING: {
      return { ...state, createVendorPending: payload };
    }
    case CREATE_VENDOR_ERROR: {
      return { ...state, createVendorError: payload };
    }
    case GET_WORKER_WAGE_DETAILS: {
      return { ...state, getWorkerWage: payload };
    }
    case GET_WORKER_WAGE_PENDING: {
      return { ...state, getWorkerWagePending: payload };
    }
    case CHECK_WORKER_EXISTS: {
      return {
        ...state,
        checkWorkerExist: payload,
      };
    }
    case CHECK_WORKER_EXISTS_PENDING: {
      return {
        ...state,
        checkWorkerExistPending: payload,
      };
    }
    case LABOUR_WAGE_DATA: {
      return {
        ...state,
        labourWageData: payload,
      };
    }
    case RESET_LABOUR_WAGE_DATA: {
      return {
        ...state,
        labourWageData: null,
      };
    }
    case PAYABLE_RECORDS: {
      return {
        ...state,
        payableRecordsData: payload,
      };
    }

    case PROJECT_LABOUR_ATTENDANCE_SUMMARY: {
      return {
        ...state,
        projectLabourAttendanceSummary: payload,
      };
    }

    case ORG_LABOUR_ATTENDANCE_SUMMARY_ALL: {
      return {
        ...state,
        allOrgLabourAttendanceSummary: payload,
      };
    }

    case ORG_ATTENDANCE_ACTIVE_PROJECTS: {
      return {
        ...state,
        orgAttendanceActiveProjects: payload,
      };
    }

    case ORG_ATTENDANCE_DATA: {
      return {
        ...state,
        orgAttendanceData: payload,
      };
    }

    case ORG_ATTENDANCE_ALL_PROJECTS: {
      return {
        ...state,
        orgAttendanceAllProjects: payload,
      };
    }

    case DATE_WISE_ATTENDANCE_PAYABLE: {
      return {
        ...state,
        dateWiseAttendancePayable: payload,
      };
    }

    case DATE_WISE_PROJECTS_LIST: {
      return {
        ...state,
        dateWiseProjectList: payload,
      };
    }

    case VENDOR_LABOUR_LIST_WAGES: {
      return {
        ...state,
        vendorLabourListWages: payload,
      };
    }

    case PAYABLE_RECORDS_ATTENDANCE: {
      return {
        ...state,
        payableRecordsAttendance: payload,
      };
    }

    case ATTENDANCE_FINANCIAL_DATA: {
      return {
        ...state,
        attendanceFinancialData: payload,
      };
    }

    default:
      return state;
  }
}
