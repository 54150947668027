import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { cn } from '@/lib/utils';

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & {
    className?: string;
    sideOffset?: number;
  }
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'z-[1221] overflow-hidden rounded-md bg-main px-3 py-1.5 text-xs-12 text-main-foreground animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className
    )}
    {...props}
  />
));

TooltipContent.displayName = TooltipPrimitive.Content.displayName;

interface CustomToolTipProps {
  content: React.ReactNode;
  children: React.ReactNode;
  side?: 'top' | 'bottom' | 'left' | 'right';
  align?: 'start' | 'center' | 'end';
  sideOffset?: number;
  tooltipContentProps?: React.HTMLAttributes<HTMLDivElement>;
  tooltipTriggerProps?: React.HTMLAttributes<HTMLButtonElement>;
  contentClassName?: string;
  triggerClassName?: string;
}

const CustomToolTip = ({
  content,
  children,
  side = 'top',
  align = 'center',
  sideOffset = 5,
  tooltipContentProps = {},
  tooltipTriggerProps = {},
  contentClassName = '',
  triggerClassName = '',
}: CustomToolTipProps) => {
  return (
    <Tooltip delayDuration={200}>
      <TooltipTrigger asChild {...tooltipTriggerProps}>
        <span className={`${triggerClassName}`}>{children}</span>
      </TooltipTrigger>
      {Boolean(content) && (
        <TooltipContent
          side={side}
          align={align}
          sideOffset={sideOffset}
          {...tooltipContentProps}
          className={contentClassName}
        >
          {content}
          <TooltipPrimitive.TooltipArrow />
        </TooltipContent>
      )}
    </Tooltip>
  );
};

export {
  CustomToolTip,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
};
