export const GET_ALL_SEQ = 'GET_ALL_SEQ';
export const GET_ACTIVE_SEQ = 'GET_ACTIVE_SEQ';
export const GET_ARCHIVED_SEQ = 'GET_ARCHIVED_SEQ';
export const GET_SEQ_WO = 'GET_SEQ_WO';
export const GET_SEQ_PROJECT = 'GET_SEQ_PROJECT';

export const CREATE_SEQ = 'CREATE_SEQ';
export const UPDATE_SEQ = 'UPDATE_SEQ';
export const UPDATE_ALL_SEQ = 'UPDATE_ALL_SEQ';
export const UPDATE_ACTIVE_SEQ = 'UPDATE_ACTIVE_SEQ';
export const UPDATE_ARCHIVED_SEQ = 'UPDATE_ARCHIVED_SEQ';

export const ARCHIVE_SEQ = 'ARCHIVE_SEQ';
export const RESTORE_SEQ = 'RESTORE_SEQ';
export const SEQ_API_ERROR = 'SEQ_API_ERROR';

export const GET_ALL_PENDING = 'GET_ALL_PENDING';
export const GET_ACTIVE_PENDING = 'GET_ACTIVE_PENDING';
export const GET_ARCHIVED_PENDING = 'GET_ARCHIVED_PENDING';
export const CREATE_PENDING = 'CREATE_PENDING';
export const UPDATE_PENDING = 'UPDATE_PENDING';
export const UPDATE_ALL_PENDING = 'UPDATE_ALL_PENDING';
export const UPDATE_ACTIVE_PENDING = 'UPDATE_ACTIVE_PENDING';
export const UPDATE_ARCHIVED_PENDING = 'UPDATE_ARCHIVED_PENDING';
export const ARCHIVE_PENDING = 'ARCHIVE_PENDING';
export const GET_SEQ_WO_PENDING = 'GET_SEQ_WO_PENDING';
export const GET_SEQ_PROJECT_PENDING = 'GET_SEQ_PROJECT_PENDING';
export const RESTORE_PENDING = 'RESTORE_PENDING';
