import dayjs from 'dayjs';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import UtcPlugin from 'dayjs/plugin/utc';
import { DAY_IN_MS, ONE_DAY, ONE_HOUR, ONE_MIN } from '../constants';
import DayjsUtils from '@date-io/dayjs';
import {
  convertOvertimeMinutesToHours,
  getOvertimeInFormat,
} from './Common.util';

dayjs.extend(relativeTimePlugin);
dayjs.extend(UtcPlugin);

type DateType = string | number | Date | dayjs.Dayjs | undefined;
type TimestampOrDateInputType = number | string | Date;

export const DateUtils = DayjsUtils;

export const formatDate = (dateMS: DateType, formatString = 'DD MMM, YYYY') => {
  if (typeof dateMS === 'number') {
    return dateMS > 0 ? dayjs(dateMS).format(formatString) : '';
  } else {
    return dayjs(dateMS).isValid() ? dayjs(dateMS).format(formatString) : '';
  }
};

export const resetHours = (date: DateType) => {
  const md = dayjs(date);
  md.startOf('day');
  return md.valueOf();
};

export const convertMomentToGMT = (date: DateType) => {
  return (
    dayjs(date).startOf('day').valueOf() -
    new Date().getTimezoneOffset() * 60 * 1000
  );
};

export const convertExactMomentToGMT = (date: DateType) => {
  return dayjs(date).valueOf() - new Date().getTimezoneOffset() * 60 * 1000;
};

export const getTimeZone = () => {
  const timeZone = /\((.*)\)/.exec(new Date().toString())[1].split(' ');
  let shortTimeZone = '';
  for (const zone of timeZone) shortTimeZone = shortTimeZone.concat(zone[0]);

  return shortTimeZone;
};

export const convertToUserTimezone = (date: DateType, resetTime = false) => {
  if (date === 0) return date;

  let md: any = dayjs(date);
  if (resetTime) md = resetHours(md);

  return md.valueOf() - new Date().getTimezoneOffset() * 60 * 1000;
};

export const diffInDays = (
  dateA: DateType,
  dateB: DateType,
  inclusive = false
) => {
  let difference = 0;
  const ma = dayjs(dateA);
  const mb = dayjs(dateB);
  if (dateA > dateB) difference = ma.diff(mb, 'days');
  else difference = mb.diff(ma, 'days');
  if (inclusive) ++difference;

  return difference;
};

export const addDays = (date: number, days: any) => {
  return date + ONE_DAY * Math.ceil(days);
};

export const subtractDays = (date: number, days: any) => {
  return date - ONE_DAY * Math.ceil(days);
};

export const sameFullYear = (...datesArray: DateType[]) => {
  const currentyear = dayjs().year();

  for (const element of datesArray) {
    if (dayjs(element).year() !== currentyear) {
      return false;
    }
  }

  return true;
};

export const getTodayGMT = () => {
  const date = dayjs(Date.now());
  const val = date.utc().startOf('day').valueOf();
  return val;
};

export const getTodayTimezoneGMT = () => {
  const date = dayjs(Date.now());
  return (
    dayjs(date).startOf('day').valueOf() -
    new Date().getTimezoneOffset() * 60 * 1000
  );
};

export const getTodayTimezone = (resetTime = false) => {
  let date = convertToUserTimezone(new Date(Date.now()));
  if (resetTime) {
    date = resetHours(date);
  }
  return date;
};

export const getTime = (dateStr: DateType, formatString = 'DD MMM, YYYY') => {
  return dayjs(dateStr, formatString).valueOf();
};

export const getTimeFromDate = (date: DateType) => {
  return dayjs(date).valueOf();
};

export const isValid = (dateStr: DateType, formatString = 'DD MMM, YYYY') => {
  return dayjs(dateStr, formatString).isValid();
};

export const compareDate = (dateA = getTodayGMT(), dateB = getTodayGMT()) => {
  dateA = new Date(dateA).getDate();
  dateB = new Date(dateB).getDate();

  return dateA - dateB;
};

export const fromNow = (date: DateType, removeSuffix = false) => {
  return dayjs(date).fromNow(removeSuffix);
};

export function getStartDateEpoch(date: TimestampOrDateInputType = 0) {
  if (!date) date = Date.now();

  const d = new Date(date);
  return d.setHours(0, 0, 0, 0);
}

export function getEndDateEpoch(date: TimestampOrDateInputType = 0) {
  if (!date) date = Date.now();

  const d = new Date(date);
  return d.setHours(23, 59, 59, 999);
}

export function getStartAndEndDateEpoch(date: number | string | Date = 0) {
  return [getStartDateEpoch(date), getEndDateEpoch(date)];
}

export function getTimestampOfPreviousSunday() {
  return dayjs().startOf('week').valueOf();
}

export function getEpochToday() {
  const startOfDay = dayjs().startOf('day').valueOf();
  const endOfDay = dayjs().endOf('day').valueOf();

  return { start: startOfDay, end: endOfDay };
}

export function getSevenDaysBeforeEpoch(originalEpoch) {
  const SEVEN_DAYS = 7;

  const startDate = getStartDateEpoch(originalEpoch);
  const sevenDaysBeforeDate = subtractDays(startDate, SEVEN_DAYS);

  return sevenDaysBeforeDate;
}

export function getSevenDaysAfterEpoch(originalEpoch) {
  const SEVEN_DAYS = 7;

  const startDate = getStartDateEpoch(originalEpoch);
  const sevenDaysAfterDate = addDays(startDate, SEVEN_DAYS);

  return sevenDaysAfterDate;
}

export function currWeekStartDay(date: number) {
  const startDate = getTodayGMT();
  const SEVEN_DAYS = 7;

  let currDate = date;

  let currStartDate = date,
    currEndDate = addDays(date, SEVEN_DAYS) - 1;
  if (currDate <= startDate) {
    while (currDate <= startDate) {
      if (
        startDate >= currDate &&
        startDate < currDate + ONE_DAY * Math.ceil(SEVEN_DAYS)
      ) {
        currStartDate = currDate;
        currEndDate = currDate + ONE_DAY * Math.ceil(SEVEN_DAYS) - 1;
      }

      currDate = addDays(currDate, SEVEN_DAYS);
    }
  } else {
    while (currDate > startDate) {
      currDate = subtractDays(currDate, SEVEN_DAYS);
      if (
        startDate >= currDate &&
        startDate < currDate + ONE_DAY * Math.ceil(SEVEN_DAYS)
      ) {
        currStartDate = currDate;
        currEndDate = currDate + ONE_DAY * Math.ceil(SEVEN_DAYS) - 1;
      }
    }
  }

  return {
    startDate: currStartDate,
    endDate: currEndDate,
  };
}

export const formatDateGmt = (
  dateMS: number,
  formatString = 'DD MMM, YYYY'
) => {
  const offset = Number(new Date().getTimezoneOffset() * 60 * 1000);
  const temp = dateMS + offset;
  if (typeof temp === 'number') {
    return temp > 0 ? dayjs(temp).format(formatString) : '';
  }
};

export const getGmtEndDate = (dateMS: number) => {
  return (
    getEndDateEpoch(dateMS) - Number(new Date().getTimezoneOffset() * 60 * 1000)
  );
};

export const getGmtStartDate = (dateMS: number) => {
  return (
    getStartDateEpoch(dateMS) -
    Number(new Date().getTimezoneOffset() * 60 * 1000)
  );
};

export const convertGMTtoLocalTime = (dateMS: number): number => {
  return (
    Number(new Date(dateMS)) +
    Number(new Date().getTimezoneOffset() * 60 * 1000)
  );
};

export const areEpochsSameDay = (epoch1, epoch2) => {
  const date1 = new Date(epoch1);
  const date2 = new Date(epoch2);

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const getElapsedTimeString = date => {
  const dateMS = Number(new Date()) - Number(new Date(date));
  const days = dateMS / ONE_DAY;
  const hours = (dateMS % ONE_DAY) / ONE_HOUR;
  const mins = (dateMS % ONE_HOUR) / ONE_MIN;

  if (Math.round(days) > 0) {
    return `${Math.round(days)} day`;
  } else if (Math.round(hours) > 0) {
    return `${Math.round(hours)} hour`;
  } else if (Math.round(mins) > 0) {
    return `${Math.round(mins)} minute`;
  } else {
    return 'few min';
  }
};

export const isDefaultDateRange = obj => {
  const { create_date, ...rest } = obj;
  return (
    !Object.keys(rest).length &&
    create_date?.start === getStartDateEpoch(Date.now() - DAY_IN_MS * 30) &&
    create_date?.end === getEndDateEpoch(Date.now())
  );
};

export const getHoursFromMinute = obj => {
  if (!obj || typeof obj.minutes !== 'number' || obj.minutes < 0) {
    return 0;
  }
  return obj.minutes / 60;
};

export const getHoursAndMinutesFromMinutes = obj => {
  if (!obj || typeof obj.minutes !== 'number' || obj.minutes < 0) {
    return '-';
  }
  return getOvertimeInFormat(convertOvertimeMinutesToHours(obj.minutes));
};

export const getMinutesFromHrs = obj => {
  if (!obj || typeof obj.hrs !== 'number' || obj.hrs < 0) {
    return 0;
  }
  return obj.hrs * 60;
};

export const convertToEpochMillis = dateRange => {
  const fromMillis = new Date(dateRange.from).getTime();
  const toMillis = new Date(dateRange.to).getTime();
  return { from: fromMillis, to: toMillis };
};

export const convertSOD = x => {
  const date = new Date(x);
  date.setUTCHours(0, 0, 0, 0);
  x = date.getTime();
  return x;
};

export const convertEOD = x => {
  const date = new Date(x);
  date.setUTCHours(23, 59, 59, 999);
  x = date.getTime();
  return x;
};
