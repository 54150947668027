import { ReducerProps } from '@/interfaces/Base';
import { GET_VENDOR_CUSTOM_INFO } from './CustomInfo.type';

const initialState = {
  vendor_custom_info: [],
};

const actionHandlers = {
  [GET_VENDOR_CUSTOM_INFO]: (state: any, payload: any) => ({
    ...state,
    vendor_custom_info: payload,
  }),
};

export default function customInfoReducer(
  state = initialState,
  { type, payload }: ReducerProps
) {
  const handler = actionHandlers[type];
  return handler ? handler(state, payload) : state;
}
