export const PO_TYPES = {
  NORMAL: 0,
  EXTERNAL: 1,
  SIMPLE: 2,
  PRO_PO: 3,
} as const;

export const PO_SEQUENCE_TYPES = {
  AUTO: 0,
  MANUAL: 1,
} as const;

export const GRN_PAYMENT_STATUS = {
  UNPAID: 0,
  PARTIALLY_PAID: 1,
  FULLY_PAID: 2,
} as const;

export const INDENT_TYPES = {
  BOM_LIST: 0,
  PHOTO: 1,
};

export const INDENT_STATUS = {
  NOT_CONVERTED_TO_PO: 0,
  PARIALLY_CONVERTED_TO_PO: 1,
  CONVERTED_TO_PO: 2,
};

export const GRN_TYPES = {
  MATERIAL: 0,
  PHOTO: 1,
};

export const OPERATION_TYPE = {
  ADDITION: 0,
  REMOVAL: 1,
};

export const APPROVER_MODES = {
  MULTIPLE: 0,
  SINGLE: 1,
} as const;

export const EXPENSE_TYPES = {
  PETTY_CASH: 0,
  TRANSER: 1,
  GRN_PHOTO: 2,
  GRN_INVENTORY: 3,
  ATTENDANCE_LABOUR: 4,
  ATTENDANCE_VENDOR: 5,
  PAYABLE_PAYMENT: 6,
  ADVANCE: 7,
} as const;

export const EXPENSE_STATUS = {
  DRAFT: 0,
  APPROVAL_PENDING: 1,
  REJECTED: 2,
  TRANSFERRED: 3,
} as const;

export const PO_SEQ_TYPES = {
  EXTERNAL: 'external',
  AUTOMATIC: 'auto',
};

export const ENTITY_TYPE = Object.freeze({
  PO: 0,
  WO: 1,
});

export const PAYABLE_STATUS = {
  PENDING: 0,
  PARTIALLY_RECORDED: 1,
  RECORDED: 2,
};
