import {
  SET_BOQ_LOADING,
  SET_BOQ_LOG_LOADING,
  SET_BOQ_LOGS,
  SET_COLLAPSED_BOQ_IDS,
  SET_SELECTED_BOQ,
  SET_SELECTED_BOQ_LOG,
  SET_CONSOLIDATED_SUMMARY,
  SET_BOQ_SUMMARY_LOADING,
  SET_BOQ_UPDATE_PENDING,
} from './Boq.type';

const initialState = {
  selectedBoq: null,
  selectedBoqLog: null,
  isBoqLoading: false,
  isBoqLogLoading: false,
  isBoqSummaryLoading: false,
  isBoqUpdatePending: false,
  boqLogs: [],
  collapsedBoqIds: new Set(),
  consolidatedSummary: {
    _id: null,
    boq_count: null,
    achieved_budget_amount: null,
    total_budget_amount: null,
    achieved_boq_amount: null,
    total_boq_amount: null,
    total_received: null,
    receivable_count: null,
  },
};

export default function boqReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_SELECTED_BOQ:
      return {
        ...state,
        selectedBoq: payload,
      };

    case SET_SELECTED_BOQ_LOG:
      return {
        ...state,
        selectedBoqLog: payload,
      };

    case SET_BOQ_LOADING:
      return {
        ...state,
        isBoqLoading: payload,
      };

    case SET_BOQ_LOG_LOADING:
      return {
        ...state,
        isBoqLogLoading: payload,
      };

    case SET_BOQ_LOGS:
      return {
        ...state,
        boqLogs: payload,
      };

    case SET_COLLAPSED_BOQ_IDS:
      return {
        ...state,
        collapsedBoqIds: new Set(payload),
      };

    case SET_CONSOLIDATED_SUMMARY:
      return {
        ...state,
        consolidatedSummary: payload,
      };

    case SET_BOQ_SUMMARY_LOADING:
      return {
        ...state,
        isBoqSummaryLoading: payload,
      };

    case SET_BOQ_UPDATE_PENDING:
      return {
        ...state,
        isBoqUpdatePending: payload,
      };

    default:
      return state;
  }
}
