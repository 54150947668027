import React from 'react';
import { Navigate } from 'react-router-dom';

import { RESOURCE_ID_MAP, TEAM_MODULES } from '../constant/module_constants';
import { parseString } from '../utils';
import { ORG_PATHS, PROJECT_PATHS } from '../paths.constant';
import lazyRetry from './helpers/LazyRetry.helper';

const PremiumOnboarding = React.lazy(() =>
  lazyRetry(
    () => import('../components/auth/PremiumOnboarding'),
    'PremiumOnboarding'
  )
);

const SampleDocuments = React.lazy(() =>
  lazyRetry(
    () => import('../components/organization/orgProfile/SampleDocuments'),
    'SampleDocuments'
  )
);
const AutoDPRView = React.lazy(() =>
  lazyRetry(
    () => import('../components/organization/orgProfile/AutoDPRView'),
    'AutoDPRView'
  )
);
const OnboardingDetailsView = React.lazy(() =>
  lazyRetry(
    () => import('../components/organization/orgProfile/OnboardingDetailsView'),
    'OnboardingDetailsView'
  )
);
const OrgNewAttendance = React.lazy(() =>
  lazyRetry(
    () => import('../views/org-new-attendance/OrgAttendance'),
    'OrgNewAttendance'
  )
);
const SitePlan = React.lazy(() =>
  lazyRetry(() => import('../views/projectPlan/SitePlan'), 'SitePlan')
);
const OrganisationCommercial = React.lazy(() =>
  lazyRetry(
    () => import('../views/commercial/OrganisationCommercial'),
    'OrganisationCommercial'
  )
);
const Drive = React.lazy(() =>
  lazyRetry(() => import('../views/drive/Drive'), 'Drive')
);
const Commercial = React.lazy(() =>
  lazyRetry(() => import('../views/commercial/Commercial'), 'Commercial')
);
const NewAttendance = React.lazy(() =>
  lazyRetry(
    () => import('../views/new-attendance/NewAttendance'),
    'NewAttendance'
  )
);
const NewReport = React.lazy(() =>
  lazyRetry(
    () => import('../views/asyncReport/ProjectAsyncReport'),
    'NewReport'
  )
);
const ScPayabals = React.lazy(() =>
  lazyRetry(() => import('../views/scPaybals/scPaybals'), 'ScPayabals')
);
const BroadPlan = React.lazy(() =>
  lazyRetry(() => import('../views/BroadPlan/SitePlanGC'), 'BroadPlan')
);
const ProjectProfileView = React.lazy(() =>
  lazyRetry(
    () => import('../views/settings/ProjectSettings'),
    'ProjectProfileView'
  )
);

const CustomInfoList = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        '../modules/vendor/components/vendor-form/vendor-create/CustomInfoCards'
      ),
    'CustomInfoList'
  )
);

const OrgAsyncReportView = React.lazy(() =>
  lazyRetry(
    () => import('../views/asyncReport/OrgAsyncReport'),
    'OrgAsyncReportView'
  )
);
const GrnProfile = React.lazy(() =>
  lazyRetry(() => import('../views/grn/GrnProfileDialog'), 'GrnProfile')
);
const VendorMVPView = React.lazy(() =>
  lazyRetry(() => import('../components/mvp'), 'VendorMVPView')
);
const Projects = React.lazy(() =>
  lazyRetry(() => import('../views/projects/Projects'), 'Projects')
);
const Organization = React.lazy(() =>
  lazyRetry(() => import('../views/Orgainzation/Organization'), 'Organization')
);
const UserRoles = React.lazy(() =>
  lazyRetry(() => import('../views/Orgainzation/UserRoles'), 'UserRoles')
);
const TeamPage = React.lazy(() =>
  lazyRetry(
    () => import('../components/organization/RoleAndPermissions/TeamPage'),
    'TeamPage'
  )
);
const Reports = React.lazy(() =>
  lazyRetry(() => import('../views/reports/Reports'), 'Reports')
);
const Threads = React.lazy(() =>
  lazyRetry(() => import('../views/Threads/Issue'), 'Threads')
);
const ActivityView = React.lazy(() =>
  lazyRetry(() => import('../views/activity/Activity'), 'ActivityView')
);
const Dashboard = React.lazy(() =>
  lazyRetry(() => import('../views/dashboard/Dashboard'), 'Dashboard')
);
const Members = React.lazy(() =>
  lazyRetry(() => import('../views/Orgainzation/Members'), 'Members')
);
const PurchaseOrderForm = React.lazy(() =>
  lazyRetry(
    () => import('../views/commercial/PurchaseOrderForm'),
    'PurchaseOrderForm'
  )
);
const CreateAndEditIndentForm = React.lazy(() =>
  lazyRetry(
    () => import('../views/commercial/CreateAndEditIndentForm'),
    'CreateAndEditIndentForm'
  )
);
const GroupedMaterialForm = React.lazy(() =>
  lazyRetry(
    () => import('../views/commercial/GroupedMaterialForm'),
    'GroupedMaterialForm'
  )
);
const ExternalPOForm = React.lazy(() =>
  lazyRetry(
    () => import('../views/commercial/ExternalPOForm'),
    'ExternalPOForm'
  )
);

const ProPOForm = React.lazy(() =>
  lazyRetry(() => import('../views/commercial/ProPOForm'), 'ProPOForm')
);

const BoQ = React.lazy(() =>
  lazyRetry(() => import('../modules/boq/views/BoQ'), 'BoQ')
);

const WO = React.lazy(() =>
  lazyRetry(() => import('../modules/work-order/views/WorkOrderView'), 'WO')
);
const WOCreationForm = React.lazy(() =>
  lazyRetry(() => import('../modules/work-order/CreateWO'), 'WO')
);

const Receivables = React.lazy(() =>
  lazyRetry(() => import('../modules/boq/views/Receivables'), 'Receiveables')
);

const PurchaseOrder = React.lazy(() =>
  lazyRetry(
    () => import('../views/purchaseOrder/PurchaseOrders'),
    'PurchaseOrder'
  )
);
const OwnerDPR = React.lazy(() =>
  lazyRetry(() => import('../views/ProjectReport'), 'OwnerDPR')
);
const Onboarding = React.lazy(() =>
  lazyRetry(() => import('../views/Onboarding/Onboarding'), 'Onboarding')
);
const NewOnboarding = React.lazy(() =>
  lazyRetry(
    () => import('../views/NewOnboarding/OnboardingV2'),
    'NewOnboarding'
  )
);
const VerifyEmail = React.lazy(() =>
  lazyRetry(() => import('../components/auth/VerifyEmail'), 'VerifyEmail')
);
const Login = React.lazy(() =>
  lazyRetry(() => import('../views/auth/Login'), 'Login')
);
const VendorFormByLink = React.lazy(() =>
  lazyRetry(
    () => import('../components/vendor/vendorFormByLink'),
    'VendorFormByLink'
  )
);
const ShareableImage = React.lazy(() =>
  lazyRetry(() => import('../views/sharedImage'), 'ShareableImage')
);
const OrgDashboard = React.lazy(() =>
  lazyRetry(
    () => import('../views/organisationDashboard/OrgDashboard'),
    'OrgDashboard'
  )
);
const OrgSettings = React.lazy(() =>
  lazyRetry(
    () => import('../views/organisationSettings/OrgSettings'),
    'OrgSettings'
  )
);
const OrgAddressDetails = React.lazy(() =>
  lazyRetry(
    () => import('../components/organization/orgProfile/OrgAddressDetails'),
    'OrgAddressDetails'
  )
);
const ApprovalSettingsView = React.lazy(() =>
  lazyRetry(
    () => import('../components/organization/orgProfile/ApprovalSettingsView'),
    'ApprovalSettingsView'
  )
);
const OrgMaterialTags = React.lazy(() =>
  lazyRetry(
    () => import('../components/organization/orgProfile/OrgMaterialTags'),
    'OrgMaterialTags'
  )
);
const OrgWc = React.lazy(() =>
  lazyRetry(
    () => import('../components/organization/orgProfile/OrgWorkCategory'),
    'OrgWc'
  )
);
const OrgStages = React.lazy(() =>
  lazyRetry(
    () => import('../components/organization/orgProfile/OrgStages'),
    'OrgStages'
  )
);
const TaskMasterChecklist = React.lazy(() =>
  lazyRetry(
    () => import('../components/organization/orgProfile/TaskMasterChecklist'),
    'TaskMasterChecklist'
  )
);
const Attendance = React.lazy(() =>
  lazyRetry(() => import('../views/org-attendance/Attendance'), 'Attendance')
);
const AnalyticsDashboard = React.lazy(() =>
  lazyRetry(
    () => import('../views/analyticsDashboard/AnalyticsDashboard'),
    'AnalyticsDashboard'
  )
);
const AnalyticsDashboardView = React.lazy(() =>
  lazyRetry(
    () => import('../components/analyticsDashboard/Dashboard'),
    'AnalyticsDashboardView'
  )
);
const mergeLabours = React.lazy(() =>
  lazyRetry(
    () => import('../components/org-new-attendance/mergeLabour'),
    'mergeLabours'
  )
);
const mergeVendor = React.lazy(() =>
  lazyRetry(
    () => import('../components/org-new-attendance/mergeVendor'),
    'mergeVendor'
  )
);
const mergeVendorLabours = React.lazy(() =>
  lazyRetry(
    () => import('../components/org-new-attendance/mergeVendorLabours'),
    'mergeVendorLabours'
  )
);
const MaterialIssueForm = React.lazy(() =>
  lazyRetry(
    () => import('../views/commercial/material-issue/MaterialIssueForm'),
    'MaterialIssueForm'
  )
);
const SiteTransferForm = React.lazy(() =>
  lazyRetry(
    () => import('../views/commercial/site-transfer/SiteTransferForm'),
    'SiteTransferForm'
  )
);
const VendorCreateForm = React.lazy(() =>
  lazyRetry(
    () => import('../modules/vendor/views/VendorCreateForm.view'),
    'VendorCreateForm'
  )
);
const VendorEditForm = React.lazy(() =>
  lazyRetry(
    () => import('../modules/vendor/views/VendorEditForm.view'),
    'VendorEditForm'
  )
);

const CreateGrnForm = React.lazy(() =>
  lazyRetry(
    () => import('../modules/grn/views/GrnCreateForm.view'),
    'CreateGrnFormView'
  )
);

const EditGrnForm = React.lazy(() =>
  lazyRetry(
    () => import('../modules/grn/views/GrnEditForm.view'),
    'EditGrnFormView'
  )
);

const Superset = React.lazy(() =>
  lazyRetry(() => import('../modules/superset/views/Superset.view'), 'Superset')
);

const Payables = React.lazy(() =>
  lazyRetry(
    () => import('../modules/payables/views/OrgPayables.view'),
    'Payables'
  )
);

const PayableForm = React.lazy(() =>
  lazyRetry(
    () => import('../modules/payables/views/PayableForm.view'),
    'PayableForm'
  )
);
const ProjectLabourProfile = React.lazy(() =>
  lazyRetry(
    () =>
      import('../modules/attendance-profiles/views/LabourProjectProfile.view'),
    'LabourProjectProfileView'
  )
);

const OrgLabourProfile = React.lazy(() =>
  lazyRetry(
    () => import('../modules/attendance-profiles/views/LabourOrgProfile.view'),
    'LabourOrgProfileView'
  )
);
const ProjectVendorProfile = React.lazy(() =>
  lazyRetry(
    () =>
      import('../modules/attendance-profiles/views/VendorProjectProfile.view'),
    'VendorProjectProfile'
  )
);

const OrgVendorProfile = React.lazy(() =>
  lazyRetry(
    () => import('../modules/attendance-profiles/views/VendorOrgProfile.view'),
    'VendorOrgProfile'
  )
);

const AdvanceHistory = React.lazy(() =>
  lazyRetry(
    () => import('../modules/payments/views/AdvanceHistory.view'),
    'AdvanceHistory'
  )
);

export const ORG_ROUTES = [
  {
    path: ORG_PATHS.WORK_ORDER,
    component: WO,
    id: TEAM_MODULES.WORK_ORDER.resource_id,
  },
  {
    path: ORG_PATHS.VENDOR,
    component: ScPayabals,
    id: TEAM_MODULES.VENDOR.resource_id,
  },
  {
    path: ORG_PATHS.CUSTOM_INFO_LIST,
    component: CustomInfoList,
    id: undefined,
  },
  {
    path: ORG_PATHS.ORG_PROFILE,
    component: Organization,
    id: TEAM_MODULES.ORGANISATION_PROFILE.resource_id,
  },
  {
    path: ORG_PATHS.ORG_ADDRESS_DETAILS,
    component: OrgAddressDetails,
    id: TEAM_MODULES.ORGANISATION_PROFILE.resource_id,
  },
  {
    path: ORG_PATHS.ORG_WC,
    component: OrgWc,
    id: TEAM_MODULES.ORGANISATION_PROFILE.resource_id,
  },
  {
    path: ORG_PATHS.ORG_MATERIAL_CATEGORIES,
    component: OrgMaterialTags,
    id: TEAM_MODULES.ORGANISATION_PROFILE.resource_id,
  },
  {
    path: ORG_PATHS.SAMPLE_DOCS,
    component: SampleDocuments,
    id: TEAM_MODULES.ORGANISATION_PROFILE.resource_id,
  },
  {
    path: ORG_PATHS.ORG_PROJECT_STAGES,
    component: OrgStages,
    id: TEAM_MODULES.ORGANISATION_PROFILE.resource_id,
  },
  {
    path: ORG_PATHS.ORG_TASK_CHECKLIST,
    component: TaskMasterChecklist,
    id: TEAM_MODULES.ORGANISATION_PROFILE.resource_id,
  },
  {
    path: ORG_PATHS.PROJECT_LIST,
    component: Projects,
    id: undefined,
  },
  {
    path: parseString(ORG_PATHS.MEMBERS, '/*?'),
    component: Members,
    id: TEAM_MODULES.ORGANISATION_MEMBERS.resource_id,
  },
  {
    path: ORG_PATHS.TEAMS,
    component: UserRoles,
    id: TEAM_MODULES.ORGANISATION_ROLES_AND_PERMISSIONS.resource_id,
  },
  {
    path: parseString(ORG_PATHS.TEAM_PROFILE, ':id'),
    component: TeamPage,
    id: undefined,
  },
  {
    path: ORG_PATHS.PURCHASE_ORDER,
    component: PurchaseOrder,
    id: TEAM_MODULES.PURCHASE_ORDERS.resource_id,
  },
  {
    path: ORG_PATHS.ORG_DASHBOARD,
    component: OrgDashboard,
    id: undefined,
  },
  {
    path: parseString(ORG_PATHS.ORGANISATION_SETTINGS),
    component: OrgSettings,
    id: undefined,
  },
  {
    path: parseString(ORG_PATHS.ORG_ATTENDANCE, 'tab'),
    component: OrgNewAttendance,
    useRender: true,
    id: undefined,
  },
  {
    path: parseString(ORG_PATHS.COMMERCIAL, '/*?'),
    component: OrganisationCommercial,
    id: undefined,
  },
  {
    path: parseString(ORG_PATHS.APPROVAL_SETTINGS, ':id'),
    component: ApprovalSettingsView,
    id: undefined,
  },
  {
    path: parseString(ORG_PATHS.AUTO_DPR, ':id'),
    component: AutoDPRView,
    id: undefined,
  },
  {
    path: parseString(ORG_PATHS.ONBOARDING_DETAILS, ':id'),
    component: OnboardingDetailsView,
    id: undefined,
  },
  {
    path: parseString(ORG_PATHS.GRN_PROFILE, ':id'),
    component: GrnProfile,
    id: undefined,
  },
  {
    path: parseString(ORG_PATHS.VENDOR_PROFILE, ':id'),
    component: VendorMVPView,
    id: undefined,
  },
  {
    path: parseString(ORG_PATHS.VENDOR_COMMERCIAL_PROFILE, ':id'),
    component: VendorMVPView,
    id: undefined,
  },
  {
    path: parseString(ORG_PATHS.ATTENDANCE, ':type'),
    component: Attendance,
    id: undefined,
  },
  {
    path: parseString(ORG_PATHS.MERGE_LABOUR, ':type'),
    component: mergeLabours,
    id: undefined,
  },
  {
    path: parseString(ORG_PATHS.MERGE_VENDOR, ':type'),
    component: mergeVendor,
    id: undefined,
  },
  {
    path: parseString(ORG_PATHS.MERGE_VENDOR_LABOURS, ':type'),
    component: mergeVendorLabours,
    id: undefined,
  },
  {
    path: ORG_PATHS.ANALYTICS_DASHBOARD,
    component: AnalyticsDashboard,
    exact: true,
    id: undefined,
  },
  {
    path: parseString(ORG_PATHS.ANALYTICS_DASHBOARD_VIEW, ':id?'),
    component: AnalyticsDashboardView,
    UserRender: false,
    id: undefined,
  },
  {
    path: parseString(ORG_PATHS.GROUDPED_MATERIAL_FORM_NEW, ':projId'),
    component: GroupedMaterialForm,
    id: undefined,
  },
  {
    path: parseString(ORG_PATHS.GROUPED_MATERIAL_FORM_EDIT, ':groupMaterialId'),
    component: GroupedMaterialForm,
    id: undefined,
  },
  {
    path: ORG_PATHS.SUPERSET_DASHBOARD,
    component: Superset,
    id: undefined,
  },
  {
    path: ORG_PATHS.VENDOR_CREATE_FORM,
    component: VendorCreateForm,
    id: TEAM_MODULES.VENDOR.resource_id,
  },
  {
    path: parseString(ORG_PATHS.VENDOR_EDIT_FORM, ':mode', ':vendorId'),
    component: VendorEditForm,
    id: TEAM_MODULES.VENDOR.resource_id,
  },
  {
    path: ORG_PATHS.ORG_ASYNC_REPORT,
    component: OrgAsyncReportView,
    id: undefined,
  },
  {
    path: parseString(PROJECT_PATHS.GRN_NEW_FORM, ':projId'),
    component: CreateGrnForm,
    id: RESOURCE_ID_MAP.GRN,
  },
  {
    path: parseString(PROJECT_PATHS.GRN_EDIT_FORM, ':projId', ':grnId'),
    component: EditGrnForm,
    id: RESOURCE_ID_MAP.GRN,
  },
  {
    path: parseString(ORG_PATHS.PAYABLES, ':tab'),
    component: Payables,
    id: undefined,
  },
  {
    path: parseString(
      ORG_PATHS.VENDOR_PROFILE_NEW,
      ':organizationId',
      ':vendorId'
    ),
    component: OrgVendorProfile,
    id: undefined,
  },
  {
    path: parseString(ORG_PATHS.LABOUR_PROFILE, ':organizationId', ':labourId'),
    component: OrgLabourProfile,
    id: undefined,
  },
  {
    path: parseString(
      ORG_PATHS.ADVANCE_HISTORY,
      ':organizationId',
      ':itemType',
      ':itemId'
    ),
    component: AdvanceHistory,
    id: undefined,
  },
  {
    path: ORG_PATHS.WORK_ORDER_NEW,
    component: WOCreationForm,
    id: undefined,
  },
  {
    path: parseString(ORG_PATHS.EDIT_WORK_ORDER, ':woId'),
    component: WOCreationForm,
    id: undefined,
  },
];
export const ORG_ROUTES_PATHS = ORG_ROUTES.map(r => r.path);

export const PROJECT_ROUTES = [
  {
    path: parseString(PROJECT_PATHS.DASHBOARD, ':projId'),
    component: Dashboard,
    id: TEAM_MODULES.PROJECT_DASHBOARD.resource_id,
  },
  {
    path: parseString(PROJECT_PATHS.SITE_PLAN_GC, ':projId', '*'),
    component: BroadPlan,
    id: TEAM_MODULES.TASKS.resource_id,
  },
  {
    path: parseString(PROJECT_PATHS.SITE_PLAN, ':projId', '*'),
    component: SitePlan,
    id: TEAM_MODULES.TASKS.resource_id,
  },
  {
    path: parseString(PROJECT_PATHS.NEW_PURCHASE_ORDER_FORM, ':projId'),
    component: PurchaseOrderForm,
    id: TEAM_MODULES.PURCHASE_ORDERS.resource_id,
  },
  {
    path: parseString(PROJECT_PATHS.NEW_INDENT_FORM, ':projId'),
    component: CreateAndEditIndentForm,
    id: TEAM_MODULES.INDENT.resource_id,
  },
  {
    path: parseString(PROJECT_PATHS.MATERIAL_ISSUE_NEW_FORM, ':projId'),
    component: MaterialIssueForm,
    id: TEAM_MODULES.MATERIAL_ISSUES.resource_id,
  },
  {
    path: parseString(
      PROJECT_PATHS.SITE_TRANSFER_NEW_FORM,
      ':sendingProjId',
      ':receivingProjId'
    ),
    component: SiteTransferForm,
    id: TEAM_MODULES.SITE_TRANSFERS.resource_id,
  },
  {
    path: parseString(
      PROJECT_PATHS.EDIT_MATERIAL_ISSUE_FORM,
      ':projId',
      ':miId'
    ),
    component: MaterialIssueForm,
    id: TEAM_MODULES.MATERIAL_ISSUES.resource_id,
  },
  {
    path: parseString(
      PROJECT_PATHS.EDIT_SITE_TRANSFER_FORM,
      ':sendingProjId',
      ':receivingProjId',
      ':stId'
    ),
    component: SiteTransferForm,
    id: TEAM_MODULES.SITE_TRANSFERS.resource_id,
  },
  {
    path: parseString(PROJECT_PATHS.NEW_EXTERNAL_PO_FORM, ':projId'),
    component: ExternalPOForm,
    id: TEAM_MODULES.PURCHASE_ORDERS.resource_id,
  },
  {
    path: parseString(PROJECT_PATHS.NEW_PRO_PO_FORM, ':projId'),
    component: ProPOForm,
    id: TEAM_MODULES.PURCHASE_ORDERS.resource_id,
  },
  {
    path: parseString(PROJECT_PATHS.ASYNC_REPORT, ':projId'),
    component: NewReport,
    id: undefined,
  },
  {
    path: parseString(
      PROJECT_PATHS.EDIT_PURCHASE_ORDER_FORM,
      ':projId',
      ':poId'
    ),
    component: PurchaseOrderForm,
    id: TEAM_MODULES.PURCHASE_ORDERS.resource_id,
  },
  {
    path: parseString(
      PROJECT_PATHS.PROJECT_LEVEL_WORK_ORDER,
      ':projId',
      ':vendorId'
    ),
    component: WOCreationForm,
    id: undefined,
  },
  {
    path: parseString(
      PROJECT_PATHS.PROJECT_LEVEL_WORK_ORDER,
      ':projId',
      ':vendorId'
    ),
    component: WOCreationForm,
    id: undefined,
  },
  {
    path: parseString(PROJECT_PATHS.EDIT_INDENT_FORM, ':projId', ':indentId'),
    component: CreateAndEditIndentForm,
    id: TEAM_MODULES.INDENT.resource_id,
  },
  {
    path: parseString(PROJECT_PATHS.EDIT_PRO_PO_FORM, ':projId', ':poId'),
    component: ProPOForm,
    id: TEAM_MODULES.PURCHASE_ORDERS.resource_id,
  },
  {
    path: parseString(PROJECT_PATHS.BUDGET, ':projId'),
    component: BoQ,
    id: TEAM_MODULES.BUDGET_AND_BILLING.resource_id,
  },
  {
    path: parseString(PROJECT_PATHS.RECEIVABLES, ':projId'),
    component: Receivables,
    id: TEAM_MODULES.RECEIVABLES.resource_id,
  },
  {
    path: parseString(PROJECT_PATHS.EDIT_EXTERNL_PO_FORM, ':projId', ':poId'),
    component: ExternalPOForm,
    id: TEAM_MODULES.PURCHASE_ORDERS.resource_id,
  },
  {
    path: parseString(PROJECT_PATHS.COMMERCIAL, ':projId', '/*'),
    component: Commercial,
    id: [
      TEAM_MODULES.PURCHASE_ORDERS.resource_id,
      TEAM_MODULES.INDENT.resource_id,
    ],
  },
  {
    path: parseString(PROJECT_PATHS.NEW_ATTENDANCE, ':projId'),
    component: NewAttendance,
    id: undefined,
  },
  {
    path: parseString(PROJECT_PATHS.PROJECT_PROFILE_VIEW, ':projId'),
    component: ProjectProfileView,
    id: TEAM_MODULES.PROJECT_PROFILE.resource_id,
  },
  {
    path: parseString(PROJECT_PATHS.REPORTS, ':projId'),
    component: Reports,
    id: undefined,
  },
  {
    path: parseString(PROJECT_PATHS.THREADS, ':projId'),
    component: Threads,
    id: TEAM_MODULES.THREADS.resource_id,
  },
  {
    path: parseString(PROJECT_PATHS.ACTIVITIES, ':projId'),
    component: ActivityView,
    id: TEAM_MODULES.ACTIVITIES.resource_id,
  },
  {
    path: parseString(PROJECT_PATHS.FOLDER, ':projId', 'folderId'),
    component: Drive,
    id: TEAM_MODULES.PROJECT_DRIVE.resource_id,
  },
  {
    path: parseString(PROJECT_PATHS.DRIVE, ':projId'),
    component: Drive,
    id: TEAM_MODULES.PROJECT_DRIVE.resource_id,
  },
  {
    path: parseString(PROJECT_PATHS.FALLBACK_DASHBOARD, ':projId'),
    component: Dashboard,
    id: TEAM_MODULES.PROJECT_DASHBOARD.resource_id,
  },
  {
    path: parseString(
      PROJECT_PATHS.PAYABLE_NEW_FORM,
      ':projId',
      ':mode',
      ':type'
    ),
    component: PayableForm,
    id: undefined,
  },
  {
    path: parseString(PROJECT_PATHS.LABOUR_PROFILE, ':projId', ':labourId'),
    component: ProjectLabourProfile,
    id: undefined,
  },
  {
    path: parseString(PROJECT_PATHS.VENDOR_PROFILE_NEW, ':projId', ':vendorId'),
    component: ProjectVendorProfile,
    id: undefined,
  },
  {
    path: parseString(
      PROJECT_PATHS.ADVANCE_HISTORY,
      ':projId',
      ':itemType',
      ':itemId'
    ),
    component: AdvanceHistory,
    id: undefined,
  },
];

export const PROJECT_ROUTE_PATHS = PROJECT_ROUTES.map(r => r.path);

export const UNPROTECTED_ROUTES = [
  {
    path: '/report/org/:orgId/project/:projectId',
    component: OwnerDPR,
    allowSmallScreen: true,
  },
  {
    path: '/onboarding',
    component: Onboarding,
  },
  {
    path: '/new-onboarding',
    component: NewOnboarding,
  },
  {
    path: '/user/verify-email',
    component: VerifyEmail,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/vrf/:id/org/:orgId/state/:state',
    component: VendorFormByLink,
  },
  {
    path: '/shareable-img',
    component: ShareableImage,
    allowSmallScreen: true,
  },
  {
    path: '/premium/onboard/:id',
    component: PremiumOnboarding,
    allowSmallScreen: true,
  },
  {
    path: '/invite-signup',
    component: () => <Navigate to="/projects" />,
  },
];
