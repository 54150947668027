export const ADD_ADVANCE_SUCCESS = 'ADD_ADVANCE_SUCCESS';
export const ADD_ADVANCE_ERROR = 'ADD_ADVANCE_ERROR';
export const ADD_ADVANCE_PENDING = 'ADD_ADVANCE_PENDING';

export const USE_ADVANCE_SUCCESS = 'USE_ADVANCE_SUCCESS';
export const USE_ADVANCE_ERROR = 'USE_ADVANCE_ERROR';
export const USE_ADVANCE_PENDING = 'USE_ADVANCE_PENDING';

export const GET_ADANCE_LEFT = 'GET_ADANCE_LEFT';

export const GET_LEFT_OVER_ADVANCE_DATA_FOR_PAYABLE =
  'GET_LEFT_OVER_ADVANCE_DATA_FOR_PAYABLE';
export const GET_LEFT_OVER_ADVANCE_DATA_FOR_ATTENDANCE_PAYABLE =
  'GET_LEFT_OVER_ADVANCE_DATA_FOR_ATTENDANCE_PAYABLE';

export const GET_ORG_PAYMENTS = 'GET_ORG_PAYMENTS';
export const GET_ORG_PAYMENTS_PENDING = 'GET_ORG_PAYMENTS_PENDING';
export const GET_ORG_PAYMENTS_ERROR = 'GET_ORG_PAYMENTS_ERROR';
export const GET_ORG_PAYMENTS_SUCCESS = 'GET_ORG_PAYMENTS_SUCCESS';
export const GET_ORG_PAYMENT_LIST_COUNT = 'GET_ORG_PAYMENT_LIST_COUNT';

export const GET_ORG_PAYMENT_AMOUNT = 'GET_ORG_PAYMENT_AMOUNT';
export const GET_ORG_PAYMENT_AMOUNT_PENDING = 'GET_ORG_PAYMENT_AMOUNT_PENDING';
export const GET_ORG_PAYMENT_AMOUNT_ERROR = 'GET_ORG_PAYMENT_AMOUNT_ERROR';
export const GET_ORG_PAYMENT_AMOUNT_SUCCESS = 'GET_ORG_PAYMENT_AMOUNT_SUCCESS';

export const GET_ORG_PAYMENTS_EXPENSE = 'GET_ORG_PAYMENTS_EXPENSE';
export const GET_ORG_PAYMENTS_EXPENSE_PENDING =
  'GET_ORG_PAYMENTS_EXPENSE_PENDING';
export const GET_ORG_PAYMENTS_EXPENSE_ERROR = 'GET_ORG_PAYMENTS_EXPENSE_ERROR';
export const GET_ORG_PAYMENTS_EXPENSE_SUCCESS =
  'GET_ORG_PAYMENTS_EXPENSE_SUCCESS';

export const GET_ORG_PAYMENTS_EXPENSE_AMOUNT =
  'GET_ORG_PAYMENTS_EXPENSE_AMOUNT';
export const GET_ORG_PAYMENTS_EXPENSE_AMOUNT_PENDING =
  'GET_ORG_PAYMENTS_EXPENSE_AMOUNT_PENDING';
export const GET_ORG_PAYMENTS_EXPENSE_AMOUNT_ERROR =
  'GET_ORG_PAYMENTS_EXPENSE_AMOUNT_ERROR';
export const GET_ORG_PAYMENTS_EXPENSE_AMOUNT_SUCCESS =
  'GET_ORG_PAYMENTS_EXPENSE_AMOUNT_SUCCESS';

export const GET_PAYMENT_PROFILE = 'GET_PAYMENT_PROFILE';
export const GET_PAYMENT_PROFILE_PENDING = 'GET_PAYMENT_PROFILE_PENDING';
export const GET_PAYMENT_PROFILE_ERROR = 'GET_PAYMENT_PROFILE_ERROR';
export const GET_PAYMENT_PROFILE_SUCCESS = 'GET_PAYMENT_PROFILE_SUCCESS';

export const GET_ADVANCE_PROFILE = 'GET_ADVANCE_PROFILE';
export const GET_ADVANCE_PROFILE_PENDING = 'GET_ADVANCE_PROFILE_PENDING';
export const GET_ADVANCE_PROFILE_ERROR = 'GET_ADVANCE_PROFILE_ERROR';
export const GET_ADVANCE_PROFILE_SUCCESS = 'GET_ADVANCE_PROFILE_SUCCESS';

export const GET_ADVANCE_HISTORY = 'GET_ADVANCE_HISTORY';
export const GET_ADVANCE_HISTORY_PENDING = 'GET_ADVANCE_HISTORY_PENDING';
export const GET_ADVANCE_HISTORY_ERROR = 'GET_ADVANCE_HISTORY_ERROR';
export const GET_ADVANCE_HISTORY_SUCCESS = 'GET_ADVANCE_HISTORY_SUCCESS';
export const GET_ADVANCE_HISTORY_COUNT = 'GET_ADVANCE_HISTORY_COUNT';

export const GET_PAYABLE_PAYMENTS = 'GET_PAYABLE_PAYMENTS';
export const GET_PAYABLE_PAYMENTS_PENDING = 'GET_PAYABLE_PAYMENTS_PENDING';
export const GET_PAYABLE_PAYMENTS_ERROR = 'GET_PAYABLE_PAYMENTS_ERROR';
export const GET_PAYABLE_PAYMENTS_SUCCESS = 'GET_PAYABLE_PAYMENTS_SUCCESS';

export const GET_AVAILABLE_ADVANCE = 'GET_AVAILABLE_ADVANCE';
export const GET_AVAILABLE_ADVANCE_PENDING = 'GET_AVAILABLE_ADVANCE_PENDING';
export const GET_AVAILABLE_ADVANCE_ERROR = 'GET_AVAILABLE_ADVANCE_ERROR';
export const GET_AVAILABLE_ADVANCE_SUCCESS = 'GET_AVAILABLE_ADVANCE_SUCCESS';

export const ADVANCE_DELETE_PENDING = 'ADVANCE_DELETE_PENDING';
export const PAYMENT_DELETE_PENDING = 'PAYMENT_DELETE_PENDING';
