import Dexie from 'dexie';
import { ValueOfType } from '../../interfaces/Base';
import { TABLE_ID_MAP } from './tableConfigProvider';

export interface RecentProjects {
  _id: string;
  date_opened: number;
}

export interface CollapsedTaskIds {
  _id: string;
  taskIds: string[];
}

export interface CollapsedBoqIds {
  _id: string;
  boqIds: string[];
}

export interface RecentEmails {
  _id: string;
  date_opened: number;
}

export interface TableConfig {
  tableId: ValueOfType<typeof TABLE_ID_MAP>;
  columnDefs: {
    field: string;
    hide: boolean;
    colIndex: number;
    width: number;
  }[];
}

class IndexedDB extends Dexie {
  recentProjects!: Dexie.Table<RecentProjects, string>;
  collapseTaskIds!: Dexie.Table<CollapsedTaskIds, string>;
  collapsedBoqIds!: Dexie.Table<CollapsedBoqIds, string>;
  recentEmails!: Dexie.Table<RecentEmails, string>;
  tableConfig!: Dexie.Table<TableConfig, ValueOfType<typeof TABLE_ID_MAP>>;

  constructor() {
    super('IndexedDB');
    this.version(1).stores({
      recentProjects: '_id,date_opened',
      collapseTaskIds: '_id,taskIds',
      recentEmails: '_id,date_opened',
    });

    this.version(2).stores({
      recentEmails: '_id,date_opened',
    });

    this.version(3).stores({
      tableConfig: '++tableId',
    });

    this.version(4).stores({
      collapsedBoqIds: '_id,boqIds',
    });
  }

  deleteDbs() {
    this.delete({ disableAutoOpen: false });
  }
}

export const IndexedDBService = new IndexedDB();
