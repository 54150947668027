export const GET_ALL_GRN_SEQ = 'GET_ALL_GRN_SEQ';
export const GET_ACTIVE_GRN_SEQ = 'GET_ACTIVE_GRN_SEQ';
export const GET_ARCHIVED_GRN_SEQ = 'GET_ARCHIVED_GRN_SEQ';
export const GET_GRN_SEQ = 'GET_GRN_SEQ';
export const GET_GRN_SEQ_PROJECT = 'GET_GRN_SEQ_PROJECT';

export const CREATE_GRN_SEQ = 'CREATE_GRN_SEQ';
export const UPDATE_GRN_SEQ = 'UPDATE_GRN_SEQ';
export const UPDATE_ALL_GRN_SEQ = 'UPDATE_ALL_GRN_SEQ';
export const UPDATE_ACTIVE_GRN_SEQ = 'UPDATE_ACTIVE_GRN_SEQ';
export const UPDATE_ARCHIVED_GRN_SEQ = 'UPDATE_ARCHIVED_GRN_SEQ';

export const ARCHIVE_GRN_SEQ = 'ARCHIVE_GRN_SEQ';
export const RESTORE_GRN_SEQ = 'RESTORE_GRN_SEQ';
export const GRN_SEQ_API_ERROR = 'GRN_SEQ_API_ERROR';

export const ARCHIVE_GRN_PENDING = 'ARCHIVE_GRN_PENDING';
export const GRN_SEQ_PENDING = 'GRN_SEQ_PENDING';
