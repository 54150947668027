interface ValidateFormReturnType {
  isError: true | false;
  valErrors: Array<string>;
  pathToMessage: Map<string, string>;
}

async function validateForm(
  schema: any,
  obj: any,
  context?: {}
): Promise<{
  isError: true | false;
  valErrors: Array<string>;
  pathToMessage: Map<string, string>;
}> {
  const result: ValidateFormReturnType = {
    isError: false,
    valErrors: [],
    pathToMessage: new Map(),
  };

  try {
    const isValid = await schema.isValid(obj);

    if (isValid) {
      return result;
    } else {
      await schema.validate(obj, {
        abortEarly: false,
        ...(!!context && { context: context }),
      });
    }
  } catch (err) {
    if (err.inner) {
      const tempList: Array<string> = [];

      err.inner.forEach((element: any) => {
        tempList.push(element.path);
      });

      result.pathToMessage = err.inner.reduce((map, element) => {
        map.set(element.path, element.message);
        return map;
      }, new Map());

      result.isError = true;
      result.valErrors = tempList;

      return result;
    }
  }

  return result;
}

export default validateForm;
