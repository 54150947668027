import {
  GET_ALL_TASKS,
  GET_ALL_TASKS_PENDING,
  GET_TASK,
  GET_TASK_PENDING,
  GET_TASK_LOGS,
  GET_TASK_WITH_LOGS,
  GET_TASK_LOGS_PENDING,
  CREATE_TASK_PENDING,
  UPDATE_TASK_PENDING,
  DELETE_TASK,
  DELETE_TASK_PENDING,
  TOTAL_TASK_COUNT,
  TASK_COUNT_PENDING,
  TASK_API_ERROR,
  CREATE_TASK,
  CLEAR_TASKS,
  DELETE_TASK_LOG_PENDING,
  SET_FILTER_VALUES,
  SET_IF_FILTER,
  SET_FILTERED_TASKS,
  SET_SELECTED_TASK,
  SET_TASK_COMMENTS,
  NEW_TASK_INFO,
  SET_TASK_DEPENDENCY_DATA,
  SET_BASELINES,
  GET_TASK_TREE,
  TASK_TREE_PENDING,
  SET_TASK_DEPENDENCIES,
  SET_SELECTED_TASK_ID,
  LAST_ADDED_TASK,
  SET_TASK_ISSUES,
  SET_MEASUREMENT_UNITS,
  SET_SELECTED_BASELINE,
  SET_OUTLINE_LEVEL,
  SET_COPIED_TASK_DETAILS,
  SET_BULK_COPIED_TASK_DETAILS,
  SET_TASK_MATERIALS,
  SET_COLLAPSED_TASKS,
  SET_TOTAL_TASK_COUNT,
  RESET_COLLAPSED_TASKS,
  CLEAR_TASK_DETAILS,
  GET_BROAD_PLAN_MAIN_TASK,
  GET_BROAD_PLAN_WEEK_TASK,
  GET_BROAD_PLAN_TASK_DETAILS,
  GET_BROAD_PLAN_SITE_UPDATE,
  GET_BROAD_PLAN_TASK_PREV,
  GET_BROAD_PLAN_TASK_CURR,
  GET_BROAD_PLAN_TASK_NEXT,
  GET_BROAD_PLAN_TASK_DETAILS_STATE,
  GET_BROAD_PLAN_TASK_PENDING,
  GET_BROAD_SITE_UPDATE_PENDING,
  GET_BROAD_PLAN_ISSUE,
  GET_BROAD_PLAN_PHOTOS,
  SET_BROAD_PLAN_TASK_COUNT,
  GET_BROAD_PLAN_WEEK_DETAIL,
  GET_BROAD_PLAN_WEEK_TASK_STATE,
  GET_MAP_WEEKLY_TASKS,
  TASK_USAGES,
  TASK_USAGES_PENDING,
  SET_TASK_WORK_ORDER,
  SET_MAIN_TASK_PROFILE_IDS,
  SET_WEEK_TASK_PROFILE_IDS,
  GET_SITE_UPDATES,
  SET_TASK_CFS,
} from './Task.type';
import { CommentProps, ReducerProps } from '../../../../interfaces/Base';
import {
  BaselineProps,
  MeasurementUnitsProps,
  ProofFile,
  TaskLogProps,
  TaskMaterialProps,
  TaskProps,
} from '../../../../interfaces/Task';
import IssuesProps from '../../../../interfaces/Issues';
import { clamp } from '../../../../utils/Math.util';
import { DependencyProps } from '../../../../providers/dhtmlx/gantt/types';
import { IndexedDBService } from '../../../../providers/indexedDB';
import { track } from '../../common/Segment.action';
import { INDEX_DB_ERROR } from '../../../../constant';
import store from '../../../Store';
import { CFToItemProps } from '../../../../interfaces/CustomField';

const initialPendingState = {
  createTaskPending: false,
  getTaskPending: false,
  getTaskLogsPending: false,
  getAllTasksPending: false,
  updateTaskPending: false,
  deleteTaskPending: false,
  getTaskCountPending: true,
  taskToBoqCountPending: false,
  deleteTaskLogPending: false,
  uploadTaskLogFilesPending: false,
  taskTreePending: false,
  broadTaskDetailPending: false,
  broadWeekTaskDetailsPending: false,
  broadGetTaskPending: false,
  broadSiteUpdatePending: false,
  totalTaskCount: 0,
  task_usages_pending: false,
};

const initialState: {
  getTaskLogsPending: boolean;
  taskTree: any[];
  task_usages?: {
    total_dependency: number;
    dependency: {
      name?: string;
      total?: number;
      task_id?: string;
      children?: number;
      task_logs?: number;
      dependencies?: number;
    }[];
  };
  task_usages_pending: boolean;
  taskProfile: {
    details?: TaskProps | null;
    logs?: TaskLogProps[];
    issues?: IssuesProps[];
    comments?: CommentProps[];
    photos?: { [props: string]: ProofFile[] };
    materials: TaskMaterialProps[];
    materialsUsed: TaskLogProps['materials_used'];
    restricted_cfs?: CFToItemProps[];
  };
  selectedTask: TaskProps | null;
  measurementUnits: MeasurementUnitsProps[];
  taskDependencies: {
    tasks: TaskProps[];
    links: DependencyProps[];
  };
  weekTaskProfileIds: null;
  mainTaskProfileIds: null;
  broadPlanIssue: IssuesProps[];
  broadPlanPhotos: [];
  broadPlanWeekTaskDetails: Object;
  broadTaskDetailPending: boolean;
  broadWeekTaskDetailsPending: boolean;
  broadGetTaskPending: boolean;
  lastAddedTaskId: string;
  baselines: BaselineProps[];
  selectedBaselineId: string;
  outlineLevel: number;
  maxTaskOutline: number;
  createTaskPending: boolean;
  taskDependecy: {};
  getAllTasksPending: boolean;
  broadSiteUpdatePending: boolean;
  broadPlanTasks: TaskProps[];
  broadPlanWeekTasks: TaskProps[];
  broadPlanSiteUpdate: [];
  broadPlanWeeklyTaskPrev: [];
  broadPlanWeeklyTaskCurr: [];
  broadPlanWeeklyTaskNext: [];
  broadPlanMapWeekTask: [];
  broadPlanTaskDetails: Object;
  copiedTaskDetails: { name: string; _id: string } | null;
  bulkCopiedTaskDetails: string[] | null;
  taskTreePending: boolean;
  insertTaskDetails: object;
  collapsedTasks: Map<
    string,
    { index_number: number; allChildrenCount: number; ancestors: string[] }
  >;
  task_work_order: [];
  totalTaskCount: number;
  broadPlanTaskCount: number;
  [rest: string]: any;
  siteUpdates: {
    docs: Array<Object>;
    total: number;
    limit: number;
    page: number;
    pages: number;
  };
  updateTaskPending: boolean;
} = {
  ...initialPendingState,
  tasks: [],
  taskComments: [],
  filterValues: {},
  ifFilter: false,
  images: [],
  error: null,
  uploadError: [],
  taskLogs: [],
  selectedTask: null,
  count: 0,
  taskToBoqCount: 0,
  addedTask: null,
  taskWithLogs: [],
  selectedTaskLogFiles: [],
  filteredTasks: [],
  taskDependencies: { tasks: [], links: [] },
  baselines: [],
  taskTree: [],
  taskDependecy: {},
  selectedTaskId: '',
  lastAddedTaskId: '',
  selectedBaselineId: '',
  outlineLevel: 0,
  broadPlanIssue: [],
  broadPlanTasks: [],
  broadPlanWeekTasks: [],
  broadPlanSiteUpdate: [],
  broadPlanTaskCount: 0,
  broadPlanPhotos: [],
  broadPlanWeeklyTaskPrev: [],
  broadPlanWeeklyTaskCurr: [],
  broadPlanWeeklyTaskNext: [],
  broadPlanMapWeekTask: [],
  broadPlanTaskDetails: null,
  broadPlanWeekTaskDetails: null,
  taskProfile: {
    details: null,
    logs: [],
    issues: [],
    comments: [],
    photos: {},
    materials: [],
    materialsUsed: [],
    restricted_cfs: [],
  },
  maxTaskOutline: 0,
  measurementUnits: [],
  copiedTaskDetails: null,
  bulkCopiedTaskDetails: null,
  insertTaskDetails: null,
  weekTaskProfileIds: null,
  mainTaskProfileIds: null,
  collapsedTasks: new Map(),
  task_work_order: [],
  totalTaskCount: 0,
  siteUpdates: {
    docs: [], // will contain result documents
    total: 0, // total count of docs (include of all pages not just current page)
    limit: 20, // limit
    page: 1, // Current Page/ pages start from 1
    pages: 1, // Total Pages or Last Page
  },
  updateTaskPending: false,
};

export type taskReducerProps = typeof initialState;

export default function taskReducer(
  state = initialState,
  { type, payload, error }: ReducerProps
) {
  switch (type) {
    case CREATE_TASK:
      return { ...state, addedTask: payload, selectedTask: payload };
    case GET_TASK:
      return {
        ...state,
        selectedTask: payload,
        taskProfile: { ...state.taskProfile, details: payload },
      };
    case GET_BROAD_PLAN_WEEK_DETAIL:
      return { ...state, broadPlanWeekTaskDetails: payload };
    case GET_BROAD_PLAN_MAIN_TASK:
      return {
        ...state,
        broadPlanTasks: payload,
      };
    case GET_BROAD_PLAN_WEEK_TASK_STATE:
      return {
        ...state,
        broadWeekTaskDetailsPending: payload,
      };
    case GET_BROAD_PLAN_WEEK_TASK:
      return {
        ...state,
        broadPlanWeekTasks: payload,
      };
    case GET_MAP_WEEKLY_TASKS:
      return {
        ...state,
        broadPlanMapWeekTask: payload,
      };
    case SET_BROAD_PLAN_TASK_COUNT:
      return {
        ...state,
        broadPlanTaskCount: payload,
      };
    case GET_BROAD_PLAN_TASK_PREV:
      return {
        ...state,
        broadPlanWeeklyTaskPrev: payload,
      };
    case GET_BROAD_PLAN_TASK_PENDING:
      return {
        ...state,
        broadGetTaskPending: payload,
      };
    case GET_BROAD_SITE_UPDATE_PENDING:
      return {
        ...state,
        broadSiteUpdatePending: payload,
      };
    case GET_BROAD_PLAN_TASK_CURR:
      return {
        ...state,
        broadPlanWeeklyTaskCurr: payload,
      };
    case GET_BROAD_PLAN_TASK_NEXT:
      return {
        ...state,
        broadPlanWeeklyTaskNext: payload,
      };
    case GET_BROAD_PLAN_TASK_DETAILS:
      return { ...state, broadPlanTaskDetails: payload };
    case GET_BROAD_PLAN_SITE_UPDATE:
      return { ...state, broadPlanSiteUpdate: payload };
    case GET_TASK_PENDING:
      return { ...state, getTaskPending: payload };
    case GET_TASK_LOGS: {
      const photosMap: {
        [props: number]: ProofFile[];
      } = {};
      let totalProgress = 0;
      let totalWorkDone = 0;

      const materials_used = new Map();
      payload.map((log: TaskLogProps & { total_progress: number }) => {
        totalWorkDone += log.quantity_work_done || 0;
        totalProgress = clamp(
          ((totalWorkDone / log.total_task_quantity) * 100).toFixed(2),
          0,
          100
        );
        log.total_progress = totalProgress;
        const dateString = new Date(log.createdAt).valueOf();

        if (log.proof_files?.length) {
          if (photosMap[dateString]) {
            const val = photosMap[dateString];
            const imgs = log?.proof_files?.map(item => ({
              ...item,
              caption: log?.comment
                ? `${log?.comment} - ${log.percent_work_done}%`
                : `${log.percent_work_done}%`,
            }));
            val?.unshift(...(imgs || []));
          } else {
            photosMap[dateString] =
              log?.proof_files?.map(item => ({
                ...item,
                caption: log?.comment
                  ? `${log?.comment} - ${log.percent_work_done}%`
                  : `${log.percent_work_done}%`,
              })) || [];
          }
        }

        for (const bom of log.materials_used || []) {
          if (materials_used.has(bom.bom_id)) {
            const material = materials_used.get(bom.bom_id);
            material.quantity += bom.quantity;
          } else {
            materials_used.set(bom.bom_id, { ...bom });
          }
        }
      });

      return {
        ...state,
        taskLogs: payload,
        taskProfile: {
          ...state.taskProfile,
          logs: payload,
          photos: photosMap,
          materialsUsed: [...materials_used.values()],
        },
      };
    }
    case GET_TASK_WITH_LOGS:
      return { ...state, taskWithLogs: payload };
    case GET_TASK_LOGS_PENDING:
      return { ...state, getTaskLogsPending: payload };
    case GET_BROAD_PLAN_TASK_DETAILS_STATE:
      return { ...state, broadTaskDetailPending: payload };
    case SET_SELECTED_TASK:
      return { ...state, addedTask: payload, selectedTask: payload };
    case GET_ALL_TASKS:
      return { ...state, tasks: payload };
    case GET_ALL_TASKS_PENDING:
      return { ...state, getAllTasksPending: payload };
    case CREATE_TASK_PENDING:
      return { ...state, createTaskPending: payload };
    case UPDATE_TASK_PENDING:
      return { ...state, updateTaskPending: payload };
    case DELETE_TASK:
      return { ...state, createTaskPending: payload };
    case DELETE_TASK_PENDING:
      return { ...state, deleteTaskPending: payload };
    case TASK_USAGES:
      return { ...state, task_usages: payload, task_usages_pending: false };
    case TASK_USAGES_PENDING:
      return { ...state, task_usages_pending: payload };
    case DELETE_TASK_LOG_PENDING:
      return { ...state, deleteTaskLogPending: payload };
    case TOTAL_TASK_COUNT:
      return {
        ...state,
        count: payload,
        getTaskCountPending: false,
      };
    case TASK_COUNT_PENDING:
      return { ...state, getTaskCountPending: true };
    case TASK_API_ERROR:
      return {
        ...state,
        error: error,
      };
    case NEW_TASK_INFO: {
      return {
        ...state,
        insertTaskDetails: payload,
      };
    }
    case SET_FILTER_VALUES:
      return {
        ...state,
        filterValues: payload,
      };
    case SET_MAIN_TASK_PROFILE_IDS:
      return {
        ...state,
        mainTaskProfileIds: payload,
      };
    case SET_WEEK_TASK_PROFILE_IDS:
      return {
        ...state,
        weekTaskProfileIds: payload,
      };
    case SET_IF_FILTER:
      return {
        ...state,
        ifFilter: payload,
      };
    case SET_TASK_WORK_ORDER:
      return {
        ...state,
        task_work_order: payload,
      };
    case CLEAR_TASKS:
      return {
        ...state,
        tasks: [],
        taskDependencies: { tasks: [], links: [] },
        baselines: [],
        taskTree: [],
      };
    case SET_FILTERED_TASKS:
      return { ...state, filteredTasks: payload };
    case SET_TASK_COMMENTS:
      return {
        ...state,
        taskComments: payload,
        taskProfile: { ...state.taskProfile, comments: payload },
      };
    case SET_TASK_MATERIALS:
      return {
        ...state,
        taskProfile: { ...state.taskProfile, materials: payload },
      };
    case SET_TASK_CFS:
      return {
        ...state,
        taskProfile: { ...state.taskProfile, restricted_cfs: payload },
      };
    case SET_TASK_DEPENDENCY_DATA: {
      const data = { ...payload };
      data.tasks = data.tasks.map((item: any) => {
        item.text = item.name;
        item.id = item._id;
        item.start_epoch = item.start_date;
        item.end_epoch = item.end_date;
        item.start_date = item.start_date ? new Date(item.start_date) : null;
        if (item.end_date) item.end_date = new Date(item.end_date);
        item.type = item.type === 0 ? 'task' : 'project';
        item.parent = item.parent_id;
        item.progress = (item.percentage_work_done || 0) / 100;
        item._duration = item.duration || 1;
        return item;
      });
      data.links = data.links.map((item: any) => {
        item.id = item._id;
        item.type = item.type.toString();
        return item;
      });
      return { ...state, taskDependencies: data };
    }
    case SET_BASELINES:
      return { ...state, baselines: payload };
    case SET_TASK_DEPENDENCIES:
      return { ...state, taskDependecy: payload };
    case GET_TASK_TREE: {
      return { ...state, taskTree: payload };
    }
    case GET_BROAD_PLAN_PHOTOS:
      return {
        ...state,
        broadPlanPhotos: payload,
      };
    case TASK_TREE_PENDING:
      return { ...state, taskTreePending: payload };
    case SET_SELECTED_TASK_ID:
      return { ...state, selectedTaskId: payload };
    case LAST_ADDED_TASK:
      return { ...state, lastAddedTaskId: payload };
    case SET_TASK_ISSUES:
      return {
        ...state,
        taskProfile: { ...state.taskProfile, issues: payload },
      };
    case GET_BROAD_PLAN_ISSUE:
      return {
        ...state,
        broadPlanIssue: payload,
      };
    case SET_SELECTED_BASELINE:
      return { ...state, selectedBaselineId: payload };
    case SET_OUTLINE_LEVEL:
      return { ...state, outlineLevel: payload };
    case SET_MEASUREMENT_UNITS:
      return {
        ...state,
        measurementUnits: payload,
      };
    case SET_COPIED_TASK_DETAILS:
      return {
        ...state,
        copiedTaskDetails: payload,
      };
    case SET_BULK_COPIED_TASK_DETAILS:
      return {
        ...state,
        bulkCopiedTaskDetails: payload,
      };
    case SET_COLLAPSED_TASKS: {
      const temp = { ...state };
      const projectId = payload.project_id;
      if (!(payload._id && payload.value)) {
        return temp;
      }

      if (temp.collapsedTasks.has(payload._id)) {
        temp.collapsedTasks.delete(payload._id);
      } else {
        temp.collapsedTasks.set(payload._id, payload.value);
      }
      const data = Array.from(temp.collapsedTasks);
      const collapsedData = [];

      for (const item of data) {
        collapsedData.push(item[0]);
      }

      IndexedDBService.open()
        .then(() => {
          IndexedDBService.collapseTaskIds.get(projectId).then(data => {
            if (!data) {
              IndexedDBService.collapseTaskIds.add({
                _id: projectId,
                taskIds: collapsedData,
              });
            } else {
              IndexedDBService.collapseTaskIds.update(projectId, {
                taskIds: collapsedData,
              });
            }
          });
        })
        .catch(err => {
          store.dispatch(track(INDEX_DB_ERROR, err));
        });

      return temp;
    }
    case RESET_COLLAPSED_TASKS: {
      const collapsedData = [];
      payload?.collapsedTasks?.forEach(function (value, key) {
        collapsedData.push(key);
      });

      const projectId = payload.projectId;

      if (projectId) {
        IndexedDBService.open()
          .then(() => {
            IndexedDBService.collapseTaskIds.get(projectId).then(data => {
              if (!data) {
                IndexedDBService.collapseTaskIds.add({
                  _id: projectId,
                  taskIds: collapsedData,
                });
              } else {
                IndexedDBService.collapseTaskIds.update(projectId, {
                  taskIds: collapsedData,
                });
              }
            });
          })
          .catch(err => {
            store.dispatch(track(INDEX_DB_ERROR, err));
          });
      }

      return { ...state, collapsedTasks: payload.collapsedTasks };
    }
    case SET_TOTAL_TASK_COUNT:
      return {
        ...state,
        totalTaskCount: payload,
      };
    case CLEAR_TASK_DETAILS: {
      return {
        ...state,
        taskProfile: {
          details: null,
          logs: [],
          issues: [],
          comments: [],
          photos: {},
          materials: [],
          materialsUsed: [],
        },
      };
    }
    case GET_SITE_UPDATES: {
      return {
        ...state,
        siteUpdates: payload,
      };
    }

    default:
      return state;
  }
}
