export const EXPERIMENTS = {
  MSP_TYPE_DATE_CALCULATION: 'msp_type_date_calculation_exp',
  ORG_DASHBOARD: 'org_dashboard_exp',
  CLIENT_REPORTING: 'exp_client_reporting_v2',
  INDENT_OUTDENT: 'ftue_wbs_indent_outdent',
  TASK_NESTING: 'exp_task_nesting',
  WEB_GANTT_AUTO_SCHEDULING: 'web_gantt_auto_scheduling',
  WEB_NESTED_PLAN_UPLOAD: 'web_nested_plan_upload',
  GANTT_CURR_VIEW_PDF: 'gantt_curr_view_pdf',
  MATERIAL_VENDOR_PAYABLE: 'exp_material_vendor_payable',
  MATERIAL_ISSUE: 'exp_issue_material',
  OPEN_CLOSED_ISSUE_REPORT: 'exp_open_closed_issue_report',
  SITE_TRANSFER: 'exp_material_issue_site_to_site',
  TASK_REMINDER: 'exp_task_reminder',
  ATTENDANCE_MODULE: 'exp_vendor_attendance_overtime',
  NEW_ATTENDANCE_MODULE: 'exp_new_attendance_module',
  TASK_INSTRUCTIONS: 'exp_task_instructions',
  PROJECT_STAGES: 'exp_project_stages',
  EXP_CUSTOM_COLUMN_IG: 'exp_custom_column_ig',
  TASK_CHECKLIST: 'exp_task_checklist',
  SHOW_CUMULATIVE_PROGRESS: 'exp_cumulative_task_progress',
  FREE_TEXT_TL_CF: 'exp_custom_column_free_text_update',
  EXP_EXPORT_DATA: 'exp_export_data',
  CUSTOM_FIELD_V2: 'exp_custom_field_v2',
  EXP_UPLOAD_SAMPLE_DOCUMENT: 'exp_upload_sample_document',
  EXP_NEW_ONBOARDING_FLOW: 'exp_new_onboarding_invite_flow_v2',
  EXP_TASK_ATTACHMENTS: 'exp_task_attachments',
  EXP_ATTENDANCE_MODULE_V3: 'exp_attendance_module_v3',
  EXP_EXCEL_BULK_UPLOAD_PO: 'exp_excel_bulk_upload_po',
  EXP_GANTT_COLUMNS: 'exp_gantt_columns',
  EXP_MM_BOM_REMARK: 'exp_mm_bom_remark',
  EXP_MM_MI_TAGS: 'exp_mm_mi_tags',
  EXP_INVENTORY_MIN_QUANTITY: 'exp_mm_inventory_min_quantity',
  EXP_MM_TASK_CUSTOM_WEIGHTS: 'exp_mm_task_custom_weights',
  EXP_MM_MATERIAL_USED_BASELINE: 'exp_mm_material_used_baseline',
  EXP_MM_DRIVE_APPROVAL_AND_COMMENTS: 'exp_mm_drive_approval_and_comments',
  EXP_BROAD_PLAN: 'exp_broad_plan_gc',
  TASK_LOG_PERMISSION: 'exp_task_log_permission',
  EXP_ZOHO_DASHBOARD_ORG: 'exp_zoho_dashboard',
  EXP_WO_SC_PAYABLE: 'exp_wo_sc_payable',
  EXP_MM_COMPARE_BASELINES: 'exp_mm_compare_baselines',
  TASK_LOG_EDIT_EXP: 'exp_task_log_edit',
  EXP_MM_PROJECT_HOLIDAYS: 'exp_mm_project_holidays',
  EXP_BACKDATED_AND_EXCESS_QTY: 'exp_task_bckd_and_exsc',
  EXP_BI_ASYNC_REPORT: 'exp_bi_attendance_report',
  EXP_WO_SC_RESTRICTED_ACCESS: 'exp_wo_sc_restricted_access',
  EXP_PAYABLE_DISABLE: 'exp_payable_disable',
  EXP_BOQ_PHASE_1: 'exp_boq_phase_1',
};
