export const SET_RECEIVABLES = 'SET_RECEIVABLES';
export const SET_RECEIVABLES_LOADING = 'SET_RECEIVABLES_LOADING';
export const SET_RECEIVABLES_SUMMARY_LOADING =
  'SET_RECEIVABLES_SUMMARY_LOADING';
export const SET_RECEIVABLE_SUMMARY = 'SET_RECEIVABLE_SUMMARY';
export const SET_SELECTED_RECEIVABLE = 'SET_SELECTED_RECEIVABLE';
export const SET_RECORD_PAYMENT_MODE = 'SET_RECORD_PAYMENT_MODE';
export const SET_SHOW_RECORD_PAYMENT = 'SET_SHOW_RECORD_PAYMENT';
export const SYNC_RECEIVABLE_VIEW = 'SYNC_RECEIVABLE_VIEW';
export const SET_RECORD_PAYMENT_PENDING = 'SET_RECORD_PAYMENT_PENDING';
