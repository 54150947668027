import { createTheme } from '@mui/material/styles';

const fontFamilyArr = [
  '"Open Sans"',
  'sans-serif ',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

export const variables = {
  bgOverlay: 'rgba(0,0,0, 0.4)',
  primaryColor: '#109cf1',
  secondaryColor: '#c2cfe0',
  bgColor: '#F7FCFF',
  white: '#fff',
  black: '#000',
  grey: '#b5b5b5',
  green: '#68d083',
  yellow: '#ebb840',
  orange: '#e6642e',
  magenta: '#bb6bd9',
  brown: '#a52a2a',
  red: '#ff3939',
  bluelight: 'rgba(16, 156, 241, 0.05)',
  blue100: '#F7FCFF',
  blue200: '#CAE7FC',
  blue300: '#8CC7FA',
  blue400: '#8CC7FA',
  blue500: '#3D90ED',
  blue600: '#2065C7',
  blue700: '#144BA8',
  blue800: '#060F3F',
  blueA100: '#E6F4FE',
  tooltipBlue: '#373E65',
  blue500A15: 'rgba(16, 156, 241,  0.15)',
  blue500A40: 'rgba(16, 156, 241,  0.4)',
  blueGrey100: '#F5F6F8',
  blueGrey200: '#eceff2',
  blueGrey300: '#dee2e6',
  blueGrey400: '#ccdaee',
  blueGrey500: '#c2cfe0',
  blueGrey600: '#90a0b7',
  blueGrey700: '#6a707e',
  blueGrey800: '#41525c',
  grey200: '#f3f3f3',
  grey300: '#e5e5e5',
  grey400: '#c4c4c4',
  grey500: '#b5b5b5',
  grey600: '#979797',
  grey600A05: 'rgba(151,151,151, 0.05)',
  grey700: '#828282',
  grey800: '#666',
  grey900: '#212121',
  green100: '#e9f8ed',
  green500: '#68d083',
  green500A15: 'rgba(104, 208, 131, 0.15)',
  green600: '#2ed47a',
  green700: '#509c64',
  yellow400: '#f2c94c',
  yellow500: '#ebb840',
  red100: '#fbe8e0',
  red200: '#fff2e8',
  red300: '#f1e0d5',
  red400: '#ff8b5b',
  red500: '#ff3939',
  red600: '#ff783e',
  red700: '#fa541c',
  orange500: '#e6642e',
  orange500A20: 'rgba(230, 100, 46,  0.2)',
  orange500A40: 'rgba(230, 100, 46, 0.4)',
  tableDarkGrey: '#ccdaee',
  tableGreyBorder: '#bfc9d8',
  borderWhite40: '#e5e5e540',
  backgroundlight: 'rgba(194, 207, 224, 0.1)',
  subtitle: 'rgba(0, 34, 83, 0.35)',
  potableheader: '#f3f5f9',
  potableinward: '#c2cfe014',
  financesettingscard: 'rgba(194, 207, 224, 0.1)',
  errorRed: '#ea3a3c',
  blueGrey600heading: '#6a707e',
  greenLight: '#96cfa6',
  greenLightBg: '#e6f3e9',
  greenPrimaryDark: '#005517',
  yellowLight: '#ffe5a0',
  yellowLightBg: '#fff9e8',
  yellowPrimaryDark: '#9c750f',
  greyLight: '#e6e7e9',
  greyLightBg: '#fafafb',
  greyPrimaryDark: '#2e3b48',
};

export const POWERPLAY_COLORS = {
  primary: '#060F3F',
  secondary: '#E13C5A',
  white: '#FFFFFF',
  blue200: '#78C6FF',
  blue400: '#3D90ED',
  blue600: '#1946BB',
  backgroundColor: '#F7FCFF',
};

export const theme = createTheme({
  typography: {
    fontFamily: fontFamilyArr.join(','),
  },
  shape: {
    borderRadius: 2,
  },
  palette: {
    common: {
      black: variables.blue800,
      white: variables.white,
    },
    primary: {
      A15: variables.blue500A15,
      A40: variables.blue500A40,
      main: variables.blue500,
      hover: variables.blue600,
      active: variables.blue600,
      disabled: variables.blue200,
    },
    secondary: {
      main: variables.blue800,
      hover: variables.blue700,
      active: variables.blue700,
      disabled: variables.blue300,
    },
    default: {
      main: variables.grey800,
    },
    dark: {
      main: variables.grey800,
    },
    text: {
      primary: '#212121',
      secondary: variables.blueGrey600heading,
    },
    success: {
      main: variables.green500,
      hover: variables.green500,
      active: variables.green500,
      disabled: variables.green500,
    },
    grey: {
      500: variables.blueGrey600,
      700: variables.blue200,
      800: variables.blueGrey700,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1370,
      xl: 1920,
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '2px',
          padding: '0.25rem',
          fontSize: '1.5rem',
        },
        colorPrimary: {
          background: `${variables.grey600A05}`,
        },
        colorSecondary: {
          color: `transparent`,
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          color: variables.grey600,
        },
        colorPrimary: {
          color: `${variables.blue500}`,
        },
        colorSecondary: {
          color: `${variables.white}`,
        },
        colorError: {
          color: `${variables.red400}`,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '0.4rem 0.6rem',
          '&:hover': {
            backgroundColor: variables.blue200,
          },
          '&:active': {
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
              color: variables.white,
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&:hover': {
            backgroundColor: variables.blue200,
          },
        },
        inputRoot: {
          padding: '0 !important',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: 'p-0',
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '0.5em',
            height: '0.5em',
          },
          '*::-webkit-scrollbar-thumb': {
            background: variables.grey400,
            borderRadius: '0.5em',
            borderRight: '0.4em !important',
            borderLeft: '0.4em !important',
            backgroundClip: 'padding-box !important',
          },
          '*::-webkit-scrollbar-track': {
            background: 'transparent',
            borderRadius: 0,
          },
          '*': {
            scrollbarFaceColor: variables.grey400,
            scrollbarTrackColor: 'transparent',
          },
          '*::-webkit-scrollbar-thumb:hover': {
            backgroundColor: variables.grey600,
          },
        },
      },
    },
  },
});

export const searchTheme = createTheme({
  typography: {
    fontFamily: fontFamilyArr.join(','),
    fontSize: 12,
  },
  palette: {
    primary: {
      main: variables.blue500,
    },
    action: {
      hover: variables.blueGrey600,
    },
    text: {
      primary: variables.blueGrey600heading,
      secondary: variables.blueGrey600heading,
    },
    divider: variables.blueGrey600,
  },
});
