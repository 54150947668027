import {
  GET_BOM_PRICE_ITEMS,
  GET_BOM_PRICE_ITEMS_PENDING,
  GET_VENDOR_PRICING_HISTORY,
  GET_VENDOR_PRICE_ITEMS_PENDING,
  BOM_PRICE_API_ERROR,
  GET_BOM_PRICING_HISTORY,
  GET_VENDOR_PRICING_HISTORY_COUNT,
} from './BomPrice.type';
import { ReducerProps } from '../../../../interfaces/Base';

const initialPendingState = {
  getBOMPriceListPending: false,
  getVendorPriceListPending: false,
};

const initialState = {
  ...initialPendingState,
  bomPriceItemsList: [],
  bomPricingHistory: null,
  vendorPricingHistory: null,
  vendorPricingHistoryCount: null,
  error: null,
};

export default function baseReducer(
  state = initialState,
  { type, payload, error }: ReducerProps
) {
  switch (type) {
    case GET_BOM_PRICE_ITEMS:
      return {
        ...state,
        bomPriceItemsList: payload,
      };
    case GET_BOM_PRICING_HISTORY:
      return {
        ...state,
        bomPricingHistory: payload,
      };
    case GET_BOM_PRICE_ITEMS_PENDING:
      return {
        ...state,
        getBOMPriceListPending: payload,
      };
    case BOM_PRICE_API_ERROR:
      return {
        ...state,
        error: error.response.data.message,
      };
    case GET_VENDOR_PRICING_HISTORY:
      return {
        ...state,
        vendorPricingHistory: payload,
      };
    case GET_VENDOR_PRICE_ITEMS_PENDING:
      return {
        ...state,
        getVendorPriceListPending: payload,
      };
    case GET_VENDOR_PRICING_HISTORY_COUNT:
      return {
        ...state,
        vendorPricingHistoryCount: payload,
      };
    default:
      return state;
  }
}
