import {
  MyBoardActionType,
  SET_SELECTED_PROJECTS,
  SET_PROJECT_SUMMARY_LAST_UPDATED_PROGRESS_DATE,
  MyBoardInitialStateInterface,
} from './MyBoard.types';

const myBoardInitialState: MyBoardInitialStateInterface = {
  selectedProjects: [],
  projectSummaryWidget: {},
};

export default function MyBoardReducer(
  state = myBoardInitialState,
  { type, payload }: MyBoardActionType = null
) {
  switch (type) {
    case SET_SELECTED_PROJECTS:
      return {
        ...state,
        selectedProjects: payload,
      };
    case SET_PROJECT_SUMMARY_LAST_UPDATED_PROGRESS_DATE:
      return {
        ...state,
        projectSummaryWidget: {
          ...state.projectSummaryWidget,
          lastUpdatedProgressDate: payload,
        },
      };
    default:
      return state;
  }
}
