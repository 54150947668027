import { useState, useEffect, Suspense } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';

import MainNavbar from '../components/layout/MainNavbar/MainNavbar';
import { getOrgNavItems } from '../data/sidebar-nav-items';
import { setNavbarTitle } from '../redux/features/common/layout/Layout.action';
import { setInitialProjectState } from '../redux/features/common/project';
import { clearUtilsData } from '../redux/features/common/utils/Utils.action';
import { clearPOData } from '../redux/features/material/purchase-order';
import { Powerplay } from '../components/common/Icons';
import authHeader from '../utils/AuthHeader.util';
import { loginCheck } from '../redux/features/common/user';
import OnboardingDialog from '../views/Onboarding/OnboardingDialog';
import MenuDropdown from './MenuDropdown';
import { POWERPLAY_WEB_VERSION } from '../constants';
import { useAppDispatch, useAppSelector } from '../redux/Hooks';
import { clearDashboardData } from '../redux/features/dashboard';
import { theme } from '../components/common/Theme';
import './layoutStyles.scss';
import { CustomSpinner } from '../components/common';

function OrgLayout() {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const location = useLocation();

  const path = location.pathname;

  const isAuthenticated = useAppSelector(state => state.userreducer.isAuth);

  const experiments = useAppSelector(
    state => state.organisationReducer.experiments
  );
  const userPermissions = useAppSelector(
    state => state.userreducer.userPermissionMap
  );
  const orgIsPaid = useAppSelector(
    state => state.organisationReducer.orgProfile.is_paid
  );
  const orgId = useAppSelector(
    state => state.organisationReducer.orgProfile._id
  );
  const orgFetchedAndIsPaid = orgId && orgIsPaid;

  const navItems = getOrgNavItems(
    userPermissions,
    experiments,
    orgFetchedAndIsPaid
  );

  const [showOnboarding, setShowOnboarding] = useState(false);
  const projectData = useAppSelector(
    state => state.projectreducer.projectItems.projects
  );

  useEffect(() => {
    if (!isAuthenticated) {
      const payload = authHeader();
      dispatch(loginCheck(payload, history));
    }

    if (!projectData.length) {
      dispatch(setInitialProjectState());
    }
  }, []);

  const backToProjects = () => {
    history('/projects');

    dispatch(setInitialProjectState());
    dispatch(clearUtilsData());
    dispatch(clearDashboardData());
    dispatch(clearPOData());
    dispatch(setNavbarTitle(''));
  };

  return (
    <Suspense fallback={<CustomSpinner />}>
      <div className="layoutRoot">
        <CssBaseline />
        <Drawer
          variant="permanent"
          className="layoutDrawer"
          PaperProps={{
            style: {
              transition: 'width 450ms cubic-bezier(.4,0,0,1) 0ms',
              overflowX: 'hidden',
              width: theme.spacing(8),
            },
          }}
        >
          <div className="layoutToolbar hand-pointer" onClick={backToProjects}>
            <Powerplay style={{ fontSize: 45 }} />
          </div>
          <List className="layoutList">
            {navItems.map((item, index: number) => (
              <MenuDropdown
                key={`${item.to}-${item.title}-${index}`}
                item={item}
                index={index}
              />
            ))}
          </List>
          <small className="text-light text-center mt-auto">
            Version:
            <br /> {POWERPLAY_WEB_VERSION}
          </small>
        </Drawer>
        <main className="custom-scroll layoutContent d-flex flex-column">
          <MainNavbar orgView noOrg={false} />
          <Outlet />
        </main>
        {showOnboarding && path === '/projects' ? (
          <OnboardingDialog
            open={showOnboarding}
            setOpen={setShowOnboarding}
            width={100}
          />
        ) : null}
      </div>
    </Suspense>
  );
}

export default OrgLayout;
