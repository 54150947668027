import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import axios, { AxiosResponse } from 'axios';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';

import { logout } from '../redux/features/common/user';
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED } from '../constant';
import {
  LOCAL_STORAGE_TOKEN_KEY,
  POWERPLAY_BASE_URL,
  POWERPLAY_WEB_VERSION,
} from '../constants';
import { API_VERSIONS, skipAuthTokenApis } from '../routes.constant';
import store from '../redux/Store';
import { jwtDecode } from 'jwt-decode';
import { useLocalStorage } from '../utils/hooks';
import fetchAuthTokenFromRefreshToken from './fetchAuthTokenFromRefreshToken';

const { TOKEN, TOKEN_EXPIRY, REFRESH_TOKEN } = LOCAL_STORAGE_TOKEN_KEY;
const TEN_SECONDS = 1000 * 10;
let kamEmail, kamUser, isKam;

export const getAuthorizationHeaderToken = () => {
  const { getLocalStorageValue } = useLocalStorage();
  const token: string = getLocalStorageValue(TOKEN, false);
  const tokenExpiresAt = Number(getLocalStorageValue(TOKEN_EXPIRY, false));
  const refreshToken = getLocalStorageValue(REFRESH_TOKEN, false);
  if (
    !Number.isNaN(tokenExpiresAt) &&
    tokenExpiresAt - Date.now() <= TEN_SECONDS &&
    refreshToken
  ) {
    return fetchAuthTokenFromRefreshToken();
  } else {
    return token;
  }
};

const getKamData = () => {
  if (isKam === undefined) {
    const { getLocalStorageValue } = useLocalStorage();
    const token: string = getLocalStorageValue(TOKEN, false);
    const decoded: any = jwtDecode(token);
    if (decoded) {
      if (decoded.is_kam) {
        kamEmail = decoded.kam_email;
        kamUser = decoded.kam_user;
        isKam = true;
      } else {
        isKam = false;
      }
    }
  }

  return { kam_email: kamEmail, kam_user: kamUser };
};

const apiClient = axios.create({
  baseURL: POWERPLAY_BASE_URL ? `${POWERPLAY_BASE_URL}/api/` : '/api/',
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

const gqlApiClient = axios.create({
  baseURL: '/graphql',
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'x-platform': 'web',
  },
});

const addInterceptor = async apiClient => {
  const { getLocalStorageValue } = useLocalStorage();
  const isPaid = getLocalStorageValue('is_paid', false) || '';
  const orgId = getLocalStorageValue('org_id', false) || '';
  apiClient.interceptors.request.use(async config => {
    const urlWithoutVersion = config.url.substr(3);
    if (!skipAuthTokenApis.includes(urlWithoutVersion)) {
      const token = await getAuthorizationHeaderToken();
      config.headers.authorization = token ? `Bearer ${token}` : '';
    }
    config.headers['x-apm-is_paid'] = isPaid;
    config.headers['x-apm-org_id'] = orgId;
    config.headers['x-platform'] = 'web';
    config.headers['app-version'] = POWERPLAY_WEB_VERSION;

    if (isKam !== false) {
      const kamData = getKamData();
      config.headers['x-apm-kam_user'] = kamData.kam_user;
      config.headers['x-apm-kam_email'] = kamData.kam_email;
    }

    return config;
  });
};

addInterceptor(apiClient);
addInterceptor(gqlApiClient);

apiClient.interceptors.response.use(
  response => response,
  error => {
    const responseError = Object.assign({}, error);

    if (responseError.response && responseError.response.data) {
      const { status } = responseError.response;
      if (status === UNAUTHORIZED) {
        store.dispatch(logout());
      }

      if (!responseError.response.data.message) {
        responseError.response.data = { message: 'UNKNOWN_ERROR' };
      }
    } else if (responseError.request) {
      responseError.response = {
        data: {
          message:
            responseError.request.message || responseError.request.statusText,
        },
      };
    } else {
      responseError.response = {
        data: { message: responseError.message },
        status: INTERNAL_SERVER_ERROR,
      };
    }

    if (responseError.response.data.message) {
      let errMsg = responseError.response.data.message;
      const errorDetail = responseError.response.data.error_detail || [];

      if (errorDetail.length > 0) {
        errMsg = `${errMsg}\n${errorDetail.join(',')}`;
      }

      responseError.errorMsg = errMsg;
    }

    return Promise.reject(responseError);
  }
);

const httpLink = new HttpLink({ fetch: buildAxiosFetch(gqlApiClient) });

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
  },
  link: ApolloLink.from([httpLink]),
});

export type apiVersionTypes = (typeof API_VERSIONS)[keyof typeof API_VERSIONS];

export async function getRequest<T = any>(
  apiEndpoint: string,
  version: apiVersionTypes = 'v1'
): Promise<AxiosResponse<T>> {
  const res = await apiClient.get(`${version}/${apiEndpoint}`);
  return res;
}

export async function postRequest<T = any>(
  apiEndpoint: string,
  reqBody?: any,
  version: apiVersionTypes = 'v1'
): Promise<AxiosResponse<T>> {
  const res = await apiClient.post(`${version}/${apiEndpoint}`, reqBody);
  return res;
}

export async function putRequest<T = any>(
  apiEndpoint: string,
  reqBody?: any,
  version: apiVersionTypes = 'v1'
): Promise<AxiosResponse<T>> {
  const res = await apiClient.put(`${version}/${apiEndpoint}`, reqBody);
  return res;
}

export async function deleteRequest<T = any>(
  apiEndpoint: string,
  version: apiVersionTypes = 'v1'
): Promise<AxiosResponse<T>> {
  const res = await apiClient.delete(`${version}/${apiEndpoint}`);
  return res;
}
