import { Dispatch } from 'redux';

import {
  SET_SELECTED_PROJECT,
  GET_PROJECT_ITEMS,
  CREATE_PROJECT,
  PROJECT_API_SUCCESS,
  PROJECT_API_ERROR,
  PROJECT_API_PENDING,
  GET_PROJECT_PROFILE,
  UPDATE_PROJECT_PROFILE,
  CREATE_PROJECT_PROFILE,
  SHOW_PROFILE_FORM,
  PROJECT_DELETE_SUCCESS,
  GET_ORG_PROJECT_LIST,
  GET_PROJECT_USERS,
  UPDATE_PROJECT_USERS,
  DELETE_PROJECT_USERS,
  UPDATE_FINANCE_SETTINGS,
  GET_PROJECT_DETAIL,
  UPDATE_PURCHASE_SETTINGS,
  REFRESH_PROJECT_ITEMS,
  SET_INITIAL_STATE,
  SET_PROJECT_STAGES,
  SET_ALL_CUSTOM_FIELDS,
  SET_CONFIG_TASK_SETTING,
  UPDATE_CONFIG_TASK_SETTING,
  UPDATE_DRIVE_SETTINGS,
  SET_HOLIDAYS,
  SET_SELECTED_TAB_VIEW_ID,
  GET_COUNT_ASYNC_REPORT,
  SET_SELECTED_PROJECT_VIEW_ID,
  GET_ASSIGNED_USERS,
  GET_CF_DROPDOWN_FILTER,
  SET_CFS_TEMPLATES,
  SET_CLEAR_PROJECT_FILTER,
  SET_DYNAMIC_FILTERS,
} from './Project.types';
import {
  toastSuccessMessage,
  toastErrorMessage,
  toastLoadingMessage,
} from '../../../../utils/Toast.util';
import {
  USER_PROJECT_APIS,
  PROJECT_APIS,
  API_VERSIONS,
} from '../../../../routes.constant';
import {
  INVITE_CONSTANT,
  PROJECT_SEGMENT,
} from '../../../../constant/segment_constant';
import { track } from '../Segment.action';
import { eventDispatch } from '../../../base';
import ProjectProps, {
  CreateProjectProfileProps,
  DeleteProjectUserProps,
  GetOrgProjectsParamsProps,
  GetProjectUsersParamsProps,
  GetUsersProjectParamsProps,
  UpdateProjectUsersProps,
  FinanceSettingsProps,
  PurchaseSettingsProps,
  ProjectDuplicateWithDependencies,
  UserProjectUpdateProps,
  RestrictPhoneGalleryProps,
  GetConfigDetailsProps,
  DriveSettingsProps,
  CustomFieldConfiguration,
} from '../../../../interfaces/Project';
import { parseString } from '../../../../utils/Common.util';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../../../apiClient';
import { ACTION_EVENTS } from '../../../../constant';
import { CreatorDetailsProps } from '../../../../interfaces/Base';
import { GET_ALL_TAGS_TASK } from '../tag/Tag.type';
import { GET_PACKAGES_LIST } from '../package/Package.type';
import { getExperiments } from '../organisation';
import {
  SYNC_MEMBERS_VIEW,
  SYNC_PROJECT_LIST_VIEW,
} from '../refresh/Refresh.type';
import { setRefreshView } from '../refresh/Refresh.action';
import { InviteOrgTeamParams } from '../../../../interfaces/Team';
import { customStringify } from '../../../../utils/Url.util';
import { stringify } from 'querystring';
import { RootState } from '../../../Reducers';
import { CUSTOM_COLUMN_RESOURCE } from '../../../../modules/custom-columns/types/constants';
import { DynamicFilter } from '@/components/task/dynamic-filters/types/DynamicFilter';

export interface CFTemplate {
  template_id: string;
  selected?: boolean;
}
export const fetchAndSetTemplates = (resourceType: string) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const projectCfs = getState().projectreducer.all_cfs[resourceType];
    const apiUrl = parseString(
      PROJECT_APIS.getCFTemplates,
      stringify({ resource_type: resourceType })
    );

    try {
      const templates = await getRequest(apiUrl);
      const filteredTemplates = templates?.data?.filter((it: CFTemplate) => {
        const obj = projectCfs?.find(
          field => field.template_id === it.template_id
        );

        if (obj && !obj.is_removed) {
          it.selected = true;
          return false;
        }

        return true;
      });

      dispatch({
        type: SET_CFS_TEMPLATES,
        payload: filteredTemplates || [],
      });
    } catch {
      dispatch({
        type: SET_CFS_TEMPLATES,
        payload: [],
      });
    }
  };
};

export function setSelectedProj(data: any) {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: SET_SELECTED_PROJECT, payload: data });
    if (data.project.name) {
      dispatch(track(ACTION_EVENTS.PROJECT_ACTIONS.PROJECT_CARD_CLICKED));
    }
  };
}

export function setShowForm(data: boolean) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SHOW_PROFILE_FORM, payload: data });
  };
}

export function getProjectList(params: GetUsersProjectParamsProps) {
  const apiUrl = parseString(
    PROJECT_APIS.getProjectList,
    customStringify(params)
  );
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(PROJECT_API_PENDING));
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(PROJECT_API_SUCCESS));
      dispatch(eventDispatch(GET_PROJECT_ITEMS, data));
    } catch (error) {
      dispatch(eventDispatch(PROJECT_API_ERROR, error));
    }
  };
}

export function getProjectsForOrg(params: GetOrgProjectsParamsProps) {
  const apiUrl = parseString(
    PROJECT_APIS.getProjectsForOrg,
    customStringify(params)
  );
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(PROJECT_API_PENDING));
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(PROJECT_API_SUCCESS));
      dispatch(eventDispatch(GET_ORG_PROJECT_LIST, data));
    } catch (error) {
      dispatch(eventDispatch(PROJECT_API_ERROR, error));
    }
  };
}

export function getProjectProfile() {
  return async (dispatch: Dispatch, getState: any) => {
    const projectId = getState().projectreducer.selectedProj.id;
    const apiUrl = parseString(PROJECT_APIS.getProjectProfile, projectId);
    dispatch(eventDispatch(PROJECT_API_PENDING));
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(PROJECT_API_SUCCESS));
      dispatch(eventDispatch(GET_PROJECT_PROFILE, data));
    } catch (error) {
      dispatch(eventDispatch(PROJECT_API_ERROR, error));
    }
  };
}

export function createProjectProfile(reqBody: CreateProjectProfileProps) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(PROJECT_API_PENDING));
    try {
      const { data } = await postRequest(
        PROJECT_APIS.createProjectProfile,
        reqBody,
        API_VERSIONS.V1
      );
      dispatch(eventDispatch(CREATE_PROJECT_PROFILE, data));
      dispatch(eventDispatch(PROJECT_API_SUCCESS));
      dispatch(getProjectProfile());
      toastSuccessMessage('Successfully created project profile');
    } catch (error) {
      dispatch(eventDispatch(PROJECT_API_ERROR, error));
      toastErrorMessage('Some error while creating project profile');
    }
  };
}

export function updateProjectProfile(reqBody: CreateProjectProfileProps) {
  return async (dispatch: Dispatch<any>, getState: any) => {
    const projectId = getState().projectreducer.selectedProj.id;
    const apiUrl = parseString(PROJECT_APIS.updateProjectProfile, projectId);
    dispatch(eventDispatch(PROJECT_API_PENDING));
    try {
      const { data } = await putRequest(apiUrl, reqBody, API_VERSIONS.V1);
      dispatch(eventDispatch(UPDATE_PROJECT_PROFILE, data));
      dispatch(eventDispatch(PROJECT_API_SUCCESS));
      dispatch(getProjectProfile());
      toastSuccessMessage('Successfully updated project profile');
    } catch (error) {
      dispatch(eventDispatch(PROJECT_API_ERROR, error));
      toastErrorMessage('Some error while updating project profile');
    }
  };
}

export const getProjectStages =
  (projectId: string) => async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(PROJECT_APIS.getProjectStages, projectId);

    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(SET_PROJECT_STAGES, data));
    } catch (err) {
      toastErrorMessage('Error fetching filter data');
    }
  };

export function getAllCustomFields(
  projectId: string,
  successCallback?: () => void
) {
  const apiUrl = parseString(PROJECT_APIS.getAllCFs, projectId);

  return async (dispatch: Dispatch<any>) => {
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      const payload = {
        task:
          data?.custom_fields.filter(
            ({ resource_type }) => resource_type === CUSTOM_COLUMN_RESOURCE.TASK
          ) || [],
        task_log:
          data?.custom_fields.filter(
            ({ resource_type }) =>
              resource_type === CUSTOM_COLUMN_RESOURCE.TASK_LOG
          ) || [],
        po:
          data?.custom_fields.filter(
            ({ resource_type }) => resource_type === CUSTOM_COLUMN_RESOURCE.PO
          ) || [],
        grn:
          data?.custom_fields.filter(
            ({ resource_type }) => resource_type === CUSTOM_COLUMN_RESOURCE.GRN
          ) || [],
      };

      dispatch(eventDispatch(SET_ALL_CUSTOM_FIELDS, payload));
      if (successCallback) {
        successCallback();
      }
      return payload;
    } catch (error) {
      if (error.errorMsg) {
        toastErrorMessage(error.errorMsg);
      } else {
        toastErrorMessage('Error fetching custom fields for the project');
      }
      dispatch(eventDispatch(PROJECT_API_ERROR, error));
    }
  };
}

export function getProject(projId: string) {
  const apiUrl = parseString(PROJECT_APIS.getProject, projId);
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(PROJECT_API_PENDING));
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(getExperiments({ project_id: data._id, org_id: data.org_id }));
      dispatch(getProjectStages(projId));
      dispatch(getAllCustomFields(projId));
      dispatch(eventDispatch(PROJECT_API_SUCCESS));
      dispatch(eventDispatch(GET_PROJECT_DETAIL, data));
    } catch (error) {
      dispatch(eventDispatch(PROJECT_API_ERROR, error));
    }
  };
}

export function deleteProject(projectId: string, cb?: Function) {
  const apiUrl = parseString(PROJECT_APIS.deleteProject, projectId);
  return async (dispatch: Dispatch<any>) => {
    dispatch(eventDispatch(PROJECT_API_PENDING));
    try {
      await deleteRequest(apiUrl, API_VERSIONS.V1);
      dispatch(track(PROJECT_SEGMENT.PROJECT_DELETE_SUCCESS));
      dispatch(eventDispatch(PROJECT_API_SUCCESS));
      toastSuccessMessage('Successfully deleted Project');
      dispatch(eventDispatch(PROJECT_DELETE_SUCCESS));
      dispatch(eventDispatch(REFRESH_PROJECT_ITEMS, true));
      cb?.();
    } catch (error) {
      dispatch(track(PROJECT_SEGMENT.PROJECT_DELETE_ERROR));
      toastErrorMessage('Error in deleting Project');
    }
  };
}

export function getProjUsers(params: GetProjectUsersParamsProps) {
  const apiUrl = parseString(
    PROJECT_APIS.getProjUsers,
    customStringify(params)
  );
  return async (dispatch: Dispatch) => {
    dispatch(eventDispatch(PROJECT_API_PENDING));
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(PROJECT_API_SUCCESS));
      dispatch(eventDispatch(GET_PROJECT_USERS, data));
    } catch (error) {
      dispatch(eventDispatch(PROJECT_API_ERROR, error));
    }
  };
}

export function updateProjectUsers(
  reqBody: UpdateProjectUsersProps,
  showToast: boolean = true
) {
  return async (dispatch: Dispatch<any>, getState: any) => {
    const projectId = getState().projectreducer.selectedProj.id;
    dispatch(eventDispatch(PROJECT_API_PENDING));

    try {
      const { data } = await postRequest(
        USER_PROJECT_APIS.updateProjectUsers,
        reqBody,
        API_VERSIONS.V1
      );
      dispatch(track(PROJECT_SEGMENT.PROJECT_INVITE_SUCCESS));
      dispatch(eventDispatch(UPDATE_PROJECT_USERS, data));
      dispatch(eventDispatch(PROJECT_API_SUCCESS));
      dispatch(getProjUsers({ project_id: projectId }));
      if (showToast) toastSuccessMessage('Successfully updated project users');
    } catch (error) {
      dispatch(track(PROJECT_SEGMENT.PROJECT_INVITE_ERROR));
      dispatch(eventDispatch(PROJECT_API_ERROR, error));
      toastErrorMessage('Some error while updating project users');
    }
  };
}

export function userProjectUpdate(reqBody: UserProjectUpdateProps) {
  return async (dispatch: Dispatch<any>) => {
    try {
      await putRequest(USER_PROJECT_APIS.userProjectUpdate, reqBody);
      dispatch(eventDispatch(SYNC_MEMBERS_VIEW, true));
    } catch (error) {
      toastErrorMessage('Some error while updating details');
    }
  };
}

export function deleteProjectUsers(params: DeleteProjectUserProps) {
  const apiUrl = parseString(
    USER_PROJECT_APIS.deleteProjectUser,
    customStringify(params)
  );
  return async (dispatch: Dispatch<any>, getState: any) => {
    const projectId = getState().projectreducer.selectedProj.id;
    dispatch(eventDispatch(PROJECT_API_PENDING));

    try {
      const { data } = await deleteRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(DELETE_PROJECT_USERS, data));
      dispatch(eventDispatch(PROJECT_API_SUCCESS));
      dispatch(getProjUsers({ project_id: projectId }));
      dispatch(eventDispatch(SYNC_MEMBERS_VIEW, true));
      toastSuccessMessage('Successfully removed user from this project');
    } catch (error) {
      dispatch(eventDispatch(PROJECT_API_ERROR, error));
      toastErrorMessage('Some error while removing user from project');
    }
  };
}

export function updateFinanceSettings(reqBody: FinanceSettingsProps) {
  return async (dispatch: Dispatch, getState: any) => {
    const projId = getState().projectreducer.selectedProj.id;
    dispatch(eventDispatch(PROJECT_API_PENDING));
    try {
      const { data } = await putRequest(
        PROJECT_APIS.updateFinanceSettings,
        {
          ...reqBody,
          projId,
        },
        API_VERSIONS.V1
      );
      dispatch(eventDispatch(UPDATE_FINANCE_SETTINGS, data));
      dispatch(eventDispatch(PROJECT_API_SUCCESS));
      toastSuccessMessage('Successfully updated finance settings');
    } catch (error) {
      dispatch(eventDispatch(PROJECT_API_ERROR, error));
      toastErrorMessage('Some error while updating finance settings');
    }
  };
}

export function updatePurchaseSettings(
  reqBody: PurchaseSettingsProps,
  showToast = true
) {
  return async (dispatch: Dispatch, getState: any) => {
    const projId = getState().projectreducer.selectedProj.id;
    const apiUrl = parseString(PROJECT_APIS.updatePurchaseSettings, projId);
    dispatch(eventDispatch(PROJECT_API_PENDING));

    try {
      const { data } = await putRequest(apiUrl, reqBody, API_VERSIONS.V1);
      dispatch(eventDispatch(UPDATE_PURCHASE_SETTINGS, data));
      dispatch(eventDispatch(PROJECT_API_SUCCESS));
      if (showToast) {
        toastSuccessMessage('Successfully updated purchase settings');
      }
    } catch (error) {
      dispatch(eventDispatch(PROJECT_API_ERROR, error));
      toastErrorMessage('Some error while updating purchase settings');
    }
  };
}

export function updateDriveSettings(
  reqBody: DriveSettingsProps,
  showToast = true
) {
  return async (dispatch: Dispatch, getState: any) => {
    const projId = getState().projectreducer.selectedProj.id;
    const apiUrl = parseString(PROJECT_APIS.updateDriveSettings, projId);
    dispatch(eventDispatch(PROJECT_API_PENDING));

    try {
      const { data } = await putRequest(apiUrl, reqBody, API_VERSIONS.V1);
      dispatch(eventDispatch(UPDATE_DRIVE_SETTINGS, data));
      dispatch(eventDispatch(PROJECT_API_SUCCESS));
      if (showToast) {
        toastSuccessMessage('Successfully updated drive settings');
      }
    } catch (error) {
      dispatch(eventDispatch(PROJECT_API_ERROR, error));
      toastErrorMessage('Some error while updating drive settings');
    }
  };
}

export function setInitialProjectState() {
  return (dispatch: Dispatch) => {
    dispatch({ type: SET_INITIAL_STATE });
  };
}

export function updateProject(
  reqBody: ProjectProps,
  projectId: string,
  successActionEvent?: string,
  errorActionEvent?: string,
  showToast = true
) {
  const apiUrl = parseString(PROJECT_APIS.updateProjectProfile, projectId);
  return async (dispatch: Dispatch<any>) => {
    try {
      if (
        successActionEvent ===
        ACTION_EVENTS.PROJECT_ACTIONS.CHANGE_STATUS_SUCCESS
      )
        dispatch(
          track(successActionEvent, {
            project_id: reqBody._id,
            new_status: reqBody.project_status,
          })
        );
      await putRequest(apiUrl, reqBody, API_VERSIONS.V1);
      dispatch(eventDispatch(PROJECT_API_SUCCESS));
      dispatch(setRefreshView(SYNC_PROJECT_LIST_VIEW, true));
      if (showToast) {
        toastSuccessMessage('Project updated successfully');
      }
    } catch (error) {
      if (
        errorActionEvent === ACTION_EVENTS.PROJECT_ACTIONS.CHANGE_STATUS_FAILURE
      )
        dispatch(
          track(errorActionEvent, {
            project_id: reqBody._id,
            new_status: reqBody.project_status,
          })
        );
      dispatch(eventDispatch(PROJECT_API_ERROR, error));
      if (showToast) {
        toastErrorMessage('There was some error while updating the project');
      }
    }
  };
}

export function createProject(
  reqBody: ProjectProps,
  successCallback: Function = () => {}
) {
  return async (dispatch: Dispatch<any>) => {
    const toastId = toastLoadingMessage(`Creating ${reqBody.name}`);
    const eventData = {
      new_project_name: reqBody.name,
      start_date: reqBody.start_date,
      end_date: reqBody.end_date,
      site_poc_number: reqBody.purchase_settings?.site_poc_details?.mobile,
      project_poc_number:
        reqBody.purchase_settings?.project_poc_details?.mobile,
      project_plan_type: reqBody.project_plan_type,
    };
    try {
      const { data } = await postRequest<any>(
        PROJECT_APIS.createProject,
        reqBody,
        API_VERSIONS.V2
      );
      successCallback({ project_id: data._id, project_name: data.name });

      // add project poc and site poc as a project member
      const pocUser = [];
      const purchaseSetting = reqBody.purchase_settings;
      if (purchaseSetting?.project_poc_details?._id) {
        const userId = purchaseSetting?.project_poc_details?._id;
        const name = purchaseSetting?.project_poc_details?.name;
        if (data.creator_id !== userId) {
          pocUser.push({
            _id: userId,
            name,
          });
        }
      }
      if (
        !purchaseSetting?.is_project_site_poc_same &&
        purchaseSetting?.site_poc_details?._id
      ) {
        const userId = purchaseSetting?.site_poc_details?._id;
        const name = purchaseSetting?.site_poc_details?.name;
        if (data.creator_id !== userId) {
          pocUser.push({
            _id: userId,
            name,
          });
        }
      }
      if (pocUser.length > 0) {
        const updateData: UpdateProjectUsersProps = {
          project: {
            _id: data._id,
            name: data.name,
          },
          users: pocUser as CreatorDetailsProps[],
        };
        dispatch(updateProjectUsers(updateData, false));
      }

      dispatch(
        track(
          ACTION_EVENTS.PROJECT_ACTIONS.CREATE_NEW_PROJECT_SUCCESS,
          eventData
        )
      );
      toastSuccessMessage(`Successfully Created ${reqBody.name}`, toastId);
      dispatch(track(PROJECT_SEGMENT.PROJECT_CREATE_SUCCESS));
      dispatch(eventDispatch(CREATE_PROJECT, data));
      dispatch(eventDispatch(PROJECT_API_SUCCESS));
      dispatch(setRefreshView(SYNC_PROJECT_LIST_VIEW, true));
      dispatch(eventDispatch(PROJECT_API_SUCCESS));
    } catch (error) {
      dispatch(track(PROJECT_SEGMENT.PROJECT_CREATE_ERROR));
      dispatch(
        track(
          ACTION_EVENTS.PROJECT_ACTIONS.CREATE_NEW_PROJECT_FAILURE,
          eventData
        )
      );
      dispatch(eventDispatch(PROJECT_API_ERROR, error));
      toastErrorMessage(
        error.errorMsg || `Some error occured while creating ${reqBody.name}`,
        toastId
      );
    }
  };
}

export function duplicateProject(
  projectId: string,
  orgId: string,
  reqBody: ProjectDuplicateWithDependencies
) {
  const apiUrl = parseString(
    PROJECT_APIS.duplicateProjectWithDependencies,
    projectId
  );
  const toastId = toastLoadingMessage(`Duplicating ${reqBody.name || ''}`);

  return async (dispatch: Dispatch<any>) => {
    try {
      await postRequest(apiUrl, reqBody, API_VERSIONS.V2);

      dispatch(getProjectList({ org_id: orgId }));
      dispatch(eventDispatch(PROJECT_API_SUCCESS));
      dispatch(setRefreshView(SYNC_PROJECT_LIST_VIEW, true));

      toastSuccessMessage(`Successfully Duplicated ${reqBody.name}`, toastId);
    } catch (error) {
      dispatch(eventDispatch(PROJECT_API_ERROR, error));
      toastErrorMessage('Duplication failed', toastId);
    }
  };
}

export const createNewCustomField = (
  body: CustomFieldConfiguration,
  resourceType: string,
  projectId: string,
  successCallback?: () => void,
  errorCallback?: () => void
) => {
  const apiUrl = parseString(PROJECT_APIS.createNewCF, resourceType);

  return async (dispatch: Dispatch<any>) => {
    try {
      await postRequest(apiUrl, body);
      await dispatch(getAllCustomFields(projectId));
      dispatch(track('custom_column_new_field_click', body));
      if (successCallback) {
        successCallback();
      }
    } catch (error) {
      if (error.errorMsg) {
        toastErrorMessage(error.errorMsg);
      } else {
        toastErrorMessage('Error creating custom column');
      }
      if (errorCallback) {
        errorCallback();
      }
    }
  };
};

export const editCustomField = (
  body: CustomFieldConfiguration,
  field_id: string,
  projectId: string,
  successCallback?: () => void,
  errorCallback?: () => void
) => {
  const apiUrl = parseString(PROJECT_APIS.editCF, field_id);

  return async (dispatch: Dispatch<any>) => {
    try {
      await putRequest(apiUrl, body);
      await dispatch(getAllCustomFields(projectId));
      if (successCallback) {
        successCallback();
      }
    } catch (error) {
      if (error.errorMsg) {
        toastErrorMessage(error.errorMsg);
      } else {
        toastErrorMessage('Error editing custom column');
      }
      if (errorCallback) {
        errorCallback();
      }
    }
  };
};

export const deleteCustomFieldFromConfiguration = (
  field_id: string,
  projectId: string,
  successCallback?: () => void
) => {
  const apiUrl = parseString(PROJECT_APIS.editCF, field_id);

  return async (dispatch: Dispatch<any>) => {
    try {
      await deleteRequest(apiUrl);
      await dispatch(getAllCustomFields(projectId));
      if (successCallback) {
        successCallback();
      }
    } catch (error) {
      if (error.errorMsg) {
        toastErrorMessage(error.errorMsg);
      } else {
        toastErrorMessage('Error deleting custom field');
      }
    }
  };
};

export function updateCustomTaskFields(
  recordId: string,
  fields: any,
  projectId: string,
  successCallback: Function = () => {}
) {
  fields._id = recordId;
  const apiUrl = parseString(PROJECT_APIS.updateCustomTaskFields, recordId);

  return async (dispatch: Dispatch<any>) => {
    try {
      await putRequest(apiUrl, fields, API_VERSIONS.V1);
      successCallback();
    } catch (error) {
      toastErrorMessage(error.errorMsg);
      dispatch(eventDispatch(PROJECT_API_ERROR, error));
    }
    dispatch(getAllCustomFields(projectId));
  };
}

export function createCustomTaskFields(
  body,
  projectId,
  successCallback = () => {}
) {
  const apiUrl = parseString(PROJECT_APIS.updateCustomTaskFields, projectId);
  return async (dispatch: Dispatch<any>) => {
    try {
      await postRequest(apiUrl, body);
      successCallback();
      dispatch(getAllCustomFields(projectId));
    } catch (error) {
      dispatch(eventDispatch(PROJECT_API_ERROR, error));
    }
  };
}

export const getProjectTaskFilters =
  (projectId: string) => async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(PROJECT_APIS.getProjectTaskFilters, projectId);

    try {
      const {
        data: { tags, packages, assigned_users },
      } = await getRequest(apiUrl, API_VERSIONS.V1);

      dispatch(eventDispatch(GET_ALL_TAGS_TASK, tags));
      dispatch(eventDispatch(GET_PACKAGES_LIST, { data: packages }));
      dispatch(eventDispatch(GET_ASSIGNED_USERS, assigned_users));
    } catch (err) {
      toastErrorMessage('Error fetching filter data');
    }
  };

export const getAdditionalTaskFilters =
  (projectId: string) => async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(
      PROJECT_APIS.getAdditionalTaskFilters,
      projectId
    );

    try {
      const data = await getRequest(apiUrl, API_VERSIONS.V1);
      dispatch(eventDispatch(GET_CF_DROPDOWN_FILTER, data.data));
    } catch (err) {
      toastErrorMessage('Error fetching filter data');
    }
  };

export const assignTaskToProjectStage =
  (
    projectId: string,
    reqBody: { stage_id: string; task_id: string; order: number }
  ) =>
  async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(
      PROJECT_APIS.assignTaskToProjectStage,
      projectId
    );

    try {
      await putRequest(apiUrl, reqBody);
      dispatch(getProjectStages(projectId));
    } catch (err) {
      dispatch(eventDispatch(PROJECT_API_ERROR, err));
    }
  };

export function sendProjectTeamInvites(
  reqBody: InviteOrgTeamParams,
  projectId: string
) {
  const apiUrl = parseString(PROJECT_APIS.projectTeamInvites, projectId);
  return async (dispatch: Dispatch<any>) => {
    const toastId = toastLoadingMessage('Inviting Members');
    try {
      await postRequest(apiUrl, reqBody, API_VERSIONS.V1);
      dispatch(eventDispatch(SYNC_MEMBERS_VIEW, true));
      dispatch(
        track(INVITE_CONSTANT.PROJECT_INVITE_MEMBER_SUCCESS, {
          project_id: projectId,
          ...reqBody,
        })
      );
      toastSuccessMessage('Invites Sent Successfully', toastId);
    } catch {
      toastErrorMessage('Cannot send Invites', toastId);
    }
  };
}

export function createNewProjectAndSendInvites(
  projectBody: ProjectProps,
  reqBody: InviteOrgTeamParams
) {
  return async (dispatch: Dispatch<any>) => {
    try {
      const { data: project } = await postRequest(
        PROJECT_APIS.createProject,
        projectBody,
        API_VERSIONS.V2
      );

      dispatch(sendProjectTeamInvites(reqBody, project._id));
    } catch (err) {
      toastErrorMessage(err.errorMsg || 'Cannot create project');
    }
  };
}

export function updatePhoneGallery(
  projectId: string,
  reqBody: RestrictPhoneGalleryProps
) {
  return async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(
      PROJECT_APIS.updateTaskSettingGalleryEnable,
      projectId
    );
    try {
      const { data } = await putRequest(apiUrl, reqBody);

      dispatch(eventDispatch(UPDATE_CONFIG_TASK_SETTING, data));
    } catch (error) {
      toastErrorMessage('Some error while updating Gallery Options');
    }
  };
}

export function getProjectConfigDetails(
  projectId: string,
  query: GetConfigDetailsProps
) {
  return async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(
      PROJECT_APIS.getProjectConfig,
      projectId,
      customStringify(query)
    );
    try {
      const { data } = await getRequest(apiUrl, API_VERSIONS.V2);

      dispatch(eventDispatch(SET_CONFIG_TASK_SETTING, data));
    } catch (error) {
      toastErrorMessage('Some error while getting Gallery Options');
    }
  };
}

export function getProjectHolidays(projectId, query) {
  return async (dispatch: Dispatch<any>) => {
    const apiUrl = parseString(
      PROJECT_APIS.getProjectHolidays,
      projectId,
      customStringify(query)
    );
    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(SET_HOLIDAYS, data));
    } catch (error) {
      toastErrorMessage('Error while getting holidays');
    }
  };
}

export function bulkUpdateProjectHolidays(projectId, body, cb) {
  return async () => {
    const apiUrl = parseString(PROJECT_APIS.getProjectHolidays, projectId);

    try {
      await putRequest(apiUrl, body);
      cb?.();
      toastSuccessMessage('Holidays updated successfully');
    } catch (error) {
      toastErrorMessage('Error while updating holidays');
    }
  };
}

export const setViewSelectedProject = projectId => {
  return dispatch => {
    dispatch(eventDispatch(SET_SELECTED_PROJECT_VIEW_ID, projectId));
  };
};

export const setSelectedTab = (tabId: number) => {
  return dispatch => {
    dispatch(eventDispatch(SET_SELECTED_TAB_VIEW_ID, tabId));
  };
};

export const setClearProjectFilter = (isClear: boolean) => {
  return dispatch => {
    dispatch(eventDispatch(SET_CLEAR_PROJECT_FILTER, isClear));
  };
};

export const getUnviewedReports = (reqBody: { org_id: string; project_id }) => {
  return async dispatch => {
    const apiUrl = parseString(
      PROJECT_APIS.getUnviewedReportCount,
      customStringify(reqBody)
    );

    try {
      const { data } = await getRequest(apiUrl);
      dispatch(eventDispatch(GET_COUNT_ASYNC_REPORT, data));
    } catch (error) {
      toastErrorMessage('Error while fetching count');
    }
  };
};

export const getDynamicFilter = () => async (dispatch: Dispatch) => {
  const response = await postRequest(PROJECT_APIS.getDynamicFilters, {
    filter_hashes: [],
  });
  const dynamicFilters: DynamicFilter[] = response.data;
  if (dynamicFilters) {
    dispatch({
      type: SET_DYNAMIC_FILTERS,
      payload: dynamicFilters,
    });
  }
};
